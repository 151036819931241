import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage, useIntl} from "react-intl";
import TextBoxField from "../../../components/common/TextBox/TextBoxField";
import LanguageSwitcher from "../../../components/profile/LanguageSwitcher/LanguageSwitcher";
import {requireFieldValidator} from '../../../helpers/Validators';
import {uselang} from '../hooks/useLang';

const PersonalInfo = ({secondaryLang}) => {
    const [primaryLang, langs] = uselang();
    const langLength = langs.length;
    const intl = useIntl();

    const renderFields = (langCode) => {
        return (
            <React.Fragment>
                <TextBoxField
                    validate={requireFieldValidator}
                    placeholder={intl.formatMessage({id: `profile.name.${langCode}`})}
                    name={`fields.${langCode}.name`}/>
                <TextBoxField
                    placeholder={intl.formatMessage({id: `profile.middlename.${langCode}`})}
                    name={`fields.${langCode}.middlename`}/>
                <TextBoxField
                    validate={requireFieldValidator}
                    placeholder={intl.formatMessage({id: `profile.surname.${langCode}`})}
                    name={`fields.${langCode}.surname`}/>
            </React.Fragment>
        );
    };

    return (
        <fieldset className="Health-fieldset">
            <legend className="Health-fieldset__legend"><FormattedMessage id="profile.h2_personal_info"/></legend>
            <div className="row">
                <div className={langLength > 1 ? "col-6" : "col-12"}>
                    <LanguageSwitcher primary/>
                    {renderFields(primaryLang)}
                </div>
                {
                    langLength > 1 && secondaryLang &&
                    <div className="col-6">
                        <LanguageSwitcher currentLangCode={secondaryLang} />
                        {renderFields(secondaryLang)}
                    </div>
                }
            </div>
        </fieldset>
    );
};

PersonalInfo.propTypes = {
    secondaryLang: PropTypes.string,
};

export default PersonalInfo;
