import React, {createRef, useCallback, useContext, useState} from 'react';
import {Form} from 'react-final-form';
import {useSelector} from 'react-redux';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import {useIntl} from "react-intl";

import './RequestForm.scss';
import Separator from '../../../../components/common/Separator/Separator';
import {focusWatcher} from '../../../../decorators/focusWatcher';
import {maxLengthValidator, requireValidator} from '../../../../helpers/Validators';
import {useResetWindowScroll} from '../../../../hooks/useResetWindowScroll';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import {RequestNavigationPanel} from '../RequestNavigationPanel/RequestNavigationPanel';
import {RequestPageHeader} from '../RequestPageHeader/RequestPageHeader';
import {RequestSelectedConsultant} from '../RequestSelectedConsultant/RequestSelectedConsultant';
import {SidePanel} from '../../../../components/SidePanel/SidePanel';
import {RequestFormAutoSaver} from './components/RequestFormAutoSaver/RequestFormAutoSaver';
import {RequestFormView, MAX_REASON_LENGTH} from './components/RequestFormView/RequestFormView';
import {RequestInfoPanel} from './components/RequestInfoPanel/RequestInfoPanel';
import {addNewDocuments} from './mutators/add-new-documents.mutator';
import {addMedicalRecords} from "./mutators/add-medical-records.mutator";

export const RequestForm = () => {
    const isRequestDirty = useSelector(requestSelectors.isRequestDirty);
    const data = useSelector(requestSelectors.getRequestFormData);
    const requestManager = useContext(RequestManagerContext);
    const [offsetY, setOffsetY] = useState(null);
    const [targetHeight, setTargetHeight] = useState(null);
    const [activeField, setActiveField] = useState(null);
    const sidePanelRef = createRef();
    const intl = useIntl();
    const isEditSpecialities = useSelector(requestSelectors.isRequestFormEditSpecialities);

    useResetWindowScroll();

    const handleNextPage = useCallback(() => {
        requestManager.goNextPage();
    }, [requestManager]);

    const handlePrevPage = useCallback(() => {
        requestManager.goPrevPage();
    }, [requestManager]);

    const handleSaveAndExit = useCallback(() => {
        requestManager.saveAndExit();
    }, [requestManager]);

    const handleValidate = useCallback((values) => {
        let errors = {};

        if (requireValidator(_.get(values, 'reason'))) {
            _.set(errors, 'reason', true);
        } else if (maxLengthValidator(_.get(values, 'reason'), MAX_REASON_LENGTH)) {
            _.set(errors, 'reason', true);
        }
        if (requireValidator(_.get(values, 'description'))) {
            _.set(errors, 'description', true);
        }

        const errorFiles = (values.files || []).reduce((errors, {file, url, label}) => {
            const error = {};
            let invalid = false;

            if (!file && !url) {
                error.file = true;
                invalid = true;
            }
            if (!label) {
                error.label = true;
                invalid = true;
            }
            errors.push(invalid ? error : undefined);

            return errors;
        }, []);

        if (errorFiles.some(r => r)) {
            errors.files = errorFiles;
        }

        return Object.keys(errors).length ? errors : undefined;
    }, []);

    const handleFocusWatcher = useCallback((event, fieldName) => {
        const targetRect = event.target.getBoundingClientRect();
        setOffsetY(targetRect.y + window.scrollY);
        setActiveField(fieldName);
        setTargetHeight(targetRect.height);
    }, []);

    return (
        <React.Fragment>
            <div className="Request-page__main Request-form-page">
                <RequestPageHeader title={intl.formatMessage({id: 'request.page_titles.request_form'})}/>
                <div className="Request-page__body">
                    <RequestSelectedConsultant viewHelpMeHint/>
                    <Separator/>
                    <div className="Flex-column">
                        <Form
                            decorators={[
                                focusWatcher(handleFocusWatcher),
                            ]}
                            mutators={{
                                ...arrayMutators,
                                addNewDocuments,
                                addMedicalRecords,
                            }}
                            initialValues={data}
                            onSubmit={handleValidate}
                            validate={handleValidate}
                            validateOnBlur
                        >
                            {
                                (form) => (
                                    <React.Fragment>
                                        <RequestFormView
                                            isEditSpecialities={isEditSpecialities}
                                            onFocus={form.form.onFocus}/>
                                        <RequestFormAutoSaver/>
                                        <RequestNavigationPanel
                                            btnNextText={intl.formatMessage({id: 'request.buttons.next'})}
                                            canSaveAndExit={isRequestDirty}
                                            canPrev
                                            canNext={!form.invalid}
                                            onNext={handleNextPage}
                                            onPrev={handlePrevPage}
                                            onSaveAndExit={handleSaveAndExit}
                                            className="mt-4 mb-5"/>
                                    </React.Fragment>
                                )
                            }
                        </Form>
                    </div>
                </div>
            </div>
            <SidePanel ref={sidePanelRef}>
                <RequestInfoPanel
                    targetHeight={targetHeight}
                    activeField={activeField}
                    y={offsetY}
                    containerRef={sidePanelRef}/>
            </SidePanel>
        </React.Fragment>
    )
};

