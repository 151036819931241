import {fetchTranslation} from "../translations/actions";
import pageNames from "../../constants/PageNames";
import * as api from '../../api';
import {toRequestsQuickFilterStatusCode} from "../../constants/RequestsQuickFilterStatuses";
import {clearDataParams} from "../../helpers/Common";
import * as commonActions from '../common/actions';
import * as dateHelpers from './../../helpers/Date';

export const SET_REQUESTS_TABLE_PAGE_LOADING = 'SET_REQUESTS_TABLE_PAGE_LOADING';
export const SET_REQUESTS_TABLE_PAGE_CONSULTS = 'SET_REQUESTS_TABLE_PAGE_CONSULTS';

export const initRequestsTablePage = () => async (dispatch, getState) => {
    dispatch(setRequestsTablePageLoading(true));

    await Promise.all([
        dispatch(fetchTranslation({
            pageName: pageNames.Requests
        })),
        dispatch(commonActions.fetchClinics()),
        dispatch(commonActions.fetchDoctors()),
        dispatch(commonActions.fetchCountries({request_clinic: true})),
    ]);
    dispatch(setRequestsTablePageLoading(false));
};

export const setRequestsTablePageLoading = (loading) => {
    return {
        type: SET_REQUESTS_TABLE_PAGE_LOADING,
        payload: loading
    }
};

export const getRequests = (
    {
        query, currentPage, status, clinics = [], countries = [],
        sortBy, orderBy, fromDate, toDate, doctor, coordinator,
        pageSize = 10,
    },
) => async (dispath) => {
    const params = clearDataParams({
        full: true,
        s: query,
        page: currentPage - 1,
        status: status && toRequestsQuickFilterStatusCode(status),
        cou: countries.map(c => c.id).join(','),
        c: clinics.map(c => c.id).join(','),
        sortBy,
        orderBy,
        size: pageSize,
        fromDate: fromDate && dateHelpers.toServerFormat(fromDate),
        toDate: toDate && dateHelpers.toServerFormat(toDate),
        doctor,
        coor: coordinator,
    });

    try {
        const res = await api.loadConsults(params);

        dispath({
            type: SET_REQUESTS_TABLE_PAGE_CONSULTS,
            payload: res.data,
        })
    } catch (err) {
    }
};
