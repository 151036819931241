import {setLoading} from '../loading/actions';
import pageNames from '../../constants/PageNames.js';
import {fetchTranslation} from "../translations/actions";

export const initGeneralPage = () => async (dispatch) => {
    dispatch(setLoading(true));
    await dispatch(fetchTranslation({
        pageName: pageNames.General
    }));
    dispatch(setLoading(false));
};
