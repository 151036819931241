import React, {useEffect, useState} from 'react';
import Joi from "@hapi/joi";
import {set} from "lodash";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom';

import {isEmptyObject} from '../../helpers/Common';
import {RegistrationResult} from "../../components/registration/RegistrationResult/RegistrationResult";
import {RegistrationPageLayout} from "../../components/registration/RegistrationPageLayout/RegistrationPageLayout";
import {setLoading} from '../../redux/loading/actions';
import {loadingSelector} from "../../redux/loading/selectors";
import {initTranslatedRecoveryPassPage, recoveryPassword} from "../../redux/recoveryPassPage/actions";
import {isPageLoadedSelector} from "../../redux/translations/selectors";
import PageNames from "../../constants/PageNames";
import RecoveryPasswordForm from "./components/RecoveryPasswordForm";
import {PASSWORD_REG_EXP} from "../../constants/RegExps";

const buildSchema = ({errors}) => Joi.object({
    pass: Joi.string().messages({
        'any.required': errors.pass_help,
        'string.pattern.base': errors.pass_help,
    }).trim().regex(PASSWORD_REG_EXP).required(),
    confirm_pass: Joi.string().messages({
        'any.required': errors.confirm_pass_error,
        'any.only': errors.confirm_pass_error,
    }).trim().valid(Joi.ref('pass')).required(),
}).unknown();

export const RecoveryPasswordPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isLoading = useSelector(loadingSelector);
    const isTranslationLoaded = useSelector(state => isPageLoadedSelector(state, PageNames.RecoveryPassword));
    const params = useParams();

    useEffect(() => {
        dispatch(initTranslatedRecoveryPassPage());
    }, [dispatch]);

    const [status, setStatus] = useState('not_submitted');

    const onSubmit = async (values) => {
        const errors = {};
        const schema = buildSchema({
            errors: {
                pass_help: intl.formatMessage({id: "recoveryPass.pass_help"}),
                confirm_pass_error: intl.formatMessage({id: "recoveryPass.confirm_pass_error"}),
            }
        });
        const {error, value} = schema.validate(values, {
            abortEarly: false,
        });
        if (error) {
            error.details.reduce((obj, e) => {
                set(obj, e.path, e.message);
                return obj;
            }, errors);
        }
        if (!isEmptyObject(errors)) {
            return errors;
        }
        dispatch(setLoading(true));
        try {
            const response = await dispatch(recoveryPassword(params.token, value.pass));
            setStatus(response.data);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const renderBody = () => {
        if (status === 'not_submitted') {
            return <RecoveryPasswordForm onSubmit={onSubmit}/>
        } else {
            return <RegistrationResult
                status={status.status}
                title={status.status ? "recoveryPass.ok_head" : "recoveryPass.error_head"}
                description="recoveryPass.ok_text"
                btnGotoMain="recoveryPass.but_back_main"
                redirectTo="/login"
            />
        }
    };

    return (
        <RegistrationPageLayout
            className="Recovery-password"
            isLoading={isLoading}
            isTranslationLoaded={isTranslationLoaded}
            title="recoveryPass.h1"
            copyright="recoveryPass.copyright"
            linksConf="recoveryPass.links_conf"
        >
            {renderBody()}
        </RegistrationPageLayout>
    );
};
