import React, {useMemo} from 'react';
import cn from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faFileImage, faFilePdf} from '@fortawesome/free-regular-svg-icons';
import type { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
    className?: string;
    ext: string;
    color?: string;
    size?: SizeProp;
    onClick?: (event: React.MouseEvent<unknown>) => void;
}

const FileIcon = ({onClick, className, ext = 'file', color, size = '2x'}: Props) => {
    const fileIcon = useMemo(() => {
        if (/pdf$/.test(ext)) {
            return faFilePdf;
        }
        if (/(png|jpeg|jpg|tiff|bmp)$/.test(ext)) {
            return faFileImage;
        }
        return faFile;
    }, [ext]);

    return (
        <FontAwesomeIcon
            onClick={onClick}
            className={cn('Health-file-icon', className)}
            color={color}
            size={size}
            icon={fileIcon} />
    )
}


FileIcon.displayName = 'HealthFileIcon';

export default FileIcon;
