import React from 'react';
import Button from '../../../../../components/common/Button/Button';

export const RequestNavigationButton = ({children, disabled, variant, ...props}) => {
    return (
        <Button
            disabled={disabled}
            variant={disabled ? 'gray' : variant}
            {...props}>
            {children}
        </Button>
    )
};

RequestNavigationButton.propTypes = {
    ...Button.propTypes,
};
