import React, {useCallback, useContext} from "react";
import cn from "classnames";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

import './RequestConsultantInfo.scss';
import {getConsultantInfoSelector} from "../../../../../../redux/requestPage/request/selectors";
import {RequestManagerContext} from "../../../RequestManager/RequestManagerContext";
import ViewClinicLink from "../../../../../../components/Links/ViewClinicLink";
import LocationStateFroms from "../../../../../../constants/LocationStateFroms";
import ViewDoctorLink from "../../../../../../components/Links/ViewDoctorLink";

const RequestConsultantInfo = ({className}) => {
    const info = useSelector(getConsultantInfoSelector);
    const manager = useContext(RequestManagerContext);

    const handleViewDoctor = useCallback((event) => {
        event.preventDefault();
        manager.viewDoctor(info.doctor.id, true)
    }, [info.doctor, manager]);

    const handleViewClinic = useCallback((event) => {
        event.preventDefault();
        manager.viewClinic(info.clinic.id, true)
    }, [info.clinic, manager]);

    return (
        <div className={cn('Request-page__consultant-info', className)}>
            <div className="inline-block mr-2">
                <span>
                    <FormattedMessage id={"request.consultant_info.country"}/>:
                </span>
                <span className="ml-1 text-bold Request-page__consultant-info_value">
                    {
                        info.countryName ?
                            info.countryName :
                            (
                                <span className="ruby-color">
                                    <FormattedMessage id={"request.consultant_info.not_selected"}/>
                                </span>
                            )
                    }
                </span>
            </div>
            <div className="inline-block mr-2">
                    <span>
                        <FormattedMessage id={"request.consultant_info.clinic"}/>:
                    </span>
                <span className="ml-1 text-bold Request-page__consultant-info_value">
                        {
                            info.clinic ?
                                (
                                    <ViewClinicLink
                                        onClick={handleViewClinic}
                                        tabIndex={-1}
                                        from={LocationStateFroms.request}
                                        id={info.clinic.id}>
                                        {info.clinic.name}
                                    </ViewClinicLink>
                                ) :
                                (
                                    <span className="ruby-color">
                                        <FormattedMessage id={"request.consultant_info.not_selected"}/>
                                    </span>
                                )
                        }
                    </span>
            </div>
            <div className="inline-block mr-2">
                    <span>
                        <FormattedMessage id={"request.consultant_info.doctor"}/>:
                    </span>
                <span className="ml-1 text-bold Request-page__consultant-info_value">
                        {
                            info.doctor ?
                                (
                                    <ViewDoctorLink
                                        onClick={handleViewDoctor}
                                        tabIndex={-1}
                                        from={LocationStateFroms.request}
                                        id={info.doctor.id}>
                                        {info.doctor.name}
                                    </ViewDoctorLink>
                                ) :
                                (
                                    <span className="ruby-color">
                                        <FormattedMessage id={"request.consultant_info.not_selected"}/>
                                    </span>
                                )
                        }
                    </span>
            </div>
        </div>
    )
}

RequestConsultantInfo.displayName = 'RequestConsultantInfo';

export default RequestConsultantInfo;
