import pageNames from '../../constants/PageNames.js';
import {fetchTranslation} from "../translations/actions";
import * as commonActions from "../common/actions";

export const SET_ACCOUNT_PAGE_LOADING = 'SET_ACCOUNT_PAGE_LOADING';

export const initAccountPage = () => async (dispatch) => {
    dispatch(setAccountPageLoading(true));
    await Promise.all([
        dispatch(fetchTranslation({
            pageName: pageNames.Account
        })),
        dispatch(commonActions.fetchLanguages()),
        dispatch(commonActions.fetchGenders()),
    ]);
    dispatch(setAccountPageLoading(false));
};

export const setAccountPageLoading = (loading) => {
    return {
        type: SET_ACCOUNT_PAGE_LOADING,
        payload: loading
    }
};
