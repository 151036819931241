import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './TextEllipsis.scss';

const TextEllipsis = React.memo(
    ({text = '', length, ellipsisChars, showMoreText, hideMoreText, className}) => {
        const [displayText, setDisplayText] = useState('');
        const [isTruncated, setIsTruncated] = useState(true);
        const [needTruncate, setNeedTruncate] = useState(true);

        useEffect(() => {
            if (text && text.length > length) {
                setNeedTruncate(true);
            } else {
                setNeedTruncate(false);
            }
        }, [length, text]);

        useEffect(() => {
            if (isTruncated && needTruncate && text) {
                if (Math.floor(text.length / length) >= 3) {
                    setDisplayText(`${text.slice(0, Math.floor(text.length * 0.25))}${ellipsisChars}`);
                } else {
                    setDisplayText(`${text.slice(0, length)}${ellipsisChars}`);
                }
            } else {
                setDisplayText(text);
            }
        }, [text, length, isTruncated, ellipsisChars, needTruncate]);

        const toggleTruncate = useCallback(() => {
            needTruncate && setIsTruncated(!isTruncated);
        }, [isTruncated, needTruncate]);

        return (
            <div className={cn('Health-text-ellipsis', className)}>
                {displayText}
                {
                    needTruncate &&
                    <div>
                        <span className="Health-text-ellipsis__show-more"
                              onClick={toggleTruncate}>{isTruncated ? showMoreText : hideMoreText}</span>
                    </div>
                }
            </div>
        );
    }
);

TextEllipsis.propTypes = {
    ellipsisChars: PropTypes.string,
    text: PropTypes.string,
    length: PropTypes.number,
    showMoreText: PropTypes.string,
    hideMoreText: PropTypes.string,
};
TextEllipsis.defaultProps = {
    ellipsisChars: '...',
    length: 500,
};
TextEllipsis.displayName = 'HealthTextEllipsis';

export default TextEllipsis;
