import React, {useCallback} from 'react';
import {faUserMd} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {FormattedMessage} from "react-intl";

import './ConsultationConsultant.scss';
import {useSelector} from 'react-redux';
import ViewClinicLink from '../../../../components/Links/ViewClinicLink';
import ViewDoctorLink from '../../../../components/Links/ViewDoctorLink';
import Photo from '../../../../components/Photo/Photo';
import PlaceholderComponent from '../../../../components/PlaceholderComponent/PlaceholderComponent';
import LocationStateFroms from '../../../../constants/LocationStateFroms';
import * as consultationSelectors from '../../../../redux/consultationPage/consultation/selectors';

const ConsultationConsultant = () => {
    const {doctor} = useSelector(consultationSelectors.getConsultationData);

    const renderAvatar = useCallback(() => {
        if (!doctor) {
            return (
                <Photo
                    avatar
                    size="lg"
                    fallbackIcon={faUserMd}
                    fallbackIconSize={'4x'}
                    imageClassName="img-fluid figure__img" src={''}/>
            )
        }
        return (
            <ViewDoctorLink id={_.get(doctor, 'id')} from={LocationStateFroms.requests}>
                <Photo
                    avatar
                    size="lg"
                    fallbackIcon={faUserMd}
                    fallbackIconSize={'4x'}
                    imageClassName="img-fluid figure__img" src={_.get(doctor, 'url')}/>
            </ViewDoctorLink>
        )
    }, [doctor]);

    return (
        <div className="Consultation-page__consultant">
            <div className="Consultation-page__consultant-header">
            <span className="Consultation-page__consultant-title">
                <FormattedMessage id={"request.consultation_consultant"}/>
            </span>
            </div>
            <div className="Consultation-page__consultant-body">
                <div className="Consultation-page__consultant-avatar">
                    {renderAvatar()}
                </div>
                <div className="Consultation-page__consultant-info">
                    <div className="Consultation-page__consultant-doctor-name">
                        <PlaceholderComponent condition={!!doctor}>
                            <ViewDoctorLink id={_.get(doctor, 'id')} from={LocationStateFroms.requests}>
                                {_.get(doctor, 'name')}
                            </ViewDoctorLink>
                        </PlaceholderComponent>
                    </div>
                    <div className="Consultation-page__consultant-doctor-specs"
                         title={_.get(doctor, 'mainSpecialities', []).join(', ')}>
                        <PlaceholderComponent condition={!!doctor}>
                            {_.get(doctor, 'mainSpecialities', []).join(', ')}
                        </PlaceholderComponent>
                    </div>
                    <div className="Consultation-page__consultant-doctor-position">
                        <PlaceholderComponent condition={!!doctor}>
                            {_.get(doctor, 'position')}
                        </PlaceholderComponent>
                    </div>
                    <div className="Consultation-page__consultant-clinic">
                        <PlaceholderComponent condition={!!doctor}>
                            <ViewClinicLink id={_.get(doctor, ['clinic', 'id'])} from={LocationStateFroms.requests}>
                                {_.get(doctor, ['clinic', 'name'])}
                            </ViewClinicLink>, {_.get(doctor, 'city')}, {_.get(doctor, ['country', 'name'])}
                        </PlaceholderComponent>
                    </div>
                </div>
            </div>
        </div>
    )
};

ConsultationConsultant.propTypes = {};

export default ConsultationConsultant;
