import {set} from "lodash";

import pageNames from '../../constants/PageNames.js';
import {fetchTranslation} from "../translations/actions";
import * as api from "../../api";
import * as commonActions from '../common/actions';
import * as userActions from "../user/actions";
import {transformPhotoData} from "../../helpers/Common";
import {toServerFormat} from "../../helpers/Date";

export const SET_PROFILE_PAGE_LOADING = 'SET_PROFILE_PAGE_LOADING';
export const SET_PROFILE_PAGE_MY_PROFILE = 'SET_PROFILE_PAGE_MY_PROFILE';

export const initProfilePage = () => async (dispatch, getState) => {
    dispatch(setProfilePageLoading(true));
    await Promise.all([
        dispatch(fetchTranslation({
            pageName: pageNames.Profile
        })),
        dispatch(fetchProfilePageMyProfile()),
        dispatch(commonActions.fetchCountries()),
        dispatch(commonActions.fetchTimezones()),
        dispatch(commonActions.fetchCommunicationLanguages()),
    ]);
    dispatch(setProfilePageLoading(false));
};

export const setProfilePageLoading = (loading) => {
    return {
        type: SET_PROFILE_PAGE_LOADING,
        payload: loading
    }
};

export const fetchProfilePageMyProfile = () => async (dispatch) => {
    try {
        const res = await api.loadUserInfo({full: true});

        dispatch({
            type: SET_PROFILE_PAGE_MY_PROFILE,
            payload: res.data
        });
    } catch (err) {
    }
};

export const saveMyProfile = (formData) => async (dispatch, getState) => {
    // boxing fields
    let profile = Object.keys(formData.fields).reduce((acc, langCode) => {
        const field = formData.fields[langCode];
        Object.keys(field).forEach((key) => {
            set(acc, [key, langCode], field[key]);
        });
        return acc;
    }, {});

    profile.lang = formData.lang.map(code => ({
        code,
        main: code === formData.primaryLang ? 1 : 0,
    }));
    profile.lang_com = formData.lang_com.map(l => l.code);
    profile.sync_lang = !!formData.sync_lang;
    profile.country = formData.country;
    profile.birth = toServerFormat(formData.birth);
    profile.gender = formData.gender;
    profile.gmt = formData.gmt;
    profile.need_cure = formData.need_cure;
    profile = {...profile, ...transformPhotoData(formData.photo)};

    let res;
    res = await api.updateUser({profile});

    if (res.data.status) {
        dispatch(fetchProfilePageMyProfile());
        dispatch(userActions.fetchUserInfo());
    }

    return res.data;
};
