import {ROLES} from "./Roles";

const FileTypeList = {
    Clinic: 'c',
    Doctor: 'd',
    Patient: 'p',
    Coordinator: 'cor', // without type
    Medical: 'med' // only for request and consultation type list
};

export const MedicalFileTypeEnum = {
    Analyze: 8, // анализ,
    Snapshot: 9, // снимок
    Image: 11, // изображение
    Conclusion: 77, // медицинское заключение
    Other: 99,
};

export const UserRoleToFileType = {
    [ROLES.PARTNER]: FileTypeList.Coordinator,
    [ROLES.DOCTOR]: FileTypeList.Doctor,
    [ROLES.PATIENT]: FileTypeList.Patient,
};

export default FileTypeList;
