import {fetchTranslation} from "../translations/actions";
import pageNames from "../../constants/PageNames";
import * as api from '../../api';
import {currentLanguageSelector} from "../lang/selectors";

export const SET_VIEW_DOCTOR_LOADING = 'SET_VIEW_DOCTOR_LOADING';
export const CLEAR_VIEW_DOCTOR_DATA = 'CLEAR_VIEW_DOCTOR_DATA';
export const SET_VIEW_DOCTOR_ERROR = 'SET_VIEW_DOCTOR_ERROR';
export const SET_VIEW_DOCTOR_DATA = 'SET_VIEW_DOCTOR_DATA';

export const initViewDoctor = (id) => async (dispatch, getState) => {
    dispatch({type: CLEAR_VIEW_DOCTOR_DATA});
    dispatch(setViewDoctorLoading(true));
    await Promise.all([
        dispatch(fetchTranslation({
            pageName: pageNames.ViewDoctor
        })),
        dispatch(loadDoctorInfo(id)),
    ]);
    dispatch(setViewDoctorLoading(false));
};

export const setViewDoctorLoading = (loading) => {
    return {
        type: SET_VIEW_DOCTOR_LOADING,
        payload: loading
    }
};

export const loadDoctorInfo = (id) => async (dispatch, getState) => {
    const lang = currentLanguageSelector(getState());
    try {
        const res = await api.loadDoctorInfo({id, lang});
        dispatch({
            type: SET_VIEW_DOCTOR_DATA,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: SET_VIEW_DOCTOR_ERROR,
            payload: err.data
        })
    }
};
