import React, {useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from "react-intl";

import './RequestCheckout.scss';
import Separator from '../../../../components/common/Separator/Separator';
import {InfoPanel} from '../../../../components/InfoPanel/InfoPanel';
import {useResetWindowScroll} from '../../../../hooks/useResetWindowScroll';
import {RequestInfo} from '../../../../components/request/RequestInfo/RequestInfo';
import {useRequestCurrentLangCommunication} from '../../hooks/useRequestCurrentLangCommunication';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import {RequestNavigationPanel} from '../RequestNavigationPanel/RequestNavigationPanel';
import {RequestPageHeader} from '../RequestPageHeader/RequestPageHeader';
import {RequestSelectedConsultant} from '../RequestSelectedConsultant/RequestSelectedConsultant';
import {SidePanel} from '../../../../components/SidePanel/SidePanel';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';
import * as managerActions from '../../../../redux/requestPage/manager/actions';
import * as notifierActions from "../../../../redux/notifier/actions";

export const RequestCheckout = () => {
    const requestManager = useContext(RequestManagerContext);
    const requestData = useSelector(requestSelectors.getRequestData);
    const dispatch = useDispatch();
    const isRequestDirty = useSelector(requestSelectors.isRequestDirty);
    const currentLang = useRequestCurrentLangCommunication();
    const intl = useIntl();

    const handleNextPage = useCallback(async () => {
        try {
            await dispatch(managerActions.sendRequest());
        }catch(error){
            dispatch(notifierActions.notifyError(
                intl.formatMessage({id: "request.notifications.error_send"})
            ));
        }
    }, [intl, dispatch]);

    const handlePrevPage = useCallback(() => {
        requestManager.goPrevPage();
    }, [requestManager]);

    const handleSaveAndExit = useCallback(() => {
        requestManager.saveAndExit();
    }, [requestManager]);

    useResetWindowScroll();

    return (
        <React.Fragment>
            <div className="Request-page__main Request-checkout-page">
                <RequestPageHeader />
                <div className="Request-page__body">
                    <RequestSelectedConsultant viewHelpMeHint />
                    <Separator />
                    <RequestInfo data={requestData} currentLang={currentLang}/>
                    <RequestNavigationPanel
                        btnNextText={intl.formatMessage({id: 'request.buttons.send'})}
                        canPrev
                        canNext
                        canSaveAndExit={isRequestDirty}
                        onNext={handleNextPage}
                        onPrev={handlePrevPage}
                        onSaveAndExit={handleSaveAndExit}
                        className="mt-4 mb-5" />
                </div>
            </div>
            <SidePanel className="Request-checkout-page__side-panel">
                <InfoPanel
                    title={intl.formatMessage({id: 'request.checkout.panel_title'})}
                    message={intl.formatMessage({id: 'request.checkout.panel_message'})}
                />
            </SidePanel>
        </React.Fragment>
    )
};
