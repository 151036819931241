import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Content from "./Content";

class Overlay extends React.Component {
    static propTypes = {
        targetRef: PropTypes.object.isRequired,
        containerRef: PropTypes.object,
        contentRef: PropTypes.object,
        arrow: PropTypes.bool,
        placement: PropTypes.oneOf([
            "top-start",
            "top-center",
            "top-end",
            "left-start",
            "left-center",
            "left-end",
            "right-start",
            "right-center",
            "right-end",
            "bottom-start",
            "bottom-center",
            "bottom-end", "auto"
        ]),
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        open: PropTypes.bool,
        autoClose: PropTypes.bool,
        offset: PropTypes.shape({
            top: PropTypes.number,
            left: PropTypes.number
        }),
        fitContainer: PropTypes.bool,
    };

    static defaultProps = {
        open: false,
        containerRef: {
            current: document.body
        },
        arrow: false,
        placement: 'auto',
        autoClose: true,
        offset: {
            top: 0,
            left: 0
        },
        fitContainer: false,
    };

    static displayName = 'HealthOverlay';

    state = {
        open: false,
        position: null
    };

    constructor(props) {
        super(props);

        this.state.open = props.open;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.open !== this.props.open) {
            this.setState(() => ({
                open: this.props.open
            }));
        }
    }

    setClose = () => {
        this.setState(() => ({open: false}), () => {
            this.props.onClose && this.props.onClose();
        });
    };

    renderContent() {
        const {
            placement,
            onOpen,
            children,
            targetRef,
            containerRef,
            className,
            autoClose,
            offset,
            contentRef,
            fitContainer,
        } = this.props;

        return (
            <Content
                fitContainer={fitContainer}
                contentRef={contentRef}
                className={className}
                placement={placement}
                targetRef={targetRef}
                containerRef={containerRef}
                onClose={this.setClose}
                onOpen={onOpen}
                autoClose={autoClose}
                offset={offset}>
                {children}
            </Content>
        );
    }

    render() {
        const {open} = this.state;
        return open ? ReactDOM.createPortal(this.renderContent(), this.props.containerRef.current) : null;
    }
}

export default Overlay;
