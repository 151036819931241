export const COLUMN_NAMES = {
    ID: 'id',
    REASON: 'reason',
    DOCTOR: 'doctor',
    CLINIC: 'clinic',
    COUNTRY: 'country',
    CREATE_DATE: 'date',
    EDIT_DATE: 'date_edit',
    PRICE: 'consult',
    STATUS: 'status',
    ACTIONS: 'unread',
};
