import React from 'react';
import {useSelector} from 'react-redux';

import {RequestStatusText} from "../../../../components/request/RequestStatusText/RequestStatusText";
import {userRoleSelector} from '../../../../redux/user/selectors';
import {getRequestStatusTextIdByConsultation} from '../../../../helpers/Request';

export const StatusCell = ({row: {original}, cell: {value}}) => {
    const requestData = original;

    const userRole = useSelector(userRoleSelector);

    const statusTextId = getRequestStatusTextIdByConsultation(userRole, requestData);

    return (
        <RequestStatusText statusTextId={statusTextId} status={requestData.status} />
    )
};
