import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './RequestNote.scss';

export const RequestNote = (
    {className, text, type, stretch},
) => {
    return (
        <div className={cn(
            'Request-note',
            className,
            type && `Request-note--${type}`,
            stretch && 'Request-note--stretch',
        )}>
            {text}
        </div>
    )
};

RequestNote.propTypes = {
    text: PropTypes.string,
    type: PropTypes.oneOf(['accepted', 'declined', 'noted', null]),
    stretch: PropTypes.bool,
};

RequestNote.defaultProps = {
    type: 'accepted',
    stretch: false,
};
