import axios from 'axios';
import apiRoutes from '../constants/ApiRoutes';

export const loadTranslations = ({pageName}) => {
    return axios.get(apiRoutes.lang, {
        params: {
            page: pageName,
        },
    })
};

/**
 * Load file type list
 * @param type - FileListType see in constants
 * @return {Promise<Response<T>>}
 */
export const loadFileTypeList = (type) => {
    return axios.get(apiRoutes.fileTypeList, {
        params: {
            type,
        },
    })
};

/**
 * Load list of specialities
 * @param params - {d} loading for doctor, {} loading for clinic
 * @return {Promise<Response<T>>}
 */
export const loadSpecialities = (params = {}) => {
    return axios.get(apiRoutes.specialities, {
        params,
    })
};

export const loadCountries = (params) => {
    return axios.get(apiRoutes.countryList, {
        params
    })
};

export const loadCurrencies = () => {
    return axios.get(apiRoutes.currencyList)
};

export const loadLanguages = () => {
    return axios.get(apiRoutes.langList)
};

export const loadCommunicationLanguages = () => {
    return axios.get(apiRoutes.langList, {
        params: {
            s: '',
        },
    })
};

export const loadTimezones = () => {
    return axios.get(apiRoutes.gmtList)
};

export const loadGenders = () => {
    return axios.get(apiRoutes.genderList)
};

export const loadDepartments = ({lang}) => {
    return axios.get(apiRoutes.departmentList, {
        params: {
            lang,
        },
    })
};
