import React from 'react';
import {useIntl} from 'react-intl';

import CloseButton from "../components/CloseButton/CloseButton";

interface Props {
    onClose: VoidFunction
    hideModal: VoidFunction;
}

const UserAgreementModal = React.memo<Props>(
    ({
         onClose,
         hideModal,
     }) => {
        const intl = useIntl();

        const handleClose = React.useCallback(() => {
            onClose && onClose();
            hideModal();
        }, [hideModal, onClose]);

        const __html = intl.formatMessage({id: 'patientReg.app_agreements.user_agreement'})

        return (
            <React.Fragment>
                <CloseButton onClick={handleClose}/>

                <div
                    dangerouslySetInnerHTML={{__html}}
                />
            </React.Fragment>
        );
    }
);


UserAgreementModal.displayName = 'UserAgreementModal';

export default UserAgreementModal;
