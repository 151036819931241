import {setLoading} from '../loading/actions';
import pageNames from '../../constants/PageNames.js';
import {fetchTranslation} from "../translations/actions";
import * as api from "../../api";

export const initTranslatedRecoveryPassPage = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    await dispatch(fetchTranslation({
        lang: getState().lang,
        pageName: pageNames.RecoveryPassword
    }));
    dispatch(setLoading(false));
};

export const recoveryPassword = (token, newpassword) => async () => {
    return await api.recoveryAccount(token, newpassword);
};
