import React, {useCallback} from 'react';
import {Form} from 'react-final-form'
import {FormattedMessage, useIntl} from 'react-intl';

import TextBoxField from '../../../components/common/TextBox/TextBoxField';
import Button from '../../../components/common/Button/Button';
import {AuthorizationLink} from '../../../components/registration/AuthorizationLink/AuthorizationLink';

export const LoginForm = ({onSubmit, lastLocation}) => {
    const intl = useIntl();

    const onFormSubmit = (formValue) => {
        return onSubmit(formValue);
    };

    const getRegistrationLink = useCallback(() => {
        return (lastLocation && lastLocation.pathname) || '/registration';
    }, [lastLocation]);

    return (
        <React.Fragment>
            <AuthorizationLink
                className="mb-1"
                text="login.login_no_acc"
                link="login.login_reg"
                redirectTo={getRegistrationLink()}
            />
            <Form
                onSubmit={onFormSubmit}>
                {
                    ({handleSubmit}) => {
                        return (
                            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <TextBoxField
                                    inline
                                    labelClass="col-4"
                                    inputClass="col-8"
                                    name="email"
                                    label={intl.formatMessage({id: 'login.email'})} />
                                <TextBoxField
                                    inline
                                    labelClass="col-4"
                                    inputClass="col-8"
                                    name="pass"
                                    type="password"
                                    label={intl.formatMessage({id: 'login.login_pass'})} />
                                <div className="row">
                                    <div className="col-4" />
                                    <div className="col-8">
                                        <Button type="submit">
                                            <FormattedMessage id="login.but_enter" />
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        )
                    }}
            </Form>
            <AuthorizationLink
                className="mt-1"
                text="login.login_forget"
                link="login.login_recover"
                redirectTo="/remember-pass"
            />
        </React.Fragment>
    );
};

export default LoginForm;
