import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {MessageStatus} from 'components/ConsultationChat/Chat.interface';
import cn from 'classnames';

import IfCondition from '../../../common/IfCondition/IfCondition';

interface Props {
	status: MessageStatus;
}

const isReadMessage = (status: MessageStatus) => status === MessageStatus.Read;
const isSentMessage = (status: MessageStatus) => status === MessageStatus.Sent;

export const MessageStatusIcon = ({status}: Props) => {
	const classes = cn({
		'Health-chat__message_meta_status': true,
		'Health-chat__message_meta_status--read':
			isReadMessage(status),
		'Health-chat__message_meta_status--sent':
			isSentMessage(status),
	});

	return (
		<div className={classes}>
			<FontAwesomeIcon
				className={'Health-chat__message_meta_status-icon'}
				icon={faCheck}
			/>

			<IfCondition condition={isReadMessage(status)}>
				<FontAwesomeIcon
					className={'Health-chat__message_meta_status-icon'}
					icon={faCheck}
				/>
			</IfCondition>
		</div>
	);
};
