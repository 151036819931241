export default {
    pageLang: '/lang.php',
    lang: '/lang_s.php',
    userInfo: '/user_info.php',
    userEdit: '/user_edit.php',
    logout: '/logout.php',
    fileTypeList: '/file_type_list.php', // list of file types
    specialities: '/speciality_list.php',
    clinicInfo: '/clinic_info.php',
    clinicEdit: '/clinic_edit.php',
    clinicList: '/clinic_list.php',
    clinicComLang: '/clinic_com_lang.php',
    countryList: '/country_list.php',
    currencyList: '/currency_list.php',
    langList: '/lang_list.php',
    gmtList: '/gmt_list.php',
    genderList: '/gender_list.php',
    departmentList: '/department_list.php',
    clinicEditStatus: '/clinic_edit_status.php',
    cityList: '/city_list.php',
    cityInfo: '/city_info.php',
    cityEdit: '/city_edit.php',
    doctorList: '/doctor_list.php',
    doctorInfo: '/doctor_info.php',
    userEditStatus: '/user_edit_status.php',
    userList: '/user_list.php',
    coordinatorList: '/coordinator_list.php',
    coordinatorInfo: '/coordinator_info.php',
    clinicEditCoordinator: '/clinic_edit_coordinator.php',
    regClinic: '/reg_clinic.php',
    regDoctor: '/reg_doctor.php',
    regPartner: '/reg_partner.php',
    contact: '/contact.php',
    login: '/login.php',
    confirmEmail: '/confirm_email.php',
    userReg: '/reg_user.php',
    requestList: '/request_list.php',
    requestEdit: '/request_edit.php',
    requestInfo: '/request_info.php',
    requestPay: '/request_pay.php',
    requestChatUnread: '/request_chat_unread.php',
    fileList: '/file_list.php',
    fileEdit: '/file_edit.php',
    requestDoctorLang: '/request_doctor_lang.php', // проверяет нужен ли перевод заявки для выбранного врача или нет
    conclusionPdf: '/conclusion_pdf.php', // preview conclusion
    statisticRequests: '/statistic_requests.php', // get statistic of requests
    statisticDoctors: '/statistic_doctors.php', // get statistic of doctors
    remPass: '/rem_pass.php', // remember password
    transList: '/trans_list.php',
    transEdit: '/trans_edit.php', // delete or add or update record
    langEdit: '/lang_edit.php', // add or update record
    countryEdit: '/country_edit.php', // add or update record
    specialtyEdit: '/speciality_edit.php', // add or update record
   
}
