import React from 'react';
import {COLUMN_NAMES} from './constants/columns';
import HeaderCell from "../../components/common/SimpleTable/cells/HeaderCell";
import ActionsCell from "../StatusesPage/components/ActionsCell";

export const defineColumns = [
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.id"/>),
        accessor: COLUMN_NAMES.ID,
        width: 6,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.pagename"/>),
        accessor: COLUMN_NAMES.PAGENAME,
        width: 30,
        sortable: true,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.param"/>),
        accessor: COLUMN_NAMES.PARAM,
        width: 30,
        sortable: true,
    },
];

export const actionColumns = [
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.actions"/>),
        accessor: COLUMN_NAMES.ACTIONS,
        width: 100,
        align: 'right',
        widthFixed: true,
        Cell: (props) => <ActionsCell {...props}/>
    }
]