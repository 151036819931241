import React from 'react';
import cn from 'classnames';
import {useIntl} from "react-intl";

import {SpinnerBody} from './SpinnerBody';
import './Spinner.scss';

interface Props {
    className?: string;
    spinnerTag?: React.ExoticComponent<{className: string, children: any}>;
    isBlocked?: boolean,
    isLoading: boolean;
    message?: string;
    messageKey?: string;
}


const Spinner = React.memo<Props>(
    ({className, spinnerTag: SpinnerTag = 'div', isLoading, isBlocked = true, message, messageKey}) => {
        const intl = useIntl();
        const classes = cn(
            className, 'Health-spinner',
            isBlocked && 'Health-spinner--blocked'
        );
        const content = messageKey ? intl.formatMessage({id: messageKey}) : message;

        return (
            <React.Fragment>
                {
                    isLoading ?
                        <SpinnerTag className={classes}>
                            <SpinnerBody content={content} />
                        </SpinnerTag> :
                        null
                }
            </React.Fragment>
        )
    }
);


Spinner.displayName = 'HealthSpinner';

export default Spinner;
