import React from 'react';
import {useIntl} from 'react-intl';

// inspired by react-i18n
export const useTranslation = () => {
    const intl = useIntl();

    const t = React.useCallback(
        (id: string) => intl.formatMessage({id}), 
        [intl]
    );

    return {t};
};
