import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {faPaperclip} from '@fortawesome/free-solid-svg-icons';
import {faFileAlt} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './FileUploader.scss';
import Popover from '../Popover/Popover';
import Overlay from '../Overlay/Overlay';
import Button from '../Button/Button';
import {getFileExtension} from '../../../helpers/Common';

class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state.value = !!props.value ? props.value : null;
        this.containerRef = createRef();
        this.targetRef = createRef();
        this.inputRef = createRef();
    }

    state = {
        isMenuOpen: false,
        value: null,
    };

    static propTypes = {
        viewBtnText: PropTypes.string,
        editBtnText: PropTypes.string,
        deleteBtnText: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        name: PropTypes.string,
        container: PropTypes.any,
        menuPlacement: PropTypes.string,
        editable: PropTypes.bool,
    };

    static defaultProps = {
        menuPlacement: 'right-end',
        editable: true,
    };

    static displayName = 'HealthFileUploader';

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value,
                isMenuOpen: false,
            })
        }
    }

    handleFileChange = (event) => {
        if (event.target.files.length && event.target.files[0]) {
            const reader = new FileReader();
            const file = event.target.files[0];
            reader.onloadend = () => {
                const value = {
                    name: file.name,
                    result: reader.result,
                    date: file.lastModifiedDate,
                };
                this.setState(() => ({
                    value,
                }), () => {
                    this.props.onChange && this.props.onChange(value);
                });
            };

            reader.readAsDataURL(event.target.files[0]);
        }
    };

    handleShowMenu = (event) => {
        const {editable} = this.props;

        if (!editable) {
            this.handleView();
            return;
        }

        this.setState(() => ({
            isMenuOpen: true,
        }));
    };

    handleHideMenu = () => {
        this.setState(() => ({
            isMenuOpen: false,
        }));
    };

    handleView = () => {
        this.setState(() => ({
            isMenuOpen: false,
        }), () => {
            const {value} = this.state;

            if (typeof value === 'string') {
                window.open(value, '_blank');
            } else {
                let previewWindow = window.open('', '_blank');
                setTimeout(() => {
                    if (getFileExtension(value.name) === 'pdf') {
                        previewWindow.document.write(`<html><head><style>body{margin: 0;}iframe{border-width: 0;}</style></head>`);
                        previewWindow.document.write(`<body><iframe width='100%' height='100%' src='${value.result}#toolbar=0&navpanes=0&scrollbar=0'></iframe></body></html>`);
                    } else {
                        previewWindow.document.write(`<html><head><style>body{margin: 0;}</style></head>`);
                        previewWindow.document.write(`<body><img src="${value.result}"/></body></html>`);
                    }
                    previewWindow.document.title = value.name;
                });
            }
        })
    };

    handleEdit = () => {
        this.setState(() => ({
            isMenuOpen: false,
        }), () => {
            this.inputRef.current.click();
        });
    };

    handleDelete = () => {
        const {value} = this.state;

        this.setState(() => ({
            isMenuOpen: false,
            value: null,
        }), () => {
            this.props.onRemove && this.props.onRemove(value);
            this.props.onChange && this.props.onChange(null);
        })
    };

    render() {
        const {
            container, name, viewBtnText, editBtnText, deleteBtnText, menuPlacement, className,
            onFocus, onBlur,
        } = this.props;
        const {isMenuOpen, value} = this.state;
        const iconClass = cn(
            'File-uploader__icon',
            value && 'File-uploader__icon--uploaded',
        );
        const inputClass = cn('File-uploader__input', value && 'File-uploader__input--attached');
        const popoverContainerRef = container || this.containerRef;
        const classes = cn('File-uploader', className);

        return (
            <div className={classes} ref={this.containerRef}>
                <div ref={this.targetRef}>
                    <FontAwesomeIcon icon={value ? faFileAlt : faPaperclip}
                                     size="lg"
                                     onClick={this.handleShowMenu}
                                     className={iconClass}/>
                </div>
                <input
                    onFocus={onFocus}
                    onBlur={onBlur}
                    type="file"
                    value={''}
                    ref={this.inputRef}
                    name={name}
                    className={inputClass}
                    accept="application/pdf, image/jpeg, image/png"
                    onChange={this.handleFileChange}/>
                <Overlay
                    placement={menuPlacement}
                    targetRef={this.targetRef}
                    containerRef={popoverContainerRef}
                    onClose={this.handleHideMenu}
                    open={isMenuOpen}>
                    <Popover className="Flex-column">
                        <Button
                            variant="gray"
                            onClick={this.handleView}
                            size="sm"
                            className="mb-1"
                            viewType="minimal">
                            {viewBtnText}
                        </Button>
                        <Button
                            variant="blue"
                            onClick={this.handleEdit}
                            size="sm"
                            className="mb-1"
                            viewType="minimal">
                            {editBtnText}
                        </Button>
                        <Button
                            variant="ruby"
                            onClick={this.handleDelete}
                            size="sm"
                            viewType="minimal">
                            {deleteBtnText}
                        </Button>
                    </Popover>
                </Overlay>
            </div>
        );
    }
}

export default FileUploader;
