import React, {useState, useRef} from 'react';
import {Form} from 'react-final-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter} from '@fortawesome/free-solid-svg-icons';

import Popover from '../../../components/common/Popover/Popover';
import Overlay from '../../../components/common/Overlay/Overlay';
import Button from '../../../components/common/Button/Button';
import Separator from '../../../components/common/Separator/Separator';
import TextBoxField from '../../../components/common/TextBox/TextBoxField';
import DateRangeField from '../../../components/common/DatePicker/DateRangeField';
//
import {Lang} from '../../../constants/Lang';
//
import {CountryListField} from 'components/Entities/CountryListField';
// import {DoctorSelectField} from 'components/Entities/DoctorSelectField';
// import {ClinicListField} from 'components/Entities/ClinicListField';
import {unsetWithAllOptionValue} from 'components/Entities/hooks/use-entity-options';

interface FormValues {
	query: string;
	currentPage: number;
	status: string;
	pageSize: number;
	fromDate: string;
	toDate: string;
	doctor: number; // id
	clinics: any[];
	countries: any[];
}

interface Props {
	containerRef: React.RefObject<HTMLDivElement>;
	onApplyFilter: (v: FormValues) => void;
	onResetFilter: VoidFunction;
	data: Partial<FormValues>;
	clinics: any[];
	countries: any[];
	doctors: any[];
	lang: Lang;
}

export const Filter = ({
	containerRef,
	onApplyFilter,
	onResetFilter,
	data = undefined,
	// clinics,
	countries,
	// doctors,
	lang,
}: Props) => {
	const btnRef = useRef();

	const [isOpened, setIsOpened] = useState(false);

	const intl = useIntl();

	const open = () => setIsOpened(true);
	const hide = () => setIsOpened(false);

	const handleApplyFilter = (values: FormValues) => {
		hide();

		const _values = unsetWithAllOptionValue(values);

		onApplyFilter && onApplyFilter(_values);
	};

	const handleResetFilter = () => {
		hide();

		onResetFilter && onResetFilter();
	};

	const handleClose = () => {
		hide();
	};

	const isFiltered = () => {
		return !!(
			data.query ||
			// (data.clinics && data.clinics.length) ||
			(data.countries && data.countries.length) ||
			data.fromDate ||
			data.toDate // || data.doctor
		);
	};

	return (
		<React.Fragment>
			<Button
				onClick={open}
				ref={btnRef}
				viewType="frameless"
				variant={isFiltered() ? 'ruby' : 'gray'}
			>
				<FontAwesomeIcon size="lg" icon={faFilter} />
				<FormattedMessage id="common.buttons.filter" />
			</Button>
			<Overlay
				fitContainer
				onClose={handleClose}
				containerRef={containerRef}
				open={isOpened}
				placement="bottom-center"
				offset={{
					top: -21,
					left: 0,
				}}
				targetRef={btnRef}
			>
				<Popover className="Health-panel--filter">
					<div className="Popover__title">
						<FormattedMessage id="common.filter.caption" />
					</div>
					<Form onSubmit={handleApplyFilter} initialValues={data}>
						{form => (
							<form
								onSubmit={form.handleSubmit}
								noValidate
								autoComplete="off"
							>
								<div>
									<TextBoxField
										className="m-0"
										labelClass="col-4"
										inputClass="col-8"
										inline
										label={intl.formatMessage({
											id: 'common.filter.search',
										})}
										name="query"
									/>
									<Separator />
									<DateRangeField
                                        // @ts-ignore
										locale={lang}
										pickerClass="col-4"
										fieldClass="mt-2"
										containerClass="row m-0 Health-date-picker__overlay--align-right"
										labelClass="col-4"
										label={intl.formatMessage({
											id: 'common.filter.period',
										})}
										fromName="fromDate"
										toName="toDate"
									/>
									{ /*<Separator /> */ }
									{ /*  <ClinicListField items={clinics} /> */ }
									<Separator />

									<CountryListField items={countries} />
									{ /*<Separator /> */ }
                                    { /* <DoctorSelectField items={doctors} /> */ }
								</div>
								<div className="Popover__actions Flex-column Justify-center">
									<Button type="submit">
										<FormattedMessage id="common.filter.ok" />
									</Button>
									<Button
										onClick={handleResetFilter}
										className="mt-1"
										variant="gray"
										viewType="minimal"
									>
										<FormattedMessage id="common.filter.clear" />
									</Button>
								</div>
							</form>
						)}
					</Form>
				</Popover>
			</Overlay>
		</React.Fragment>
	);
};