import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PaginationItem from "./PaginationItem";

import './Pagination.scss';
import SelectBox from "../common/SelectBox/SelectBox";

class Pagination extends React.Component {
    constructor(props) {
        super(props);

        let initialSelected = 0;

        if (props.initialPage) {
            initialSelected = props.initialPage;
        } else if (props.forcePage) {
            initialSelected = props.forcePage;
        } else {
            initialSelected = 0;
        }

        this.state.selectedPage = initialSelected;
        this.state.inputValue = initialSelected + 1;
    }

    state = {
        selectedPage: 0,
        inputValue: 1
    };

    static propTypes = {
        totalCount: PropTypes.number.isRequired,
        rangeDisplayed: PropTypes.number,
        marginDisplayed: PropTypes.number,
        onChange: PropTypes.func,
        initialPage: PropTypes.number,
        forcePage: PropTypes.number,
        goToLabel: PropTypes.string,
        showPageSize: PropTypes.bool,
        pageSize: PropTypes.number,
        onChangePageSize: PropTypes.func,
        pageSizeOptions: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
        }))
    };

    static defaultProps = {
        rangeDisplayed: 5,
        marginDisplayed: 1,
        initialPage: 0,
        showPageSize: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.forcePage !== this.props.forcePage && this.props.forcePage !== undefined) {
            this.setState({
                selectedPage: this.props.forcePage,
                inputValue: this.props.forcePage + 1,
            })
        }
    }

    handlePreviousPage = event => {
        const {selectedPage} = this.state;
        event.preventDefault ? event.preventDefault() : (event.returnValue = false);
        if (selectedPage > 0) {
            this.handlePageSelected(selectedPage - 1, event);
        }
    };

    handleNextPage = event => {
        const {selectedPage} = this.state;
        const {totalCount} = this.props;

        event.preventDefault ? event.preventDefault() : (event.returnValue = false);
        if (selectedPage < totalCount - 1) {
            this.handlePageSelected(selectedPage + 1, event);
        }
    };

    handlePageSelected = (selectedPage, event) => {
        event.preventDefault ? event.preventDefault() : (event.returnValue = false);

        if (this.state.selectedPage === selectedPage) return;

        this.setState(() => ({selectedPage: selectedPage, inputValue: selectedPage + 1}), () => {
            this.props.onChange && this.props.onChange(selectedPage);
        });
    };

    getForwardJump() {
        const {selectedPage} = this.state;
        const {totalPage, rangeDisplayed} = this.props;

        const forwardJump = selectedPage + rangeDisplayed;
        return forwardJump >= totalPage ? totalPage - 1 : forwardJump;
    }

    getBackwardJump() {
        const {selectedPage} = this.state;
        const {rangeDisplayed} = this.props;

        const backwardJump = selectedPage - rangeDisplayed;
        return backwardJump < 0 ? 0 : backwardJump;
    }

    handleBreakClick = (index, event) => {
        event.preventDefault ? event.preventDefault() : (event.returnValue = false);

        const {selectedPage} = this.state;

        this.handlePageSelected(
            selectedPage < index ? this.getForwardJump() : this.getBackwardJump(),
            event
        );
    };

    createPaginationItem = (index) => {
        const {selectedPage} = this.state;
        return (
            <PaginationItem
                key={index}
                selected={selectedPage === index}
                page={index + 1}
                onClick={this.handlePageSelected.bind(null, index)}/>
        );
    };

    pagination = () => {
        const items = [];
        const {
            rangeDisplayed,
            totalCount,
            marginDisplayed,
        } = this.props;

        const {selectedPage} = this.state;

        if (totalCount <= rangeDisplayed) {
            for (let index = 0; index < totalCount; index++) {
                items.push(this.createPaginationItem(index));
            }
        } else {
            let leftSide = rangeDisplayed / 2;
            let rightSide = rangeDisplayed - leftSide;

            if (selectedPage > totalCount - rangeDisplayed / 2) {
                rightSide = totalCount - selectedPage;
                leftSide = rangeDisplayed - rightSide;
            } else if (selectedPage < rangeDisplayed / 2) {
                leftSide = selectedPage;
                rightSide = rangeDisplayed - leftSide;
            }

            let index;
            let page;
            let breakView;
            let createPageView = index => this.createPaginationItem(index);

            for (index = 0; index < totalCount; index++) {
                page = index + 1;

                if (page <= marginDisplayed) {
                    items.push(createPageView(index));
                } else if (page > totalCount - marginDisplayed) {
                    items.push(createPageView(index));
                } else if (index >= selectedPage - leftSide && index <= selectedPage + rightSide) {
                    items.push(createPageView(index));
                } else if (items[items.length - 1] !== breakView) {
                    breakView = (
                        <PaginationItem
                            key={index}
                            breakView
                            onClick={this.handleBreakClick.bind(null, index)}
                        >...</PaginationItem>
                    );
                    items.push(breakView);
                }
            }
        }

        return items;
    };

    handleInputKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();

            this.handlePageSelected(this.state.inputValue - 1, event);
        }

        if (event.key === 'Escape') {
            event.preventDefault();
            event.stopPropagation();

            this.setState({
                inputValue: this.state.selectedPage + 1
            })
        }
    };

    handleInputOnBlur = (event) => {
        this.setState({
            inputValue: this.state.selectedPage + 1
        })
    };

    handleInputOnChange = (event) => {
        let value = event.target.value;
        const {totalCount} = this.props;

        if (value !== '' && value < 1) {
            value = 1;
        } else if (value > totalCount) {
            value = totalCount;
        }

        this.setState({
            inputValue: value
        });
    };

    handlePageSizeChange = (value) => {
        const {onChangePageSize} = this.props;
        onChangePageSize && onChangePageSize(value);
    };

    render() {
        const {
            totalCount,
            goToLabel,
            showPageSize,
            pageSize,
            pageSizeOptions,
        } = this.props;

        const {selectedPage, inputValue} = this.state;

        const previousClasses = cn('Pagination__item-arrow', selectedPage === 0 && 'Pagination__item--disabled');
        const nextClasses = cn('Pagination__item-arrow', selectedPage === totalCount - 1 && 'Pagination__item--disabled');
        const previousAriaDisabled = selectedPage === 0 ? 'true' : 'false';
        const nextAriaDisabled = selectedPage === totalCount - 1 ? 'true' : 'false';

        return (
            <div className="Pagination Flex-row Align-center">
                <label className="Pagination__label">
                    {goToLabel}
                </label>
                <input
                    onChange={this.handleInputOnChange}
                    onKeyDown={this.handleInputKeyDown}
                    onBlur={this.handleInputOnBlur}
                    className="Pagination__input"
                    type="number"
                    value={inputValue}
                    min={0}
                    max={totalCount}/>
                {
                    showPageSize &&
                    <SelectBox
                        menuPosition="up"
                        onChange={this.handlePageSizeChange}
                        value={pageSize}
                        excludeSelected={false}
                        className="Pagination__page-size ml-1 mr-1"
                        options={pageSizeOptions}/>
                }

                <ul className="Pagination__list">
                    <PaginationItem
                        aria-disabled={previousAriaDisabled}
                        className={previousClasses}
                        onClick={this.handlePreviousPage}>
                        <FontAwesomeIcon size="1x" icon={faChevronLeft}/>
                    </PaginationItem>

                    {this.pagination()}

                    <PaginationItem
                        aria-disabled={nextAriaDisabled}
                        className={nextClasses}
                        onClick={this.handleNextPage}>
                        <FontAwesomeIcon size="1x" icon={faChevronRight}/>
                    </PaginationItem>
                </ul>
            </div>
        );
    }
}

export default Pagination;
