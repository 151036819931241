import React, {useEffect} from 'react';
import {Link, withRouter, Switch, Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';

import './MainPage.scss';
import {logoutUser} from '../../redux/user/actions';
import {initMainPage} from '../../redux/mainPage/actions';
import {loadingSelector} from '../../redux/loading/selectors';
import * as translationsSelectors from '../../redux/translations/selectors';
import pageNames from '../../constants/PageNames';
import Loader from '../../components/Loader/Loader';
import * as userSelectors from '../../redux/user/selectors';
import ProfileHeader from '../../components/ProfileHeader/ProfileHeader';
import AccountPage from '../AccountPage/AccountPage';
import ConsultationPage from '../ConsultationPage/ConsultationPage';
import RequestTablePage from '../RequestTablePage/RequestTablePage';
import RequestPage from '../RequestPage/RequestPage';
import {StubPage} from '../StubPage/StubPage';
import MedicalRecordTablePage from '../MedicalRecordTablePage/MedicalRecordTablePage';
import OverviewPage from '../OverviewPage/OverviewPage';
import SystemPage from '../SystemPage/SystemPage';

import {useCompleteProfileReminder} from './hooks/use-complete-profile-reminder';
import {useDeferredCallback} from 'libs/hooks/use-deferred-callback';
import {fetchUnreadChatMessages} from 'redux/common/actions';
import {useAppDispatch} from 'hooks/use-app-dispatch';
import {loadNotifications} from 'redux/overviewPage/actions';
import {ShowPrivacyPolicyLink} from 'components/Policy/ShowPrivacyPolicyLink';

// Fetch new chat consulstation messages every 10 secs
const EVERY_15_SECONDS = 1000 * 15;

const MainPage = ({
	initMainPage,
	logoutUser,
	match,
	loading,
	isTranslationLoaded,
	userInfoLoaded,
	simpleUserInfo,
}) => {
	const [logout, setLogout] = React.useState(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		initMainPage();
	}, [initMainPage]);

	const onSignOut = React.useCallback(() => {
		setLogout(true);
		logoutUser();
	}, [logoutUser]);

	useCompleteProfileReminder();

	useDeferredCallback(
		EVERY_15_SECONDS,
		() => {
			dispatch(fetchUnreadChatMessages());
			dispatch(loadNotifications());
		},
		[],
	);

	return (
		<>
			{isTranslationLoaded && userInfoLoaded && !logout ? (
				<div className="Page Main Flex-column">
					<Loader loading={loading} />
					<ProfileHeader
						fio={simpleUserInfo.fio}
						onSignOut={onSignOut}
					/>
					<div className="Page-body Main__body">
						<Switch>
							<Route path={`${match.path}/requests`}>
								<Switch>
									<Route
										exact
										path={`${match.path}/requests`}
										component={RequestTablePage}
									/>
									<Route
										exact
										path={`${match.path}/requests/:id?`}
										component={RequestPage}
									/>
									<Route
										path={`${match.path}/requests/consultation/:id`}
										component={ConsultationPage}
									/>
									<Route path="*">
										<Redirect
											to={`${match.path}/requests`}
										/>
									</Route>
								</Switch>
							</Route>
							<Route
								path={`${match.path}/overview`}
								component={OverviewPage}
							/>
							<Route
								path={`${match.path}/messages`}
								component={StubPage}
							/>
							<Route
								path={`${match.path}/medical-records`}
								component={MedicalRecordTablePage}
							/>
							<Route
								path={`${match.path}/account`}
								component={AccountPage}
							/>
							<Route
								path={`${match.path}/system`}
								component={SystemPage}
							/>
							<Route path="*">
								<Redirect to={`${match.path}/overview`} />
							</Route>
						</Switch>
					</div>
					<footer className="Page-footer Flex-row Align-center Justify-space-between">
						<div>
							<FormattedHTMLMessage id="main.copyright" />
						</div>
						<ShowPrivacyPolicyLink
							messageId={'main.links_conf'}
						/>
					</footer>
				</div>
			) : null}
		</>
	);
};

const mapStateToProps = state => {
	return {
		loading: loadingSelector(state),
		isTranslationLoaded: translationsSelectors.isPageLoadedSelector(
			state,
			pageNames.Main,
		),
		userInfoLoaded: userSelectors.userInfoLoadedSelector(state),
		simpleUserInfo: userSelectors.getSimpleUserInfoSelector(state),
	};
};

export default connect(mapStateToProps, {
	logoutUser,
	initMainPage,
})(withRouter(MainPage));
