import React, {useCallback} from "react";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {withRouter} from 'react-router-dom';
import {useIntl} from "react-intl";

import OverviewAction from "../../../OverviewAction/OverviewAction";

const PROFILE_URL = '/main/account/profile';

const OverviewProfileAction = ({history}) => {
    const intl = useIntl();

    const handleProfile = useCallback(() => {
        history.push(PROFILE_URL);
    }, [history]);

    return (
        <OverviewAction
            title={intl.formatMessage({id: 'overview.actions.profile'})}
            icon={faUser}
            variant={"blue"}
            onClick={handleProfile}/>
    )
}

OverviewProfileAction.displayName = 'OverviewProfileAction'

export default withRouter(OverviewProfileAction);
