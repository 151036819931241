import React, {useEffect} from 'react';
import {withRouter, Route, Switch, Redirect, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';

import './AccountPage.scss';
import * as translationsSelectors from '../../redux/translations/selectors';
import * as accountPageSelectors from '../../redux/accountPage/selectors';
import pageNames from "../../constants/PageNames";
import {FormattedMessage} from "react-intl";
import {initAccountPage} from "../../redux/accountPage/actions";
import GeneralPage from "../GeneralPage/GeneralPage";
import SecurityPage from "../SecurityPage/SecurityPage";
import ProfilePage from "../ProfilePage/ProfilePage";

export const AccountPage = ({match, initAccountPage, loading, isTranslationLoaded}) => {
    useEffect(() => {
        initAccountPage();
    }, [initAccountPage]);

    return (
        <>
            {
                !loading && isTranslationLoaded &&
                <div className={`Flex-column Account-page`}>
                    <div className={`Account-page__title`}>
                        <FormattedMessage id="account.h2_tab"/>
                    </div>
                    <div className={`Account-nav`}>
                        <NavLink
                            className={`Account-nav__link`}
                            activeClassName={`Account-nav__link--active`}
                            to={`${match.url}/general`}>
                            <FormattedMessage id="account.tab_general"/>
                        </NavLink>
                        <NavLink
                            className={`Account-nav__link`}
                            activeClassName={`Account-nav__link--active`}
                            to={`${match.url}/security`}>
                            <FormattedMessage id="account.tab_security"/>
                        </NavLink>
                        <NavLink
                            className={`Account-nav__link`}
                            activeClassName={`Account-nav__link--active`}
                            to={`${match.url}/profile`}>
                            <FormattedMessage id="account.tab_profile"/>
                        </NavLink>
                    </div>
                    <div>
                        <Switch>
                            <Route path={`${match.path}/general`} component={GeneralPage}/>
                            <Route path={`${match.path}/security`} component={SecurityPage}/>
                            <Route path={`${match.path}/profile`} component={ProfilePage}/>
                            <Route path="*">
                                <Redirect to={`${match.path}/general`}/>
                            </Route>
                        </Switch>
                    </div>
                </div>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, pageNames.Account),
        loading: accountPageSelectors.isLoadingSelector(state),
    }
};

export default connect(mapStateToProps, {
    initAccountPage,
})(withRouter(AccountPage));
