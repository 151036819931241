import React from 'react';
import cn from 'classnames';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import type {Tag} from './TagList.interface';

interface Props {
	labelField: string;
	onRemove: React.MouseEventHandler;
	tag: Tag;
	onClick?: React.MouseEventHandler & React.KeyboardEventHandler;
	index: number;
	canSelectedTagRemove: boolean;
	disabledRemoveTags: boolean;
	selectedTag: any[];
	canSelectTag: boolean;
}

const TagListItem = ({
	tag,
	labelField = 'label',
	onClick,
	onRemove,
	canSelectTag,
	selectedTag,
	canSelectedTagRemove,
	disabledRemoveTags,
}: Props) => {
	const label = tag[labelField];
	const tagSelected = canSelectTag
		? selectedTag.find(t => t === tag.id)
		: false;
	const classes = cn(
		'Health-taglist__tag',
		tagSelected && 'Health-taglist__tag--selected',
	);

	return (
		<div className={classes} onClick={onClick} onKeyDown={onClick}>
			<span className="Health-taglist__tag-text">{label}</span>
			{(canSelectedTagRemove || !tagSelected) && !disabledRemoveTags && (
				<FontAwesomeIcon
					className="Health-taglist__tag-remove"
					size="1x"
					icon={faTimes}
					onClick={onRemove}
				/>
			)}
		</div>
	);
};

TagListItem.displayName = 'HealthTagListItem';

export default TagListItem;
