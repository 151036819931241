import React, {useEffect} from 'react';
import cn from 'classnames';

import './Menu.scss';
import MenuList from './components/MenuList/MenuList';
import type {SelectedOption, MenuOption} from '../../SelectBox.interface';

export type HTMLDomainMutableMenuElement = HTMLDivElement & {
	_scrollToItem_?: (childIndex: number) => void;
};

interface Props<T> {
	listRef: React.RefObject<HTMLDivElement>;
	ref: React.RefObject<HTMLDomainMutableMenuElement>
	isOpen: boolean;
	options: MenuOption[];
	noOptionsMessage: string;
	selectedOption: SelectedOption;
	itemIdSelector?: Function;
	optionRenderLabel: (o: T) => React.ReactNode;
	focusedOptionIndex: number;
	onSelectItem: (o: SelectedOption) => void;
	itemKeySelector: string;
	position: 'down' | 'up' | null;
	onMenuMouseDown: (e: React.MouseEvent) => void;
	children?: React.ReactNode;
}

const Menu = <T,>(
	{
		isOpen,
		children,
		listRef,
		position,
		onMenuMouseDown,
		...menuListProps
	}: Props<T>,
	ref: React.MutableRefObject<HTMLDomainMutableMenuElement | null>,
) => {
	useEffect(() => {
		if (!ref.current) {
			return;
		}

		ref.current._scrollToItem_ = (index: number) => {
			if (listRef.current && ref.current && index !== -1) {
				const requestId = requestAnimationFrame(() => {
					if (listRef.current.children.length) {
						const child = listRef.current.children[index];

						ref.current.scrollTo({
							left: 0,
							top: (child as HTMLElement).offsetTop,
							behavior: 'smooth',
						});
					}

					cancelAnimationFrame(requestId);
				});
			}
		};
	}, [listRef, ref]);

	return (
		<div
			onMouseDown={onMenuMouseDown}
			className={cn('Health-select-box__menu', {
				'Health-select-box__menu--open': isOpen,
				[`Health-select-box__menu--${position}`]: position,
			})}
			ref={ref}
		>
			<MenuList<T> {...menuListProps} ref={listRef} />
		</div>
	);
};
Menu.displayName = 'HealthSelectBoxMenu';

export default React.forwardRef(Menu) as typeof Menu;
