import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './QuickFilter.scss';
import {QuickFilterItem} from './QuickFilterItem';

const QuickFilter = React.memo(
	({className, items, activeItem, onChange, withoutCount}) => {
		const handleClick = useCallback(
			itemId => {
				onChange && onChange(itemId);
			},
			[onChange],
		);

		return (
			<ul className={cn(className, 'Quick-filter')}>
				{items.map((item, index) => (
					<li
						key={index}
						className={cn(
							'Quick-filter__item',
							item.id === activeItem &&
								'Quick-filter__item--selected',
						)}
					>
						<QuickFilterItem
							name={item.label}
							count={item.count}
							withoutCount={withoutCount}
							showBadge={item.hasBadge}
							onClick={() => handleClick(item.id)}
						/>
					</li>
				))}
			</ul>
		);
	},
);

QuickFilter.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.any.isRequired,
			label: PropTypes.string.isRequired,
			count: PropTypes.number,
		}),
	),
	activeItem: PropTypes.any,
	onChange: PropTypes.func,
	withoutCount: PropTypes.bool,
};

QuickFilter.defaultProps = {
	withoutCount: false,
};

export default QuickFilter;
