import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import cn from 'classnames';

import {hasError} from '../../../helpers/Common';
import SelectTagList from './SelectTagList';

const SelectTagListField = React.forwardRef((
    {
        className, name, selectedOption, ...props
    }, ref) => {
    return (
        <Field
            name={name}
            value={selectedOption}
            type="select"
            render={
                ({input: {value, ...inputProps}, meta}) => {
                    const error = hasError(meta) ? (meta.error || meta.submitError) : undefined;
                    const classes = cn(className, {
                        'Health-select-tag-list--error': !!error,
                    });

                    return (
                        <SelectTagList
                            {...props}
                            {...inputProps}
                            selectedOption={value}
                            className={classes}
                            containerRef={ref} />
                    )
                }
            }
        />
    )
});

SelectTagListField.propTypes = {
    name: PropTypes.string.isRequired,
    ...SelectTagList.propTypes,
};

export default SelectTagListField;
