import React from 'react';
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import ButtonIcon from "./ButtonIcon";

interface Props {
    className?: string;
    title: string;
}

export const InfoButtonIcon = ({title, className}: Props) => {
  return (
    <ButtonIcon
      type="button"
      className={className}
      title={title}
      icon={faQuestionCircle}
      color="#6592ab"
      frameless
    />
  );
};
