import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

class Content extends React.Component {
    state = {
        position: null,
    };

    static propTypes = {
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        contentRef: PropTypes.object,
        targetRef: PropTypes.object.isRequired,
        containerRef: PropTypes.object.isRequired,
        autoClose: PropTypes.bool,
        offset: PropTypes.shape({
            top: PropTypes.number,
            left: PropTypes.number
        }),
        fitContainer: PropTypes.bool,
    };
    static defaultProps = {};

    static displayName = 'HealthOverlayContent';

    constructor(props) {
        super(props);

        this.contentRef = props.contentRef || React.createRef();
        this.arrowRef = React.createRef();
    }

    calcPosition() {
        const {contentRef, arrowRef} = this;
        const {targetRef, arrow, placement, containerRef, offset} = this.props;

        const targetBound = targetRef.current.getBoundingClientRect();
        const containerBound = containerRef.current.getBoundingClientRect();
        const contentBound = contentRef.current.getBoundingClientRect();

        let arrowBound = {width: 0, height: 0};
        if (arrow) {
            arrowBound = arrowRef.current.getBoundingClientRect();
        }

        const {innerWidth, innerHeight} = window;

        const calcCoverLeft = contentBound.x - contentBound.width;
        const coverLeft = calcCoverLeft < 0 ? calcCoverLeft : 0;

        const calcCoverRight = contentBound.x + targetBound.width + contentBound.width;
        const coverRight = calcCoverRight > innerWidth ? innerWidth - calcCoverRight : 0;

        const calcCoverTop = contentBound.y - contentBound.height;
        const coverTop = calcCoverTop < 0 ? calcCoverTop : 0;

        const calcCoverBottom = targetBound.bottom + contentBound.height;
        const coverBottom = calcCoverBottom > innerHeight ? innerHeight - calcCoverBottom : 0;

        const calcXCenterLeft = contentBound.x + targetBound.width / 2 - contentBound.width / 2;

        const calcXCenterRight = contentBound.x + targetBound.width / 2 - contentBound.width / 2 + contentBound.width;

        const coverXCenterLeft = calcXCenterLeft < 0 ? calcXCenterLeft : 0;

        const coverXCenterRight = calcXCenterRight > innerWidth ? innerWidth - calcXCenterRight : 0;

        const calcYCenterTop = contentBound.y + targetBound.height / 2 - contentBound.height / 2;

        const coverYCenterTop = calcYCenterTop < 0 ? calcYCenterTop : 0;

        const calcYCenterBottom = contentBound.y + targetBound.height / 2 - contentBound.height / 2 + contentBound.height;

        const coverYCenterBottom = calcYCenterBottom > innerHeight ? calcYCenterBottom : 0;

        const calcTopStart = contentBound.x + contentBound.width;
        const coverTopStart = calcTopStart > innerWidth ? innerWidth - calcTopStart : 0;

        const calcTopEnd = contentBound.x - (contentBound.width - targetBound.width);
        const coverTopEnd = calcTopEnd < 0 ? calcTopEnd : 0;

        const calcLeftEndTop = contentBound.y - (contentBound.height - targetBound.height);
        const coverLeftEndTop = calcLeftEndTop < 0 ? calcLeftEndTop : 0;

        const coverRightEndTop = coverLeftEndTop;

        const calcLefStartBottom = contentBound.y + contentBound.height;
        const coverLeftStartBottom = calcLefStartBottom > innerHeight ? innerHeight - calcLefStartBottom : 0;

        const coverRightStartBottom = coverLeftStartBottom;

        const coverBottomStartRight = coverTopStart;
        const coverBottomEndLeft = coverTopEnd;

        const xCenterStyle = targetBound.height / 2 - contentBound.height / 2;
        const rightLeftEnd = -(contentBound.height - targetBound.height);
        const topBottomEnd = -(contentBound.width - targetBound.width);
        const topBottomCenter = targetBound.width / 2 - contentBound.width / 2;

        const computeArrowW = arrowBound.width / 2;
        const computearrowH = arrowBound.height / 2;

        const leftLeftStyle = -(contentBound.width + computeArrowW);
        const topTopStyle = -(contentBound.height + arrowBound.height / 2);
        const rightLeftStyle = targetBound.width + computeArrowW;
        const bottomTopStyle = targetBound.height + computearrowH;

        const customOffset = {
            top: 0,
            left: 0,
            ...offset
        };
        const offsetY = targetBound.y - containerBound.y + customOffset.top;
        const offsetX = targetBound.x - containerBound.x + customOffset.left;

        const styles = {
            topStart: {
                top: offsetY + topTopStyle,
                left: offsetX
            },
            topCenter: {
                top: offsetY + topTopStyle,
                left: offsetX + topBottomCenter
            },
            topEnd: {
                top: offsetY + topTopStyle,
                left: offsetX + topBottomEnd
            },

            leftStart: {
                top: offsetY,
                left: offsetX + leftLeftStyle
            },
            leftCenter: {
                left: offsetX + leftLeftStyle,
                top: offsetY + xCenterStyle,
            },
            leftEnd: {
                top: offsetY + rightLeftEnd,
                left: offsetX + leftLeftStyle
            },

            rightStart: {
                top: offsetY,
                left: offsetX + rightLeftStyle
            },
            rightCenter: {
                left: offsetX + rightLeftStyle,
                top: offsetY + xCenterStyle
            },
            rightEnd: {
                left: offsetX + rightLeftStyle,
                top: offsetY + rightLeftEnd
            },

            bottomStart: {
                top: offsetY + bottomTopStyle,
                left: offsetX
            },
            bottomCenter: {
                top: offsetY + bottomTopStyle,
                left: offsetX + topBottomCenter
            },
            bottomEnd: {
                top: offsetY + bottomTopStyle,
                left: offsetX + topBottomEnd
            },
        };

        const transform = {
            bottom: {
                transform: "rotate(-45deg)",
            },
            top: {
                transform: "rotate(135deg)",
            },
            left: {
                transform: "rotate(45deg)",
            },
            right: {
                transform: "rotate(45deg)",
            },
        };

        const arrowBottomTop = Math.ceil(-arrowBound.height / 2);

        const arrowBottomTopCenter = contentBound.width / 2 - arrowBound.width / 2;

        const arrowTop = contentBound.height - arrowBound.height / 2;
        const arrowTopBottomEnd = targetBound.width / 2 - arrowBound.width / 2;

        const arrowLeftRightEnd = contentBound.height - arrowBound.height / 2 - targetBound.height / 2;

        const arrowLeftRightCenter = contentBound.height / 2 - Math.ceil(arrowBound.height / 2);
        const arrowTopBottomStartLeft = targetBound.width / 2 - arrowBound.width / 2;

        const arrowLeftLeft = Math.ceil(contentBound.width - arrowBound.width / 2);
        const arrowLeftRightTop = targetBound.height / 2 - arrowBound.height / 2;

        const arrowStyle = {
            topStart: {
                ...transform.top,
                top: arrowTop,
                left: arrowTopBottomStartLeft,
            },
            topCenter: {
                ...transform.top,
                top: arrowTop,
                left: arrowBottomTopCenter,
            },
            topEnd: {
                ...transform.top,
                top: arrowTop,
                right: arrowTopBottomEnd,
            },
            leftStart: {
                ...transform.left,
                left: arrowLeftLeft,
                top: arrowLeftRightTop,
            },

            leftCenter: {
                ...transform.left,
                left: arrowLeftLeft,
                top: arrowLeftRightCenter,
            },

            leftEnd: {
                ...transform.left,
                left: arrowLeftLeft,
                top: arrowLeftRightEnd,
            },
            rightStart: {
                ...transform.right,
                left: -arrowBound.width,
                top: arrowLeftRightTop,
            },

            rightCenter: {
                ...transform.right,
                left: -arrowBound.width,
                top: arrowLeftRightCenter,
            },
            rightEnd: {
                ...transform.right,
                left: -arrowBound.width,
                top: arrowLeftRightEnd,
            },
            bottomStart: {
                ...transform.bottom,
                top: arrowBottomTop,
                left: arrowTopBottomStartLeft,
            },
            bottomCenter: {
                ...transform.bottom,
                top: arrowBottomTop,
                left: arrowBottomTopCenter,
            },
            bottomEnd: {
                ...transform.bottom,
                top: arrowBottomTop,
                right: arrowTopBottomEnd,
            },
        };

        const pos = [
            {
                at: "top-start",
                cover: [coverTop, coverTopStart, 0],
                style: styles.topStart,
                arrow: arrowStyle.topStart,
            },
            {
                at: "top-center",
                cover: [coverTop, coverXCenterLeft, coverXCenterRight],
                style: styles.topCenter,
                arrow: arrowStyle.topCenter,
            },
            {
                at: "top-end",
                cover: [coverTop, coverTopEnd, 0],
                style: styles.topEnd,
                arrow: arrowStyle.topEnd,
            },
            {
                at: "left-start",
                cover: [coverLeft, coverLeftStartBottom, 0],
                style: styles.leftStart,
                arrow: arrowStyle.leftStart,
            },
            {
                at: "left-center",
                cover: [coverLeft, coverYCenterTop, coverYCenterBottom],
                style: styles.leftCenter,
                arrow: arrowStyle.leftCenter,
            },
            {
                at: "left-end",
                cover: [coverLeft, coverLeftEndTop, 0],
                style: styles.leftEnd,
                arrow: arrowStyle.leftEnd,
            },
            {
                at: "right-start",
                cover: [coverRight, coverRightStartBottom, 0],
                style: styles.rightStart,
                arrow: arrowStyle.rightStart,
            },
            {
                at: "right-center",
                cover: [coverRight, coverYCenterTop, coverYCenterBottom],
                style: styles.rightCenter,
                arrow: arrowStyle.rightCenter,
            },
            {
                at: "right-end",
                cover: [coverRight, coverRightEndTop, 0],
                style: styles.rightEnd,
                arrow: arrowStyle.rightEnd,
            },
            {
                at: "bottom-start",
                cover: [coverBottom, coverBottomStartRight, 0],
                style: styles.bottomStart,
                arrow: arrowStyle.bottomStart,
            },
            {
                at: "bottom-center",
                cover: [coverBottom, coverXCenterLeft, coverXCenterRight],
                style: styles.bottomCenter,
                arrow: arrowStyle.bottomCenter,
            },
            {
                at: "bottom-end",
                cover: [coverBottom, coverBottomEndLeft, 0],
                style: styles.bottomEnd,
                arrow: arrowStyle.bottomEnd,
            },
        ];

        let newPosition;

        if (placement === "auto") {
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            const compute = pos.map(({cover}) => cover.reduce(reducer));
            const findIndex = compute.indexOf(Math.max(...compute));
            newPosition = pos[findIndex];
        } else {
            newPosition = pos.filter(val => val.at === placement)[0];
        }
        return newPosition;
    }

    updatePosition = () => {
        this.setState(() => ({
            position: this.calcPosition(),
        }), () => {
            this.recalcContainerMinHeight();
        });
    };

    recalcContainerMinHeight = () => {
        if (!this.contentRef.current) {
            return;
        }
        const containerRect = this.props.containerRef.current.getBoundingClientRect();
        const contentRect = this.contentRef.current.getBoundingClientRect();
        const minHeight = contentRect.y - containerRect.y + contentRect.height + 10;

        if (this.originalHeight === undefined) {
            this.originalHeight = containerRect.height;
        }
        if (this.originalMinHeight === undefined) {
            this.originalMinHeight = this.props.containerRef.current.style.minHeight || '';
        }

        if (minHeight > this.originalHeight) {
            this.props.containerRef.current.style.minHeight = `${minHeight}px`;
        } else {
            this.props.containerRef.current.style.minHeight = this.originalMinHeight;
        }

        if (this.props.fitContainer) {
            this.timerId = setTimeout(() => {
                this.recalcContainerMinHeight();
            }, 200);
        }
    };

    componentDidMount() {
        const {onOpen} = this.props;

        if (onOpen) onOpen();

        this.setState(() => ({
            position: this.calcPosition(),
        }), () => {
            if (this.props.autoClose) {
                document.addEventListener('click', this.handleOutsideClick);
            }
            window.addEventListener('resize', this.updatePosition);
            if (this.props.fitContainer) {
                this.recalcContainerMinHeight();
            }
        });
    }

    componentWillUnmount() {
        if (this.props.autoClose) {
            document.removeEventListener('click', this.handleOutsideClick);
        }
        this.props.onClose && this.props.onClose();
        window.removeEventListener('resize', this.updatePosition);
        if (this.props.fitContainer && this.timerId) {
            clearTimeout(this.timerId);
            if (this.props.containerRef.current) {
                this.props.containerRef.current.style.minHeight = this.originalMinHeight;
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.offset !== this.props.offset) {
            this.updatePosition();
        }
    }

    handleOutsideClick = (event) => {
        const overlay = event.target.closest('.Health-overlay');
        const classList = event.target.classList;
        // workaround when click outside element overlay closed
        const isDayPickerClick = classList.contains('DayPicker-Day');

        if (overlay !== this.contentRef.current && !isDayPickerClick) {
            document.removeEventListener('click', this.handleOutsideClick);
            this.props.onClose && this.props.onClose();
        }
    };

    render() {
        const {position} = this.state;
        const defaultStyle = {
            display: "inline-block",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 10,
        };
        const positionStyle = position ? position.style : {};
        const arrowStyle = position ? position.arrow : {};
        const {children, arrow, arrowProps = {}, className} = this.props;
        const {style = {}, ...arrowRest} = arrowProps;

        return (
            <div className={cn(className, 'Health-overlay')} style={{...defaultStyle, ...positionStyle}}
                 ref={this.contentRef}>
                {arrow ? (
                    <div ref={this.arrowRef}
                         style={{...{position: "absolute", top: 0, ...arrowStyle}, ...style}} {...arrowRest}>
                        {"◥"}
                    </div>
                ) : null}
                {children}
            </div>
        )
    }
}

export default Content;
