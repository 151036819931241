import React, {useMemo} from 'react';
import cn from 'classnames';
import {useIntl} from "react-intl";

import './RequestProgress.scss';
import {useSelector} from 'react-redux';
import RequestStepIds from '../../../../constants/RequestStepIds';
import * as managerSelectors from '../../../../redux/requestPage/manager/selectors';

export const RequestProgress = React.memo(
    ({
         className,
     }) => {
        const currentStep = useSelector(managerSelectors.getCurrentStepSelector);
        const intl = useIntl();

        const groups = useMemo(() => {
            const groupMapping = [
                {
                    label: intl.formatMessage({id: 'request.progress.consultant'}),
                    steps: [RequestStepIds.RequestConsultantsId],
                },
                {
                    label: intl.formatMessage({id: 'request.progress.request'}),
                    steps: [RequestStepIds.RequestFormId],
                },
                {
                    label: intl.formatMessage({id: 'request.progress.check'}),
                    steps: [
                        RequestStepIds.RequestCheckoutId,
                        RequestStepIds.RequestSendingId,
                    ],
                },
                {
                    label: intl.formatMessage({id: 'request.progress.confirmation'}),
                    steps: [
                        RequestStepIds.RequestConfirmId,
                        RequestStepIds.RequestPaymentId,
                        RequestStepIds.RequestReplacementId,
                        RequestStepIds.RequestDeclineId,
                    ],
                },
            ];

            const currentIndex = groupMapping.findIndex(g => g.steps.includes(currentStep));

            return groupMapping.map((group, index) => {
                return {
                    ...group,
                    isActive: currentIndex === index,
                    isCompleted: currentIndex > index,
                }
            })
        }, [currentStep, intl]);

        return (
            <div className={cn('Health-request-progress', className)}>
                <ul className="Health-request-progress__list">
                    {
                        groups.map((group, index) => (
                            <li
                                key={index}
                                className={cn('Health-request-progress__item', {
                                    'Health-request-progress__item--active': group.isActive,
                                    'Health-request-progress__item--completed': group.isCompleted,
                                })}>
                                {group.label}
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
    },
);
