import React, {useContext, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";

import {ViewModalContext} from "../Contexts/ViewModalContext";
import {showViewClinicDetailsModal} from "../../redux/modal/actions";

const ViewClinicLink = React.memo(
    ({id, from, search, params, children, onClick, ...props}) => {
        const dispatch = useDispatch();
        const {
            preventViewLink = false,
            modalView = false,
            noSelect = true,
        } = useContext(ViewModalContext) || {};

        if (preventViewLink) {
            return children;
        }

        const handleClickLink = useCallback((event) => {
            if (onClick) {
                onClick(event);
            } else {
                if (modalView) {
                    event.stopPropagation();
                    event.nativeEvent.stopImmediatePropagation();
                    event.preventDefault();
                    dispatch(showViewClinicDetailsModal({
                        clinicId: id,
                        noSelect,
                    }))
                }
            }
        }, [dispatch, id, modalView, noSelect, onClick]);

        return (
            <Link {...props}
                  href="#"
                  onClick={handleClickLink}>
                {children}
            </Link>
        )
    }
);

ViewClinicLink.propTypes = {
    id: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    search: PropTypes.string,
    params: PropTypes.object,
    onClick: PropTypes.func,
};
ViewClinicLink.defaultProps = {};

ViewClinicLink.displayName = 'HealthViewClinicLink';


export default ViewClinicLink;
