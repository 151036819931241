import React, {useCallback, useContext} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FormattedMessage} from 'react-intl';
import {faUserMd} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import './RequestDoctorCard.scss';
import Button from '../../../../components/common/Button/Button';
import ViewClinicLink from '../../../../components/Links/ViewClinicLink';
import Photo from '../../../../components/Photo/Photo';
import LocationStateFroms from '../../../../constants/LocationStateFroms';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import ViewDoctorLink from "../../../../components/Links/ViewDoctorLink";
import Price from "../../../../components/Price/Price";

export const RequestDoctorCard = (
    {
        doctor = {}, onSelect,
        className, isSelectable, isSelected,
        isConsultTranslate,
    },
) => {
    const manager = useContext(RequestManagerContext);

    const handleSelect = useCallback(() => {
        if (onSelect) {
            onSelect(doctor);
        }
    }, [doctor, onSelect]);

    const handleView = useCallback((event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        if (doctor) {
            manager.viewDoctor(doctor.id, !isSelectable, handleSelect)
        }
    }, [doctor, handleSelect, isSelectable, manager]);

    const handleViewClinic = useCallback(() => {
        if (doctor) {
            manager.viewClinic(doctor.clinic.id, true)
        }
    }, [doctor, manager]);

    const renderPrice = useCallback(() => {
        if (!doctor || !doctor.consult) {
            return null;
        }

        return (
            <React.Fragment>
                <div
                    className="Request-doctor-card__consult-title">
                    <FormattedMessage id={"request.doctor_card.consultation_price"}/>
                </div>
                <Price
                    className="block"
                    size="lg"
                    value={_.get(doctor, 'consult.value')}
                    currency={_.get(doctor, 'consult.symbol')}/>
                {
                    isConsultTranslate &&
                    <React.Fragment>
                        <div
                            className="Request-clinic-card__consult-title">
                            <FormattedMessage id={"request.doctor_card.translation_price"}/>
                        </div>
                        <Price
                            className="block"
                            size="lg"
                            value={_.get(doctor, 'consult.translate')}
                            currency={_.get(doctor, 'consult.symbol')}/>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }, [doctor, isConsultTranslate]);

    const renderLocation = useCallback(() => {
        return (
            <div className="Request-doctor-card__location">
                <span className="text-uppercase">
                    {_.get(doctor, 'country.name')}
                </span>
                <br/>
                {
                    doctor.city ?
                        <span>{doctor.city}</span> : null
                }
            </div>
        )
    }, [doctor]);

    const renderSpeciality = useCallback(() => {
        const text = (doctor.mainSpecialities || []).join(',\n');
        return (
            <div
                className="Request-doctor-card__speciality"
                title={text}>
                <span>
                    {text}
                </span>
            </div>
        )
    }, [doctor.mainSpecialities]);

    return (
        <div className={cn('Request-doctor-card', className)}>
            <div className="Request-doctor-card__avatar">
                <Photo
                    avatar
                    size="lg"
                    onClick={handleView}
                    src={_.get(doctor, 'url')}
                    alt={_.get(doctor, 'name')}
                    fallbackIcon={faUserMd}
                    fallbackIconSize={'4x'}/>
                {renderLocation()}
            </div>
            <div className="Request-doctor-card__general">
                <h3>
                    <ViewDoctorLink
                        className="link-text"
                        title={_.get(doctor, 'name')}
                        onClick={handleView}
                        id={doctor.id} from={LocationStateFroms.request}>
                        {_.get(doctor, 'name')}
                    </ViewDoctorLink>
                    {
                        _.get(doctor, 'grade') &&
                        <span
                            title={_.get(doctor, 'grade')}
                            className="Request-doctor-card__grade">
                            ,&nbsp;{_.get(doctor, 'grade')}
                        </span>
                    }
                </h3>
                {renderSpeciality()}
                <div
                    className="Request-doctor-card__position">
                    {doctor.position}
                </div>
                <div className="Request-doctor-card__languages">
                    <span className="Request-doctor-card__languages_label">
                        <FormattedMessage id="request.doctor_card.language_label"/>:
                    </span>
                    <span
                        title={(doctor.languages || []).join(', ')}
                        className="Request-doctor-card__languages_values">
                        {(doctor.languages || []).join(', ')}
                    </span>
                </div>
                {
                    !!(doctor.lang_com && doctor.lang_com.length) &&
                    <div className="Request-doctor-card__languages">
                        <span className="Request-doctor-card__languages_label">
                            <FormattedMessage id="request.doctor_card.translate_label"/>:
                        </span>
                        <span
                            title={(doctor.lang_com || []).map(l => l.label).join(', ')}
                            className="Request-doctor-card__languages_values">
                            {(doctor.lang_com || []).map(l => l.label).join(', ')}
                        </span>
                    </div>
                }
                <div className="Request-doctor-card__clinic">
                    {
                        doctor.clinic &&
                        <React.Fragment>
                            <span className="Request-doctor-card__clinic-label">
                                <FormattedMessage id="request.doctor_card.clinic_label"/>:
                            </span>
                            <ViewClinicLink
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleViewClinic();
                                }}
                                className="link-text"
                                tabIndex={-1}
                                from={LocationStateFroms.request}
                                id={doctor.clinic.id}>
                                {doctor.clinic.name}
                            </ViewClinicLink>
                        </React.Fragment>

                    }

                </div>
            </div>
            <div className="Request-doctor-card__actions">
                {renderPrice()}
                {
                    isSelectable &&
                    <Button
                        active={isSelected}
                        onClick={handleSelect}
                        size="sm"
                        viewType="minimal">
                        <span className="text-uppercase">
                            <FormattedMessage id={"request.buttons.select"}/>
                        </span>
                    </Button>
                }
            </div>
        </div>
    );
};

RequestDoctorCard.propTypes = {
    doctor: PropTypes.shape({
        id: PropTypes.any.isRequired,
    }),
    onSelect: PropTypes.func,
    isSelectable: PropTypes.bool,
    isSelected: PropTypes.bool,
    isConsultTranslate: PropTypes.bool,
};

RequestDoctorCard.defaultProps = {
    isSelectable: false,
    isSelected: false,
};
