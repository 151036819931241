import React from "react";

import Price from "../../../../components/Price/Price";

export const PriceCell = ({cell: {value}})=>{
    if(!value){
        return null;
    }

    return (
        <Price currency={value.symbol} value={value.summ} />
    )
}