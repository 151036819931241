export default {
	ru: {
		email: 'Ваш Email',
		email_error: 'Некорректный Email',
		h1: 'Востановление пароля',
		links_conf: 'Политика конфиденциальности',
		reg_auth: 'Авторизация',
		reg_has_acc: 'Есть аккаунт?',
		but_back_main: 'Вернуться на главную',
		but_send: 'Восстановить',
		copyright: '&copy; 2019-2021 best2health',
		ok_head: 'Восстановление пароля',
		error_head: 'Ошибка восстановления пароля',
		ok_text:
			'На Ваш адрес электронной почты отправлено письмо со ссылкой для восстановления пароля.',
		confirmation_error:
			'Письмо с кодом подтверждения отправлено на Вашу электронную почту',
		unknown_error: 'Что-то пошло не так, попробуйте позже',
	},
	en: {
		email: 'Email',
		email_error: 'Incorrect Email',
		h1: 'Password recovery',
		links_conf: 'Privacy Policy',
		reg_auth: 'Authorize',
		reg_has_acc: 'Have an account?',
		but_back_main: 'Back to main',
		but_send: 'Recovery',
		copyright: '&copy; 2019-2021 best2health',
		ok_head: 'Recovery password',
		error_head: 'Error recovery password',
		ok_text:
			'An email has been sent to your email address with a link to reset your password.',
		confirmation_error:
			'An email with a confirmation code has been sent to your email address',
		unknown_error: 'Something went wrong, try again later',
	},
};
