import * as api from '../../../api';
import * as commonActions from '../../common/actions';
import * as commonSelectors from '../../common/selectors';
import * as selectors from './selectors';
import {toServerFormat} from "../../../helpers/Date";

export const CONSULTATION_PAGE_CONSULTATION_SET_LOADING = 'CONSULTATION_PAGE_CONSULTATION_SET_LOADING';
export const CONSULTATION_PAGE_CONSULTATION_SET_DATA = 'CONSULTATION_PAGE_CONSULTATION_SET_DATA';
export const CONSULTATION_PAGE_CONSULTATION_SET_ERROR = 'CONSULTATION_PAGE_CONSULTATION_SET_ERROR';
export const CONSULTATION_PAGE_CONSULTATION_SET_COM_LANGS = 'CONSULTATION_PAGE_CONSULTATION_SET_COM_LANGS';
export const CONSULTATION_PAGE_CONSULTATION_REFRESH_FILES = 'CONSULTATION_PAGE_CONSULTATION_REFRESH_FILES';

export const loadConsultation = ({id}) => async (dispatch, getState) => {
    try {
        const {data: consultation} = await api.loadRequestInfo(id);

        if (consultation.specialities) {
            await dispatch(commonActions.fetchSpecialities());
            consultation.specialities = consultation.specialities
                .map(id => commonSelectors.getSpecialityByIdSelector(getState(), id))
                .filter(Boolean);
        }

        dispatch({
            type: CONSULTATION_PAGE_CONSULTATION_SET_DATA,
            payload: {
                id,
                data: consultation,
            }
        });

    } catch (error) {
        dispatch({
            type: CONSULTATION_PAGE_CONSULTATION_SET_ERROR,
            payload: {
                id,
                error,
            }
        });

        throw error;
    }
};

export const setConsultationLoading = (value) => ({
    type: CONSULTATION_PAGE_CONSULTATION_SET_LOADING,
    payload: value,
});

export const refreshFiles = () => async (dispatch, getState) => {
    try {
        const id = selectors.getConsultationId(getState());

        const {data: consultation} = await api.loadRequestInfo(id);

        dispatch({
            type: CONSULTATION_PAGE_CONSULTATION_REFRESH_FILES,
            payload: {
                files: consultation.files || [],
            }
        })
    } catch (err) {
        console.error(err);
    }
};

export const addFileToMedicalRecord = ({name, type, id, date}) => async () => {
    await api.saveMedicalRecord({
        name,
        type,
        id,
        date: toServerFormat(date),
    });
}

export const loadConclusionAsFile = (conclusionUrl) => api.loadFile(conclusionUrl);
