import React, {useState, useEffect, useRef} from 'react';
import {toInteger} from 'lodash';
import {connect} from 'react-redux';
import {RouteComponentProps, useLocation, useHistory} from 'react-router-dom';
//
import Pagination from '../../components/Pagination/Pagination';
import SimpleTable from '../../components/common/SimpleTable/SimpleTable';
import {CellProps} from 'components/common/SimpleTable/SimpleTable.interface';
import SystemPageHeader from '../../components/SystemPageHeader/SystemPageHeader';
import SearchTextBox from '../../components/fields/SearchTextBox/SearchTextBox';

//
import PageName from 'constants/PageNames';
import {COLUMN_NAMES} from './constants/columns';
import queryParams from 'constants/QueryParams';
import {getUrlSearchParams, setUrlSearchParams} from 'helpers/UrlHelper';
import {
	DEFAULT_PAGE_SIZE,
	PageSizes,
	PageSizeValues,
} from 'constants/PageSizes';
import {despace} from 'libs/string/despace';
//
import {showEditTranslateModal} from 'redux/modal/actions';
import {getLanguagesSelector} from 'redux/common/selectors';

import {getTranslates, initTranslatesPage} from 'redux/translatesPage/actions';
import * as translationsSelectors from '../../redux/translations/selectors';
import * as translatesPageSelectors from 'redux/translatesPage/selectors';
//
import './AgreementsPage.scss';
import {defineColumns, actionColumns} from './columns';
import type {RootState} from '../../context/store';
import {usePrevious} from 'libs/hooks/use-previous';
import {useAppDispatch} from 'hooks/use-app-dispatch';
import {useNotifier} from 'hooks/use-notifier';
import {useTranslation} from 'libs/hooks/use-translation';
import {AddTranslateButton} from '../StatusesPage/components/AddTranslateButton';
import CommonCell from 'components/common/SimpleTable/cells/CommonCell';
import {Lang} from 'constants/Lang';

interface Props extends RouteComponentProps<{}> {
	languages: any[];
	showEditTranslateModal: Function;
	showConfirmModal: Function;
	translates: any[];
	totalPages: any;
	isLoading: boolean;
	isTranslationLoaded: boolean;
}

type SearchParams = Partial<{
	currentPage: number;
	pageSize: number;
	query: string;
	sortBy: string;
	orderBy: string;
}>;

type SortBy = {id: string; desc: boolean};

const DEFAULT_SORT_FIELD = COLUMN_NAMES.PARAM;
const DEFAULT_ORDER_BY = 'desc';

const AgreementsPage = ({
	showEditTranslateModal,
	//
	isLoading,
	translates,
	languages,
	totalPages,
	isTranslationLoaded,
}: Props) => {
	//
	const {t} = useTranslation();
	const history = useHistory();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const prevLocation = usePrevious(location);

	// Refs
	const pageRef = useRef<HTMLDivElement>();

	// States
	const [columns, setColumns] = useState([]);
	const [isInitialSortBy, setInitialSortBy] = useState(false);
	const [searchParams, setSearchParams] = useState<SearchParams>({
		currentPage: 1,
		pageSize: DEFAULT_PAGE_SIZE,
		query: null,
	});

	const [sortBy, setSortBy] = useState<SortBy>({
		id: DEFAULT_SORT_FIELD,
		desc: false,
	});

	//
	const {notifySuccess, notifyError} = useNotifier();

	const getSearchParams = () => {
		const search = getUrlSearchParams(location.search);
		const currentPage = toInteger(search[queryParams.currentPage]);
		const pageSize = toInteger(search[queryParams.pageSize]);
		const sortBy = search[queryParams.sortBy] || DEFAULT_SORT_FIELD;
		const orderBy =
			search[queryParams.orderBy] &&
			/(asc|desc)/.test(search[queryParams.orderBy])
				? search[queryParams.orderBy]
				: DEFAULT_ORDER_BY;

		return {
			currentPage: currentPage > 0 ? currentPage : 1,
			pageSize:
				PageSizeValues.indexOf(pageSize) === -1
					? DEFAULT_PAGE_SIZE
					: pageSize,
			query: despace(search[queryParams.query]),
			sortBy: sortBy,
			orderBy: orderBy,
		};
	};

	const updateData = (p: SearchParams) => {
		// @ts-expect-error
		dispatch(getTranslates({
			...p,
			param: 'app_agreements', //
		}));
	};

	useEffect(() => {
		// @ts-expect-error
		updateData(getSearchParams(searchParams));
	}, [searchParams]);

	useEffect(() => {
		const params = getSearchParams();

		dispatch(initTranslatesPage());

		setSortBy({
			id: params.sortBy,
			desc: params.orderBy !== 'asc',
		});

		setSearchParams(params);
		setInitialSortBy(true);
	}, []);

	useEffect(() => {
		if (location.search !== prevLocation.search) {
			setSearchParams(getSearchParams());
		}
	}, [location.search]);

	useEffect(() => {
		setColumns([
			...defineColumns,
			...languages.map(({code, label}) => ({
				Header: label,
				accessor: code,
				sortable: true,
				Cell: props => <CommonCell {...props} />,
				width: 12,
			})),
			...actionColumns,
		]);
	}, [languages]);

	const pushHistoryWithSearchParams = <T,>(params: T) => {
		history.push({
			pathname: history.location.pathname,
			search: setUrlSearchParams(location.search, params),
		});
	};

	const handleChangePage = (page: number) => {
		const currentPage = page + 1;

		if (currentPage === searchParams.currentPage) {
			return;
		}

		pushHistoryWithSearchParams({
			[queryParams.currentPage]: currentPage === 1 ? null : currentPage,
		});
	};

	const handleChangeSort = (sort: SortBy[]) => {
		const [{id: sortBy, desc}] = sort;

		pushHistoryWithSearchParams({
			[queryParams.currentPage]: null,
			[queryParams.sortBy]: sortBy,
			[queryParams.orderBy]: desc ? 'desc' : 'asc',
		});
	};

	const handlePageSizeChange = (value: number) => {
		pushHistoryWithSearchParams({
			[queryParams.currentPage]: null,
			[queryParams.pageSize]: value === DEFAULT_PAGE_SIZE ? null : value,
		});
	};

	const getCellProps = (cell: CellProps<any>) => {
		switch (cell.column.originalId) {
			case COLUMN_NAMES.ACTIONS:
				return {
					editTranslate: handleEditTranslate,
				};
			case Lang.EN:
			case Lang.RU:
				return {
					className: 'Agreements-page__ellipsis-cell',
				};
			default:
		}
		return {};
	};

	const _showEditTranslateModal = (data: object): void => {
		showEditTranslateModal({
			shouldDisablePageNameField: true,
			defaultFields: {
				pagename: '[],[b2b],[lk]',
				param: 'app_agreements/',
			},
			onSuccess: () => {
				notifySuccess(t('translates.notify.added'));
				updateData(searchParams);
			},
			onError: () => {
				notifyError(t('translates.notify.add_error'));
			},
			...data,
		});
	};

	const handleAddTranslate = () => {
		_showEditTranslateModal({
			isNew: true,
			title: t('common.status.add'),
		});
	};

	const handleEditTranslate = (id: number) => {
		const record = translates.find(t => t.id === id);

		if (!record) {
			return;
		}

		_showEditTranslateModal({
			record,
			isNew: false,
			title: t('translates.dlg_add_or_edit.edit_title'),
		});
	};

	const handleSearch = (query: string) => {
		pushHistoryWithSearchParams({
			[queryParams.currentPage]: null,
			[queryParams.query]: query,
		});
	};

	const handleResetSearch = () => {
		pushHistoryWithSearchParams({
			[queryParams.currentPage]: null,
			[queryParams.query]: null,
		});
	};

	return (
		<React.Fragment>
			{isTranslationLoaded && !isLoading && (
				<div className="Statuses-page Flex-column" ref={pageRef}>
					<SystemPageHeader>
						<div className="Flex-row Statuses-page__actions">
							<SearchTextBox
								onSearch={handleSearch}
								onClear={handleResetSearch}
								query={searchParams.query}
								className="mr-1"
							/>
							<AddTranslateButton onClick={handleAddTranslate} />
						</div>
					</SystemPageHeader>
					<div className="Translates-page__table">
						{isInitialSortBy && (
							<SimpleTable
								initialState={{
									sortBy: [sortBy],
								}}
								onChangeSort={handleChangeSort}
								columns={columns}
								data={translates}
								getCellProps={getCellProps}
							/>
						)}
					</div>
					<div className="Flex-row Justify-center mt-4 mb-4">
						<Pagination
							onChangePageSize={handlePageSizeChange}
							pageSize={searchParams.pageSize}
							pageSizeOptions={PageSizes}
							showPageSize
							onChange={handleChangePage}
							goToLabel={t('common.pages_goto')}
							totalCount={totalPages['all']}
							forcePage={searchParams.currentPage - 1}
							initialPage={searchParams.currentPage - 1}
						/>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

const mapStateToProps = (state: RootState) => ({
	isTranslationLoaded: translationsSelectors.isPageLoadedSelector(
		state,
		// @ts-ignore
		PageName.Translates,
	),
	isLoading: translatesPageSelectors.isLoadingSelector(state),
	translates: translatesPageSelectors.getTranslatesSelector(state),
	totalPages: translatesPageSelectors.getTotalPagesSelector(state),
	totalCounts: translatesPageSelectors.getTotalCountsSelector(state),
	languages: getLanguagesSelector(state),
});

export default connect(mapStateToProps, {
	showEditTranslateModal,
})(AgreementsPage);
