import {actions} from 'react-redux-toastr';
import Immutable from 'seamless-immutable';

import NotificationTypes from "../../constants/NotificationTypes";

function buildNotifyAction(type) {
    return (title, message, options = {}) => {
        const defaultOptions = {
            type,
            position: 'top-right',
            options: {
                removeOnHover: true,
                timeOut: 5000, //ms
                icon: type,
                //showCloseButton: true
            }
        };

        return actions.add({
            title,
            message,
            ...Immutable.merge({...defaultOptions}, options, {deep: true})
        });
    };
}

export const notifySuccess = buildNotifyAction(NotificationTypes.Success);
export const notifyInfo = buildNotifyAction(NotificationTypes.Info);
export const notifyWarning = buildNotifyAction(NotificationTypes.Warning);
export const notifyError = buildNotifyAction(NotificationTypes.Error);
export const removeNotificationByType = (type) => {
    return actions.removeByType(type);
};
export const cleanNotifications = () => {
    return actions.clean();
};
