import _ from 'lodash';
import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from "react-intl";

import './RequestPayment.scss';
import Separator from '../../../../components/common/Separator/Separator';
import {useResetWindowScroll} from '../../../../hooks/useResetWindowScroll';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';
import {useRequestCurrentLangCommunication} from '../../hooks/useRequestCurrentLangCommunication';
import {RequestDoctorCard} from '../RequestDoctorCard/RequestDoctorCard';
import {RequestInfo} from '../../../../components/request/RequestInfo/RequestInfo';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import {RequestNote} from '../RequestNote/RequestNote';
import {RequestPageHeader} from '../RequestPageHeader/RequestPageHeader';
import {SidePanel} from '../../../../components/SidePanel/SidePanel';
import {InfoPanel} from "../../../../components/InfoPanel/InfoPanel";
import { PaymentForm } from './PaymentForm';

export const RequestPayment = () => {
   // const requestManager = useContext(RequestManagerContext);
    const requestData = useSelector(requestSelectors.getRequestData);
    const currentLang = useRequestCurrentLangCommunication();
    const intl = useIntl();
    const doctorText = _.get(requestData.text, requestData.lang);

    useResetWindowScroll();

    /* unused yet
    const handleSaveAndExit = useCallback(async () => {
        await requestManager.payRequest();
        requestManager.goNextPage();
    }, [requestManager]);
    */
    return (
        <React.Fragment>
            <div className="Request-page__main Request-payment-page">
                <RequestPageHeader title={intl.formatMessage({id: 'request.request_title'})} />
                <div className="Request-page__body">
                    <h2 className="Request-page__title">
                        <FormattedMessage id={"request.payment.title"} />
                    </h2>
                    <RequestDoctorCard
                        doctor={requestData.doctor || {}}
                        isConsultTranslate={
                            _.get(currentLang, 'translate', false) ||
                            !!_.get(requestData.doctor, ['consult', 'translate'], false)
                        }
                        isSelectable={false} />
                    {
                        doctorText &&
                        <RequestNote text={doctorText}/>
                    }
                    <Separator className="mt-2" />
                    <RequestInfo data={requestData} currentLang={currentLang}/>
                    <Separator className="mt-3" />
                    <PaymentForm />
                </div>
            </div>
            <SidePanel className="Request-payment-page__side-panel">
                <InfoPanel
                    message={intl.formatMessage({id: 'request.payment.panel_message'})}
                />
            </SidePanel>
        </React.Fragment>
    )
};

