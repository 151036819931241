import React, { useEffect } from 'react';
import {Field, useField} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners'
import _ from 'lodash';

interface Props {
    fieldTo: string;
    fieldFrom: string;
    transform: (v: string) => any;
    initialSync?: boolean;
}

const SyncFields = ({ fieldTo, fieldFrom, transform, initialSync }: Props) => {
    const fromField = useField(fieldFrom);
    const toField = useField(fieldTo);

    const transformValueIfNeed = (v: string) => transform ? transform(v) : v;

    useEffect(() => {
        const value = fromField.input.value;

        if (initialSync) {
            toField.input.onChange(transformValueIfNeed(value));
        }
    }, []);

    return (
        <Field name={fieldTo} subscription={{ value: true }}>
            {(
                // No subscription. We only use Field to get to the change function
                { input: { onChange, value: fieldToValue } },
            ) => (
                <OnChange name={fieldFrom}>
                    {value => {
                        const transformValue = transformValueIfNeed(value)
                    
                        if (!_.isEqual(fieldToValue, transformValue)) {
                            onChange(transformValue);
                        }
                    }}
                </OnChange>
            )}
        </Field>
    );
}

export default SyncFields;
