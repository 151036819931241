import React from 'react';
import _ from 'lodash';
import {useInView} from 'react-intersection-observer';

import './MessageBox.scss';
import { Message, isMessageStatusSent } from '../../Chat.interface';
import MessageBoxContent from './MessageBoxContent';


interface Props {
	className?: string;
	message: Message;
	canActions: boolean;
	position: string;
	isMe: boolean;
	background: 'gray' | 'green' | 'blue' | null;
	isRenderMeta: boolean;
    //
    onMarkMessageRead(messageId: number): void;
}

const MessageBox = ({
	message,
	position,
	className,
	background,
	canActions,
	isMe,
	isRenderMeta,
    //
    onMarkMessageRead
}: Props) => {
	const {ref} = useInView({
		triggerOnce: true,
        // inView = is the ref-element visible
		onChange: inView => {
            // don't mark my message as read
            if (isMe) {
                return;
            }

            // if is not visible
            if (!inView) {
                return;
            }

            if (isMessageStatusSent(message.status)) {
                onMarkMessageRead(message.id);
            }
		},
	});

	return (
		<MessageBoxContent
            ref={ref}
            className={className}
			background={background}
			position={position}
			isMe={isMe}
			isRenderMeta={isRenderMeta}
			canActions={canActions}
			message={message}
		/>
	);
};

MessageBox.displayName = 'HealthMessage';

export default MessageBox;
