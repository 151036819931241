import {push} from 'connected-react-router';
import {RequestStatuses} from '../../../constants/RequestStatuses';
import RequestStepIds from '../../../constants/RequestStepIds';
import {REQUEST_PAGE_BEFORE_LOADING_REQUEST} from '../actions';

import * as requestActions from '../request/actions';
import * as requestSelectors from '../request/selectors';
import * as userSelectors from '../../user/selectors';
import {stepTransitions} from './constants';
import * as managerSelectors from './selectors';
import * as pageActions from '../actions';

export const REQUEST_PAGE_MANAGER_SET_CURRENT_STEP = 'REQUEST_PAGE_MANAGER_SET_CURRENT_STEP';
export const REQUEST_PAGE_MANAGER_LOADING_REQUEST = 'REQUEST_PAGE_MANAGER_LOADING_REQUEST';
export const REQUEST_PAGE_MANAGER_LOADED_REQUEST = 'REQUEST_PAGE_MANAGER_LOADED_REQUEST';
export const REQUEST_PAGE_MANAGER_REQUEST_ERROR = 'REQUEST_PAGE_MANAGER_REQUEST_ERROR';
export const REQUEST_PAGE_MANAGER_NEW_REQUEST = 'REQUEST_PAGE_MANAGER_NEW_REQUEST';
export const REQUEST_PAGE_MANAGER_SET_BACKLINK = 'REQUEST_PAGE_MANAGER_SET_BACKLINK';

export const initializeNewRequest = () => (dispatch, getState) => {
    const userMainLang = userSelectors.userMainLangSelector(getState());
    const userComLangs = userSelectors.userComLangsSelector(getState());
    let lang = userMainLang;

    const hasCommunicationLangs = userComLangs.length > 0

    if (hasCommunicationLangs && !userComLangs.find(l => l.code !== userMainLang)) {
        lang = userComLangs[0].code;
    }

    dispatch({type: REQUEST_PAGE_MANAGER_NEW_REQUEST});
    dispatch(requestActions.newRequest(lang));
};

export const setCurrentStep = (step) => ({
    type: REQUEST_PAGE_MANAGER_SET_CURRENT_STEP,
    payload: step,
});

export const setBackLink = (link) => ({
    type: REQUEST_PAGE_MANAGER_SET_BACKLINK,
    payload: link,
});

export const goNextStep = () => (dispatch, getState) => {
    const currentStep = managerSelectors.getCurrentStepSelector(getState());

    const {nextStepId} = stepTransitions[currentStep];

    if (nextStepId) {
        dispatch(setCurrentStep(nextStepId));
    }
};

export const goPrevStep = () => (dispatch, getState) => {
    const currentStep = managerSelectors.getCurrentStepSelector(getState());

    const {prevStepId} = stepTransitions[currentStep];

    if (prevStepId) {
        dispatch(setCurrentStep(prevStepId));
    }
};

export const goToStep = (stepId) => (dispatch, getState) => {
    dispatch(setCurrentStep(stepId));
};

export const saveAndExit = () => async (dispatch, getState) => {
    const backLink = managerSelectors.getBackLinkSelector(getState());

    dispatch(pageActions.showRequestPageLoader());
    try {
        await dispatch(requestActions.saveRequest());
        dispatch(push(backLink));
    } finally {
        dispatch(pageActions.hideRequestPageLoader());
    }
};

export const loadRequest = (requestId) => async (dispatch, getState) => {
    const backLink = managerSelectors.getBackLinkSelector(getState());
    const userMainLang = userSelectors.userMainLangSelector(getState());
    const userComLangs = userSelectors.userComLangsSelector(getState());
    let lang = userMainLang;

    if (!userComLangs.find(l => l.code !== userMainLang)) {
        lang = userComLangs[0].code;
    }

    dispatch({
        type: REQUEST_PAGE_BEFORE_LOADING_REQUEST,
        payload: lang,
    });
    dispatch(pageActions.showRequestPageLoader());
    try {
        await dispatch(requestActions.loadRequest(requestId));

        const requestData = requestSelectors.getRequestData(getState());

        switch (requestData.status) {
            case RequestStatuses.Draft:
                dispatch(setCurrentStep(RequestStepIds.RequestFormId));
                break;
            case RequestStatuses.Accepted_5_1:
            case RequestStatuses.Accepted_5_3:
                if (requestData.doctor_new) {
                    dispatch(setCurrentStep(RequestStepIds.RequestReplacementId));
                } else {
                    dispatch(setCurrentStep(RequestStepIds.RequestConfirmId));
                }
                break;
            case RequestStatuses.Accepted_5_2:
                dispatch(setCurrentStep(RequestStepIds.RequestPaymentId));
                break;
            case RequestStatuses.DeclineByClinic:
            case RequestStatuses.DeclineByPatient:
                dispatch(setCurrentStep(RequestStepIds.RequestDeclineId));
                break;
            default:
                dispatch(setCurrentStep(RequestStepIds.RequestSendingId));
                break;
        }
    } catch (err) {
        dispatch(push(backLink));
        throw err;
    } finally {
        dispatch(pageActions.hideRequestPageLoader());
    }
};

export const sendRequest = () => async (dispatch) => {
    dispatch(pageActions.showRequestPageLoader());
    try {
        await dispatch(requestActions.sendRequest());
        dispatch(goNextStep());
    } finally {
        dispatch(pageActions.hideRequestPageLoader());
    }
};

export const cancelRequest = () => async (dispatch, getState) => {
    const backLink = managerSelectors.getBackLinkSelector(getState());

    dispatch(pageActions.showRequestPageLoader());
    try {
        await dispatch(requestActions.cancelRequest());
        dispatch(push(backLink));
    } finally {
        dispatch(pageActions.hideRequestPageLoader());
    }
};

export const confirmRequest = (stepId) => async (dispatch) => {
    dispatch(pageActions.showRequestPageLoader());
    try {
        await dispatch(requestActions.confirmRequest());
        if (stepId) {
            dispatch(goToStep(stepId));
        }
    } finally {
        dispatch(pageActions.hideRequestPageLoader());
    }
};

export const payRequest = () => async (dispatch) => {
    dispatch(pageActions.showRequestPageLoader());
    try {
        await dispatch(requestActions.payRequest());
    }finally {
        dispatch(pageActions.hideRequestPageLoader());
    }
};

