import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Photo from "../Photo/Photo";
import {faUserMd} from "@fortawesome/free-solid-svg-icons";

import './DoctorCard.scss';
import LocationStateFroms from "../../constants/LocationStateFroms";
import ViewDoctorLink from "../Links/ViewDoctorLink";

export const doctorFullShape = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    gender: PropTypes.number,
    position: PropTypes.string,
    grade: PropTypes.string,
    age: PropTypes.number,
    country: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    city: PropTypes.string,
    mainSpecialities: PropTypes.arrayOf(PropTypes.string),
    specialities: PropTypes.arrayOf(PropTypes.string),
    languages: PropTypes.arrayOf(PropTypes.string),
    consult: PropTypes.shape({
        value: PropTypes.number,
        currency: PropTypes.string,
        symbol: PropTypes.string,
    }),
    clinic: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    date: PropTypes.string,
    status: PropTypes.number,
    confirmed: PropTypes.number,
});

const DoctorCard = React.memo(
    ({className, doctor, type, from}) => {
        return (
            <div className={cn(className, 'Health-doctor-card', type && `Health-doctor-card--${type}`)}>
                <Photo
                    avatar
                    src={doctor.url}
                    alt={doctor.name}
                    fallbackIcon={faUserMd}
                    fallbackIconSize={"lg"}/>
                <div className="Health-doctor-card__body">
                    <h4>
                        <ViewDoctorLink
                            className="link-text"
                            id={doctor.id}
                            from={from}>
                            {doctor.name}
                        </ViewDoctorLink>
                    </h4>
                    <div className="Health-doctor-card__specialities">
                        {
                            doctor.mainSpecialities && doctor.mainSpecialities.join(', ')
                        }
                    </div>
                </div>
            </div>
        )
    }
);

DoctorCard.propTypes = {
    doctor: doctorFullShape,
    type: PropTypes.oneOf(['short', null]),
    from: PropTypes.string,
};
DoctorCard.defaultProps = {
    from: LocationStateFroms.viewClinic,
};
DoctorCard.displayName = 'HealthDoctorCard';

export default DoctorCard;
