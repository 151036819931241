import React from 'react';
import {Field} from "react-final-form";
import cn from "classnames";

import {hasError} from "../../../helpers/Common";
import TextBox, { Props as TextBoxProps} from "./TextBox";

interface Props extends TextBoxProps {
    fieldClassName?: string;
    min?: number;
    maxlength?: number;
    noError?: boolean;
    validate?: (value: string) => boolean; // TODO: check (why)
}

const TextBoxField = React.forwardRef<HTMLInputElement, Props>(({
            className, 
            fieldClassName, 
            hint, 
            label, 
            ellipsis, 
            labelClass, 
            inputClass, 
            inline, 
            placeholder,
            multi, 
            rows, 
            cols, 
            maxlength, 
            autoComplete, 
            icon, 
            readOnly,
            noError = false, 
            onChange, 
            disabled,
            iconClick,
            ...props
        }, ref
    ) => {
        return (
            <Field
                {...props}
                render={({input: {onChange: onChangeInput, ...inputProps}, meta}) => {
                    // @ts-ignore
                    const error = hasError(meta) ? (meta.error || meta.submitError) : undefined;

                    // eslint-disable-next-line
                    const onChangeValue = React.useCallback((value) => {
                        onChangeInput(value);
                        if (onChange) {
                            onChange(value);
                        }
                        // eslint-disable-next-line
                    }, [onChangeInput, onChange]);

                    return (
                        <div className={cn(fieldClassName, "Health-form__field")}>
                            <TextBox
                                disabled={disabled}
                                onChange={onChangeValue}
                                readOnly={readOnly}
                                icon={icon}
                                autoComplete={autoComplete}
                                error={noError ? !!error : error}
                                placeholder={placeholder}
                                ref={ref}
                                label={label}
                                labelClass={labelClass}
                                inputClass={inputClass}
                                inline={inline}
                                ellipsis={ellipsis}
                                hint={hint}
                                multi={multi}
                                rows={rows}
                                cols={cols}
                                className={className}
                                maxLength={maxlength}
                                iconClick={iconClick}
                                {...inputProps}/>
                        </div>
                    )
                }}/>
        );
    }
);

TextBoxField.displayName = 'HealthTextBoxField';


export default TextBoxField;
