import axios from 'axios';
import apiRoutes from '../constants/ApiRoutes';

/**
 * load list of medical records
 * @param {number} params.size - page size
 * @param {number} params.page - page number
 * @param {string} params.s - query (term)
 * @param {string} params.sortBy - sorting field
 * @param {string} params.orderBy - sorting direction asc or desc
 * @param {string} params.fromDate - from register date clinic
 * @param {string} params.toDate - to register date clinic (YYYY-MM_DD)
 * @param {string} params.type - status code see clinic status codes in constants
 * @return {Promise<AxiosResponse<T>>}
 */
export const loadMedicalRecords = (params = {}, options = {}) => {
    return axios.get(apiRoutes.fileList, {
        params,
        ...options,
    });
};

export const saveMedicalRecord = (data = {}) => {
    return axios.post(apiRoutes.fileEdit, data);
}

export const removeMedicalRecord = (id) => {
    return axios.post(apiRoutes.fileEdit, undefined, {
        params: {
            id,
            del: true,
        }
    });
}
