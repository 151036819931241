import React from 'react';
import FileUploader from "./FileUploader";
import {Field} from "react-final-form";
import cn from 'classnames';
import {hasError} from "../../../helpers/Common";

const FileUploaderField = ({viewBtnText, editBtnText, deleteBtnText, onRemove, container, menuPlacement, className, editable, ...props}) => {
    return (
        <Field
            {...props}
            render={
                ({input, meta}) => (
                    <FileUploader
                        {...input}
                        editable={editable}
                        className={cn(className, hasError(meta) && 'File-uploader--error')}
                        onRemove={onRemove}
                        container={container}
                        menuPlacement={menuPlacement}
                        viewBtnText={viewBtnText}
                        editBtnText={editBtnText}
                        deleteBtnText={deleteBtnText}
                    />
                )
            }/>
    );
};

FileUploaderField.propTypes = {
    ...FileUploader.propTypes,
};

export default FileUploaderField;

