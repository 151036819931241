import Immutable from 'seamless-immutable';
import {SET_OVERVIEW_PAGE_NOTIFICATIONS} from "./actions";

const INITIAL_STATE = {
    loading: true,
    notifications: [],
    statistics: {
        active: 0,
        all: 0,
        draft: 0,
        end: 0,
        consult: 0,
        income: 0,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_OVERVIEW_PAGE_NOTIFICATIONS:
            return Immutable.merge(state, {
                loading: false,
                notifications: action.payload.notifications,
                statistics: action.payload.statistics,
            });
        default:
            return state;
    }
}
