import ModalTypes from "../../constants/ModalTypes";
import AttachFilesModal from './AttachFilesModal/AttachFilesModal';
import SaveChangesModal from "./SaveChangesModal/SaveChangesModal";
import ConfirmModal from "./ConfirmModal/ConfirmModal";
import ViewClinicDetailsModal from "./ViewClinicDetailsModal/ViewClinicDetailsModal";
import ViewDoctorDetailsModal from './ViewDoctorDetailsModal/ViewDoctorDetailsModal';
import AddOrEditMedicalRecordModal from "./AddOrEditMedicalRecordModal/AddOrEditMedicalRecordModal";
import AddFilesFromMedicalRecordsModal from "./AddFilesFromMedicalRecordsModal/AddFilesFromMedicalRecordsModal";
import PreviewConclusionModal from "./PreviewConclusionModal/PreviewConclusionModal";
import EditTranslateModal from "./EditTranslateModal/EditTranslateModal";
import PrivacyPolicyModal from './PrivacyPolicyModal/PrivacyPolicyModal';
import UserAgreementModal from './UserAgreementModal/UserAgreementModal';

export default {
    [ModalTypes.saveChangesModal]: SaveChangesModal,
    [ModalTypes.confirmModal]: ConfirmModal,
    [ModalTypes.viewClinicDetailsModal]: ViewClinicDetailsModal,
    [ModalTypes.viewDoctorDetailsModal]: ViewDoctorDetailsModal,
    [ModalTypes.attachFilesModal]: AttachFilesModal,
    [ModalTypes.addOrEditMedicalRecordModal]: AddOrEditMedicalRecordModal,
    [ModalTypes.addFilesFromMedicalRecordsModal]: AddFilesFromMedicalRecordsModal,
    [ModalTypes.previewConclusionModal]: PreviewConclusionModal,
    [ModalTypes.editTranslateModal]: EditTranslateModal,
    [ModalTypes.privacyPolicyModal]: PrivacyPolicyModal,
    [ModalTypes.userAgreementModal]: UserAgreementModal,
};
