import {SET_LOADING} from './actions';

const initialState = 0;

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            if (action.payload) {
                return state + 1;
            } else if (state - 1 < 0) {
                return 0;
            } else {
                return state - 1;
            }
        default:
            return state;
    }
}
