import React from 'react';

import _size from 'lodash/size';
import _isString from 'lodash/isString';

import HeaderCommonCell from './cells/HeaderCommonCell';
import {CellProps} from './SimpleTable.interface';
import type {
	Column,
	Row,
	Cell,
	InitialState,
	InternalColumn,
} from './SimpleTable.interface';

export const actions = {
	init: 'init',
	toggleSortBy: 'toggleSortBy',
	setSortBy: 'setSortBy',
};

const defaultCallback = () => null;
const defaultInitialState = {
	sortBy: [],
};
const defaultColumnWidth = 150;
const renderErr =
	'Simple Table: You must specify a valid render component. This could be "column.Cell", "column.Header" or any other custom renderer component.';

interface State<T> {
	data: T;
	state: State<T>;
    columns: Array<InternalColumn<T>>;
    rows: Array<Row<T>>;
	dispatch: React.Dispatch<State<T>>;
	prepareRow: (row: Row<T>) => void;
	toggleSortBy: Function;
	setSortBy: Function;
	sumTotalWidth: number;
	flatColumns: Array<InternalColumn<T>>;
	sortBy: Array<{id: string; desc: boolean}>;
	sumFixedWidth: number;
	uniqueColumns: Array<InternalColumn<T>>;
}

interface Props<T> {
	data: Array<T>;
	columns: Array<Column<T>>;
	onChangeSort?: Function;
	initialState?: InitialState;
	paddingRow?: number;
}

export const useSimpleTable = <T,>(props: Props<T>) => {
	const {
		data,
		columns: userColumns,
		onChangeSort = defaultCallback,
		initialState = defaultInitialState,
		paddingRow = 20, // px
	} = props;

	const reducer = (state: Partial<State<T>>, action) => {
		if (action.type === actions.toggleSortBy) {
			const {
				payload: {columnId, desc},
				instanceRef: {
					current: {uniqueColumns: columns},
				},
			} = action;
			const {sortBy} = state;
			const column = columns.find(d => d.originalId === columnId);
			const {sortDescFirst} = column;
			const existingSortBy = sortBy.find(d => d.id === columnId);
			const hasDescDefined = typeof desc !== 'undefined' && desc !== null;

			let newSortBy = [];

			if (existingSortBy) {
				newSortBy = sortBy.map(d => {
					if (d.id === columnId) {
						return {
							...d,
							desc: hasDescDefined ? desc : !existingSortBy.desc,
						};
					}
					return d;
				});
			} else {
				newSortBy = [
					{
						id: columnId,
						desc: hasDescDefined ? desc : sortDescFirst,
					},
				];
			}

			onChangeSort(newSortBy);

			return {
				...state,
				sortBy: newSortBy,
			};
		}

		if (action.type === actions.setSortBy) {
			return {
				...state,
				sortBy: [
					{
						id: action.payload.columnId,
						desc: action.payload.desc,
					},
				],
			};
		}

		return state;
	};

	const [state, originalDispatch] = React.useReducer(
		reducer,
		initialState,
		() => reducer(initialState, {type: actions.init}),
	);

	let instanceRef = React.useRef<State<T>>({} as any);

	const dispatch = React.useCallback(action => {
		if (!action.type) {
			throw Error();
		}
		originalDispatch({...action, instanceRef});
	}, []);

	const toggleSortBy = (columnId: string, desc: boolean) => {
		dispatch({type: actions.toggleSortBy, payload: {columnId, desc}});
	};

	const setSortBy = (columnId: string, desc: boolean) => {
		dispatch({type: actions.setSortBy, payload: {columnId, desc}});
	};

	Object.assign(instanceRef.current, {
		...props,
		data,
		state,
		dispatch,
		toggleSortBy,
		setSortBy,
	});

	const columns = React.useMemo<Array<InternalColumn<T>>>(
		() => makeColumns(userColumns),
		[userColumns],
	);

	const flatColumns = React.useMemo<InternalColumn<T>[]>(
		() => flattenColumns(columns),
		[columns],
	);

	const rows = React.useMemo<Array<Row<T>>>(() => {
		const accessRow = (originalRow: T, i: number) => {
			const row = {
				key: `row_${i}`,
				original: originalRow,
				index: i,
				cells: [{}] as any, // This is a dummy cell
				isOdd: i % 2 === 0,
			} as Row<T>;

			const unpreparedAccessWarning = () => {
				throw new Error(
					'Simple Table: You have not called prepareRow(row) one or more rows you are attempting to render.',
				);
			};
			row.cells.map = unpreparedAccessWarning;
			row.cells.filter = unpreparedAccessWarning;
			row.cells.forEach = unpreparedAccessWarning;

			row.values = {};
			flatColumns.forEach(column => {
				row.values[column.originalId] = column.accessor
					? column.accessor(originalRow, i, data)
					: undefined;
			});

			return row;
		};

		return data.map((d, i) => accessRow(d, i));
	}, [data, flatColumns]);

	const sumTotalWidth = calculateColumnWidths(columns);

	Object.assign(instanceRef.current, {
		columns,
		flatColumns,
		rows,
		sumTotalWidth,
		sumFixedWidth: calculateColumnFixedWidths(columns),
	});

	instanceRef.current.prepareRow = React.useCallback(
		(row: Row<T>) => {
			const {sumTotalWidth, flatColumns, sumFixedWidth} =
				instanceRef.current;
			const per = (100 * (sumFixedWidth + paddingRow)) / sumTotalWidth;
			const mul = (100 - per) / (sumTotalWidth - sumFixedWidth);

			row.getRowProps = props =>
				mergeProps(
					{
						key: ['row', row.index].join('_'),
					},
					props || {},
				);

			row.cells = flatColumns.map(
				(column: InternalColumn<T>): Cell<T> => {
					const cell = {
						column,
						row,
						value: row.values[column.originalId],
					} as any;

					cell.render = (type, userProps = {}) => {
						const Comp =
							typeof type === 'string' ? column[type] : type;

						if (typeof Comp === 'undefined') {
							throw new Error(renderErr);
						}

						return flexRender(Comp, {
							...instanceRef.current,
							column,
							row,
							cell,
							...userProps,
						});
					};

					cell.getCellProps = props => {
						return mergeProps(
							{
								key: `cell_${column.id}_${row.index}`,
								style: {
									...(column.widthFixed
										? {
												flexBasis: column.width,
												minWidth: 0, // fixed overflow flex
										  }
										: {
												flexGrow: column.width,
												flexBasis: `${
													column.width * mul
												}%`,
												minWidth: 0, // fixed overflow flex
										  }),
									flexShrink: 0,
								},
							},
							props || {},
						);
					};

					return cell;
				},
			);
		},
		[paddingRow],
	);

	const uniqueColumns = arrayUniqueColumns(columns);
	instanceRef.current.uniqueColumns = uniqueColumns;

	uniqueColumns.forEach(column => {
		const {sumTotalWidth, sumFixedWidth} = instanceRef.current;
		const per = (100 * (sumFixedWidth + paddingRow)) / sumTotalWidth;
		const mul = (100 - per) / (sumTotalWidth - sumFixedWidth);

		if (column.sortable) {
			column.toggleSortBy = desc => toggleSortBy(column.originalId, desc);

			const {sortBy} = instanceRef.current.state;
			const columnSort = sortBy.find(d => d.id === column.originalId);

			column.isSorted = !!columnSort;
			column.sortedIndex = sortBy.findIndex(
				d => d.id === column.originalId,
			);
			column.isSortedDesc = column.isSorted ? columnSort.desc : undefined;
		}

		column.render = (type, userProps = {}) => {
			const Comp = typeof type === 'string' ? column[type] : type;

			if (typeof Comp === 'undefined') {
				throw new Error(renderErr);
			}

			return flexRender(Comp, {
				...instanceRef.current,
				column,
				...userProps,
			});
		};

		column.getHeaderProps = props => {
			const mulWidth = column.parent ? 100 / column.parent.width : mul;
			// const width = percentWidth && !column.widthFixed ? `${column.width * mulWidth}%` : column.width
			return mergeProps(
				{
					key: ['header', column.id].join('_'),
					style: {
						...(column.widthFixed
							? {
									flexBasis: column.width,
							  }
							: {
									flexGrow: column.width,
									flexBasis: `${column.width * mulWidth}%`,
							  }),
						flexShrink: 0,
					},
					onClick: column.sortable
						? e => {
								e.persist();
								column.toggleSortBy(undefined);
						  }
						: undefined,
				},
				props || {},
			);
		};
	});

	return instanceRef.current;
};

function arrayUniqueColumns(columns) {
	const flat = [];

	const recurse = arr => {
		arr.forEach(d => {
			flat.push(d);
			if (d.columns) {
				recurse(d.columns);
			}
		});
	};

	recurse(columns);

	return flat;
}

export function flexRender(Comp, props) {
	return isReactComponent(Comp) ? (
		<Comp {...props} />
	) : (
		<HeaderCommonCell {...props}>{Comp}</HeaderCommonCell>
	);
}

function isReactComponent(component) {
	return isClassComponent(component) || isFunctionComponent(component);
}

function isClassComponent(component) {
	return (
		typeof component === 'function' &&
		!!(() => {
			let proto = Object.getPrototypeOf(component);
			return proto.prototype && proto.prototype.isReactComponent;
		})()
	);
}

function isFunctionComponent(component) {
	return typeof component === 'function';
}

export const defaultRenderer = ({value = ''}) => value;
export const emptyRenderer = () => <>&nbsp;</>;

function makeColumns<T>(
	columns: Array<Column<T>>,
	parent?,
	depth = 0,
	last = false,
) {
	let uid = 0;
	const getUID = () => uid++;
	const length = columns.length - 1;

	return columns.map((column, index) => {
		let {id, accessor, Header} = column;
		const isLast = depth && index === length ? last : length === index;

		if (_size(column.columns) <= 0) {
			delete column.columns;
		}

		if (typeof accessor === 'string') {
			id = id || accessor;
			const accessorPath = accessor.split('.');
			// @ts-ignore todo
			accessor = (row: Row<T>) => getBy(row, accessorPath);
		}

		if (!id && _isString(Header) && Header) {
			id = Header as string;
		}

		if (column.columns) {
			id = `root_column_${getUID()}`;
		}

		if (!id) {
			console.error(column);
			throw new Error(
				'Simple Table: A column ID (or string accessor) is required!',
			);
		}

		// @ts-ignore todo
		column = {
			// Make sure there is a fallback header, just in case
			Header: emptyRenderer,
			// @ts-ignore check
			Cell: ({cell: {value = ''}}: CellProps<T>) => String(value),
			width: defaultColumnWidth,
			widthFixed: false,
			sortable: false,
			sortDescFirst: false,
			highlight: false,
			align: 'left', // left - by default, center, right
			alignHeader: column.alignHeader || column.align || 'left',
			...column,
			id: `${id}_${depth}_${getUID()}`,
			originalId: id,
			accessor,
			index,
			parent,
			depth,
			isLast,
		} as InternalColumn<T>;

		if (column.columns) {
			// @ts-ignore todo
			column.columns = makeColumns(
				column.columns,
				column,
				depth + 1,
				isLast,
			);
		}

		return column;
	}) as Array<InternalColumn<T>>;
}

function getBy<T, K extends keyof T>(
	obj: T,
	path: string[],
	def?: unknown,
): T[K] | T {
	if (!path) {
		return obj;
	}

	const pathObj = makePathArray(path);
	let val;
	try {
		val = pathObj.reduce((cursor, pathPart) => cursor[pathPart], obj);
	} catch (e) {
		// continue regardless of error
	}
	return typeof val !== 'undefined' ? val : def;
}

function makePathArray(obj) {
	return flattenDeep(obj)
		.map(d => String(d).replace('.', '_'))
		.join('.')
		.replace(/\[/g, '.')
		.replace(/\]/g, '')
		.split('.');
}

function flattenDeep(arr, newArr = []) {
	if (!Array.isArray(arr)) {
		newArr.push(arr);
	} else {
		for (let i = 0; i < arr.length; i += 1) {
			flattenDeep(arr[i], newArr);
		}
	}
	return newArr;
}

export function mergeProps(...props) {
	return props.reduce(
		(a, b) => {
			const className = b.className
				? [a.className, b.className].map(s => s.trim()).join(' ')
				: a.className;
			const style = {...a.style, ...b.style};
			return {
				...a,
				...b,
				className,
				style,
			};
		},
		{className: '', style: {}},
	);
}

export function flattenColumns<T>(columns: Array<InternalColumn<T>>) {
	return flattenBy(columns, 'columns');
}

export function flattenBy<T>(arr: T[], key: string) {
	const flat = [];

	const recurse = (arr: T[]) => {
		arr.forEach(d => {
			if (!d[key]) {
				flat.push(d);
			} else {
				recurse(d[key]);
			}
		});
	};

	recurse(arr);

	return flat;
}

function calculateColumnWidths<T>(columns: Array<InternalColumn<T>>, left = 0) {
	let sumTotalWidth = 0;

	columns.forEach(column => {
		let {columns: subColumns} = column;

		column.totalLeft = left;

		if (subColumns && subColumns.length) {
			column.width = calculateColumnWidths(subColumns, left);
		}
		left += column.width;
		sumTotalWidth += column.width;
	});

	return sumTotalWidth;
}

function calculateColumnFixedWidths<T>(columns: Array<InternalColumn<T>>) {
	let sumTotalWidth = 0;
	let width = 0;

	columns.forEach(column => {
		let {columns: subColumns} = column;

		if (subColumns && subColumns.length) {
			width = calculateColumnFixedWidths(subColumns);
		} else {
			width = column.width;
		}
		sumTotalWidth += column.widthFixed ? width : 0;
	});

	return sumTotalWidth;
}
