import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import './GeneralPage.scss';

import {initGeneralPage} from '../../redux/generalPage/actions';
import * as userSelectors from "../../redux/user/selectors";
import * as commonSelectors from "../../redux/common/selectors";
import {loadingSelector} from "../../redux/loading/selectors";
import * as translationsSelectors from "../../redux/translations/selectors";
import SafeButton from "../../components/common/SafeButton/SafeButton";
import CheckboxField from "../../components/common/Checkbox/CheckboxField";
import SelectBoxField from "../../components/common/SelectBox/SelectBoxField";
import PagePrompt from "../../components/PagePrompt/PagePrompt";
import {Form} from 'react-final-form'
import NavigationPrompt from 'react-router-navigation-prompt';
import {updateUser} from "../../redux/user/actions";
import {FormattedMessage, useIntl} from "react-intl";
import PageNames from "../../constants/PageNames";
import * as notifierActions from "../../redux/notifier/actions";

export const GeneralPage = ({
                                loading,
                                initGeneralPage,
                                languages,
                                isTranslationLoaded,
                                generalInfo,
                                updateUser,
                                notifySuccess,
                                notifyError,
                            }) => {
    const [isSuccess, setIsSuccess] = useState(false);

    const intl = useIntl();

    useEffect(() => {
        initGeneralPage();
    }, [initGeneralPage]);

    const onSubmit = async (values) => {
        try {
            setIsSuccess(false);
            await updateUser({
                lang: values.lang,
                notify: [{
                    type: 'email_consult', value: values.notify.email_consult
                }, {
                    type: 'email_messages', value: values.notify.email_messages
                }]
            });
            setIsSuccess(true);
            notifySuccess(intl.formatMessage({id: 'common.info_save'}))
        } catch (err) {
            setIsSuccess(false);
            notifyError(intl.formatMessage({id: 'common.info_save'}))
        }
    };

    return (
        <>
            {!loading && isTranslationLoaded &&
            <div className={`Flex-column General-page`}>
                <Form
                    initialValues={generalInfo}
                    onSubmit={onSubmit}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit} className="Flex-column Health-form" noValidate
                              autoComplete="off">
                            <div className="Flex-row">
                                <fieldset className="Health-fieldset Flex-1">
                                    <legend className="Health-fieldset__legend">
                                        <FormattedMessage id="general.h2_language"/>
                                    </legend>
                                    <SelectBoxField
                                        placeholder={intl.formatMessage({id: 'common.select_help'})}
                                        getOptionValue={o => o.code}
                                        fieldClassName="Select-language-field"
                                        options={languages}
                                        name='lang'/>
                                </fieldset>
                                <fieldset className="Health-fieldset Flex-1">
                                    <legend className="Health-fieldset__legend">
                                        <FormattedMessage id="general.h2_notify"/>
                                    </legend>
                                    <CheckboxField
                                        name="notify.email_consult"
                                        type="checkbox">
                                        <FormattedMessage id="general.notify_consult"/>
                                    </CheckboxField>
                                    <CheckboxField
                                        name="notify.email_messages"
                                        type="checkbox">
                                        <FormattedMessage id="general.notify_messages"/>
                                    </CheckboxField>
                                </fieldset>
                            </div>
                            <div className="Flex-row Justify-center Actions">
                                <SafeButton
                                    disabled={props.pristine && !props.dirty}
                                    type="submit"
                                    loading={props.submitting}
                                    isSuccess={isSuccess}
                                    doneText={intl.formatMessage({id: 'common.info_save'})}>
                                    <FormattedMessage id="common.buttons.save"/>
                                </SafeButton>
                            </div>
                            {
                                !props.pristine && props.dirty &&
                                <NavigationPrompt when={!props.pristine && !props.submitting}>
                                    {
                                        ({isActive, onCancel, onConfirm}) => {
                                            if (isActive) {
                                                return (
                                                    <PagePrompt
                                                        title={intl.formatMessage({id: 'common.dlg_leave_title'})}
                                                        message={intl.formatMessage({id: 'common.dlg_leave_message'})}
                                                        btnSave={intl.formatMessage({id: 'common.buttons.save'})}
                                                        btnCancel={intl.formatMessage({id: 'common.buttons.exit_no_save'})}
                                                        onConfirm={onCancel}
                                                        onClose={onCancel}
                                                        onCancel={onConfirm}/>
                                                );
                                            }
                                        }
                                    }
                                </NavigationPrompt>
                            }
                        </form>
                    )}
                </Form>
            </div>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        // TODO move to helper
        languages: commonSelectors.getLanguagesSelector(state),
        loading: loadingSelector(state),
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, PageNames.General),
        generalInfo: userSelectors.getUserGeneralInfo(state)
    }
};

export default connect(mapStateToProps, {
    initGeneralPage,
    updateUser,
    notifySuccess: notifierActions.notifySuccess,
    notifyError: notifierActions.notifyError,
})(withRouter(GeneralPage));
