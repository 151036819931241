import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './Button.scss';
import {Link} from "react-router-dom";

const ButtonLink = React.forwardRef(
    (
        {className, size, viewType, variant, ...props},
        ref
    ) => {
        const classes = cn(
            className,
            "Health-button",
            "Health-button--link",
            size && `Health-button--${size}`,
            viewType && `Health-button--${viewType}`,
            variant && `Health-button--${variant}`
        );
        return (
            <Link className={classes} ref={ref} {...props}/>
        )
    }
);

ButtonLink.propTypes = {
    size: PropTypes.oneOf(['sm', 'lg', null]),
    viewType: PropTypes.oneOf(['minimal', 'frameless', null]),
    variant: PropTypes.oneOf(['blue', 'gray', 'ruby', 'green', 'orange', 'primary', null]),
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

ButtonLink.defaultProps = {
    variant: 'blue',
};

ButtonLink.displayName = "HealthButtonLink";

export default ButtonLink;
