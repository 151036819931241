import React, {useEffect, useState} from 'react';
import {Field} from 'react-final-form';
import {useIntl} from "react-intl";

import MessageInput from '../../MessageInput/MessageInput';

interface Props {
    name: string;
    hasAttachments: boolean;
}

const SendMessageInputField = React.forwardRef<HTMLInputElement, Props>(({
    name, 
    hasAttachments
}, ref) => {
    const intl = useIntl();
    const [placeholder, setPlaceholder] = useState(() => intl.formatMessage({id: 'common.consultation_chat.input_message_placeholder'}));

    useEffect(() => {
        setPlaceholder(hasAttachments ?
            intl.formatMessage({id: 'common.consultation_chat.input_attachments_placeholder'}) :
            intl.formatMessage({id: 'common.consultation_chat.input_message_placeholder'}));
    }, [hasAttachments, intl]);

    return (
        <div className="Health-form__field">
            <Field
                name={name}
                render={({input, meta}) => {
                    return (
                        <MessageInput
                            placeholder={placeholder}
                            ref={ref}
                            {...input} />
                    )
                }}/>
        </div>
    )
})

SendMessageInputField.displayName = 'SendMessageInputField';


export default SendMessageInputField;
