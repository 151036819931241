import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";

import FileLink from "../../FileLink/FileLink";

const FileItem = ({item}) => {
    return (
        <React.Fragment>
            <FontAwesomeIcon
                className="Health-list__item-style"
                icon={faCircle} size={"xs"}/>
            <FileLink file={item}/>
        </React.Fragment>
    )
}

FileItem.propTypes = {
    item: PropTypes.any.isRequired,
};
FileItem.displayName = 'FileItem';

export default FileItem;
