import React, {useCallback, useEffect, useState} from "react";
import PropsTypes from "prop-types";
import {Document, Page} from 'react-pdf';
import {FormattedMessage} from "react-intl";

import './PdfViewer.scss';
import Spinner from "../Spinner/Spinner";
import Button from "../common/Button/Button";

const options = {
    withCredentials: true,
}

const PdfViewer = ({fileUrl, renderTextLayer, onSuccess, onError, width, onClose}) => {
    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        setNumPages(null);
    }, [fileUrl]);

    const handleDocumentLoadSuccess = useCallback(({numPages}) => {
        setNumPages(numPages);
        onSuccess && onSuccess();
    }, [onSuccess]);

    const handleDocumentLoadError = useCallback((error) => {
        onError && onError(error);
    }, [onError]);

    const renderError = useCallback(()=>{
        return (
            <React.Fragment>
                <FormattedMessage id={"common.pdf_viewer.error_load"} />
                {
                    onClose &&
                    <Button
                        className="mt-2"
                        onClick={onClose}
                        viewType="minimal"
                        variant="gray">
                        <FormattedMessage id={"common.pdf_viewer.btn_close"}/>
                    </Button>
                }
            </React.Fragment>
        )
    }, [onClose]);

    const renderNoData = useCallback(()=>{
        return (
            <React.Fragment>
                <FormattedMessage id={"common.pdf_viewer.no_data"} />
                <Button
                    className="mt-2"
                    onClick={onClose}
                    viewType="minimal"
                    variant="gray">
                    <FormattedMessage id={"common.pdf_viewer.btn_close"} />
                </Button>
            </React.Fragment>
        )
    }, [onClose]);

    return (
        <Document
            options={options}
            error={renderError}
            loading={() => <Spinner isLoading/>}
            noData={renderNoData}
            className={'PdfViewer-document'}
            onLoadSuccess={handleDocumentLoadSuccess}
            onLoadError={handleDocumentLoadError}
            file={fileUrl}>
            {
                Array.from(new Array(numPages), (_, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        className={'PdfViewer-document__page'}
                        width={width}
                        renderTextLayer={renderTextLayer}
                        pageNumber={index + 1}/>
                ))
            }
        </Document>
    )
}

PdfViewer.displayName = 'PdfViewer';
PdfViewer.propTypes = {
    fileUrl: PropsTypes.string.isRequired,
    onSuccess: PropsTypes.func,
    onError: PropsTypes.func,
    renderTextLayer: PropsTypes.bool,
    width: PropsTypes.number,
    onClose: PropsTypes.func,
};

PdfViewer.defaultProps = {
    renderTextLayer: false,
}

export default PdfViewer;
