import React from 'react';
import {Field} from "react-final-form";
import cn from "classnames";
import type DayPickerInput from 'react-day-picker/DayPickerInput';

import DatePicker, { Props as DatePickerProps } from "./DatePicker";
import {hasError} from "../../../helpers/Common";

interface Props extends DatePickerProps {
    fieldClass?: string;
}

const DatePickerField = React.forwardRef<DayPickerInput, Props>(
    ({
         fieldClass,
         className, label, labelClass, inline, format, locale,
         inputClass, placeholder,
         ...props
     }, ref) => {
        return (
            <Field
                {...props}
                render={
                    ({input: {value, ...input}, meta}) => {
                        // @ts-ignore
                        const error = hasError(meta) ? (meta.error || meta.submitError) : undefined;

                        return (
                            <div className={cn(fieldClass, "Health-form__field")}>
                                <DatePicker
                                    placeholder={placeholder}
                                    inputClass={inputClass}
                                    locale={locale}
                                    format={format}
                                    className={className}
                                    label={label}
                                    labelClass={labelClass}
                                    inline={inline}
                                    ref={ref}
                                    error={error}
                                    value={value ? value : undefined}
                                    {...input}/>
                            </div>
                        );
                    }
                }
            />
        );
    }
);

DatePickerField.displayName = "HealthDatePickerField";

export default DatePickerField;

