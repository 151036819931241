import React from 'react';

import cn from 'classnames';
import './Badgev2.scss';

export interface Props {
	className?: string;
	as?: React.ElementType | string;
	variant: 'ruby';
	transparent: boolean;
	children: React.ReactNode;
}

const BadgeV2 = React.forwardRef(
	(
		{
			as: Component = 'div',
			variant,
			className,
			transparent,
			children,
		}: Props,
		ref,
	) => {
		const classes = cn(className, {
			'Health-badgev2': true,
			'Health-badgev2--ruby': variant === 'ruby',
			'Health-badgev2--transparent': transparent,
		});

		return (
			<Component ref={ref} className={classes}>
				{children}
				<div className={`Health-badgev2__point`}></div>
			</Component>
		);
	},
);

BadgeV2.displayName = 'HealthBadgeV2';

export default BadgeV2;
