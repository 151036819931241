export const addMedicalRecords = ([records = []], state, tools) => {
    const files = tools.getIn(state.formState.values, 'files') || [];

    records.forEach(record => {
        // eslint-disable-next-line eqeqeq
        if (!files.some(item => item.id == record.id)) {
            files.push({
                id: record.id,
                ext: record.ext,
                file: record.file,
                label: record.name,
                type_id: record.type,
                med: true,
            });
        }
    });

    tools.changeValue(state, 'files', () => files);
};
