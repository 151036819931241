import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useIntl} from "react-intl";

import {initConfirmEmailPage} from '../../redux/confirmRegistration/actions'
import * as translationsSelectors from '../../redux/translations/selectors';
import pageNames from "../../constants/PageNames";
import {getConfirmEmailSelector} from "../../redux/confirmRegistration/selectors";
import {RegistrationPageLayout} from "../../components/registration/RegistrationPageLayout/RegistrationPageLayout";
import {RegistrationResult} from "../../components/registration/RegistrationResult/RegistrationResult";

export const ConfirmRegistration = ({match, initConfirmEmailPage, loading, isTranslationLoaded, confirm}) => {
    const intl = useIntl();

    useEffect(() => {
        initConfirmEmailPage(match.params.hash);
    }, [initConfirmEmailPage]);

    const renderBody = () => {
        return <RegistrationResult
            status={confirm.status}
            title={confirm.status ? "confirmEmail.confirm_ok_head" : "confirmEmail.confirm_error_head"}
            description={`confirmEmail.${confirm.message}`}
            errorText={intl.formatMessage({id: 'confirmEmail.confirm_error_text'})}
            btnGotoMain="confirmEmail.but_back_main"
            redirectTo="/"
        />
    };

    return (
        <RegistrationPageLayout
            className="Confirm-registration"
            isLoading={loading}
            isTranslationLoaded={isTranslationLoaded}
            title="confirmEmail.h1"
            copyright="confirmEmail.copyright"
            linksConf="confirmEmail.links_conf"
        >
            {renderBody()}
        </RegistrationPageLayout>
    );
};

const mapStateToProps = (state) => {
    return {
        confirm: getConfirmEmailSelector(state),
        loading: state.loading,
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, pageNames.ConfirmEmail),
    }
};

export default connect(mapStateToProps, {
    initConfirmEmailPage,
})(ConfirmRegistration);
