// based on https://github.com/based-ghost/react-functional-select
import React, {
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import _isUndefined from 'lodash/isUndefined';
import _isNil from 'lodash/isNil';
import cn from 'classnames';

import './SelectBox.scss';
import * as KEYS from './constants/keys';
import Value from './components/Value/Value';
import Input from './components/Input/Input';
import Drop from './components/Drop/Drop';
import Menu from './components/Menu/Menu';
import {normalizeOption} from './utils/utils';
import {useMenuOptions} from './hooks/useMenuOptions';
import {OptionIndexEnum} from './constants/enums';
import {usePrevious} from 'libs/hooks/use-previous';
import  {
	type Option,
	type SelectedOption,
	type FocusedOption,
	type MenuOption,
	MenuPosition,
}  from './SelectBox.interface';
import {HTMLDomainMutableMenuElement} from './components/Menu/Menu';

export interface Props<T = Option> {
	options: Option[];
	autoComplete?: 'off';
	staticOptions?: Option[];
	getOptionValue?: (o: T) => string | number;
	getOptionLabel?: (o: T) => string;
	onBlur?: () => void;
	onChange?: (value: number) => void;
	onFocus?: () => void;
	disabled?: boolean;
	value?: string;
	optionRender?: (o: T) => React.ReactNode;
	itemKeySelector?: string;
	placeholder?: string;
	excludeSelected?: boolean;
	searchable?: boolean;
	menuPosition?: MenuPosition | null,
	noOptionsMessage?: string;
	hasError?: boolean;
	className?: string;
}

const DEFAULT_FOCUSED_OPTION = {index: -1} as FocusedOption;
const getOptionLabelDefault = <T, >(option: T) => option['label'];
const getOptionValueDefault = <T, >(option: T) => option['id'];
const isEmptyValue = (option: unknown) => {
	return _isUndefined(option) || _isNil(option);
};

const emptyOptions = [];

type Ref = {
	focus: () => void;
	blur: () => void;
} | null;

const SelectBox = <T,>(
	{
		className,
		disabled = false,
		placeholder = '',
		options = emptyOptions,
		staticOptions = emptyOptions,
		getOptionValue = getOptionValueDefault,
		getOptionLabel = getOptionLabelDefault,
		onFocus,
		onChange,
		onBlur,
		value,
		optionRender,
		excludeSelected = true,
		searchable = false,
		menuPosition = MenuPosition.Down,
		noOptionsMessage = 'No options ...',
		itemKeySelector = 'id',
		hasError = false,
	}: Props<T>,
	ref: any,
) => {
	// refs
	const menuRef = useRef<HTMLDomainMutableMenuElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const controlRef = useRef<HTMLDivElement>(null);
	const listRef = useRef<HTMLDivElement>(null);
	
	// state
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [isFocused, setIsFocused] = useState(false);
	const [focusedOption, setFocusedOption] = useState<FocusedOption>(
		DEFAULT_FOCUSED_OPTION,
	);
	const previousValue = usePrevious(value);
	const previousOptions = usePrevious(options);

	// callbacks
	const getOptionLabelCallback: any = useMemo(
		() => getOptionLabel || getOptionLabelDefault,
		[getOptionLabel],
	);
	const getOptionValueCallback: any = useMemo(
		() => getOptionValue || getOptionValueDefault,
		[getOptionValue],
	);
	const optionRenderLabelCallback: any = useMemo(
		() => optionRender || getOptionLabel,
		[getOptionLabel, optionRender],
	);

	// initial value
	const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
		() => {
			if (isEmptyValue(value)) {
				return null;
			}

			const optionValue = [...staticOptions, ...options].find(
				o => `${getOptionValueCallback(o)}` === `${value}`,
			);

			return normalizeOption(
				optionValue,
				getOptionLabelCallback,
				getOptionValueCallback,
			);
		},
	);

	const menuOptions = useMenuOptions(
		options,
		staticOptions,
		inputValue,
		selectedOption,
		getOptionLabelCallback,
		getOptionValueCallback,
		excludeSelected,
		searchable,
	);

	useEffect(() => {
		// the same, no updates
		if (previousValue === value && previousOptions === options) {
			return;
		}

		if (isEmptyValue(value)) {
			setSelectedOption(undefined);
			return;
		}

		// ok, new updates -> update value
		setSelectedOption(
			normalizeOption(
				[...staticOptions, ...options].find(
					o => `${getOptionValueCallback(o)}` === `${value}`,
				),
				getOptionLabelCallback,
				getOptionValueCallback,
			),
		);
	}, [
		getOptionLabelCallback,
		getOptionValueCallback,
		options,
		previousOptions,
		previousValue,
		staticOptions,
		value,
	]);

	const blurInput = () => inputRef.current && inputRef.current.blur();
	const focusInput = () => inputRef.current && inputRef.current.focus();
	const scrollToItemIndex = (index: number) => {
		const menuRefCurrent = menuRef.current;

		if (menuRefCurrent && menuRefCurrent._scrollToItem_) {
			menuRefCurrent._scrollToItem_(index);
		}
	};

	const selectOptionFromFocused = () => {
		if (focusedOption.original) {
			handleSelectItem(focusedOption);
		}
	};

	const focusOptionOnArrowKey = (direction: OptionIndexEnum) => {
		if (!menuOptions || !menuOptions.length) return;

		const index =
			direction === OptionIndexEnum.DOWN
				? (focusedOption.index + 1) % menuOptions.length
				: focusedOption.index > 0
				? focusedOption.index - 1
				: menuOptions.length - 1;

		setFocusedOption({index, ...menuOptions[index]});
		scrollToItemIndex(index);
	};

	const handleUpDownKeySubRoutine = (key: string) => {
		const downKey = key === KEYS.ARROW_DOWN;
		const downUpIndex = downKey ? OptionIndexEnum.DOWN : OptionIndexEnum.UP;
		const posIndex = downKey ? OptionIndexEnum.FIRST : OptionIndexEnum.LAST;

		isMenuOpen
			? focusOptionOnArrowKey(downUpIndex)
			: openMenuAndFocusOption(posIndex);
	};

	// handlers
	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (disabled) {
			return;
		}

		switch (event.key) {
			case KEYS.ARROW_DOWN:
			case KEYS.ARROW_UP:
				handleUpDownKeySubRoutine(event.key);
				break;
			case KEYS.SPACE:
				if (inputValue) {
					return;
				}

				if (!isMenuOpen) {
					openMenuAndFocusOption(OptionIndexEnum.FIRST);
				} else if (!focusedOption.original) {
					return;
				} else {
					selectOptionFromFocused();
				}

				break;
			case KEYS.ENTER:
				// Check e.keyCode !== 229 (Input Method Editor)
				if (isMenuOpen && event.keyCode !== 229) {
					selectOptionFromFocused();
				}
				break;
			case KEYS.ESCAPE:
				if (isMenuOpen) {
					setMenuOpen(false);
					setInputValue('');
				}
				break;
			case KEYS.DELETE:
			case KEYS.BACKSPACE:
				if (inputValue) {
					return;
				}
				setSelectedOption(null);
				break;
			default:
				return;
		}

		event.preventDefault();
	};

	const handleOnMenuMouseDown = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		focusInput();
	};

	const openMenuAndFocusOption = useCallback(
		(position: OptionIndexEnum) => {
			const selectedIndex = menuOptions.findIndex(o => o.isSelected);
			const index =
				selectedIndex > -1
					? selectedIndex
					: position === OptionIndexEnum.FIRST
					? 0
					: menuOptions.length - 1;

			if (menuRef.current) {
				setMenuOpen(true);
			}

			setFocusedOption({
				index,
				...menuOptions[index],
			});
			scrollToItemIndex(index);
		},
		[menuOptions],
	);

	const handleSelectItem = useCallback(
		(option: MenuOption) => {
			if (!option.isSelected) {
				setSelectedOption({
					...option,
					isSelected: true,
				});
			}
			setMenuOpen(false);
			setInputValue('');
			blurInput();
			onChange && onChange(option.value);
		},
		[onChange],
	);

	const handleInputFocus = useCallback(() => {
		setIsFocused(true);
		onFocus && onFocus();
	}, [onFocus]);

	const handleInputBlur = useCallback(() => {
		setIsFocused(false);
		setMenuOpen(false);
		setInputValue('');
		onBlur && onBlur();
	}, [onBlur]);

	const handleInputChange = useCallback(e => {
		const newInputVal = e.currentTarget.value || '';
		setInputValue(newInputVal);
		setMenuOpen(true);
	}, []);

	const handleOnControlMouseDown = e => {
		if (disabled) return;
		if (!isFocused) focusInput();

		const evtTarget = e.target;
		const isNotInput = evtTarget.nodeName !== 'INPUT';

		if (!isMenuOpen) {
			openMenuAndFocusOption(OptionIndexEnum.FIRST);
		} else if (isNotInput) {
			isMenuOpen && setMenuOpen(false);
			inputValue && setInputValue('');
		}

		if (isNotInput) e.preventDefault();
	};

	useImperativeHandle(
		ref,
		() => ({
			blur: blurInput,
			focus: focusInput,
		}),
		[],
	);

	useEffect(() => {
		if (isFocused && !disabled) {
			openMenuAndFocusOption(OptionIndexEnum.FIRST);
		}
	}, [disabled, isFocused, openMenuAndFocusOption]);

	return (
		<div
			className={cn(
				'Health-select-box',
				{
					'Health-select-box--focused': isFocused,
					'Health-select-box--disabled': disabled,
					'Health-select-box--error': hasError,
				},
				className,
			)}
			onKeyDown={handleKeyDown}
		>
			<div
				onMouseDown={handleOnControlMouseDown}
				ref={controlRef}
				className={'Health-select-box__wrapper'}
			>
				<div className={'Health-select-box__value-wrapper'}>
					<Value
						selectedOption={selectedOption}
						optionRenderLabel={optionRenderLabelCallback}
						inputValue={inputValue}
						placeholder={placeholder}
					/>
					<Input
						disabled={disabled}
						readOnly={!searchable}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						onChange={handleInputChange}
						inputValue={inputValue}
						ref={inputRef}
					/>
				</div>
				<Drop isOpen={isMenuOpen} disabled={disabled} />
			</div>
			<Menu<T>
				onMenuMouseDown={handleOnMenuMouseDown}
				onSelectItem={handleSelectItem}
				position={menuPosition}
				itemKeySelector={itemKeySelector}
				options={menuOptions}
				selectedOption={selectedOption}
				focusedOptionIndex={focusedOption.index}
				optionRenderLabel={optionRenderLabelCallback}
				noOptionsMessage={noOptionsMessage}
				ref={menuRef}
				listRef={listRef}
				isOpen={isMenuOpen}
			/>
		</div>
	);
};

SelectBox.displayName = 'HealthSelectBox';

export default React.forwardRef(SelectBox) as typeof SelectBox;
