import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import {hideModal, showSaveChangesModal} from "../../redux/modal/actions";

class PagePrompt extends Component {
    static propTypes = {
        onConfirm: PropTypes.func,
        onCancel: PropTypes.func,
        showSaveChangesModal: PropTypes.func,
        hideModal: PropTypes.func,
        title: PropTypes.string,
        message: PropTypes.string,
        btnSave: PropTypes.string,
        btnCancel: PropTypes.string,
    };

    componentDidMount() {
        const { onConfirm, onCancel, onClose, title, message, btnSave, btnCancel, payload, hideModal } = this.props;
        this.props.showSaveChangesModal({
            payload,
            title,
            message,
            btnSave,
            btnCancel,
            isPromptModal: true,
            onSuccess: () => {
                hideModal();
                onConfirm();
            },
            onCancel: () => {
                hideModal();
                onCancel();
            },
            onClose: () => {
                hideModal();
                onClose();
            }
        });
    }


    render() {
        return null;
    }
}

const mapDispatchToProps = { showSaveChangesModal, hideModal };

export default connect(null, mapDispatchToProps)(PagePrompt);
