import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {FormattedMessage} from "react-intl";

import './ConsultationMenu.scss';
import Separator from '../../../../components/common/Separator/Separator';

const ConsultationMenu = ({match}) => {
    return (
        <div className="Consultation-page__consultation-menu">
            <div className="Consultation-page__consultation-menu_title">
                <FormattedMessage id={"request.menu.title"} />
            </div>
            <Separator />
            <NavLink
                exact
                activeClassName="Consultation-page__consultation-menu_item--active"
                className="Consultation-page__consultation-menu_item"
                to={`${match.url}`}>
                <React.Fragment>
                    <span className="Consultation-page__consultation-menu_item-text">
                        <FormattedMessage id={"request.menu.request"} />
                    </span>
                    <div className="Consultation-page__consultation-menu_bookmark" />
                </React.Fragment>
            </NavLink>
            <Separator />
            <NavLink
                activeClassName="Consultation-page__consultation-menu_item--active"
                className="Consultation-page__consultation-menu_item"
                to={`${match.url}/files`}>
                <React.Fragment>
                    <span className="Consultation-page__consultation-menu_item-text">
                        <FormattedMessage id={"request.menu.files"} />
                    </span>
                    <div className="Consultation-page__consultation-menu_bookmark" />
                </React.Fragment>
            </NavLink>
            <Separator />
            <NavLink
                activeClassName="Consultation-page__consultation-menu_item--active"
                className="Consultation-page__consultation-menu_item"
                to={`${match.url}/chat`}>
                <React.Fragment>
                    <span className="Consultation-page__consultation-menu_item-text">
                        <FormattedMessage id={"request.menu.chat"} />
                    </span>
                    <div className="Consultation-page__consultation-menu_bookmark" />
                </React.Fragment>
            </NavLink>
            <Separator />
            <NavLink
                activeClassName="Consultation-page__consultation-menu_item--active"
                className="Consultation-page__consultation-menu_item"
                to={`${match.url}/conclusion`}>
                <React.Fragment>
                    <span className="Consultation-page__consultation-menu_item-text">
                        <FormattedMessage id={"request.menu.conclusion"} />
                    </span>
                    <div className="Consultation-page__consultation-menu_bookmark" />
                </React.Fragment>
            </NavLink>
            <Separator />
        </div>
    )
};

export default withRouter(ConsultationMenu);
