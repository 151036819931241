import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './InfoPanel.scss';

export const InfoPanel = React.forwardRef(
    ({className, title, message, fixedHeight}, ref) => {
        return (
            <div
                ref={ref}
                className={cn('Health-info-panel', className, {
                    'Health-info-panel--fixed': fixedHeight,
                })}>
                {
                    title &&
                    <div className="Health-info-panel__title">{title}</div>
                }
                <div
                    className="Health-info-panel__body"
                    dangerouslySetInnerHTML={{__html: message}}/>
            </div>
        )
    },
);

InfoPanel.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    fixedHeight: PropTypes.bool,
};

InfoPanel.defaultProps = {
    fixedHeight: false,
    message: '',
};

InfoPanel.displayName = 'HealthInfoPanel';
