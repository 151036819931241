import type {Option} from '../../common/SelectBox/SelectBox.interface';
import { useStaticOption } from '../../common/SelectBox/hooks/use-static-option';

const ALL_VALUE = 'all';

const isAllOptionValue = (v: unknown): v is typeof ALL_VALUE => (
	v === ALL_VALUE
);

export const unsetWithAllOptionValue = <T extends object>(v: T) => {
	return Object.keys(v).reduce((data, nextKey) => {
		const value = v[nextKey];

		if (isAllOptionValue(value)) {
			data[nextKey] = null;
			return data;
		}

		data[nextKey] = value;

		return data;
	}, {} as any)
}

export const useCountryStaticOptions = (): Option[] => {
	return useStaticOption({
		id: ALL_VALUE,
		labelTextId: 'common.countries.all'
	});
};

export const useDoctorStaticOptions = (): Option[] => {
	return useStaticOption({
		id: ALL_VALUE,
		labelTextId: 'common.doctors.all'
	});
};

export const useClinicStaticOptions = (): Option[] => {
	return useStaticOption({
		id: ALL_VALUE,
		labelTextId: 'common.clinics.all'
	});
};
