// TODO: Rename 'Role'
export enum ROLES {
    ADMIN = 'ADMIN',
    ADMIN_CLINIC = 'ADMIN_CLINIC',
    PARTNER = 'PARTNER',
    DOCTOR = 'DOCTOR',
    PATIENT = 'PATIENT'
};

export enum RoleAccessRight {
    ADMIN = 1,
    ADMIN_CLINIC = 11,
    PARTNER = 22,
    DOCTOR = 33,
    PATIENT = 99,
};

export const ROLE_CODE = {
    1: ROLES.ADMIN,
    11: ROLES.ADMIN_CLINIC,
    22: ROLES.PARTNER,
    33: ROLES.DOCTOR,
    99: ROLES.PATIENT,
};

export const UserRoleCode = {
    [ROLES.ADMIN]: 1,
    [ROLES.ADMIN_CLINIC]: 11,
    [ROLES.PARTNER]: 22,
    [ROLES.DOCTOR]: 33,
    [ROLES.PATIENT]: 99,
};

export type RoleCodes = keyof typeof ROLE_CODE;

export const LK_ROLES = [ROLES.PATIENT];
export const B2B_ROLES = [ROLES.ADMIN, ROLES.ADMIN_CLINIC, ROLES.PARTNER, ROLES.DOCTOR];

export const toUserRole = (code: RoleCodes) => ROLE_CODE[code];
export const toUserRoleCode = (role: ROLES) => UserRoleCode[`${role}`];
export const checkUserRoleExists = (role: string) => {
    role = `${role}`.toUpperCase();
    return !ROLE_CODE[role];
};