import React from 'react';
import cn from 'classnames';

import './RequestSectionTitle.scss';

export const RequestSectionTitle = ({className, children}) => {
    return (
        <div className={cn('Request-section-title', className)}>
            {children}
        </div>
    )
};
