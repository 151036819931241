import React from 'react';
//
import cn from 'classnames';

import './Button.scss';

interface Props {
    className?: string;
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler;
}

export const ButtonAsLink = React.forwardRef<HTMLButtonElement, Props>(({
    className, 
    children,
    onClick
}, ref) => {
        const classes = cn(
            className,
            "Health-button-link",
        );

        return (
            <button ref={ref} className={classes} onClick={onClick}>
                {children}
            </button>
        );
    }
);

ButtonAsLink.displayName = "HealthButtonAsLink";