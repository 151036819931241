import Immutable from 'seamless-immutable';

import { SET_ACCOUNT_PAGE_LOADING } from './actions';

const INITIAL_STATE = {
    loading: true
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ACCOUNT_PAGE_LOADING:
            return Immutable.merge(state, { loading: action.payload });
        default:
            return state;
    }
}
