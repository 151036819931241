import React, {useCallback, useEffect, useState} from 'react'
import cn from "classnames";
import {throttle} from 'lodash';

import "./SimpleTable.scss";
import {useSimpleTable, mergeProps} from "./hookTable";
import Spinner from "../../Spinner/Spinner";

import type {Column, InternalColumn, InitialState, Row} from './SimpleTable.interface';

const defaultPropGetter = (param) => ({});

interface Props<T> {
    data: T[];
    className?: string;
    columns: Array<Column<T>>;
    percentWidth?: boolean;
    //
    getRowProps?: Function;
    getCellProps?: Function;
    getTdProps?: Function;
    getThProps?: Function;
    getHeaderProps?: Function;
    //
    onChangeSort: Function;
    initialState?: InitialState;
    isLoading?: boolean;
    withBorder?: boolean
}

//TODO: testing
const SimpleTable = <T, >(
    {
        columns,
        data,
        getRowProps = defaultPropGetter,
        getCellProps = defaultPropGetter,
        getHeaderProps = defaultPropGetter,
        getTdProps = defaultPropGetter,
        getThProps = defaultPropGetter,
        onChangeSort,
        initialState,
        isLoading = false,
        withBorder = false,
        className,
    }: Props<T>
    ) => {
        const wrapperRef = React.useRef<HTMLDivElement>();
        const table = useSimpleTable<T>({
            columns,
            data,
            onChangeSort,
            initialState,
        });
        const containerRef = React.useRef<HTMLDivElement>();
        const [scrollWidth, setScrollWidth] = useState(0);

        const updateScroll = useCallback(() => {
            if (wrapperRef.current && containerRef.current) {
                setScrollWidth(wrapperRef.current.clientWidth - containerRef.current.clientWidth);
            }
        }, []);

        useEffect(() => {
            updateScroll()
        }, [data, updateScroll]);

        useEffect(() => {
            const throttleUpdate = throttle(updateScroll, 50)
            window.addEventListener('resize', throttleUpdate);

            return () => {
                window.removeEventListener('resize', throttleUpdate);
            }
        }, [updateScroll]);

        const getRowOddProps = (row: Row<T>) => ({
            className: row.isOdd ? "Table__row--odd" : "",
        });

        const renderHeaders = (columns: Array<InternalColumn<T>>) => (
            <div className="Table__thead">
                {
                    columns.map(col => {
                        return (
                            <div
                                key={col.id}
                                {...mergeProps(
                                    col.getHeaderProps({
                                        className: cn("Table__column Table__cell Table__cell-header", {
                                            "Table__cell-header--sortable": col.sortable,
                                            "Table__cell-header--complex": !!col.columns,
                                            "Table__cell-header--last": col.isLast,
                                            "Table__cell-header--highlight": col.highlight,
                                        })
                                    }),
                                    getThProps(col)
                                )}>
                                {col.render('Header', getHeaderProps(col))}
                                {
                                    col.columns ? renderHeaders(col.columns) : null
                                }
                            </div>
                        )
                    })
                }
            </div>
        );

        // Render the UI for your table
        return (
            <div className={cn("Table__wrapper", {
                "Table__wrapper--with-border": withBorder,
            }, className)} ref={wrapperRef}>
                <div className="Table__header-container" style={{paddingRight: scrollWidth}}>
                    <div className="Table">
                        {
                            renderHeaders(table.columns)
                        }
                    </div>
                </div>
                <div className="Table__container" ref={containerRef}>
                    <Spinner isLoading={isLoading}/>
                    <div className="Table">
                        <div className="Table__body">
                            {table.rows.map(
                                (row, i) => {
                                    table.prepareRow(row);
                                    return (
                                        <div {...mergeProps(
                                            row.getRowProps({className: "Table__row"}),
                                            getRowOddProps(row),
                                            getRowProps(row)
                                        )}>
                                            {row.cells.map(cell => {
                                                return <div {...mergeProps(
                                                    cell.getCellProps({
                                                        className: cn("Table__cell", {
                                                            "Table__cell--justify-right": cell.column.align === 'right',
                                                            "Table__cell--justify-center": cell.column.align === 'center',
                                                            "Table__cell--highlight": cell.column.highlight,
                                                        })
                                                    }),
                                                    getTdProps(cell)
                                                )}>
                                                    {cell.render('Cell', getCellProps(cell))}
                                                </div>
                                            })}
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
;


export default SimpleTable;
