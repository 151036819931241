import React from 'react';
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './ConfirmModal.scss';
import Button from "../../common/Button/Button";

const ConfirmModal = ({
                          title,
                          message,
                          question,
                          btnOk,
                          btnCancel,
                          onSuccess,
                          onCancel,
                          hideModal,
                      }) => {
    const handleOk = React.useCallback(() => {
        onSuccess && onSuccess();
        hideModal();
    }, [hideModal, onSuccess]);
    const handleCancel = React.useCallback(() => {
        onCancel && onCancel();
        hideModal();
    }, [hideModal, onCancel]);

    return (
        <React.Fragment>
            <div className="Modal__header">
                <FontAwesomeIcon className="Modal-confirm__icon"
                                 icon={faQuestionCircle}
                                 color="#6592ab"/>
                <div className="Modal__title">{title}</div>
            </div>
            <div className="Modal__body">
                <p className="Modal__message">
                    {message}
                </p>
                {
                    question &&
                    <div className="Modal__message mt-1">
                        {question}
                    </div>
                }
            </div>
            <div className="Modal__footer">
                <Button variant="gray" viewType="minimal" onClick={handleCancel}>{btnCancel}</Button>
                <Button onClick={handleOk}>{btnOk}</Button>
            </div>
        </React.Fragment>
    );
};

export default ConfirmModal;
