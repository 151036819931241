import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '../Button/Button';

import './SafeButton.scss';

const SafeButton = React.forwardRef(
    (
        {className, loading, isSuccess, doneText, children, ...props},
        ref
    ) => {
        const [successState, setSuccessState] = React.useState(false);
        const classes = cn(
            className,
            loading && 'Health-button--loading',
            successState && 'Health-button--done',
        );

        React.useEffect(() => {
            setSuccessState(isSuccess);
            let id;
            if (isSuccess) {
                id = setTimeout(() => {
                    setSuccessState(false);
                }, 3000);
            }
            return () => clearTimeout(id);
        }, [isSuccess]);

        return (
            <Button className={classes} ref={ref} {...props}>
                { successState ? doneText : children}
            </Button>
        )
    }
);

SafeButton.propTypes = {
    ...Button.propTypes,
    loading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    doneText: PropTypes.string
};

SafeButton.defaultProps = {
    ...Button.defaultProps,
    loading: false,
    isSuccess: false
};

SafeButton.displayName = "HealthSafeSafeButton";

export default SafeButton;
