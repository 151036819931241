import {
    INIT_VIEW_CONCLUSION, SET_VIEW_CONCLUSION_CLINIC_INFO, SET_VIEW_CONCLUSION_DATA, SET_VIEW_CONCLUSION_DOCTOR_INFO,
    SET_VIEW_CONCLUSION_ERROR,
    SET_VIEW_CONCLUSION_LOADING,
    SET_VIEW_CONCLUSION_PREVIEW_DATA,
} from './actions';
import Immutable from 'seamless-immutable';

const INITIAL_STATE = {
    loading: false,
    doctorInfo: null,
    clinicInfo: null,
    lang: null,
    data: null,
    previewData: null,
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT_VIEW_CONCLUSION:
            return Immutable(INITIAL_STATE);
        case SET_VIEW_CONCLUSION_LOADING:
            return Immutable.merge(state, {
                loading: action.payload,
            });
        case SET_VIEW_CONCLUSION_ERROR:
            return Immutable.merge(state, {
                loading: false,
                error: action.payload,
            });
        case SET_VIEW_CONCLUSION_PREVIEW_DATA:
            return Immutable.merge(state, {
                previewData: action.payload,
            });
        case SET_VIEW_CONCLUSION_CLINIC_INFO:
            return Immutable.merge(state, {
                clinicInfo: action.payload,
            });
        case SET_VIEW_CONCLUSION_DOCTOR_INFO:
            return Immutable.merge(state, {
                doctorInfo: action.payload,
            });
        case SET_VIEW_CONCLUSION_DATA:
            return Immutable.merge(state, {
                data: action.payload.data,
                lang: action.payload.lang,
            });
        default:
            return state;
    }
}
