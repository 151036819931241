import axios from "axios";
import apiRoutes from "../constants/ApiRoutes";

export const loadTranslates = (params) => {
    return axios.get(apiRoutes.transList, {
        params: params || {},
    });
};

export const addTranslate = (params) => {
    const {pagename, param, ...rest} = params || {};

    return axios.post(apiRoutes.transEdit, {
        pagename,
        param,
        ...rest,
    });
};

export const updateTranslate = (params) => {
    const {id, pagename, param, ...rest} = params || {};

    return axios.post(apiRoutes.transEdit, {
        id,
        pagename,
        param,
        ...rest,
    });
};

export const removeTranslate = (params) => {
    const id = (params || {}).id;

    return axios.post(apiRoutes.transEdit, {
        id,
        del: true,
    });
};