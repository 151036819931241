import axios from "axios";
import apiRoutes from "../constants/ApiRoutes";

export const pageLoadTranslations = ({pageName, lang}) => {
    return axios.get(apiRoutes.pageLang, {
        params: {
            page: pageName,
            lang
        }
    })
};
