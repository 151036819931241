export default {
    "ru": {
        "pass": "Новый пароль",
        "pass_error": "Неверно задан пароль",
        "pass_help": "Пароль должен содержать минимум одну строчную, одну прописную букву и одну цифру и быть минимум 8 символов",
        "confirm_pass": "Повторите пароль",
        "confirm_pass_error": "Пароли не совпадают",
        "h1": "Востановление пароля",
        "links_conf": "Политика конфиденциальности",
        "but_send": "Восстановить",
        "but_back_main": "Вернуться на главную",
        "copyright": "&copy; 2019-2021 best2health",
        "ok_head": "Восстановление пароля",
        "error_head": "Ошибка восстановления пароля",
        "ok_text": "Ваш пароль восстановлен.",
    },
    "en": {
        "pass": "New password",
        "pass_error": "Invalid password",
        "pass_help": "Password must contain at least one lowercase, one uppercase letter and one number and must be at least 8 characters",
        "confirm_pass": "Confirm password",
        "confirm_pass_error": "Passwords do not match",
        "but_send": "Recovery",
        "but_back_main": "Back to main",
        "copyright": "&copy; 2019-2021 best2health",
        "ok_head": "Recovery password",
        "error_head": "Error recovery password",
        "ok_text": "Your password has been recovered.",
    },
}
