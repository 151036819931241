import React from 'react';
import {FormattedDate} from "react-intl";

const DateCell = React.memo((props) => {
    return (
        <div className={props.className}>
            {
                props.cell.value &&
                <FormattedDate value={props.cell.value}/>
            }
        </div>
    )
});

export default DateCell;
