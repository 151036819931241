import {createSelector} from 'reselect';
import * as commonSelectors from '../common/selectors';
import Immutable from 'seamless-immutable';

export const getViewClinicSelector = state => state.viewClinic;

export const isLoadingSelector = createSelector(
    getViewClinicSelector,
    page => page.loading,
);

export const getClinicDataSelector = createSelector(
    getViewClinicSelector,
    commonSelectors.getCurrenciesSelector,
    (page, currencies) => {
        if (page.clinic) {
            const currency = currencies.find(c => c.id == page.clinic.consult.id);
            return Immutable.asMutable({
                ...page.clinic,
                consult: {
                    ...page.clinic.consult,
                    code: currency ? currency.code3 : '',
                },
            }, {deep: true})
        }
    },
);

export const getClinicErrorSelector = createSelector(
    getViewClinicSelector,
    page => page.error,
);

export const getSpecialists = createSelector(
    getViewClinicSelector,
    page => page.doctors,
);

export const getDepartments = createSelector(
    getViewClinicSelector,
    (page) => {
        if (!page.doctors) {
            return [];
        }

        const specialities = new Set();
        const doctors = Immutable.asMutable(page.doctors, {deep: true});

        doctors.forEach((doctor) => {
            doctor.specs.forEach(specialities.add, specialities);
        });

        return Array.from(specialities)
            .sort((a, b) => {
                return a.localeCompare(b);
            })
            .map((speciality) => {
                return {
                    speciality,
                    doctors: doctors.filter((d) => d.specs.includes(speciality)),
                }
            })
    },
);

export const getLangCommunication = createSelector(
    getViewClinicSelector,
    (page) => {
        if (page.clinic) {
            const {langs = [], lang_com = []} = Immutable.asMutable(page.clinic, {deep: true});
            return [
                ...langs.filter(l => l.code).map(l => ({
                    ...l,
                    translate: false,
                })).sort((a, b) => a.label.localeCompare(b.label)),
                ...lang_com.map(l => ({
                    ...l,
                    translate: true,
                })).sort((a, b) => a.label.localeCompare(b.label)),
            ];
        }
        return [];
    },
);
