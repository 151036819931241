import React, {useCallback, useMemo} from 'react';

import FileIcon from '../../../../common/FileIcon/FileIcon';
import { Attachment } from '../../../Chat.interface';

interface Props {
    attachment: Attachment;
    onClick: (data: Attachment) => void;
}

const MessageAttachment = ({attachment, onClick}: Props) => {
    const name = useMemo(() => {
        return attachment.name || attachment.id
    }, [attachment.id, attachment.name]);

    const handleClick = useCallback(() => {
        onClick && onClick(attachment);
    }, [attachment, onClick]);

    return (
        <div className="Health-chat__message-attachment">
            <FileIcon
                onClick={handleClick}
                ext={attachment.ext} />
            <a
                tabIndex={-1}
                className="Health-chat__message-attachment_link"
                target="_blank"
                rel="noreferrer noopener"
                href={attachment.url}>
                <span
                    onClick={handleClick}
                    className="Health-chat__message-attachment_text">
                    {name}
                </span>
            </a>
        </div>
    )
}

MessageAttachment.displayName = 'ChatMessageAttachment';

export default MessageAttachment;
