import axios from "axios";
import apiRoutes from "../constants/ApiRoutes";

export const loadUserInfo = ({full, lang, id} = {}) => {
    return axios.get(apiRoutes.userInfo, {
        params: {
            full,
            ...lang ? {lang} : {},
            ...id ? {id} : {},
        }
    });
};

export const logout = () => {
    return axios.get(apiRoutes.logout);
};

export const updateUser = ({userId, profile}) => {
    return axios.post(apiRoutes.userEdit, {
        ...profile,
        ...userId ? {id: userId} : {}
    });
};

export const changeEmail = ({email, password}) => {
    return axios.post(apiRoutes.userEdit, new URLSearchParams({
        email,
        password
    }));
};

export const changePassword = ({password, newpassword}) => {
    return axios.post(apiRoutes.userEdit, new URLSearchParams({
        password,
        newpassword
    }));
};

export const removeAccount = (password) => {
    return axios.post(apiRoutes.userEdit, {
        password,
        delete: true
    });
};

export const login = (email, password) => {
    return axios.post(apiRoutes.login, new URLSearchParams({
        u: email,
        p: password,
    }));
};

export const confirmEmail = (hash) => {
    return axios.get(apiRoutes.confirmEmail, {
        params: {
            confirm: hash
        }
    });
};

export const registerPatient = (lang, params) => {
    return axios.post(apiRoutes.userReg, new URLSearchParams({
        ...params,
        lang,
    }));
};

export const rememberPassword = (email, captcha) => {
    return axios.post(apiRoutes.remPass, new URLSearchParams({
        email,
        'g-recaptcha-response': captcha,
    }))
}

export const recoveryAccount = (token, newpassword) => {
    return axios.post(apiRoutes.remPass, new URLSearchParams({
        confirm: token,
        newpassword,
    }))
}
