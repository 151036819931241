import React, {useState} from 'react';
import SafeButton from "../../../components/common/SafeButton/SafeButton";
import {Form} from "react-final-form";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import TextBoxField from "../../../components/common/TextBox/TextBoxField";
import {changePassword} from "../../../redux/user/actions";
import {PASSWORD_REG_EXP} from "../../../constants/RegExps";
import {isEmptyObject} from "../../../helpers/Common";
import ErrorCodes from "../../../constants/ErrorCodes";

const ChangePassword = ({changePassword}) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const intl = useIntl();

    const onSubmit = async (values) => {
        const errors = {};
        if (!values.currpassword) {
            errors.currpassword = intl.formatMessage({id: 'security.pass_error'});
        }
        if (!values.newpassword || !PASSWORD_REG_EXP.test(values.newpassword)) {
            errors.newpassword = intl.formatMessage({id: 'security.pass_error'});
        }
        if (!values.confirmpassword || values.newpassword !== values.confirmpassword) {
            errors.confirmpassword = intl.formatMessage({id: 'security.confirm_pass_error'});
        }
        if (!isEmptyObject(errors)) {
            return errors;
        }
        const {error, status} = await changePassword({password: values.currpassword, newpassword: values.newpassword});
        if (!status) {
            if (error === ErrorCodes.WrongCurrentPassword) {
                return {
                    currpassword: intl.formatMessage({id: 'security.pass_error'})
                }
            }
        } else {
            setIsSuccess(true);
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            render={
                ((props) => (
                    <form onSubmit={
                        (event) => props.handleSubmit(event).then((res) => {
                            !res && props.reset()
                        })} autoComplete="off" className="Flex-column Health-form"
                          noValidate>
                        <fieldset className="Health-fieldset Flex-1">
                            <legend className="Health-fieldset__legend">
                                <FormattedMessage id="security.h2_pass"/>
                            </legend>
                            <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />
                            <TextBoxField
                                inline
                                type="password"
                                name="currpassword"
                                autoComplete="new-password"
                                label={intl.formatMessage({id: 'security.current_pass'})}
                                labelClass="col-4"
                                inputClass="col-6"/>
                            <TextBoxField
                                inline
                                type="password"
                                name="newpassword"
                                autoComplete="new-password"
                                label={intl.formatMessage({id: 'security.new_pass'})}
                                hint={intl.formatMessage({id: 'security.pass_help'})}
                                labelClass="col-4"
                                inputClass="col-6"/>
                            <TextBoxField
                                inline
                                type="password"
                                name="confirmpassword"
                                autoComplete="confirm-password"
                                label={intl.formatMessage({id: 'security.confirm_pass'})}
                                labelClass="col-4"
                                inputClass="col-6"/>
                        </fieldset>
                        <div className="Flex-row Justify-center Actions">
                            <SafeButton
                                viewType="minimal"
                                disabled={props.pristine && !props.dirty}
                                type="submit"
                                loading={props.submitting}
                                isSuccess={isSuccess}
                                doneText={intl.formatMessage({id: 'common.info_save'})}>
                                <FormattedMessage id="common.buttons.save"/>
                            </SafeButton>
                        </div>
                    </form>
                ))}/>
    );
};

export default connect(null, {
    changePassword
})(ChangePassword);
