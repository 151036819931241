import { Option } from '../SelectBox.interface';

export const normalizeOption = (
    option: Option, 
    getOptionLabel: (o: Option) => string,
    getOptionValue: (o: Option) => number,
) => {
    if (!option) {
        return null;
    }

    return {
        original: option,
        value: getOptionValue(option),
        label: getOptionLabel(option),
    };
}