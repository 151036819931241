import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'react-final-form-arrays';
import {useForm} from 'react-final-form';
import {FormattedHTMLMessage, FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";

import './RequestDocumentManager.scss';
import Hint from '../../../../../../components/common/Hint/Hint';
import RequestDocumentItem from './RequestDocumentItem';
import Button from "../../../../../../components/common/Button/Button";
import {useLoadFiles} from "../../../../../../hooks/useLoadFiles";
import Spinner from "../../../../../../components/Spinner/Spinner";
import {showAddFilesFromMedicalRecordsModal} from "../../../../../../redux/modal/actions";

const RequestDocumentManager = () => {
    const intl = useIntl();
    const formApi = useForm();
    const uploader = useLoadFiles();
    const dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);

    const handleAddNewDocument = useCallback(async (event) => {
        setUploading(true);
        const attachedFiles = Array.from(event.target.files);

        const uploadedFiles = await uploader(attachedFiles);

        const files = uploadedFiles.map(({error, name, value, file}) => {
            if (error) {
                return undefined;
            }
            return {
                file: {
                    name,
                    result: value,
                    date: file.lastModifiedDate,
                },
                label: name,
            }
        }).filter(Boolean);

        formApi.mutators.addNewDocuments(files);
        setUploading(false);
    }, [uploader, formApi.mutators]);

    const handleAddFromMedicalRecords = useCallback(() => {
        dispatch(showAddFilesFromMedicalRecordsModal({
            onAddRecords: (records = []) => {
                formApi.mutators.addMedicalRecords(records);
            }
        }));
    }, [dispatch, formApi.mutators]);

    return (
        <fieldset className="Health-fieldset Request-document-manager mr-1">
            <div className="Flex-row mb-3">
                <div className="Flex-1">
                    <Hint className="mt-1">
                        <FormattedHTMLMessage id={"request.form.files_hint"}/>
                    </Hint>
                </div>
                <div className="Health-form__field Flex-row Align-end ml-1">
                    <div className="Flex-row Align-center">
                        <Button
                            className={"button--upload"}
                            viewType="minimal"
                            size="sm">
                            <FormattedMessage id={"request.buttons.add_from_device"}/>
                            <input
                                title={""}
                                multiple
                                type="file"
                                value={''}
                                accept="application/pdf, image/jpeg, image/png"
                                onChange={handleAddNewDocument}/>
                        </Button>
                        <Button
                            className={"ml-1"}
                            onClick={handleAddFromMedicalRecords}
                            viewType="minimal"
                            size="sm">
                            <FormattedMessage id={"request.buttons.add_from_medical_records"}/>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="Request-document-manager__files-container">
                <FieldArray name={'files'}>
                    {
                        ({fields: files}) => (
                            files.map((fileName, index) => (
                                    <RequestDocumentItem
                                        key={index}
                                        onRemove={() => files.remove(index)}
                                        fieldName={fileName}
                                        translations={{
                                            placeholder: intl.formatMessage({id: 'request.form.files_placeholder'}),
                                            viewBtnText: intl.formatMessage({id: 'request.form.files_viewBtnText'}),
                                            editBtnText: intl.formatMessage({id: 'request.form.files_editBtnText'}),
                                            deleteBtnText: intl.formatMessage({id: 'request.form.files_deleteBtnText'}),
                                        }}/>
                                )
                            )
                        )
                    }
                </FieldArray>
                <Spinner isLoading={uploading}/>
            </div>
        </fieldset>
    );
};

RequestDocumentManager.propTypes = {
    translations: PropTypes.shape({
        viewBtnText: PropTypes.string,
        editBtnText: PropTypes.string,
        deleteBtnText: PropTypes.string,
        title: PropTypes.string,
        hint: PropTypes.string,
    }),
};
RequestDocumentManager.defaultProps = {
    translations: {},
};
RequestDocumentManager.displayName = 'RequestHealthDocumentManager';

export default RequestDocumentManager;
