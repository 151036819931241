import React from 'react';
import _isNull from 'lodash/isNull';

import BadgeV2 from 'components/common/Badge/BadgeV2';

interface Props {
	onClick: React.MouseEventHandler;
	name: string;
	count: number;
	showBadge: boolean;
	withoutCount: boolean;
	className?: string;
}

export const QuickFilterItem = ({
	onClick,
	name,
	count,
	withoutCount,
	showBadge,
	className,
}: Props) => {
	return (
		<BadgeV2 className={className} variant="ruby" transparent={!showBadge}>
			<span onClick={onClick} className="Quick-filter__item-label">
				{name}
			</span>
			{!withoutCount ? <>&nbsp;({count})</> : null}
		</BadgeV2>
	);
};
