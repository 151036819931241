import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import NavigationPrompt from 'react-router-navigation-prompt';
import {useHistory} from 'react-router-dom';

import PagePrompt from '../../../../components/PagePrompt/PagePrompt';
import RequestStepIds from '../../../../constants/RequestStepIds';
import {
    showViewClinicDetailsModal,
    showViewDoctorDetailsModal,
    showConfirmModal,
} from '../../../../redux/modal/actions';
import {RequestCheckout} from '../RequestCheckout/RequestCheckout';
import {RequestConsultants} from '../RequestConsultants/RequestConsultants';
import {RequestConfirm} from '../RequestConfirm/RequestConfirm';
import {RequestDeclinePage} from '../RequestDeclinePage/RequestDeclinePage';
import {RequestForm} from '../RequestForm/RequestForm';
import {RequestPayment} from '../RequestPayment/RequestPayment';
import {RequestReplacement} from '../RequestReplacement/RequestReplacement';
import {RequestSending} from '../RequestSending/RequestSending';
import {RequestSuccess} from '../RequestSuccess/RequestSuccess';
import {RequestManagerContext} from './RequestManagerContext';
import * as managerSelectors from '../../../../redux/requestPage/manager/selectors';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';
import * as managerActions from '../../../../redux/requestPage/manager/actions';
import * as pageActions from '../../../../redux/requestPage/actions';
import * as notifierActions from "../../../../redux/notifier/actions";

const RequestManager = ({requestId, isNew}) => {
    const dispatch = useDispatch();
    const currentStep = useSelector(managerSelectors.getCurrentStepSelector);
    const [initialized, setInitialized] = useState(false);
    const isRequestDirty = useSelector(requestSelectors.isRequestDirty);
    const isRequestDeclined = useSelector(requestSelectors.isRequestDeclined);
    const isRequestLoading = useSelector(requestSelectors.isRequestLoading);
    const intl = useIntl();
    const history = useHistory();
    const [backLink, setBackLink] = useState('/main/requests');

    useEffect(() => {
        if (!initialized) {
            if (isNew) {
                dispatch(managerActions.initializeNewRequest());
            } else {
                dispatch(managerActions.loadRequest(requestId)).catch(() => {
                    dispatch(notifierActions.notifyError(
                        intl.formatMessage({id: 'request.notifications.error_load'})
                    ));
                });
            }
            setInitialized(true);
        }
    }, [dispatch, initialized, intl, isNew, requestId]);

    useEffect(() => {
        const {location: {state}} = history;

        if (state) {
            const link = `${state.pathname}${state.search}`;

            setBackLink(link);
            dispatch(managerActions.setBackLink(link));
        }
    }, [dispatch, history]);

    const renderStep = useCallback((stepId) => {
        switch (stepId) {
            case RequestStepIds.RequestConsultantsId:
                return <RequestConsultants/>;
            case RequestStepIds.RequestFormId:
                return <RequestForm/>;
            case RequestStepIds.RequestCheckoutId:
                return <RequestCheckout/>;
            case RequestStepIds.RequestSuccessId:
                return <RequestSuccess
                    title={intl.formatMessage({id: 'request.success_sent_page.title'})}
                    message={intl.formatHTMLMessage({id: 'request.success_sent_page.message'})}/>;
            case RequestStepIds.RequestSendingId:
                return <RequestSending/>;
            case RequestStepIds.RequestConfirmId:
                return <RequestConfirm/>;
            case RequestStepIds.RequestReplacementId:
                return <RequestReplacement/>;
            case RequestStepIds.RequestDeclineId:
                return <RequestDeclinePage/>;
            case RequestStepIds.RequestPaymentId:
                return <RequestPayment/>;
            case RequestStepIds.RequestPaymentSuccessId:
                return <RequestSuccess
                    title={intl.formatMessage({id: 'request.success_paid_page.title'})}
                    message={intl.formatHTMLMessage({id: 'request.success_paid_page.message'})}/>;
            default:
                return null;
        }
    }, [intl]);

    const showLoader = useCallback((state) => {
        if (state) {
            dispatch(pageActions.showRequestPageLoader());
        } else {
            dispatch(pageActions.hideRequestPageLoader());
        }
    }, [dispatch]);

    const handleGoRequestTable = useCallback(() => {
        history.push(backLink);
    }, [backLink, history]);

    const handleGoNextPage = useCallback(() => {
        dispatch(managerActions.goNextStep());
    }, [dispatch]);

    const handleGoPrevPage = useCallback(() => {
        dispatch(managerActions.goPrevStep());
    }, [dispatch]);

    const handleSaveAndExit = useCallback(async () => {
        try {
            await dispatch(managerActions.saveAndExit());
            dispatch(notifierActions.notifySuccess(
                intl.formatMessage({id: "request.notifications.request_saved"})
            ));
        } catch (err) {
            dispatch(notifierActions.notifyError(
                intl.formatMessage({id: "request.notifications.error_saved"})
            ));
        }
    }, [dispatch, intl]);

    const handleGoToPage = useCallback((pageId) => {
        dispatch(managerActions.goToStep(pageId));
    }, [dispatch]);

    const handleViewDoctor = useCallback((id, noSelect, onSelect) => {
        dispatch(showViewDoctorDetailsModal({
            doctorId: id,
            noSelect,
            onSelect,
        }));
    }, [dispatch]);

    const handleViewClinic = useCallback((id, noSelect, onSelect) => {
        dispatch(showViewClinicDetailsModal({
            clinicId: id,
            noSelect,
            onSelect,
        }));
    }, [dispatch]);

    const handleCancelRequest = useCallback(() => {
        dispatch(showConfirmModal({
            title: intl.formatMessage({id: 'request.modal_refuse.title'}),
            message: intl.formatMessage({id: 'request.modal_refuse.message'}),
            btnOk: intl.formatMessage({id: 'request.modal_refuse.btn_refuse'}),
            btnCancel: intl.formatMessage({id: 'request.modal_refuse.btn_cancel'}),
            onSuccess: async () => {
                try {
                    await dispatch(managerActions.cancelRequest())
                } catch (error) {
                    dispatch(notifierActions.notifyError(
                        intl.formatMessage({id: "request.notifications.error_cancel"})
                    ));
                }
            },
        }));
    }, [dispatch, intl]);

    const handleConfirmRequest = useCallback(async () => {
        try {
            await dispatch(managerActions.confirmRequest(RequestStepIds.RequestPaymentId));
        } catch (error) {
            dispatch(notifierActions.notifyError(
                intl.formatMessage({id: "request.notifications.error_confirm"})
            ));
        }
    }, [dispatch, intl]);

    const handleConfirmReplacement = useCallback(async () => {
        try {
            await dispatch(managerActions.confirmRequest(RequestStepIds.RequestPaymentId));
        } catch (error) {
            dispatch(notifierActions.notifyError(
                intl.formatMessage({id: "request.notifications.error_confirm"})
            ));
        }
    }, [dispatch, intl]);

    const handlePayRequest = useCallback(async () => {
        try {
            await dispatch(managerActions.payRequest());
        } catch (error) {
            dispatch(notifierActions.notifyError(
                intl.formatMessage({id: "request.notifications.error_payment"})
            ));
        }
    }, [dispatch, intl]);

    return (
        <React.Fragment>
            <RequestManagerContext.Provider value={{
                backLink,
                goNextPage: handleGoNextPage,
                goPrevPage: handleGoPrevPage,
                goToPage: handleGoToPage,
                saveAndExit: handleSaveAndExit,
                viewDoctor: handleViewDoctor,
                viewClinic: handleViewClinic,
                cancelRequest: handleCancelRequest,
                confirmRequest: handleConfirmRequest,
                confirmReplacement: handleConfirmReplacement,
                payRequest: handlePayRequest,
                goRequestTable: handleGoRequestTable,
                showLoader,
            }}>
                {initialized && !isRequestLoading && renderStep(currentStep)}
            </RequestManagerContext.Provider>
            {
                isRequestDirty && !isRequestDeclined &&
                <NavigationPrompt when={isRequestDirty}>
                    {
                        ({isActive, onCancel, onConfirm}) => {
                            if (isActive) {
                                return (
                                    <PagePrompt
                                        title={intl.formatMessage({id: 'common.dlg_leave_title'})}
                                        btnSave={intl.formatMessage({id: 'common.buttons.save'})}
                                        btnCancel={intl.formatMessage({id: 'common.buttons.exit_no_save'})}
                                        onConfirm={onCancel}
                                        onClose={onCancel}
                                        onCancel={onConfirm}/>
                                );
                            }
                        }
                    }
                </NavigationPrompt>
            }
        </React.Fragment>
    )
};

RequestManager.propTypes = {
    requestId: PropTypes.any,
    isNew: PropTypes.bool,
};

RequestManager.defaultProps = {};

RequestManager.displayName = 'HealthRequestManager';

export default RequestManager;
