import React from 'react';
import cn from 'classnames';

import "./Popover.scss";

interface Props {
    className: string;
    placement?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

const Popover = React.forwardRef<HTMLDivElement, Props>((
    {className, children, placement, style, ...props}, ref
) => {
    const popoverClass = cn('Popover', className, placement && `Popover-placement--${placement}`);

    return (
        <div {...props} ref={ref} className={popoverClass} style={style}>
            {children}
        </div>
    );
});


Popover.displayName = 'HealthPopover';

export default Popover;
