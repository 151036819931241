import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, useIntl} from 'react-intl';

import TextBoxField from '../../common/TextBox/TextBoxField';
import Button from '../../common/Button/Button';

const EditTranslateForm = ({
	form,
	languages,
	handleCancel,
	isNew = false,
	shouldDisablePageNameField = false,
}) => {
	const intl = useIntl();

	return (
		<form
			className="Flex-column Flex-1"
			noValidate
			onSubmit={form.handleSubmit}
			autoComplete="off"
		>
			<div className="Modal__body">
				<TextBoxField
					name="pagename"
					autoComplete="off"
					labelClass="col-3"
					inputClass="col-9"
					inline
					multi
					rows={2}
					label={intl.formatMessage({
						id: 'translates.dlg_add_or_edit.pagename_label',
					})}
					placeholder={intl.formatMessage({
						id: 'translates.dlg_add_or_edit.pagename_placeholder',
					})}
					fieldClassName="Flex-1"
                    disabled={shouldDisablePageNameField}
				/>
				<TextBoxField
					name="param"
					autoComplete="off"
					labelClass="col-3"
					inputClass="col-9"
					inline
					label={intl.formatMessage({
						id: 'translates.dlg_add_or_edit.param_label',
					})}
					placeholder={intl.formatMessage({
						id: 'translates.dlg_add_or_edit.param_placeholder',
					})}
					fieldClassName="Flex-1"
				/>
				{languages.map(lang => (
					<TextBoxField
						autoComplete="off"
						key={lang.code}
						labelClass="col-3"
						inputClass="col-9"
						inline
						multi
						rows={3}
						label={lang.label}
						name={`fields.${lang.code}`}
					/>
				))}
			</div>
			<div className="Modal__footer">
				<Button
					variant="gray"
					viewType="minimal"
					onClick={handleCancel}
				>
					<FormattedMessage id="common.buttons.cancel" />
				</Button>
				<Button disabled={form.pristine && !form.dirty} type="submit">
					<FormattedMessage
						id={
							isNew ? 'common.buttons.add' : 'common.buttons.save'
						}
					/>
				</Button>
			</div>
		</form>
	);
};

EditTranslateForm.propTypes = {
	form: PropTypes.object.isRequired,
	languages: PropTypes.any,
	handleCancel: PropTypes.func,
	isNew: PropTypes.bool,
};

export default EditTranslateForm;
