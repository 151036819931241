import {useField} from 'react-final-form';

export const uselang = (primaryName = 'primaryLang', langName = 'lang') => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {input: {value: primaryLang}} = useField(primaryName, {subscription: {value: true}});
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {input: {value: langs}} = useField(langName, {subscription: {value: true}});

    return [
        primaryLang,
        langs,
    ]
};
