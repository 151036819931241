import {createSelector} from "reselect";
import Immutable from 'seamless-immutable';

export const getViewDoctorSelector = state => state.viewDoctor;

export const isLoadingSelector = createSelector(
    getViewDoctorSelector,
    page => page.loading
);

export const getDoctorDataSelector = createSelector(
    getViewDoctorSelector,
    (page) => {
        if (page.doctor) {
            return Immutable.asMutable(page.doctor, {deep: true})
        }
    }
);

export const getDoctorErrorSelector = createSelector(
    getViewDoctorSelector,
    page => page.error
);
