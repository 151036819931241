import Immutable from 'seamless-immutable';
import {
    SET_MEDICAL_RECORDS_MODAL_INIT,
    SET_MEDICAL_RECORDS_MODAL_LOADING,
    SET_MEDICAL_RECORD_MODAL_RECORDS,
    SET_MEDICAL_RECORD_MODAL_SELECTED,
    SET_MEDICAL_RECORD_MODAL_UNSELECT,
    SET_MEDICAL_RECORD_MODAL_ERROR,
} from "./actions";

const INITIAL_STATE = {
    loading: false,
    records: [],
    count: 0,
    pages: 0,
    error: null,
    selected: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MEDICAL_RECORDS_MODAL_INIT:
            return Immutable(INITIAL_STATE);
        case SET_MEDICAL_RECORDS_MODAL_LOADING:
            return Immutable.merge(state, {
                loading: action.payload,
                error: null,
            });
        case SET_MEDICAL_RECORD_MODAL_RECORDS:
            return Immutable.merge(state, action.payload);
        case SET_MEDICAL_RECORD_MODAL_SELECTED:
            return Immutable.merge(state, {
                selected: [...state.selected, action.payload],
            });
        case SET_MEDICAL_RECORD_MODAL_UNSELECT:
            return Immutable.merge(state, {
                selected: state.selected.filter(item => item.file !== action.payload.file),
            });
        case SET_MEDICAL_RECORD_MODAL_ERROR:
            return Immutable.merge(state, {error: action.payload});
        default:
            return state;
    }
}
