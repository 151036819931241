import React, {Component} from 'react';
import PropTypes from "prop-types";
import {toInteger} from "lodash";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";

import './TranslatesPage.scss';
import SystemPageHeader from "../../components/SystemPageHeader/SystemPageHeader";
import {COLUMN_NAMES} from './constants/columns';
import HeaderCell from "../../components/common/SimpleTable/cells/HeaderCell";
import ActionsCell from "./components/ActionsCell";
import {getUrlSearchParams, setUrlSearchParams} from "../../helpers/UrlHelper";
import queryParams from "../../constants/QueryParams";
import {DEFAULT_PAGE_SIZE, PageSizes, PageSizeValues} from "../../constants/PageSizes";
import {despace} from "../../helpers/String";
import SimpleTable from "../../components/common/SimpleTable/SimpleTable";
import Pagination from "../../components/Pagination/Pagination";
import * as translatesPageSelectors from "../../redux/translatesPage/selectors";
import * as translationsSelectors from "../../redux/translations/selectors";
import pageNames from "../../constants/PageNames";
import {notifyError, notifySuccess} from "../../redux/notifier/actions";
import {showConfirmModal, showEditTranslateModal} from "../../redux/modal/actions";
import {getTranslates, initTranslatesPage, removeTranslate} from "../../redux/translatesPage/actions";
import Button from "../../components/common/Button/Button";
import * as commonSelectors from "../../redux/common/selectors";
import SearchTextBox from "../../components/fields/SearchTextBox/SearchTextBox";

const defineColumns = [
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.id"/>),
        accessor: COLUMN_NAMES.ID,
        width: 6,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.pagename"/>),
        accessor: COLUMN_NAMES.PAGENAME,
        width: 30,
        sortable: true,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.param"/>),
        accessor: COLUMN_NAMES.PARAM,
        width: 30,
        sortable: true,
    },

];

const actionColumns = [
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.actions"/>),
        accessor: COLUMN_NAMES.ACTIONS,
        width: 100,
        align: 'right',
        widthFixed: true,
        Cell: (props) => <ActionsCell {...props}/>
    }
]

class TranslatesPage extends Component {
    DEFAULT_SORT_FIELD = COLUMN_NAMES.PARAM;
    DEFAULT_ORDER_BY = 'desc';

    constructor(props) {
        super(props);
        this.pageRef = React.createRef();
        this.state.columns = [
            ...defineColumns,
            ...this.props.languages.map(({code, label}) => ({
                Header: label,
                accessor: code,
                sortable: true,
                width: 12,
            })),
            ...actionColumns,
        ];
    }

    static propTypes = {
        location: PropTypes.object,
        history: PropTypes.object,
        initTranslatesPage: PropTypes.func,
        getTranslates: PropTypes.func,
        notifySuccess: PropTypes.func,
        notifyError: PropTypes.func,
        showConfirmModal: PropTypes.func,
        showEditTranslateModal: PropTypes.func,
        translates: PropTypes.any,
        totalPages: PropTypes.object,
        totalCounts: PropTypes.object,
        intl: PropTypes.object,
        isTranslationLoaded: PropTypes.bool,
        isLoading: PropTypes.bool,
        languages: PropTypes.any,
    };

    state = {
        isInitialSortBy: false,
        isFetching: false,
        searchParams: {},
        sortBy: {
            id: this.DEFAULT_SORT_FIELD,
            desc: false
        },
        columns: [],
    };

    componentDidMount() {
        const params = this.getSearchParams();

        this.props.initTranslatesPage();

        this.setState(() => ({
            isInitialSortBy: true,
            isFetching: true,
            searchParams: params,
            sortBy: {id: params.sortBy, desc: params.orderBy !== 'asc'}
        }), () => {
            this.updateData();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;

        if (location.search !== prevProps.location.search) {
            const params = this.getSearchParams();

            this.setState(() => ({
                isFetching: true,
                searchParams: params,
            }), () => {
                this.updateData();
            });
        }

        if (prevProps.languages !== this.props.languages) {
            this.setState((prevState) => ({
                columns: [
                    ...defineColumns,
                    ...this.props.languages.map(({code, label}) => ({
                        Header: label,
                        accessor: code,
                        sortable: true,
                        width: 12,
                    })),
                    ...actionColumns
                ]
            }))
        }
    }

    getSearchParams() {
        const search = getUrlSearchParams(this.props.location.search);
        const currentPage = toInteger(search[queryParams.currentPage]);
        const pageSize = toInteger(search[queryParams.pageSize]);
        const sortBy = search[queryParams.sortBy] || this.DEFAULT_SORT_FIELD;
        const orderBy = search[queryParams.orderBy] && /(asc|desc)/.test(search[queryParams.orderBy]) ?
            search[queryParams.orderBy] : this.DEFAULT_ORDER_BY;

        return {
            currentPage: currentPage > 0 ? currentPage : 1,
            pageSize: PageSizeValues.indexOf(pageSize) === -1 ? DEFAULT_PAGE_SIZE : pageSize,
            query: despace(search[queryParams.query]),
            sortBy: sortBy,
            orderBy: orderBy,
        };
    }

    updateData = () => {
        this.props.getTranslates({
            ...this.state.searchParams
        });
    };

    handleChangePage = (page) => {
        const {history, location} = this.props;
        const currentPage = page + 1;

        if (currentPage !== this.state.searchParams.currentPage) {
            history.push({
                pathname: history.location.pathname,
                search: setUrlSearchParams(location.search, {
                    [queryParams.currentPage]: currentPage === 1 ? null : currentPage
                })
            });
        }
    };

    handleChangeSort = (sort) => {
        const {history, location} = this.props;
        const [{id: sortBy, desc}] = sort;

        history.push({
            pathname: history.location.pathname,
            search: setUrlSearchParams(location.search, {
                [queryParams.currentPage]: null,
                [queryParams.sortBy]: sortBy,
                [queryParams.orderBy]: desc ? 'desc' : 'asc',
            })
        });
    };

    handlePageSizeChange = (value) => {
        const {history, location} = this.props;

        history.push({
            pathname: history.location.pathname,
            search: setUrlSearchParams(location.search, {
                [queryParams.currentPage]: null,
                [queryParams.pageSize]: value === DEFAULT_PAGE_SIZE ? null : value,
            })
        });
    };

    getCellProps = (cell) => {
        switch (cell.column.originalId) {
            case COLUMN_NAMES.ACTIONS:
                return {
                    removeTranslate: this.handleRemoveTranslate,
                    editTranslate: this.handleEditTranslate,
                };
            default:
        }
        return {};
    };

    getTotalPages = () => {
        return this.props.totalPages['all'];
    };

    handleAddTranslate = () => {
        const {showEditTranslateModal, intl, notifySuccess, notifyError} = this.props;

        showEditTranslateModal({
            isNew: true,
            title: intl.formatMessage({id: 'translates.dlg_add_or_edit.add_title'}),
            onSuccess: () => {
                notifySuccess(intl.formatMessage({id: 'translates.notify.added'}));
                this.updateData();
            },
            onError: () => {
                notifyError(intl.formatMessage({id: 'translates.notify.add_error'}));
            },
        });
    }

    handleRemoveTranslate = (id) => {
        const {translates, intl, showConfirmModal, notifySuccess, notifyError, removeTranslate} = this.props;
        const record = translates.find(t => t.id === id);

        if (!record) {
            return
        }

        showConfirmModal({
            title: intl.formatMessage({
                id: 'translates.dlg_remove.title',
            }, record),
            onSuccess: async () => {
                try {
                    await removeTranslate(id);
                    notifySuccess(intl.formatMessage({id: 'translates.notify.removed'}));
                    this.updateData();
                } catch (err) {
                    notifyError(intl.formatMessage({id: 'translates.notify.remove_error'}));
                }
            },
            btnOk: intl.formatMessage({id: 'common.buttons.yes'}),
            btnCancel: intl.formatMessage({id: 'common.buttons.no'}),
        });
    }

    handleEditTranslate = (id) => {
        const {translates, intl, showEditTranslateModal, notifySuccess, notifyError} = this.props;
        const record = translates.find(t => t.id === id);

        if (!record) {
            return
        }

        showEditTranslateModal({
            record,
            isNew: false,
            title: intl.formatMessage({id: 'translates.dlg_add_or_edit.edit_title'}),
            onSuccess: () => {
                notifySuccess(intl.formatMessage({id: 'translates.notify.updated'}));
                this.updateData();
            },
            onError: () => {
                notifyError(intl.formatMessage({id: 'translates.notify.update_error'}));
            }
        });
    }

    handleSearch = (query) => {
        const {history, location} = this.props;

        history.push({
            pathname: history.location.pathname,
            search: setUrlSearchParams(location.search, {
                [queryParams.currentPage]: null,
                [queryParams.query]: query,
            })
        });
    }

    handleResetSearch = () => {
        const {history, location} = this.props;

        history.push({
            pathname: history.location.pathname,
            search: setUrlSearchParams(location.search, {
                [queryParams.currentPage]: null,
                [queryParams.query]: null,
            })
        });
    }

    render() {
        const {searchParams, isInitialSortBy, sortBy, columns} = this.state;
        const {translates, intl, isTranslationLoaded, isLoading} = this.props;

        return (
            <React.Fragment>
                {isTranslationLoaded && !isLoading &&
                <div className="Translates-page Flex-column">
                    <SystemPageHeader>
                        <div className="Flex-row Translates-page__actions">
                            <SearchTextBox
                                onSearch={this.handleSearch}
                                onClear={this.handleResetSearch}
                                query={searchParams.query}
                                className="mr-1"/>
                            <Button
                                onClick={this.handleAddTranslate}
                                viewType="frameless"
                                variant="gray">
                                <FontAwesomeIcon className="mr-05" size="lg" icon={faPlusCircle}/>
                                <FormattedMessage id="translates.buttons.add_translate"/>
                            </Button>
                        </div>
                    </SystemPageHeader>
                    <div className="Translates-page__table">
                        {
                            isInitialSortBy &&
                            <SimpleTable
                                initialState={{
                                    sortBy: [sortBy]
                                }}
                                onChangeSort={this.handleChangeSort}
                                columns={columns}
                                data={translates}
                                getCellProps={this.getCellProps}/>
                        }
                    </div>
                    <div className="Flex-row Justify-center mt-4 mb-4">
                        <Pagination
                            onChangePageSize={this.handlePageSizeChange}
                            pageSize={searchParams.pageSize}
                            pageSizeOptions={PageSizes}
                            showPageSize
                            onChange={this.handleChangePage}
                            goToLabel={intl.formatMessage({id: 'common.pages_goto'})}
                            totalCount={this.getTotalPages()}
                            forcePage={this.state.searchParams.currentPage - 1}
                            initialPage={this.state.searchParams.currentPage - 1}/>
                    </div>
                </div>
                }
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        isLoading: translatesPageSelectors.isLoadingSelector(state),
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, pageNames.Translates),
        translates: translatesPageSelectors.getTranslatesSelector(state),
        totalPages: translatesPageSelectors.getTotalPagesSelector(state),
        totalCounts: translatesPageSelectors.getTotalCountsSelector(state),
        languages: commonSelectors.getLanguagesSelector(state),
    }
};

export default connect(mapStateToProps, {
    initTranslatesPage,
    getTranslates,
    removeTranslate,
    notifySuccess,
    notifyError,
    showConfirmModal,
    showEditTranslateModal,
})(withRouter(injectIntl(TranslatesPage)));

