import React, {useEffect, useState, useCallback} from 'react';
import {withRouter} from 'react-router-dom';
import {useIntl} from "react-intl";
import {set} from "lodash";
import Joi from "@hapi/joi";
import {connect} from 'react-redux';

import PatientRegistrationForm from './components/PatientRegistrationForm';
import {isEmptyObject} from '../../helpers/Common';
import {loadingSelector} from "../../redux/loading/selectors";
import * as translationsSelectors from '../../redux/translations/selectors';
import {initPatientRegistrationPage, registerPatient} from "../../redux/patientRegistration/actions";
import {PASSWORD_REG_EXP} from "../../constants/RegExps";
import pageNames from "../../constants/PageNames";
import {setLoading} from "../../redux/loading/actions";
import {RegistrationPageLayout} from "../../components/registration/RegistrationPageLayout/RegistrationPageLayout";
import {RegistrationResult} from "../../components/registration/RegistrationResult/RegistrationResult";
import {getUrlSearchParams} from "../../helpers/UrlHelper";

const buildSchema = ({errors}) => Joi.object({
    newemail: Joi.string().email({
        tlds: false
    }).messages({
        'any.required': errors.newemail_error,
    }).required(),
    newpassword: Joi.string().messages({
        'any.required': errors.newpassword_help,
        'string.pattern.base': errors.newpassword_help,
    }).trim().regex(PASSWORD_REG_EXP).required(),
    confirm_pass: Joi.string().messages({
        'any.required': errors.confirm_pass_error,
        'any.only': errors.confirm_pass_error,
    }).trim().valid(Joi.ref('newpassword')).required(),
    agreement_accepted: Joi.boolean().messages({
        'any.required': errors.reg_rules_error,
        'any.only': errors.reg_rules_error,
    }).valid(true).required(),
}).unknown();

export const PatientRegistration = (
    {
        initPatientRegistrationPage, lang, loading, setLoading,
        isTranslationLoaded, registerPatient,
    }
) => {
    const intl = useIntl();

    useEffect(() => {
        const searchParams = getUrlSearchParams(window.location.search);

        initPatientRegistrationPage(searchParams.lang);
    }, [initPatientRegistrationPage]);

    const [regState, setRegState] = useState('not_submitted');
    const [userEmail, setUserEmail] = useState('');

    const onSubmit = useCallback(async (values) => {
        const errors = {};
        const schema = buildSchema({
            errors: {
                newemail_error: intl.formatMessage({id: "patientReg.email_error"}),
                newpassword_help: intl.formatMessage({id: "patientReg.pass_error"}),
                confirm_pass_error: intl.formatMessage({id: "patientReg.confirm_pass_error"}),
                reg_rules_error: intl.formatMessage({id: "patientReg.reg_rules_error"}),
            }
        });
        const {error, value} = schema.validate(values, {
            abortEarly: false,
        });
        if (error) {
            error.details.reduce((obj, e) => {
                set(obj, e.path, e.message);
                return obj;
            }, errors);
        }
        if (!value.captcha) {
            errors.captcha = '';
        }
        if (!isEmptyObject(errors)) {
            return errors;
        }
        setLoading(true);
        try {
            const response = await registerPatient({
                email: value.newemail,
                password: value.newpassword,
                'g-recaptcha-response': value.captcha,
            });
            setUserEmail(value.newemail);
            setRegState(response.data);
        } catch (error) {
            setRegState(error.data);
        } finally {
            setLoading(false);
        }
    }, [intl, registerPatient, setLoading]);

    const getErrorDescription = useCallback(() => {
        let errorDescription = '';
        if (regState.error === 'reg_error_confirm') {
            const error1 = intl.formatMessage({id: "patientReg.reg_error_confirm_text1"});
            const error2 = intl.formatMessage({id: "patientReg.reg_error_confirm_text2"});
            errorDescription = `${error1}${userEmail}${error2}`;
        } else if (regState.error === 'reg_error') {
            const error1 = intl.formatMessage({id: "patientReg.reg_error_text1"});
            const error2 = intl.formatMessage({id: "patientReg.reg_error_text2"});
            errorDescription = `${error1}${userEmail}${error2}`;
        }
        return errorDescription;
    }, [intl, regState.error, userEmail]);

    const renderBody = useCallback(() => {
        if (regState === 'not_submitted') {
            return <PatientRegistrationForm
                redirectTo="/login"
                lang={lang}
                onSubmit={onSubmit}/>
        } else {
            return <RegistrationResult
                status={regState.status}
                title={regState.status ? "patientReg.reg_ok_head" : "patientReg.reg_error_head"}
                description="patientReg.reg_ok_text"
                errorText={!regState.status ? getErrorDescription() : ''}
                btnGotoMain="patientReg.but_back_main"
                redirectTo="/login"
            />
        }
    }, [regState, lang, onSubmit, getErrorDescription]);

    return (
        <RegistrationPageLayout
            className="Patient-registration"
            isLoading={loading}
            isTranslationLoaded={isTranslationLoaded}
            title="patientReg.h1"
            copyright="patientReg.copyright"
            linksConf="patientReg.links_conf"
        >
            {renderBody()}
        </RegistrationPageLayout>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: loadingSelector(state),
        lang: state.lang,
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, pageNames.PatientReg),
    }
};

export default connect(mapStateToProps, {
    initPatientRegistrationPage,
    setLoading,
    registerPatient,
})(withRouter(PatientRegistration));
