import React from 'react';
import cn from 'classnames';

import './Separator.scss';

const Separator = React.memo(({className}) => (
    <div className={cn(className, 'Separator')}/>
));

Separator.displayName = 'Separator';

export default Separator;
