import React, {useCallback, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {useDispatch} from 'react-redux';
import {faFrown} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormattedMessage} from "react-intl";

import './ConsultationChat.scss';
import {ROLES, toUserRole} from '../../constants/Roles';
import {ViewModalContextProvider} from '../Contexts/ViewModalContext';
import Spinner from '../Spinner/Spinner';
import {ConsultationChatProvider} from './components/context/ConsultationChatContext';
import MessagesContainer from './components/MessagesContainer/MessagesContainer';
import SendMessageForm from './components/SendMessageForm/SendMessageForm';
import {MessageRecipientTypes} from './constants/MessageRecipientTypes';
import {useAttachmentFiles} from './hooks/useAttachmentFiles';
import useConsultationChat from './useConsultationChat';
import {showConfirmModal} from '../../redux/modal/actions';
import * as notifierActions from '../../redux/notifier/actions';
import { Attachment, Message } from './Chat.interface';
import { useTranslation } from 'libs/hooks/use-translation';

/**
 * кейсы
 * пациент
 * - посылать файлы
 * - отвечать на сообщения
 * - писать новые сообщения
 * координатор
 * - отвечать на сообщения доктора и пациента
 *   - если отвечает на сообщения доктору, то доступна только кнопка ответить доктору. Тоже самое с пациентом
 *   - предудущее состояние сообщения стирается
 *   - может копировать файлы только от доктора, если отвечает пациенту. и наоборот если отвечает доктору.
 * - писать новые сообщения доктору и пациенту
 * - редактировать свои сообщения
 *   - при редактировании сообщения нельзя копировать файлы
 *   - предудущее состояние сообщения стирается
 * доктор
 * - отвечать на сообщения пациента и координатора
 * - отправлять файлы
 * - писать новые сообщения
 * /
*/
interface Props {
    className?: string;
    me: number;
    role: ROLES
    session: string,
    chatId: string,
    lang: string
    langCom: string,
    allowChatting: boolean,
    onMessageSent: (params: {needToRefreshFilesPage: boolean}) => void;
    onOpenAttachFiles: (data: {attachments: Attachment[], onSubmit: Function}) => void;
    onUnauthorize: () => void
}

const ConsultationChat = ({
        me, role, session, chatId, className, lang, langCom, 
        allowChatting = true, 
        onMessageSent, 
        onOpenAttachFiles,
        onUnauthorize,
    }: Props
) => {
    const chat = useConsultationChat({onUnauthorize});
    const dispatch = useDispatch();
    const [replayMessage, setReplyMessage] = useState<Message>();
    const [editMessage, setEditMessage] = useState<Message>();
    const sendMessageFormRef = useRef<HTMLInputElement>();
    const attachmentFiles = useAttachmentFiles();
    const [attachmentsOwner, setAttachmentsOwner] = useState(MessageRecipientTypes.DEFAULT);
    const { t } = useTranslation()

    useEffect(() => {
        if (session && me && !chat.reconnecting) {
            chat.connect({phpsession: session, userId: me});

            return () => chat.disconnect();
        }
    }, [chat, session, me]);

    useEffect(() => {
        if (chat.connected && !chat.joining) {
            let joinLang = undefined; // координатор, админ клиники видят все сообщения в чате

            if (role === ROLES.PATIENT) { // это сделано для того чтобы пациент видил сообщения на своем языке заявки
                joinLang = lang;
            } else if (role === ROLES.DOCTOR) { // доктор видит сообщения на своем языке
                joinLang = langCom || lang;
            }

            chat.join({chatId, lang: joinLang});
        }
        // eslint-disable-next-line
    }, [chat.connected, chatId, lang, langCom, role]);

    useEffect(() => {
        if (replayMessage) {
            const message = chat.messages.find(m => m.id === replayMessage.id);
            if (!message) {
                setReplyMessage(undefined);
            }
        }
    }, [chat.messages, replayMessage]);

    const handleSendMessage = useCallback(async ({text = '', replyId, messageId, sendTo}) => {
        if (messageId) {
            try {
                chat.edit({
                    text,
                    messageId,
                });
                setReplyMessage(undefined);
                setEditMessage(undefined);
                setAttachmentsOwner(MessageRecipientTypes.DEFAULT);
            } catch (error) {
                dispatch(notifierActions.notifyError(t('common.consultation_chat.edit_error')));
                throw error;
            }
        } else {
            let messageLang = lang; // язык сообщений по умолчанию язык заявки

            if (role === ROLES.DOCTOR) {
                messageLang = langCom || lang; // доктор ответил на своем языке (если требовался перевод)
            } else if (role === ROLES.PARTNER || role === ROLES.ADMIN_CLINIC) {
                if (sendTo === MessageRecipientTypes.CONSULTANT) { // послали врачу
                    messageLang = langCom || lang;
                }
            }
            try {
                await chat.send({
                    text,
                    replyId,
                    lang: messageLang,
                    attachments: attachmentFiles.attachments,
                });
                setReplyMessage(undefined);
                setEditMessage(undefined);
                setAttachmentsOwner(MessageRecipientTypes.DEFAULT);
                onMessageSent && onMessageSent({
                    needToRefreshFilesPage: attachmentFiles.isEmpty(),
                })
                attachmentFiles.clear();
            } catch (error) {
                dispatch(notifierActions.notifyError(t('common.consultation_chat.send_error')));
                throw error;
            }
        }
    }, [attachmentFiles, chat, dispatch, t, lang, langCom, onMessageSent, role]);

    const handleLoadPrevious = useCallback(() => {
        if (!chat.fetching) {
            chat.prev();
        }
    }, [chat]);

    const handleRemoveMessage = useCallback((messageId: number) => {
        // @ts-ignore
        dispatch(showConfirmModal({
            title: t('common.consultation_chat.dlg_remove_title'),
            message: t('common.consultation_chat.dlg_remove_message'),
            btnOk: t('common.consultation_chat.dlg_remove_btn_ok'),
            btnCancel: t('common.consultation_chat.dlg_remove_btn_cancel'),
            onSuccess: () => {
                if (editMessage && editMessage.id === messageId) {
                    setEditMessage(undefined);
                }
                chat.remove(messageId);
            },
        }));
    }, [chat, dispatch, editMessage, t]);

    const handleEditMessage = useCallback((messageId: number) => {
        const message = chat.messages.find(m => m.id === messageId);

        if (message) {
            setEditMessage(message);
            setReplyMessage(undefined);
            setAttachmentsOwner(MessageRecipientTypes.DEFAULT);
            attachmentFiles.clear();
            sendMessageFormRef.current.focus();
        }
    }, [attachmentFiles, chat.messages]);

    const handleReplyMessage = useCallback((messageId: number) => {
        const message = chat.messages.find(m => m.id === messageId);

        if (message) {
            setReplyMessage(message);
            setEditMessage(undefined);
            setAttachmentsOwner(MessageRecipientTypes.DEFAULT);
            attachmentFiles.clear();
            sendMessageFormRef.current.focus();
        }
    }, [attachmentFiles, chat.messages]);

    const handleCancelReplyOrEdit = useCallback(() => {
        setReplyMessage(undefined);
        setEditMessage(undefined);
    }, []);

    const handleCanCopyAttachments = useCallback((message) => {
        if (role === ROLES.PARTNER || role === ROLES.ADMIN_CLINIC) {
            const attachments = message.attachments || [];

            if (attachments.some(({names = {}}) => !names[lang] || !names[langCom])) {
                return false;
            }

            const userMessageRole = toUserRole(message.user.rights);

            if (editMessage) {
                return false;
            } else if (replayMessage) {
                const userReplyMessageRole = toUserRole(replayMessage.user.rights);
                return userMessageRole !== userReplyMessageRole;
            } else if (attachmentsOwner) {
                if (attachmentsOwner === MessageRecipientTypes.PATIENT) {
                    return userMessageRole === ROLES.DOCTOR;
                } else if (attachmentsOwner === MessageRecipientTypes.CONSULTANT) {
                    return userMessageRole === ROLES.PATIENT;
                }
            }
        }
        return true;
    }, [attachmentsOwner, editMessage, lang, langCom, replayMessage, role]);

    const handleCopyAttachments = useCallback(({attachments, user: {rights}}) => {
        let ownerType = MessageRecipientTypes.DEFAULT;

        if (role === ROLES.ADMIN_CLINIC || role === ROLES.PARTNER) {
            // eslint-disable-next-line default-case
            switch (toUserRole(rights)) {
                case ROLES.PATIENT:
                    ownerType = MessageRecipientTypes.CONSULTANT;
                    break;
                case ROLES.DOCTOR:
                    ownerType = MessageRecipientTypes.PATIENT;
                    break;
            }
            attachmentFiles.merge(
                attachments.map((attachment) => ({
                    ...attachment,
                    name: ownerType === MessageRecipientTypes.PATIENT ?
                        attachment.names[lang] :
                        attachment.names[langCom]
                }))
            );
        } else {
            attachmentFiles.merge(attachments);
        }
        setAttachmentsOwner(ownerType);
    }, [attachmentFiles, lang, langCom, role]);

    const handleAttachFiles = useCallback(() => {
        onOpenAttachFiles({
            attachments: attachmentFiles.attachments,
            onSubmit: (values) => {
                attachmentFiles.set(values);
            },
        });
    }, [attachmentFiles, onOpenAttachFiles]);

    const handleRemoveFile = useCallback((attachment: Attachment) => {
        attachmentFiles.remove(attachment);
        // remove last file, workaround because setState work after, attachments doesn't changed
        if (attachmentFiles.attachments.length === 1) {
            setAttachmentsOwner(MessageRecipientTypes.DEFAULT);
        }
    }, [attachmentFiles]);

    const renderBody = useCallback(() => {
        if (chat.connectionError) {
            return (
                <div className="Flex-column Flex-1 Justify-center Align-center">
                    <FontAwesomeIcon icon={faFrown} size="lg"/>
                    <FormattedMessage id={"common.consultation_chat.error"}/>
                </div>
            )
        }

        return (
            <React.Fragment>
                <MessagesContainer
                    allowChatting={allowChatting}
                    role={role}
                    lang={lang}
                    langCom={langCom}
                    onLoadPrevious={handleLoadPrevious}
                    onMarkMessageRead={chat.markRead}
                    fetching={chat.fetching}
                    hasMore={chat.hasMore}
                    me={me}
                    messages={chat.messages}/>
                {
                    allowChatting &&
                    <SendMessageForm
                        attachmentsOwner={attachmentsOwner}
                        sending={chat.sending}
                        consultantLang={langCom || lang}
                        patientLang={lang}
                        role={role}
                        ref={sendMessageFormRef}
                        attachedFiles={attachmentFiles.attachments}
                        onAttachFiles={handleAttachFiles}
                        onRemoveFile={handleRemoveFile}
                        onCancelReplyOrEdit={handleCancelReplyOrEdit}
                        replyMessage={replayMessage}
                        editMessage={editMessage}
                        onSendMessage={handleSendMessage}/>
                }
            </React.Fragment>
        )
    }, [
        allowChatting, attachmentFiles.attachments,
        chat.connectionError, chat.fetching, chat.hasMore,
        chat.messages, chat.sending, editMessage, handleAttachFiles,
        handleCancelReplyOrEdit, handleLoadPrevious, handleSendMessage,
        lang, langCom, me, replayMessage, role, handleRemoveFile,
        attachmentsOwner,
    ]);

    return (
        <ViewModalContextProvider>
            <ConsultationChatProvider value={{
                onRemoveMessage: handleRemoveMessage,
                onEditMessage: handleEditMessage,
                onReplyMessage: handleReplyMessage,
                onCopyAttachments: handleCopyAttachments,
                onCanAttachments: handleCanCopyAttachments,
                role,
            }}>
                <div className={cn('Health-chat', className)}>
                    <div className="Health-chat__title">
                        <FormattedMessage id={"common.consultation_chat.title"}/>
                    </div>
                    {renderBody()}
                    <Spinner
                        isLoading={chat.connecting || chat.joining}
                        isBlocked/>
                </div>
            </ConsultationChatProvider>
        </ViewModalContextProvider>
    )
}

ConsultationChat.displayName = 'HealthConsultationChat';

export default ConsultationChat;
