import {useMemo} from 'react';
import {useIntl} from 'react-intl';

export const useDateTime = (date) => {
    const intl = useIntl();

    return useMemo(() => {
        const formatDate = intl.formatDate(date);
        const formatTime = intl.formatTime(date, {
            hour12: false,
            hour: '2-digit',
            minute: 'numeric',
        });
        return `${formatDate} ${formatTime}`;
    }, [intl, date])
};
