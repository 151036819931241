import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';

import {rootRequestPageSelector} from '../selectors';

export const rootCommonSelector = createSelector(
    rootRequestPageSelector,
    (root) => root.common,
);

export const getCommonFileTypes = createSelector(
    rootCommonSelector,
    common => Immutable.asMutable(common.fileTypes, {deep: true}),
);
