import React from 'react';
import {FormattedMessage} from 'react-intl';
import DeclineRequestButton from '../common/DeclineRequestButton';
import Button from '../../../../components/common/Button/Button';
import {useAppSelector} from '../../../../hooks/use-app-selector';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';

export const PaymentForm = () => {
	const form = useAppSelector(requestSelectors.getPaymentForm);

	return (
		<form
			className="Flex-row Justify-center Align-center mt-5 mb-4"
			action="https://auth.robokassa.ru/Merchant/Index.aspx"
			method="POST"
		>
			<input
				type="hidden"
				name="MerchantLogin"
				value={form.merchantLogin}
			/>
			<input type="hidden" name="OutSum" value={form.outSum} />
			<input
				type="hidden"
				name="OutSumCurrency"
				value={form.outSumCurrency}
			/>
			<input type="hidden" name="InvId" value={form.invId} />
			<input type="hidden" name="Description" value={form.description} />
			<input
				type="hidden"
				name="SignatureValue"
				value={form.signatureValue}
			/>
			<input type="hidden" name="Culture" value={form.culture} />
			<input type="hidden" name="IsTest" value={'1'} />
			{/*   */}
			<DeclineRequestButton type="button" />
			<Button type="submit" className="ml-2">
				<FormattedMessage id={'request.buttons.pay'} values={{
					sum: form.outSum,
					currencySymbol: form.currencySymbol || '—',
				}} />
			</Button>
		</form>
	);
};
