import React from 'react';
import {withRouter, Route, Switch, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import './SystemPage.scss';

import TranslatesPage from "../TranslatesPage/TranslatesPage";
import StatusesPage from "../StatusesPage/StatusesPage";
import AgreementsPage from "../AgreementsPage/AgreementsPage";

// TODO: AuthGuard
export const SystemPage = ({match}) => {
    return (
        <Switch>
            <Route path={`${match.path}/translates`} component={TranslatesPage}/>
            <Route path={`${match.path}/statuses`} component={StatusesPage}/>
            <Route path={`${match.path}/agreements`} component={AgreementsPage}/>
            <Route path="*">
                <Redirect to={`${match.path}/translates`}/>
            </Route>
        </Switch>
    );
};

export default connect(null)(withRouter(SystemPage));
