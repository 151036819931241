import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch, useParams} from 'react-router-dom';

import './ConsultationPage.scss';
import {ViewModalContextProvider} from '../../components/Contexts/ViewModalContext';
import Loader from '../../components/Loader/Loader';
import {SidePanel} from '../../components/SidePanel/SidePanel';
import pageNames from '../../constants/PageNames';
import {useResetWindowScroll} from '../../hooks/useResetWindowScroll';
import * as commonActions from '../../redux/common/actions';
import * as consultationSelectors from '../../redux/consultationPage/consultation/selectors';
import {fetchTranslation} from '../../redux/translations/actions';
import {isPageLoadedSelector} from '../../redux/translations/selectors';
import ConsultationConclusionPage from './components/ConsultationConclusionPage/ConsultationConclusionPage';
import ConsultationChatPage from './components/ConsultationChatPage/ConsultationChatPage';
import ConsultationConsultant
    from './components/ConsultationConsultant/ConsultationConsultant';
import ConsultationFilesPage from './components/ConsultationFilesPage/ConsultationFilesPage';
import ConsultationHeader from './components/ConsultationHeader/ConsultationHeader';
import ConsultationMenu from './components/ConsultationMenu/ConsultationMenu';
import ConsultationInfoPage from './components/ConsultationInfoPage/ConsultationInfoPage';
import { useConsultationLoaderById } from './hooks/use-consultation-loader-by-id';

const ConsultationPage = ({match}) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const isTranslationLoaded = useSelector(state => isPageLoadedSelector(state, pageNames.Request));
    const isLoading = useSelector(consultationSelectors.isConsultationLoading);

    useConsultationLoaderById(id);

    useResetWindowScroll();

    useEffect(() => {
        dispatch(fetchTranslation({pageName: pageNames.Request}));
    }, [dispatch]);

    useEffect(() => {
        dispatch(commonActions.fetchCountries());
    }, [dispatch]);

    useEffect(() => {
        dispatch(commonActions.fetchMedicalFileTypes());
    }, [dispatch]);

    useEffect(() => {
        dispatch(commonActions.fetchCommunicationLanguages());
    }, [dispatch]);

    return (
        <div className="Consultation-page">
            <ConsultationHeader/>
            <div className="Consultation-page__main">
                <SidePanel left>
                    <ViewModalContextProvider>
                        <ConsultationConsultant/>
                    </ViewModalContextProvider>
                    <ConsultationMenu/>
                </SidePanel>
                {
                    isTranslationLoaded &&
                    <Switch>
                        <Route exact path={`${match.path}`} component={ConsultationInfoPage}/>
                        <Route path={`${match.path}/files`} component={ConsultationFilesPage}/>
                        <Route path={`${match.path}/chat`} component={ConsultationChatPage}/>
                        <Route path={`${match.path}/conclusion`} component={ConsultationConclusionPage}/>
                        <Route path="*">
                            <Redirect to={`${match.path}`}/>
                        </Route>
                    </Switch>
                }
            </div>
            <Loader loading={isLoading || !isTranslationLoaded}/>
        </div>
    )
};

export default ConsultationPage;
