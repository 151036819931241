import React from 'react';
import PropTypes from 'prop-types';

class Tab extends React.Component {
    static propTypes = {
        title: PropTypes.node.isRequired,
    };

    // eslint-disable-next-line react/require-render-return
    render() {
        throw new Error('The `Tab` component is not meant to be rendered!');
    }
}

export default Tab;
