export const addNewDocuments = ([records = []], state, tools) => {
    const files = tools.getIn(state.formState.values, 'files') || [];

    records.forEach(item => {
        files.push({
            ...item,
            med: false,
        });
    });

    tools.changeValue(state, 'files', () => files);
};
