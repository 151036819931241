import React from 'react';
import PhotoUploader from './PhotoUploader';
import {Field} from "react-final-form";
import PropTypes from "prop-types";

const PhotoUploaderField = ({uploadText, changeText, removeText, className, ...props}) => {
    return (
        <Field
            {...props}
            render={
                ({input}) => (
                    <PhotoUploader
                        {...input}
                        className={className}
                        uploadText={uploadText}
                        changeText={changeText}
                        removeText={removeText}
                    />
                )
            }/>
    );
};

PhotoUploaderField.propTypes = {
    ...PhotoUploader.propTypes,
    uploadText: PropTypes.string,
    changeText: PropTypes.string,
    removeText: PropTypes.string,
};

export default PhotoUploaderField;
