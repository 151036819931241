export const RequestStatuses = {
    Draft: '0',

    HelpMe_1: '1',
    HelpMe_1_1: '1.1',
    HelpMe_1_2: '1.2',
    HelpMe_1_3: '1.3',
    HelpMe_1_4: '1.4',
    HelpMe_1_5: '1.5',
    HelpMe_1_6: '1.6',
    HelpMe_1_7: '1.7',

    Clinic_2: '2',
    Clinic_2_1: '2.1',
    Clinic_2_2: '2.2',
    Clinic_2_3: '2.3',
    Clinic_2_4: '2.4',
    Clinic_2_5: '2.5',
    Clinic_2_6: '2.6',
    Clinic_2_7: '2.7',

    Doctor_3: '3',
    Doctor_3_1: '3.1',
    Doctor_3_2: '3.2',
    Doctor_3_3: '3.3',
    Doctor_3_4: '3.4',
    Doctor_3_5: '3.5',
    Doctor_3_6: '3.6',
    Doctor_3_7: '3.7',

    Accepted_5: '5',
    Accepted_5_1: '5.1', // confirm and replace page (doctor_new)
    Accepted_5_2: '5.2', // payment page
    Accepted_5_3: '5.3', // the same 5_1

    DeclineByClinic: '4',
    DeclineByPatient: '6',
    Active: '7', // transfer request to consult

    NeedTranslate: '8',
    CompletedNeedTranslate: '8.9', // doctor complete need to translate
    End: '9',
};

export const isDeclinedByClinicStatus = (status) => (
    status === RequestStatuses.DeclineByClinic
)

export const splitRequestStatus = (status) => status.split('.')[0];
