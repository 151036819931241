import {REQUEST_PAGE_BEFORE_LOADING_REQUEST} from '../actions';
import {REQUEST_PAGE_MANAGER_NEW_REQUEST} from '../manager/actions';
import {
    REQUEST_PAGE_CONSULTANT_SET_FILTER,
    REQUEST_PAGE_CONSULTANT_SET_VIEW,
    REQUEST_PAGE_CONSULTANT_SET_LOADING,
    REQUEST_PAGE_CONSULTANT_SET_ERROR,
    REQUEST_PAGE_CONSULTANT_SET_CLINICS,
    REQUEST_PAGE_CONSULTANT_SET_DOCTORS,
} from './actions';
import Immutable from 'seamless-immutable';

const INITIAL_STATE = {
    loading: 0,
    error: null,
    doctors: {
        all_pages: 0,
        all_count: 0,
        list: [],
    },
    clinics: {
        all_pages: 0,
        all_count: 0,
        list: [],
    },
    filter: {
        query: null,
        country: null,
        langs: [],
        specialities: [],
        sortBy: 'name',
        orderBy: 'desc',
        favorite: false,
        currentPage: 0,
        pageSize: 10,
    },
    view: 'doctors',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REQUEST_PAGE_MANAGER_NEW_REQUEST:
        case REQUEST_PAGE_BEFORE_LOADING_REQUEST:
            return Immutable.from(INITIAL_STATE);
        case REQUEST_PAGE_CONSULTANT_SET_LOADING:
            const error = action.payload ? {error: null} : {};
            const loading = action.payload ? state.loading + 1 : state.loading - 1;
            return Immutable.merge(state, {
                ...error,
                loading: loading < 0 ? 0 : loading,
            });
        case REQUEST_PAGE_CONSULTANT_SET_ERROR:
            return Immutable.merge(state, {
                error: action.payload,
            });
        case REQUEST_PAGE_CONSULTANT_SET_FILTER:
            return Immutable.merge(state, {
                filter: {
                    ...action.payload,
                },
            }, {deep: true});
        case REQUEST_PAGE_CONSULTANT_SET_VIEW:
            return Immutable.merge(state, {
                view: action.payload,
                filter: {
                  currentPage: 0,
                  pageSize: 10,
                },
            }, {deep: true});
        case REQUEST_PAGE_CONSULTANT_SET_CLINICS:
            return Immutable.merge(state, {
                clinics: action.payload,
            });
        case REQUEST_PAGE_CONSULTANT_SET_DOCTORS:
            return Immutable.merge(state, {
                doctors: action.payload,
            });
        default:
            return state;
    }
}
