import {createSelector} from "reselect";
import Immutable from 'seamless-immutable';
import _ from 'lodash';

export const getViewConclusionSelector = state => state.viewConclusion;

export const isConclusionLoadingSelector = createSelector(
    getViewConclusionSelector,
    conclusion => conclusion.loading
);

export const getConclusionDataSelector = createSelector(
    getViewConclusionSelector,
    ({doctorInfo, clinicInfo, data, lang, previewData}) => {
        if (!doctorInfo || !data || !clinicInfo) {
            return null;
        }

        const conclusionData = {
            coverPage: {},
            doctorInfo: {},
            summary: null,
            questions: [],
            lang,
            clinicName: '',
            recommendation: null,
            reference: null,
            treatment: null,
        };

        conclusionData.clinicName = _.get(clinicInfo, 'name');

        conclusionData.coverPage.clinicLogo = _.get(clinicInfo, 'url');
        conclusionData.coverPage.reason = _.get(data, ['reason', lang]);
        conclusionData.coverPage.datetime = _.get(data, 'datetime');

        conclusionData.doctorInfo.name = _.get(doctorInfo, 'name');
        conclusionData.doctorInfo.url = _.get(doctorInfo, 'url');
        conclusionData.doctorInfo.mainSpecialities = _.get(doctorInfo, 'mainSpecialities', []);
        conclusionData.doctorInfo.grade = _.get(doctorInfo, 'grade');
        conclusionData.doctorInfo.age = _.get(doctorInfo, 'age');
        conclusionData.doctorInfo.country = _.get(doctorInfo, ['country', 'name']);
        conclusionData.doctorInfo.city = _.get(doctorInfo, 'city');
        conclusionData.doctorInfo.position = _.get(doctorInfo, 'position');
        conclusionData.doctorInfo.about = _.get(doctorInfo, 'about');
        conclusionData.doctorInfo.files = _.get(doctorInfo, 'files', []);

        conclusionData.summary = _.get(data, ['conclusion', 'summary', lang]);
        conclusionData.recommendation = _.get(data, ['conclusion', 'recommendation', lang]);
        conclusionData.reference = _.get(data, ['conclusion', 'reference', lang]);
        conclusionData.treatment = _.get(data, ['conclusion', 'treatment', lang]);

        conclusionData.questions = _.get(data, ['conclusion', 'questions'], []).map(q => ({
            ask: _.get(q, ['ask', lang]),
            answer: _.get(q, ['answer', lang]),
        }));

        if (previewData) {
            conclusionData.summary = _.get(previewData, ['summary', lang]) || conclusionData.summary;
            conclusionData.recommendation = _.get(previewData, ['recommendation', lang]) || conclusionData.recommendation;
            conclusionData.reference = _.get(previewData, ['reference', lang]) || conclusionData.reference;
            conclusionData.treatment = _.get(previewData, ['treatment', lang]) || conclusionData.treatment;

            if (previewData.questions) {
                conclusionData.questions = _.get(previewData, ['questions'], []).map(q => ({
                    ask: _.get(q, ['ask', lang]),
                    answer: _.get(q, ['answer', lang]),
                }));
            }
        }

        return Immutable.asMutable(conclusionData, {deep: true});
    }
);

export const getConclusionErrorSelector = createSelector(
    getViewConclusionSelector,
    conclusion => conclusion.error
);

export const getConclusionDoctorInfoSelector = createSelector(
    getViewConclusionSelector,
    conclusion => conclusion.doctorInfo,
);
