import React from 'react';
import {connect} from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {IntlProvider} from 'react-intl';
import ReduxToastr from 'react-redux-toastr';

import ModalRoot from "./components/Modals/ModalRoot";
import {currentLanguageSelector} from "./redux/lang/selectors";
import { AppRouter } from './AppRouter';


interface Props extends RouteComponentProps {
    lang: string;
    session: string;
    messages: any;
}


// if you are looking for a notification-prompt to fill/complete out a profile
// check: containers/MainPage/hooks/useCompleteProfileReminder

const App = ({lang, session, messages}: Props) => {
    const renderApp = () => (
        <IntlProvider locale={lang} messages={messages}>
            <ReduxToastr
                newestOnTop={false}
                preventDuplicates
                transitionIn='fadeIn'
                transitionOut='fadeOut'
                position='top-right'/>
            <AppRouter session={session}/>
            <ModalRoot/>
        </IntlProvider>
    );



    return (
        <React.Fragment>
            {lang && renderApp()}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    lang: currentLanguageSelector(state),
    session: state.session,
    messages: state.translations.messages
});

export default connect(mapStateToProps, null)(withRouter(App));
