import Immutable from 'seamless-immutable';

import RequestStepIds from '../../../constants/RequestStepIds';
import {
    REQUEST_PAGE_HIDE_LOADER,
    REQUEST_PAGE_SHOW_LOADER,
    REQUEST_PAGE_BEFORE_LOADING_REQUEST,
} from '../actions';
import {
    REQUEST_PAGE_MANAGER_NEW_REQUEST,
    REQUEST_PAGE_MANAGER_SET_CURRENT_STEP,
    REQUEST_PAGE_MANAGER_SET_BACKLINK,
} from './actions';

const INITIAL_STATE = {
    currentStep: null,
    loading: 0,
    backLink: '/main/requests',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REQUEST_PAGE_MANAGER_SET_CURRENT_STEP:
            return Immutable.merge(state, {
                currentStep: action.payload,
            });
        case REQUEST_PAGE_MANAGER_SET_BACKLINK:
            return Immutable.merge(state, {
                backLink: action.payload,
            });
        case REQUEST_PAGE_MANAGER_NEW_REQUEST:
            return {
                ...INITIAL_STATE,
                currentStep: RequestStepIds.RequestConsultantsId,
            };
        case REQUEST_PAGE_BEFORE_LOADING_REQUEST:
            return Immutable(INITIAL_STATE);
        case REQUEST_PAGE_SHOW_LOADER:
            return Immutable.merge(state, {
                loading: state.loading + 1,
            });
        case REQUEST_PAGE_HIDE_LOADER:
            const loading = state.loading - 1;
            return Immutable.merge(state, {
                loading: loading < 0 ? 0 : loading,
            });
        default:
            return state;
    }
}
