export default {
    query: 'q', // search term
    currentPage: 'p', // current page
    pageSize: 'ps', // page size
    status: 's', // entity status (blocked, new, active and etc)
    countries: 'c', // list of countries ids 1,2,3
    specialities: 'spec', // list of specialities ids 1,2,3
    fromDate: 'fd', // register from date
    toDate: 'td', // register to date
    sortBy: 'sb', // sort by field
    orderBy: 'ob', // order by desc, asc
    clinics: 'cls', // list of clinics
    coordinatorType: 'crt', // coordinator type
    userRole: 'ur', // user role (rights) see in constants
    languages: 'lang', // languages
    doctor: 'd', // doctor
    coordinator: 'coor', // coordinator
    type: 't', // type
    fileTypes: 'ft', // list of file types
    country: 'cty', // country
    date: 'date',
}
