import React from 'react';
import {FormattedMessage} from "react-intl";
import HeaderCommonCell from "./HeaderCommonCell";

import type { CellProps } from '../SimpleTable.interface';

interface Props extends CellProps {
    titleKey: string;
    children?: React.ReactNode;
}

const HeaderCell = React.memo<Props>(({children, titleKey, ...props}) => {
    const {column} = props;
    return (
        <HeaderCommonCell
            {...props}
            align={column.align}>
                {
                    children ?
                        children :
                        <span className="Table__cell-header-text">
                            <FormattedMessage id={titleKey}/>
                        </span>
                }
        </HeaderCommonCell>
    );
});

HeaderCell.displayName="HeaderCell";

export default HeaderCell;
