import React, {useCallback, useEffect, useState} from 'react';
import _identity from 'lodash/identity';
import {useField} from 'react-final-form';
import cn from 'classnames';

import TagList, {type Props as TagListProps} from './TagList';
import {hasError} from '../../../helpers/Common';
import type {Tag} from './TagList.interface';

interface Props extends TagListProps {
	filterSelectedTag?: (v: string, index: number) => boolean;
	itemBuilder?: (v: any) => any;
	selectedItemHandler?: Function;
}

const TagListField = React.memo<Props>(
	({
		filterSelectedTag,
		itemBuilder = _identity,
		selectedItemHandler,
		canSelectTag,
		name,
		suggestions,
		handleRemoved,
		handleAddition,
		className,
		handleFilterSuggestions,
		renderSuggestion,
		...props
	}) => {
		const {
			input: {value: fieldValue = [], onChange, onFocus, onBlur},
			meta,
		} = useField(name);
		const [tags, setTags] = useState([]);
		const [selectedTags, setSelectedTags] = useState([]);

		useEffect(() => {
			if (Array.isArray(fieldValue)) {
				setTags(
					suggestions.filter(s => {
						return fieldValue.find(v => v.id === s.id);
					}),
				);
				if (canSelectTag) {
					setSelectedTags(
						fieldValue.filter(filterSelectedTag).map(s => s.id),
					);
				}
			} else {
				setTags([]);
				if (canSelectTag) {
					setSelectedTags([]);
				}
			}
		}, [canSelectTag, fieldValue, filterSelectedTag, suggestions]);

		const handleTagAdd = useCallback(
			(tag: Tag) => {
				const item = itemBuilder(tag);
				onChange([...fieldValue, item]);
				handleAddition && handleAddition(item);
			},
			[fieldValue, handleAddition, itemBuilder, onChange],
		);

		const handleTagRemove = useCallback(
			(tag: Tag) => {
				onChange(fieldValue.filter(i => i.id !== tag.id));
				handleRemoved && handleRemoved(tag);
			},
			[fieldValue, handleRemoved, onChange],
		);

		const handleSelectedTag = useCallback(
			(ids: number[]) => {
				if (!canSelectTag) {
					return;
				}

				onChange(selectedItemHandler(fieldValue, ids));
			},
			[canSelectTag, fieldValue, onChange, selectedItemHandler],
		);

		return (
			<TagList
				onFocus={onFocus}
				onBlur={onBlur}
				renderSuggestion={renderSuggestion}
				handleFilterSuggestions={handleFilterSuggestions}
				tags={tags}
				selectedTag={canSelectTag && selectedTags}
				onChangeSelectedTag={handleSelectedTag}
				handleRemoved={handleTagRemove}
				handleAddition={handleTagAdd}
				name={name}
				className={cn(
					className,
					// @ts-ignore
					hasError(meta) && 'Health-taglist--error',
				)}
				suggestions={suggestions}
				canSelectTag={canSelectTag}
				{...props}
			/>
		);
	},
);

TagListField.displayName = 'HealthTagListField';

export default TagListField;
