import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './OverviewAction.scss';
import Badge from '../../../../components/common/Badge/Badge';

const OverviewAction = ({
	className,
	icon,
	title,
	onClick,
	disabled,
	variant,
	renderBadge,
    renderBottomBadge,
	children,
	...props
}) => {
	return (
		<div className={'Overview-action'}>
			<button
				{...props}
				disabled={disabled}
				onClick={onClick}
				className={cn(
					'Overview-action__button',
					className,
					disabled && 'Overview-action__button--disabled',
					variant &&
						`Overview-action__button--${
							disabled ? 'gray' : variant
						}`,
				)}
				type="button"
				title={title}
			>
				<FontAwesomeIcon
					className={'Overview-action__icon'}
					size="3x"
					icon={icon}
				/>
				{children}
				{renderBadge && (
					<Badge
						link
						variant="darkgray"
						className={'Overview-action__badge Overview-action__badge--top'}
					>
						{renderBadge()}
					</Badge>
				)}
				{renderBottomBadge && (
					<Badge
						variant="darkgray"
						className={
							'Overview-action__badge Overview-action__badge--bottom'
						}
					>
						{renderBottomBadge()}
					</Badge>
				)}
			</button>
			<div className={'Overview-action__title'}>{title}</div>
		</div>
	);
};

OverviewAction.displayName = 'OverviewAction';

OverviewAction.propTypes = {
	icon: PropTypes.object,
	title: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	variant: PropTypes.oneOf([
		'blue',
		'gray',
		'ruby',
		'green',
		'orange',
		'primary',
		null,
	]),
	renderBadge: PropTypes.func,
};

OverviewAction.defaultProps = {
	disabled: false,
	variant: 'blue',
};

export default OverviewAction;
