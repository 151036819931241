
import React, { DependencyList } from 'react';

import _delay from 'lodash/delay';

export const useDeferredCallback = (ms: number, fn: () => void, deps: DependencyList) => {
    React.useEffect(() => {
        let timerId;
    
        fn();
    
        timerId = _delay(function loopTimer() {
            fn();
    
          timerId = _delay(loopTimer, ms);
        }, ms);
    
        return () => clearInterval(timerId);
      }, deps);
}