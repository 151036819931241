import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import cn from "classnames";
import {faCircle, faUserMd} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage, useIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";

import './ViewDoctor.scss';
import * as viewDoctorActions from "../../redux/viewDoctor/actions";
import * as viewDoctorSelectors from "../../redux/viewDoctor/selectors";
import * as translationsSelectors from "../../redux/translations/selectors";
import pageNames from "../../constants/PageNames";
import Spinner from "../Spinner/Spinner";
import Photo from "../Photo/Photo";
import List from "../List/List";
import {MAX_DISPLAYED_FILES, MAX_DISPLAYED_SPECIALITIES, MAX_TRUNCATED_TEXT_LENGTH} from "../../constants/AppConfig";
import TextEllipsis from "../TextEllipsis/TextEllipsis";
import ViewFiles from "../ViewFiles/ViewFiles";
import ViewClinicLink from "../Links/ViewClinicLink";
import LocationStateFroms from "../../constants/LocationStateFroms";
import {RowDisplayField} from "../RowDisplayField/RowDisplayField";
import FileItem from "../ViewFiles/components/FileItem";
import Price from "../Price/Price";

const COLUMNS_COUNT = 2;

const ViewDoctor = React.memo(
    ({
         id, initViewDoctor, className, isLoading, isTranslationLoaded,
         doctor, error
     }) => {
        const intl = useIntl();

        useEffect(() => {
            initViewDoctor(id);
        }, [id, initViewDoctor]);

        return (
            <div className={cn(className, 'Health-view-doctor')}>
                {
                    isTranslationLoaded && !isLoading && doctor && !error &&
                    <React.Fragment>
                        <div className="Health-view-doctor__general mb-3">
                            <div className="Health-view-clinic__photo-wrapper Align-stretch bg-white">
                                <figure className="figure">
                                    <Photo
                                        fallbackIcon={faUserMd}
                                        fallbackIconSize={"8x"}
                                        imageClassName="img-fluid figure__img" src={doctor.url}/>
                                    <figcaption
                                        className="figure__caption mt-1">
                                        {
                                            doctor.languages.map((lang) => (<span>{lang}</span>))
                                        }
                                    </figcaption>
                                </figure>
                            </div>
                            <div className="Flex-1">
                                <div className="ml-2">
                                    <h2>{doctor.name}</h2>
                                    <RowDisplayField labelKey="viewDoctor.main_spec">
                                        <ul className="text-bold">
                                            {
                                                doctor.mainSpecialities.map((s, i) => (<li key={i}>{s}</li>))
                                            }
                                        </ul>
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewDoctor.clinic">
                                        {
                                            doctor.clinic ?
                                                <ViewClinicLink
                                                    className="Clinic__link link-text"
                                                    id={doctor.clinic.id}
                                                    from={LocationStateFroms.viewDoctor}
                                                    params={{
                                                        backLabel: doctor.name,
                                                        id,
                                                    }}>{doctor.clinic.name}</ViewClinicLink>
                                                : null
                                        }
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewDoctor.grade">
                                        {doctor.grade}
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewDoctor.years">
                                        {doctor.age}
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewDoctor.country">
                                        {doctor.country ? doctor.country.name : null}
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewDoctor.city">
                                        {doctor.city}
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewDoctor.position">
                                        {doctor.position}
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewDoctor.price">
                                        <Price
                                            value={_.get(doctor, 'consult.value')}
                                            currency={_.get(doctor, 'consult.symbol')}/>
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewDoctor.translate_price">
                                        {
                                            doctor.consult.translate ?
                                                <Price
                                                    value={_.get(doctor, 'consult.translate')}
                                                    currency={_.get(doctor, 'consult.symbol')}/>
                                                : '-'
                                        }
                                    </RowDisplayField>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <h3><FormattedMessage id="viewDoctor.speciality"/></h3>
                            <List
                                columns={COLUMNS_COUNT}
                                renderItem={
                                    (item) => (
                                        <React.Fragment>
                                            <FontAwesomeIcon
                                                className="Health-list__item-style"
                                                icon={faCircle} size={"xs"}/>
                                            <span>{item}</span>
                                        </React.Fragment>
                                    )
                                }
                                isTruncate
                                hideText={intl.formatMessage({id: 'common.more_hide_all'})}
                                showMoreText={intl.formatMessage({id: 'common.more_show_all'})}
                                maxCount={MAX_DISPLAYED_SPECIALITIES}
                                items={doctor.specialities}/>
                        </div>
                        <div className="mb-3">
                            <h3><FormattedMessage id="viewDoctor.doctor_about"/></h3>
                            <TextEllipsis
                                text={doctor.about}
                                hideMoreText={intl.formatMessage({id: 'common.more_hide_all'})}
                                showMoreText={intl.formatMessage({id: 'common.more_show_all'})}
                                length={MAX_TRUNCATED_TEXT_LENGTH}/>
                        </div>
                        <ViewFiles
                            renderItem={(item) => <FileItem item={item}/>}
                            hideText={intl.formatMessage({id: 'common.more_hide_all'})}
                            showMoreText={intl.formatMessage({id: 'common.more_show_all'})}
                            maxCount={MAX_DISPLAYED_FILES}
                            columns={2}
                            files={doctor.files}/>
                    </React.Fragment>
                }
                <Spinner isLoading={isLoading}/>
                {
                    error &&
                    <div>Ошибка загрузки доктора</div>
                }
            </div>
        );
    }
);

ViewDoctor.propTypes = {
    id: PropTypes.any.isRequired,
};
ViewDoctor.displayName = 'HealthViewDoctor';

const mapStateToProps = (state) => {
    return {
        isLoading: viewDoctorSelectors.isLoadingSelector(state),
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, pageNames.ViewDoctor),
        doctor: viewDoctorSelectors.getDoctorDataSelector(state),
        error: viewDoctorSelectors.getDoctorErrorSelector(state),
    }
};

export default connect(mapStateToProps, {
    initViewDoctor: viewDoctorActions.initViewDoctor
})(ViewDoctor);
