import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedHTMLMessage, FormattedMessage, useIntl} from "react-intl";
import {useField} from "react-final-form";

import './RequestFormView.scss';
import Hint from '../../../../../../components/common/Hint/Hint';
import SelectTagListField from '../../../../../../components/common/SelectTagList/SelectTagListField';
import TagList from '../../../../../../components/common/TagList/TagList';
import TagListField from '../../../../../../components/common/TagList/TagListField';
import TextBoxField from '../../../../../../components/common/TextBox/TextBoxField';
import * as commonActions from '../../../../../../redux/common/actions';
import * as commonSelectors from '../../../../../../redux/common/selectors';
import {RequestSectionTitle} from '../../../../../../components/request/RequestSectionTitle/RequestSectionTitle';
import LanguageTagItem from '../LanguageTagItem/LanguageTagItem';
import RequestDocumentManager from '../RequestDocumentManager/RequestDocumentManager';
import * as requestCommonSelectors from '../../../../../../redux/requestPage/common/selectors';
import * as requestSelectors from '../../../../../../redux/requestPage/request/selectors';
import IfCondition from "../../../../../../components/common/IfCondition/IfCondition";
import SelectTagList from "../../../../../../components/common/SelectTagList/SelectTagList";

export const MAX_REASON_LENGTH = 120;

export const RequestFormView = ({onFocus, isEditSpecialities}) => {
    const dispatch = useDispatch();
    const specialities = useSelector(commonSelectors.getSpecialitiesSelector);
    const fileTypes = useSelector(requestCommonSelectors.getCommonFileTypes);
    const langsCommunication = useSelector(requestSelectors.getRequestFormLanguages);
    const {helpMe} = useSelector(requestSelectors.getRequestData);
    const intl = useIntl();
    const doctorSpecialities = useField('specialities');

    useEffect(() => {
        dispatch(commonActions.fetchSpecialities());
    }, [dispatch]);

    return (
        <form
            onFocus={onFocus}
            className="Request-form-view Health-form"
            noValidate
            autoComplete={'off'}>
            <RequestSectionTitle className="mt-2">
                <FormattedMessage id={"request.form.consultation_language"}/>
            </RequestSectionTitle>
            <SelectTagListField
                renderItem={(props) => <LanguageTagItem {...props} />}
                name="lang"
                idField="code"
                labelField="label"
                options={langsCommunication}/>
            <Hint className="mt-1 mb-2">
                {!helpMe ?
                    <FormattedHTMLMessage id={"request.form.translate_hint"}/> :
                    <FormattedHTMLMessage id={"request.form.translate_clinic_hint"}/>}
            </Hint>
            <RequestSectionTitle>
                <FormattedMessage id={"request.form.reason"}/>
            </RequestSectionTitle>
            <TextBoxField
                maxlength={MAX_REASON_LENGTH}
                className="mb-3"
                name="reason"/>
            <RequestSectionTitle>
                <FormattedMessage id={"request.form.description"}/>
            </RequestSectionTitle>
            <TextBoxField
                className="mb-3"
                multi
                rows={4}
                name="description"/>
            <div className="Flex-column mb-3">
                <RequestSectionTitle>
                    <FormattedMessage id={"request.form.specialities"}/>&nbsp;
                    <Hint>
                        <FormattedMessage id={"request.form.optional_hint"}/>
                    </Hint>
                </RequestSectionTitle>
                <IfCondition condition={isEditSpecialities}>
                    <TagListField
                        showArrow
                        className="Request-form-view__specialities"
                        inputContainerClass="inline-block width-50 mr-1"
                        inlineTags
                        tagsPosition="right"
                        minQueryLength={0}
                        placeholder={intl.formatMessage({id: 'common.letter_help'})}
                        shouldExcludeTags
                        labelField="label"
                        suggestions={specialities}
                        handleFilterSuggestions={TagList.FilterSuggestions.withStartWordFilter}
                        renderSuggestion={TagList.RenderSuggestions.simpleRender}
                        name='specialities'/>
                </IfCondition>
                <IfCondition condition={!isEditSpecialities}>
                    <SelectTagList disabled options={doctorSpecialities.input.value} />
                </IfCondition>
            </div>
            <RequestSectionTitle>
                <FormattedMessage id={"request.form.diagnostic"}/>&nbsp;
                <Hint><FormattedMessage id={"request.form.optional_hint"}/></Hint>
            </RequestSectionTitle>
            <TextBoxField
                className="width-75 mb-3"
                name="diagnostic"/>
            <div className="Flex-column mb-3">
                <RequestSectionTitle>
                    <FormattedMessage id={"request.form.specials"}/>&nbsp;
                    <Hint><FormattedMessage id={"request.form.optional_hint"}/></Hint>
                </RequestSectionTitle>
                <TextBoxField
                    multi
                    rows={3}
                    name="specials"/>
            </div>
            <div>
                <RequestSectionTitle>
                    <FormattedMessage id={"request.form.files"}/>
                </RequestSectionTitle>
                <RequestDocumentManager
                    fileTypes={fileTypes}
                    prefixDocumentsKey="test"/>
            </div>
        </form>
    )
};

RequestFormView.propTypes = {
    onFocus: PropTypes.func,
    isEditSpecialities: PropTypes.bool,
};

RequestFormView.defaultProps = {
    isEditSpecialities: true,
};
