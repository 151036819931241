import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import './Badge.scss';

const Badge = ({className, pill, link, variant, children}) => {
    return (
        <span className={
            cn('Health-badge', {
                    'Health-badge--pill': pill,
                    'Health-badge--link': link,
                },
                variant && `Health-badge--${variant}`
                , className)
        }>
            {children}
        </span>
    )
}

Badge.diplayName = 'HealthBadge';
Badge.propTypes = {
    variant: PropTypes.oneOf(['blue', 'gray', 'darkgray', 'ruby', 'green', 'orange', 'primary', 'transparent', null]),
    pill: PropTypes.bool,
    link: PropTypes.bool,
};
Badge.defaultProps = {
    variant: 'transparent',
    pill: true,
    link: false,
};

export default Badge;
