import {combineReducers} from 'redux';
import consultantReducer from './consultant/reducer';
import requestReducer from './request/reducer';
import managerReducer from './manager/reducer';
import commonReducer from './common/reducer';

export default combineReducers({
    request: requestReducer,
    consultant: consultantReducer,
    manager: managerReducer,
    common: commonReducer,
});
