import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import {FormattedMessage, useIntl} from "react-intl";

import './AddOrEditMedicalRecordModal.scss';
import Button from '../../common/Button/Button';
import CloseButton from '../components/CloseButton/CloseButton';
import {useSelector} from "react-redux";
import {getMedicalFileTypesSelector} from "../../../redux/common/selectors";
import TextBoxField from "../../common/TextBox/TextBoxField";
import DatePickerField from "../../common/DatePicker/DatePickerField";
import SelectBoxField from "../../common/SelectBox/SelectBoxField";
import {currentLanguageSelector} from "../../../redux/lang/selectors";
import {requireFieldValidator} from "../../../helpers/Validators";
import {MedicalFileTypeEnum} from '../../../constants/FileTypeList';

const AddOrEditMedicalRecordModal = ({
                                         onAddOrEdit,
                                         onClose,
                                         hideModal,
                                         file,
                                         isAdd,
                                         disableFileTypeField,
                                         hideConclusion77FileType,
                                     }) => {
    const intl = useIntl();
    const fileTypeItems = useSelector(getMedicalFileTypesSelector);
    const currentLang = useSelector(currentLanguageSelector);

    const fileTypes = React.useMemo(() => {
        if (!hideConclusion77FileType) {
            return fileTypeItems;
        }

        return fileTypeItems.filter(file => file.id !== MedicalFileTypeEnum.Conclusion)
    }, [fileTypeItems, hideConclusion77FileType]);

    const validateForm = useCallback((values) => {
        const errors = {};

        if (!values.date) {
            errors.date = true;
        }

        if (!values.type) {
            errors.type = true;
        }
        return Object.keys(errors).length ? errors : undefined;
    }, []);

    const handleSubmit = useCallback((values) => {
        const errors = validateForm(values);

        if (errors) {
            return errors;
        }

        onAddOrEdit && onAddOrEdit(values);
        hideModal();
    }, [hideModal, onAddOrEdit, validateForm]);

    const handleClose = useCallback(() => {
        onClose && onClose();
        hideModal();
    }, [hideModal, onClose]);

    return (
        <Form
            initialValues={file}
            onSubmit={handleSubmit}>
            {
                (formApi) => (
                    <form
                        onSubmit={formApi.handleSubmit}
                        noValidate autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off">
                        <CloseButton onClick={handleClose}/>
                        <div className="Modal__header">
                            <div className="Modal__title">
                                <FormattedMessage
                                    id={isAdd ?
                                        'common.dlg_add_or_edit_medical_file.title_add' :
                                        'common.dlg_add_or_edit_medical_file.title_edit'
                                    }/>
                            </div>
                        </div>
                        <div className="Modal__body mt-2">
                            <TextBoxField
                                validate={requireFieldValidator}
                                inline
                                labelClass="col-4"
                                inputClass="col-8"
                                label={intl.formatMessage({id: "common.dlg_add_or_edit_medical_file.name"})}
                                name="name"/>
                            <DatePickerField
                                inline
                                labelClass="col-4"
                                inputClass="col-8"
                                locale={currentLang}
                                label={intl.formatMessage({id: "common.dlg_add_or_edit_medical_file.date"})}
                                name="date"/>
                            <SelectBoxField
                                inline
                                disabled={disableFileTypeField}
                                labelClassName="col-4"
                                fieldBodyClassName="col-8"
                                fieldClassName="row"
                                label={intl.formatMessage({id: "common.dlg_add_or_edit_medical_file.type"})}
                                name="type"
                                options={fileTypes}/>
                        </div>
                        <div className="Modal__footer">
                            <Button
                                type="button"
                                variant="gray"
                                viewType="minimal"
                                onClick={handleClose}>
                                <FormattedMessage id={'common.dlg_add_or_edit_medical_file.btn_close'}/>
                            </Button>
                            <Button type="submit">
                                <FormattedMessage
                                    id={isAdd ?
                                        'common.dlg_add_or_edit_medical_file.btn_add' :
                                        'common.dlg_add_or_edit_medical_file.btn_save'
                                    }/>
                            </Button>
                        </div>
                    </form>
                )
            }
        </Form>
    );
};

export default AddOrEditMedicalRecordModal;
