import React from 'react';
import ReactDOM from 'react-dom';

export const ConclusionPortalToHeader = ({intoId, children}) => {
    const [divElement] = React.useState(() => document.getElementById(intoId));

    // TODO
    if (!divElement) {
        return null;
    }

    return ReactDOM.createPortal(
        children,
        divElement
    );
}