import React from 'react';
import cn from 'classnames';

import './Hint.scss';

interface Props {
    className?: string;
}

const Hint: React.FC<Props> = ({className, children, ...props}) => {
    return (
        <div className={cn(className, 'Health-hint')} {...props}>
            <span className="Health-hint__text">{children}</span>
        </div>
    )
};

Hint.displayName = 'HealthHint';

export default Hint;
