import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-regular-svg-icons";
import {FormattedMessage} from "react-intl";

import './RegistrationResult.scss';
import {getRootUrl} from '../../../helpers/UrlHelper';
import Button from '../../common/Button/Button';
import ButtonLink from "../../common/Button/ButtonLink";

export const RegistrationResult = React.memo(
    ({
         status, title, description, errorText, redirectTo, className, btnGotoMain,
     }) => {
        const goMainPage = useCallback(() => window.location.replace(getRootUrl()), []);

        return (
            <div className={cn('Health-Registration__result Flex-column', className)}>
                <FontAwesomeIcon className="Align-self-center mb-3"
                                 icon={status ? faCheckCircle : faTimesCircle} size="4x"
                                 color={status ? '#46aa42' : '#ed145b'}/>
                <h2
                    className="Health-Registration__result-title">
                    <FormattedMessage id={title}/>
                </h2>
                <div
                    className="Health-Registration__result-description">
                    {
                        status ?
                            description ? <FormattedMessage id={description}/> : null :
                            <span>{errorText}</span>
                    }

                </div>
                <div className="Flex-row Justify-center">
                    {
                        status &&
                        <ButtonLink to={redirectTo}>
                            <FormattedMessage id={btnGotoMain} />
                        </ButtonLink>
                    }
                    {
                        !status &&
                        <Button onClick={goMainPage}>
                            <FormattedMessage id={btnGotoMain} />
                        </Button>
                    }
                </div>
            </div>
        )
    }
);

RegistrationResult.propTypes = {
    status: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    btnGotoMain: PropTypes.string,
    errorText: PropTypes.string,
    redirectTo: PropTypes.string,
};

RegistrationResult.defaultProps = {
    status: true,
};

RegistrationResult.displayName = 'HealthRegistrationResult';
