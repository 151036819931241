import {fetchTranslation} from "../translations/actions";
import {fetchLanguages} from "../common/actions";
import pageNames from "../../constants/PageNames";
import * as api from '../../api';
import {clearDataParams} from "../../helpers/Common";

export const SET_TRANSLATES_PAGE_LOADING = 'SET_TRANSLATES_PAGE_LOADING';
export const SET_TRANSLATES_PAGE_TRANSLATES = 'SET_TRANSLATES_PAGE_TRANSLATES';
export const SET_TRANSLATES_PAGE_ADDED_TRANSLATE = 'SET_TRANSLATES_PAGE_AEDDD_TRANSLATE';
export const SET_TRANSLATES_PAGE_UPDATED_TRANSLATE = 'SET_TRANSLATES_PAGE_UPDATED_TRANSLATE';
export const SET_TRANSLATES_PAGE_REMOVED_TRANSLATE = 'SET_TRANSLATES_PAGE_REMOVED_TRANSLATE';

export const initTranslatesPage = () => async (dispatch) => {
    dispatch(setTranslatesPageLoading(true));
    await Promise.all([
        dispatch(fetchTranslation({
            pageName: pageNames.Translates
        })),
        dispatch(fetchLanguages()),
    ]);
    dispatch(setTranslatesPageLoading(false));
};

export const setTranslatesPageLoading = (loading) => {
    return {
        type: SET_TRANSLATES_PAGE_LOADING,
        payload: loading
    }
};

export const getTranslates = (
    {
        param,
        query, currentPage,
        sortBy, orderBy,
        pageSize = 10
    }
) => async (dispath) => {
    const params = clearDataParams({
        s: query,
        page: currentPage - 1,
        sortBy,
        orderBy,
        param,
        size: pageSize,
    });

    try {
        const res = await api.loadTranslates(params);

        dispath({
            type: SET_TRANSLATES_PAGE_TRANSLATES,
            payload: res.data
        })
    } catch (err) {
    }
};

export const removeTranslate = (id) => async (dispath) => {
    try {
        await api.removeTranslate({id})

        dispath({
            type: SET_TRANSLATES_PAGE_REMOVED_TRANSLATE,
            payload: {
                id,
            }
        })
    } catch (err) {
        throw err
    }
}

export const addTranslate = (record) => async (dispath) => {
    try {
        const res = await api.addTranslate(record)

        dispath({
            type: SET_TRANSLATES_PAGE_ADDED_TRANSLATE,
            payload: res.data,
        })
    } catch (err) {
        throw err
    }
}

export const updateTranslate = (record) => async (dispath) => {
    try {
        const res = await api.updateTranslate(record)

        dispath({
            type: SET_TRANSLATES_PAGE_UPDATED_TRANSLATE,
            payload: res.data,
        })
    } catch (err) {
        throw err
    }
}
