import React from 'react';
import _identity from 'lodash/identity';
import cn from 'classnames';

import './ReplyMessage.scss';
import MessageMeta from '../MessageMeta/MessageMeta';
import type { Message } from '../../Chat.interface';

interface Props {
    className?: string;
    message: Message,
    onClick?: React.MouseEventHandler;
}

const ReplyMessage = ({message, onClick = _identity, className}: Props) => {
    return (
        <div
            onClick={onClick}
            className={cn('Health-chat__reply-message', className)}>
            <MessageMeta
                datetime={message.datetime}
                userName={message.user.name} />
            <div className="Health-chat__reply-message_text">
                {message.text}
            </div>
        </div>
    )
}

export default ReplyMessage;
