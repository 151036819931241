import React from 'react';
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import * as consultationSelectors from "../../../../redux/consultationPage/consultation/selectors";
import IfCondition from "../../../../components/common/IfCondition/IfCondition";
import ConsultationConclusionPreview from "./components/ConsultationConclusionPreview/ConsultationConclusionPreview";
import ConsultationConclusionWaiting from "./components/ConsultationConclusionWaiting/ConsultationConclusionWaiting";

const ConsultationConclusionPage = () => {
    const {id} = useParams();
    const conclusionData = useSelector(consultationSelectors.getConsultationConclusionData);
    const isLoading = useSelector(consultationSelectors.isConsultationLoading);
    const isCompleted = useSelector(consultationSelectors.isConsultationCompleted);
    const phpsession = useSelector(state => state.session);

    const isConclusionFileSaved = useSelector(consultationSelectors.isConclusionFileSavedSelector);
    const unsavedConclusion = useSelector(consultationSelectors.getUnsavedMedicalConclusionFile);

    return (
        <IfCondition condition={!isLoading} placeholder={<div className="Flex-1"/>}>
            <IfCondition condition={!isCompleted}>
                <ConsultationConclusionWaiting />
            </IfCondition>
            <IfCondition condition={isCompleted}>
                <ConsultationConclusionPreview
                    id={id}
                    isFileSaved={isConclusionFileSaved}
                    unsavedConclusionFile={unsavedConclusion}
                    phpsession={phpsession}
                    lang={conclusionData.lang}/>
            </IfCondition>
        </IfCondition>
    )
};

export default ConsultationConclusionPage;
