import axios from "axios";

export const loadFile = (url) => {
    return axios.get(url, {responseType: "blob"})
        .then((res) => {
            const type = res.headers['content-type'];
            const blob = res.data;

            blob.name = `file.${type.split('/')[1]}`;
            return blob;
        })
}
