import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './Logo.scss';

export const Logo = React.memo(
    ({
         onClick, className
     }) => (
        <div onClick={onClick}
             className={cn('Health-logo', className)}>
            <img src="/images/logo.png" className="Health-logo__img" alt="Best 2 health logo"/>
        </div>
    )
);

Logo.propTypes = {
    onClick: PropTypes.func,
};

Logo.displayName = 'HealthLogo';
