export default {
    Common: "/common",
    Main: "/main",
    Account: '/main/account',
    ConfirmEmail: '/confirm-email',
    ContactUs: '/contact-us',
    General: '/main/account/general',
    Login: '/login',
    Overview: '/main/account/overview',
    Profile: '/main/account/profile',
    Security: '/main/account/security',
    EditProfile: '/main/account/profile',
    ViewClinic: '/main/view/clinic',
    ViewDoctor: '/main/view/doctor',
    ViewCoordinator: '/main/view/coordinator',
    PatientReg: '/user-reg',
    Requests: '/main/requests',
    Request: '/main/requests/request',
	Translates: '/main/system/translates',
	Statuses: '/main/system/statuses',
    MedicalRecords: '/main/medical-records',
    RememberPassword: '/remember-pass',
    RecoveryPassword: '/account-recovery',
}
