import moment from 'moment';

export const serverFormat = 'YYYY-MM-DD';

export const toServerFormat = (date) => {
    date = moment(date);
    if (date.isValid()) {
        return date.format(serverFormat);
    }
    return null;
};

export const fromServerFormat = (date) => {
    date = moment(date, serverFormat);
    if (date.isValid()) {
        return date.toDate();
    }
    return null;
};

/**
 * convert ms to days
 * @param ms -
 * @return {{hours: number, minutes: number, days: number}}
 */
export const convertSectoDay = (ms) => {
    const msPerHour = 3600000;
    const msPerDay = 24 * msPerHour;
    return {
        days: Math.floor(ms / msPerDay),
        hours: Math.floor((ms % msPerDay) / msPerHour),
        minutes: Math.floor(((ms % msPerDay) % msPerHour) / 60000),
    }
};
