import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';

export const getTranslatesPageSelector = state => state.translatesPage;

export const getTranslatesSelector = createSelector(
    getTranslatesPageSelector,
    page => Immutable.asMutable(page.translates, {deep: true})
);

export const isLoadingSelector = createSelector(
    getTranslatesPageSelector,
    page => page.loading
);

export const getTotalCountsSelector = createSelector(
    getTranslatesPageSelector,
    page => ({
        all: page.all_count,
    })
);

export const getTotalPagesSelector = createSelector(
    getTranslatesPageSelector,
    page => ({
        all: page.all_pages,
    })
);
