import React from 'react';
import PropTypes from 'prop-types';
import {Field} from "react-final-form";

const ConditionField = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
);

ConditionField.propTypes = {
    when: PropTypes.string.isRequired,
    is: PropTypes.any
};

export default ConditionField;
