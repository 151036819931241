import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';

import './ConsultationHeader.scss';
import {BackLink} from '../../../../components/BackLink/BackLink';
import {RequestStatusText} from '../../../../components/request/RequestStatusText/RequestStatusText';
import {useBackLink} from '../../../../hooks/useBackLink';
import * as consultationSelectors from '../../../../redux/consultationPage/consultation/selectors';
import {userRoleSelector} from '../../../../redux/user/selectors';
import {getRequestStatusTextIdByConsultation} from '../../../../helpers/Request';

const ConsultationHeader = () => {
    const intl = useIntl();
    const [backLink] = useBackLink('/main/requests');
    const userRole = useSelector(userRoleSelector);
    const data = useSelector(consultationSelectors.getConsultationData);
    const reason = useSelector(consultationSelectors.getConsultationReason);

    const statusTextId = getRequestStatusTextIdByConsultation(userRole, data);

    return (
        <div className="Consultation-page__header">
                <div className='Consultation-page__header-status'>
                    <BackLink
                        to={backLink}
                        text={intl.formatMessage({id: 'main.tab_consult'})} />

                    <p>
                    <FormattedMessage id={"request.status"}/>:&nbsp;<RequestStatusText className="text-bold" statusTextId={statusTextId} status={data.status} />    

                    </p>
                </div>

                <div className="Consultation-page__header-title">
                    <div class="Consultation-page__header-title-text" title={reason}>
                        <span className="Consultation-page__header-title--label">
                            <FormattedMessage id={"request.header.subject"}/>:</span>&nbsp;
                        {reason}
                    </div>

                    <div
                        id="portal-for-conclusion-controls"
                        className="Consultation-page__header-controls"
                    />
                </div>
        </div>
    )
};

export default ConsultationHeader;
