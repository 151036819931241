import Immutable from 'seamless-immutable';
import { CONFIRM_EMAIL } from './actions';

const INITIAL_STATE = {
    confirm: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONFIRM_EMAIL:
            return Immutable.merge(state, { confirm: action.payload });
        default:
            return state;
    }
}
