import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {FormattedMessage} from "react-intl";

import {RequestSectionTitle} from "../../../../../../components/request/RequestSectionTitle/RequestSectionTitle";

const ConsultationConclusionWaiting = () => {
    return (
        <div className="Flex-column Flex-1 Consultation-page__body">
            <RequestSectionTitle className="mb-2 mt-2">
                <FormattedMessage id={'request.consultation_conclusion_page.title'}/>
            </RequestSectionTitle>
            <div className="Flex-column Flex-1 Align-center Justify-center">
                <FontAwesomeIcon icon={faClock} size={'5x'} color="#6592ab"/>
                <h3 className="text-align-center mt-2">
                    <FormattedMessage id={'request.consultation_conclusion_page.subtitle_waiting_doctor'}/>
                </h3>
            </div>
        </div>
    )
}

ConsultationConclusionWaiting.displayName = 'ConsultationConclusionWaiting';

export default ConsultationConclusionWaiting;
