import React from 'react';
import PropTypes from 'prop-types';

const PlaceholderComponent = ({children, placeholder, condition}) => {
    return (
        <React.Fragment>
            {condition ? children : placeholder}
        </React.Fragment>
    );
};

PlaceholderComponent.propTypes = {
    placeholder: PropTypes.any,
    condition: PropTypes.bool.isRequired,
};

PlaceholderComponent.defaultProps = {
    placeholder: '-',
};

export default PlaceholderComponent;
