import React from 'react';
import {faSmile} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './StubPage.scss';

export const StubPage = () => {
    return (
        <div className="Stub-page">
            <FontAwesomeIcon
                className="mb-2"
                size="4x"
                icon={faSmile} />
            Страница в разработке
        </div>
    )
};
