import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './SidePanel.scss';

export const SidePanel = React.forwardRef(
    ({className, left, children}, ref) => {
        return (
            <div ref={ref} className={cn('Health-SidePanel', {
                'Health-SidePanel--left': left,
            }, className)}>
                {children}
            </div>
        )
    },
);

SidePanel.propTypes = {
    left: PropTypes.bool,
};

SidePanel.defaultProps = {
    left: false,
};

SidePanel.displayName = 'HealthSidePanel';
