import React, {useCallback, useContext} from 'react';
import {useSelector} from 'react-redux';

import './RequestSending.scss';
import Button from '../../../../components/common/Button/Button';
import Separator from '../../../../components/common/Separator/Separator';
import {InfoPanel} from '../../../../components/InfoPanel/InfoPanel';
import {useResetWindowScroll} from '../../../../hooks/useResetWindowScroll';
import {RequestInfo} from '../../../../components/request/RequestInfo/RequestInfo';
import {useRequestCurrentLangCommunication} from '../../hooks/useRequestCurrentLangCommunication';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import {RequestPageHeader} from '../RequestPageHeader/RequestPageHeader';
import {RequestSelectedConsultant} from '../RequestSelectedConsultant/RequestSelectedConsultant';
import {SidePanel} from '../../../../components/SidePanel/SidePanel';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';
import {FormattedMessage, useIntl} from "react-intl";
import DeclineRequestButton from "../common/DeclineRequestButton";

export const RequestSending = () => {
    const requestManager = useContext(RequestManagerContext);
    const requestData = useSelector(requestSelectors.getRequestData);
    const currentLang = useRequestCurrentLangCommunication();
    const intl = useIntl();

    const handleOk = useCallback(() => {
        requestManager.goRequestTable();
    }, [requestManager]);

    useResetWindowScroll();

    return (
        <React.Fragment>
            <div className="Request-page__main Request-sending-page">
                <RequestPageHeader title={intl.formatMessage({id: 'request.request_title'})}/>
                <div className="Request-page__body">
                    <RequestSelectedConsultant viewHelpMeHint/>
                    <Separator/>
                    <RequestInfo data={requestData} currentLang={currentLang}/>
                    <div className="Flex-row Justify-center Align-center mt-5 mb-4">
                        <DeclineRequestButton/>
                        <Button
                            className="ml-2 text-uppercase"
                            onClick={handleOk}>
                            <FormattedMessage id="request.buttons.ok" defaultMessage={"Ok"}/>
                        </Button>
                    </div>
                </div>
            </div>
            <SidePanel className="Request-sending-page__side-panel">
                <InfoPanel message={intl.formatMessage({id: 'request.sending.panel_message'})}/>
            </SidePanel>
        </React.Fragment>
    )
};
