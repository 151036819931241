import React from 'react';

import {Route, Redirect, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';

import context from './context';
import PatientRegistration from './containers/PatientRegistration/PatientRegistration';
import ConfirmRegistration from './containers/ConfirmRegistration/ConfirmRegistration';
import MainPage from './containers/MainPage/MainPage';
import LoginPage from './containers/LoginPage/LoginPage';
import {PrivateRoute} from './helpers/PrivateRoute';
import {RememberPasswordPage} from "./containers/RememberPasswordPage/RememberPasswordPage";
import {RecoveryPasswordPage} from "./containers/RecoveryPasswordPage/RecoveryPasswordPage";

interface Props {
    session: string
}

export const AppRouter = ({session}: Props) => {
    return (
        <ConnectedRouter history={context.history}>
        <Switch>
            <Route exact path="/" render={() => (
                <Redirect to="/login"/>
            )}/>
            <Route path="/login/:session?" render={() => {
                return session ? <Redirect to="/main"/> : <LoginPage/>
            }}/>
            <Route path="/registration" exact render={() => {
                return session ? <Redirect to="/main"/> : <PatientRegistration/>
            }}/>
            <Route path="/confirm-registration/:hash" component={ConfirmRegistration}/>
            <Route path="/remember-pass" component={RememberPasswordPage} />
            <Route path="/account-recovery/:token" component={RecoveryPasswordPage} />
            <PrivateRoute path="/main" component={MainPage}/>
            <Route path="*">
                <Redirect to="/login"/>
            </Route>
        </Switch>
    </ConnectedRouter>
    );
}