import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import _ from 'lodash';

import './RequestPageHeader.scss';
import {BackLink} from '../../../../components/BackLink/BackLink';
import {useDateTime} from '../../../../hooks/useDateTime';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import {RequestProgress} from '../RequestProgress/RequestProgress';
import {RequestStatus} from '../RequestStatus/RequestStatus';
import {RequestHeaderFieldInfo} from '../../../../components/request/RequestHeaderFieldInfo/RequestHeaderFieldInfo';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';
import * as commonSelectors from '../../../../redux/common/selectors';
import {userRoleSelector} from '../../../../redux/user/selectors';
import {getRequestStatusTextIdByConsultation} from '../../../../helpers/Request';

export const RequestPageHeader = React.memo(
    ({
         className, children, title,
         hideProgress,
     }) => {
        const intl = useIntl();
        const requestManager = useContext(RequestManagerContext);
        const requestId = useSelector(requestSelectors.getRequestId);
        const requestData = useSelector(requestSelectors.getRequestData);
        const countryInfo = useSelector(state => commonSelectors.getCountryByIdSelector(state, requestData.country));
        const dateRequest = useDateTime(requestData.datetime);
        const userRole = useSelector(userRoleSelector);

        const statusTextId = getRequestStatusTextIdByConsultation(userRole, requestData);

        const requestHeaderTitle = _.get(requestData, `reason.${requestData.lang}`, '-');
        const requestAttrTitle =  title && !requestId ? title : requestHeaderTitle;

        return (
            <div className={cn('Request-page__header', className)}>
                <div className={'Request-page__header-wrapper'}>
                    <div className="Request-page__header_left-side">
                        <BackLink
                            to={requestManager.backLink}
                            text={intl.formatMessage({id: 'main.tab_consult'})}/>
                        <div>
                            <RequestHeaderFieldInfo
                                label="ID:"
                                value={requestId || '-'}/>
                            <RequestHeaderFieldInfo
                                className="ml-2"
                                label={intl.formatMessage({id: 'request.header.request_date'})}
                                value={dateRequest}/>
                            <RequestHeaderFieldInfo
                                className="ml-2"
                                label={intl.formatMessage({id: 'request.header.country'})}
                                value={_.get(countryInfo, 'label', '-')}/>
                        </div>
                        <div className="Request-page__header-title" title={requestAttrTitle}>
                            {
                                title && !requestId ? title
                                    :
                                    <React.Fragment>
                                        <span className="Request-page__header-title--label">
                                            <FormattedMessage id={"request.header.subject"}/>:</span>&nbsp;
                                        {requestHeaderTitle}
                                    </React.Fragment>
                            }
                        </div>
                        {
                            !hideProgress &&
                            <RequestProgress className="mt-1"/>
                        }
                    </div>
                    <div className="Request-page__header_right-side">
                        <RequestStatus title={intl.formatMessage({id: 'request.status'})}  statusTextId={statusTextId} status={requestData.status}   />
                    </div>
                </div>
                {children}
            </div>
        )
    },
);

RequestPageHeader.propTypes = {
    title: PropTypes.string,
    hideProgress: PropTypes.bool,
};

RequestPageHeader.defaultProps = {
    hideProgress: false,
};

RequestPageHeader.displayName = 'HealthRequestPageHeader';
