import React from "react";

type EventFn = (e: MouseEvent) => void;

export const useOutsideClick = (onEvent: EventFn) => {
    React.useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            onEvent && onEvent(event);
        }

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    }, [onEvent]);
}
