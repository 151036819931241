import React from 'react';

import { ROLES } from '../../../../constants/Roles';
import { Message } from '../../Chat.interface';

interface Context {
    onRemoveMessage: (messageId: number) => void,
    onEditMessage: (messageId: number) => void,
    onReplyMessage: (messageId: number) => void,
    onCopyAttachments: (message: Message) => void,
    onCanAttachments: (messageId: Message) => boolean,
    role: ROLES
}

const ConsultationChatContext = React.createContext<Context>(null);

export const ConsultationChatProvider = ConsultationChatContext.Provider;
export const useConsultationChatContext = () => React.useContext(ConsultationChatContext);

export default ConsultationChatContext;
