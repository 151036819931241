import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useField} from 'react-final-form';
import cn from 'classnames';

import './LanguageManager.scss';
import TagList from '../../common/TagList/TagList';

const LanguageManager = ({
                                        className,
                                        placeholder,
                                        hint,
                                        languages = [],
                                        name,
                                        primaryLangName,
                                        onChange,
                                        secondaryLang,
                                    }) => {
    const [tags, setTags] = useState([]);
    const [primaryLang, setPrimaryLang] = useState(null);
    const langField = useField(name, {subscription: {value: true}});
    const primaryLangNameField = useField(primaryLangName, {subscription: {value: true}});

    useEffect(() => {
        const langs = langField.input.value || [];

        setTags(languages.filter(l => langs.includes(l.code)));
    }, [langField.input.value, languages]);

    useEffect(() => {
        const tag = languages.find(l => l.code === primaryLangNameField.input.value);
        setPrimaryLang(tag ? tag.id : null);
    }, [primaryLangNameField.input.value, languages]);

    const handleSelectedTag = useCallback((ids) => {
        const newPrimary = tags.find(l => l.id === ids[0]);
        const langs = langField.input.value || [];

        primaryLangNameField.input.onChange(newPrimary.code);

        if (secondaryLang === newPrimary.code) {
            onChange(langs.filter(l => l !== newPrimary.code)[0]);
        }
    }, [tags, langField.input.value, primaryLangNameField.input, secondaryLang, onChange]);

    const handleTagRemove = useCallback((tag) => {
        const langs = langField.input.value || [];
        const newLangs = langs.filter(l => l !== tag.code);
        const primaryLangCode = primaryLangNameField.input.value;

        langField.input.onChange(newLangs);

        if (newLangs.length === 1) {
            onChange(null);
        } else if (!newLangs.includes(secondaryLang)) {
            onChange(newLangs.filter(l => l !== primaryLangCode)[0]);
        }
    }, [langField.input, primaryLangNameField.input.value, secondaryLang, onChange]);

    const handleTagAdd = useCallback((tag) => {
        const langs = langField.input.value || [];

        langField.input.onChange([...langs, tag.code]);

        if (langs.length === 1) {
            onChange(tag.code);
        }
    }, [langField.input, onChange]);

    return (
        <TagList
            showArrow
            handleAddition={handleTagAdd}
            handleRemoved={handleTagRemove}
            onChangeSelectedTag={handleSelectedTag}
            selectedTag={[primaryLang]}
            canSelectTag
            disabled={tags.length === languages.length}
            tags={tags}
            shouldExcludeTags
            className={cn(className, "Language-manager")}
            inline
            inputContainerClass="Language-manager__input"
            tagsContainerClass="Language-manager__tags"
            minQueryLength={0}
            tagsPosition="right"
            suggestions={languages}
            renderSuggestion={TagList.RenderSuggestions.simpleRender}
            handleFilterSuggestions={TagList.FilterSuggestions.withStartFilter}
            hint={hint}
            placeholder={placeholder} />
    );
};

LanguageManager.propTypes = {
    placeholder: PropTypes.string,
    hint: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
    })),
    name: PropTypes.string.isRequired,
    primaryLangName: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    secondaryLang: PropTypes.string,
};

LanguageManager.defaultProps = {
    name: 'lang',
    primaryLangName: 'primaryLang',
};

LanguageManager.displayName = 'LanguageManager';

export default LanguageManager;
