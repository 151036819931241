import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

export const useBackLink = (defaultLink) => {
    const [backLink, setBackLink] = useState(defaultLink);
    const history = useHistory();

    useEffect(() => {
        const {location: {state}} = history;

        if (state) {
            const link = `${state.pathname}${state.search}`;
            setBackLink(link);
        }
    }, [history]);

    return [backLink];
};
