import axios from 'axios';
import apiRoutes from '../constants/ApiRoutes';

export const loadClinicInfo = ({id, lang, cid} = {}) => {
    return axios.get(apiRoutes.clinicInfo, {
        params: {
            ...id ? {id} : {},
            ...lang ? {lang} : {},
            ...cid ? {c: cid} : {},
        },
    });
};

/**
 * load list of clinics
 * @param {string} params.lang - lang
 * @param {boolean} params.full - true load fulled info about clinic
 * @param {number} params.size - page size
 * @param {number} params.page - page number
 * @param {number} params.cou - list of countries ids
 * @param {number} params.spec - list of specialities ids
 * @param {string} params.s - query (term)
 * @param {string} params.la - language filter
 * @param {string} params.fromDate - from register date clinic
 * @param {string} params.toDate - to register date clinic (YYYY-MM_DD)
 * @param {string} params.status - status code see clinic status codes in constants
 * @return {Promise<AxiosResponse<T>>}
 */
export const loadClinics = (params = {}, options = {}) => {
    return axios.get(apiRoutes.clinicList, {
        params,
        ...options,
    });
};

export const loadClinicComLang = ({countryId}) => {
    return axios.get(apiRoutes.clinicComLang, {
        params: {
            cou: countryId,
        },
    })
};
