import _ from 'lodash';

import {getFileExtension} from '../../../helpers/Common';

export const transformFiles = (files, lang) => {
    const filesFlattenArray = [];
    files.forEach(({id, file, ext, med, ...label}) => {
        const transformedFile = {
            type_id: 0,
            label: {
                [lang]: '',
                ...label,
            },
        };
        if (id) {
            transformedFile.id = id;
        }
        if (typeof med !== 'undefined') {
            transformedFile.med = med;
        }
        if (file && file.result) {
            transformedFile.value = file.result;
        }
        if (file && file.name) {
            transformedFile.ext = getFileExtension(file.name);
        }
        if (typeof file === 'string' && ext) {
            transformedFile.ext = ext;
        }
        filesFlattenArray.push(transformedFile);
    });
    return filesFlattenArray;
};

export const toRequestData = (data, currentLangCommunication) => ({
    country: data.country ? data.country : null,
    clinic: data.clinic ? data.clinic.id : null,
    doctor: data.doctor ? data.doctor.id : null,
    reason: data.reason,
    description: data.description,
    specialities: data.specialities ? data.specialities.map(s => s.id) : [],
    diagnostic: data.diagnostic,
    specials: data.specials,
    files: data.files ? transformFiles(data.files, data.lang) : [],
    lang: data.lang,
    translate: currentLangCommunication.translate,
});

/**
 *
 * @param userMainLangCode - main user lang code
 * @param userComLangs
 * @param consultantLangs
 * @param consultantComLangs
 * @return {string} lang code
 */
export const detectRequestLang = (
    {
        userMainLangCode, userComLangs = [],
        consultantLangs = [], consultantComLangs = [],
    },
) => {
    const DEFAULT_LANG = 'en';

    const unionLangs = _.intersectionWith(
        userComLangs,
        [...consultantLangs, ...consultantComLangs],
        (l1, l2) => l1.code === l2.code,
    );

    // нет пересечений языков пользователя и языков клиники
    // ищем английский в языках клиники
    if (!unionLangs.length) {
        // английского тоже нет
        if (![...consultantLangs, ...consultantComLangs].find(l => l.code === DEFAULT_LANG)) {
            // выбираем первый язык на котором говорит клиника
            return consultantLangs[0].code;
        }
        // нашли англ выставляем
        return DEFAULT_LANG;
    }

    // не нашли основной язык юзера
    if (!unionLangs.find(l => l.code === userMainLangCode)) {
        return unionLangs[0].code;
    }

    // нашли основной язык юзера
    return userMainLangCode;
};

export const transformRequestToFormData = data => ({
    lang: data.lang,
    reason: _.get(data.reason, data.lang),
    description: _.get(data.description, data.lang),
    specialities: data.specialities,
    diagnostic: _.get(data.diagnostic, data.lang),
    specials: _.get(data.specials, data.lang),
    files: (data.files || []).map(({ext, file, id, med, ...label}) => ({
        id,
        ext,
        file,
        label: _.get(label, data.lang),
        med,
    })),
});

export const transformFormDataToRequestData = (data) => {
    const extracted = {
        lang: data.lang,
        specialities: data.specialities,
        files: (data.files || []).map(({label, ...item}) => {
            return {
                ...item,
                [data.lang]: label,
            }
        }),
    };

    _.set(extracted, ['reason', data.lang], data.reason);
    _.set(extracted, ['description', data.lang], data.description);
    _.set(extracted, ['diagnostic', data.lang], data.diagnostic);
    _.set(extracted, ['specials', data.lang], data.specials);

    return extracted;
};
