import React, {useContext, useCallback} from 'react';
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";

import {ViewModalContext} from "../Contexts/ViewModalContext";
import {showViewDoctorDetailsModal} from "../../redux/modal/actions";

interface Props {
    className?: string;
    id: number;
    from?: string;
    state?: Object;
    search?: string;
    params?: unknown;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    children?: any;
    title?: string;
}

const ViewDoctorLink = React.memo<Props>(
    ({id, from, children, onClick, ...props}) => {
        const dispatch = useDispatch();
        const {
            preventViewLink = false,
            modalView = false,
            noSelect = true,
        } = useContext(ViewModalContext) || {};

        const handleClickLink = useCallback((event) => {
            if (onClick) {
                onClick(event);
            } else {
                if (modalView) {
                    event.stopPropagation();
                    event.nativeEvent.stopImmediatePropagation();
                    event.preventDefault();
                    // @ts-ignore
                    dispatch(showViewDoctorDetailsModal({
                        doctorId: id,
                        noSelect,
                    }))
                }
            }
        }, [dispatch, id, modalView, noSelect, onClick]);

        if (preventViewLink) {
            return children;
        }

        return (
            <Link
                to=""
                {...props}
                href="#"
                onClick={handleClickLink}>
                {children}
            </Link>
        )
    }
);

ViewDoctorLink.displayName = 'HealthViewDoctorLink';

export default ViewDoctorLink;
