import {useCallback} from 'react';
import {get, isString} from 'lodash';

export default function useOpenFile(){
    return useCallback((file) => {
        if (isString(file.file)) {
            const w = window.open(file.file, '_blank');
            w.document.title = file.file;
        } else {
            const fileName = get(file, 'file.name');
            let previewWindow = window.open('', '_blank');

            if (/\.pdf$/.test(fileName)) {
                previewWindow.document.write(`<html><head><style>body{margin: 0;}iframe{border-width: 0;}</style></head>`);
                previewWindow.document.write(`<body><iframe width='100%' height='100%' src='${file.file.result}#toolbar=0&navpanes=0&scrollbar=0'></iframe></body></html>`);
                previewWindow.document.title = file.file.name;
            }
            if (/\.(png|jpeg|jpg|tiff|bmp)$/.test(fileName)) {
                previewWindow.document.write(`<html><head><style>body{margin: 0;}</style></head>`);
                previewWindow.document.write(`<body><img src="${file.file.result}"/></body></html>`);
            }
        }
    }, []);
}
