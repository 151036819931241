import React from "react";
import PropTypes from "prop-types";

const IfCondition = ({children, condition, placeholder}) => {
    let content = children;

    if (typeof condition === 'function' && !condition()) {
        content = placeholder || null;
    } else if (!condition) {
        content = placeholder || null;
    }

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    )
}

IfCondition.propTypes = {
    condition: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.string, PropTypes.any]),
    placeholder: PropTypes.node,
}
IfCondition.displayName = 'IfCondition';

export default IfCondition;
