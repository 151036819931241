import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Loader from '../../Loader/Loader';
import {getRootUrl} from '../../../helpers/UrlHelper';
import {Logo} from '../../Logo/Logo';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

import './RegistrationPageLayout.scss';
import IfCondition from '../../common/IfCondition/IfCondition';
import {ShowPrivacyPolicyLink} from 'components/Policy/ShowPrivacyPolicyLink';

export const RegistrationPageLayout = React.memo(
	({
		isLoading,
		isTranslationLoaded,
		onLogoClick,
		children,
		title,
		copyright,
		linksConf,
		className,
		noRerender,
	}) => {
		const handleLogoClick = useCallback(() => {
			if (onLogoClick) {
				onLogoClick();
			} else {
				window.location.replace(getRootUrl());
			}
		}, [onLogoClick]);

		return (
			<div
				className={cn('Page Flex-column Registration-page', className)}
			>
				<Loader loading={isLoading} />
				{isTranslationLoaded && (
					<React.Fragment>
						<header className="Align-center Flex-row Page-header">
							<Logo
								onClick={handleLogoClick}
								classname="Align-center Flex-row"
							/>
							<div className="Page-header-title">
								<FormattedMessage id={title} />
							</div>
						</header>
						<div className="Page-body Flex-column Justify-center Align-center">
							<div className="Page-wrapper">
								{noRerender ? (
									<div className={cn({hidden: isLoading})}>
										<React.Fragment>
											{children}
										</React.Fragment>
									</div>
								) : (
									<IfCondition condition={!isLoading}>
										<React.Fragment>
											{children}
										</React.Fragment>
									</IfCondition>
								)}
							</div>
						</div>
						<footer className="Page-footer Flex-row Align-center Justify-space-between">
							<FormattedHTMLMessage id={copyright} />

							<ShowPrivacyPolicyLink messageId={linksConf} />
						</footer>
					</React.Fragment>
				)}
			</div>
		);
	},
);

RegistrationPageLayout.propTypes = {
	isLoading: PropTypes.bool,
	isTranslationLoaded: PropTypes.bool,
	onLogoClick: PropTypes.func,
	title: PropTypes.string,
	copyright: PropTypes.string,
	linksConf: PropTypes.string,
	noRerender: PropTypes.bool,
};

RegistrationPageLayout.defaultProps = {
	isLoading: false,
	isTranslationLoaded: false,
	noRerender: false,
};

RegistrationPageLayout.displayName = 'HealthRegistrationPageLayout';
