import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage, injectIntl} from "react-intl";
import {Form} from "react-final-form";

import Popover from "../../../components/common/Popover/Popover";
import Overlay from "../../../components/common/Overlay/Overlay";
import Button from "../../../components/common/Button/Button";
import Separator from "../../../components/common/Separator/Separator";
import TextBoxField from "../../../components/common/TextBox/TextBoxField";
import TagListField from "../../../components/common/TagList/TagListField";
import TagList from "../../../components/common/TagList/TagList";
import DateRangeField from "../../../components/common/DatePicker/DateRangeField";

class Filter extends React.Component {
    constructor(props) {
        super(props);

        this.btnRef = React.createRef();
    }

    state = {
        isOpened: false
    };

    static propTypes = {
        containerRef: PropTypes.object.isRequired,
        onApplyFilter: PropTypes.func,
        onResetFilter: PropTypes.func,
        btnText: PropTypes.string,
        data: PropTypes.object,
        fileTypes: PropTypes.arrayOf(PropTypes.any),
        intl: PropTypes.object.isRequired,
        lang: PropTypes.string.isRequired,
        disableFileTagInput: PropTypes.bool,
    };

    static defaultProps = {
        data: undefined
    };

    open = () => {
        this.setState({
            isOpened: true
        });
    };

    handleApplyFilter = (values) => {
        const {onApplyFilter} = this.props;

        this.setState(() => ({
            isOpened: false
        }), () => {
            onApplyFilter && onApplyFilter(values);
        });
    };

    handleResetFilter = () => {
        const {onResetFilter} = this.props;

        this.setState(() => ({
            isOpened: false
        }), () => {
            onResetFilter && onResetFilter();
        });
    };

    handleClose = () => {
        this.setState(() => ({
            isOpened: false
        }));
    };

    isFiltered = () => {
        const {data} = this.props;
        return !!(data.query ||
            (data.fileTypes && data.fileTypes.length) ||
            data.fromDate ||
            data.toDate);
    };

    render() {
        const {
            containerRef,
            data: formData,
            fileTypes,
            intl,
            lang,
            //
            disableFileTagInput
        } = this.props;

        return (
            <React.Fragment>
                <Button
                    onClick={this.open}
                    ref={this.btnRef}
                    viewType="frameless"
                    variant={this.isFiltered() ? 'ruby' : 'gray'}>
                    <FontAwesomeIcon size="lg" icon={faFilter}/>
                    <FormattedMessage id="common.buttons.filter"/>
                </Button>
                <Overlay
                    fitContainer
                    onClose={this.handleClose}
                    containerRef={containerRef}
                    open={this.state.isOpened}
                    placement="bottom-center"
                    offset={{
                        top: -21,
                        left: 0,
                    }}
                    targetRef={this.btnRef}>
                    <Popover className="Health-panel--filter">
                        <div className="Popover__title"><FormattedMessage id="common.filter.caption"/></div>
                        <Form
                            onSubmit={this.handleApplyFilter}
                            initialValues={formData}>
                            {
                                (form) => (
                                    <form onSubmit={form.handleSubmit} noValidate autoComplete="off">
                                        <div>
                                            <TextBoxField
                                                className="m-0"
                                                labelClass="col-4"
                                                inputClass="col-8"
                                                inline
                                                label={intl.formatMessage({id: 'common.filter.search'})}
                                                name="query"/>
                                            <Separator/>
                                            <TagListField
                                                showArrow
                                                className="mt-2"
                                                disabled={disableFileTagInput}
                                                placeholder={intl.formatMessage({id: 'common.letter_help'})}
                                                inputContainerClass="row m-0"
                                                labelClass="col-4 text-uppercase"
                                                inputClass="col-8"
                                                minQueryLength={0}
                                                label={intl.formatMessage({id: 'common.filter.type'})}
                                                shouldExcludeTags
                                                suggestions={fileTypes}
                                                handleFilterSuggestions={TagList.FilterSuggestions.withStartWordFilter}
                                                renderSuggestion={TagList.RenderSuggestions.simpleRender}
                                                name='fileTypes'/>
                                            <Separator/>
                                            <DateRangeField
                                                locale={lang}
                                                pickerClass="col-4"
                                                fieldClass="mt-2"
                                                containerClass="row m-0 Health-date-picker__overlay--align-right"
                                                labelClass="col-4"
                                                label={intl.formatMessage({id: 'common.filter.date'})}
                                                fromName="fromDate"
                                                toName="toDate"/>
                                            <Separator/>
                                        </div>
                                        <div className="Popover__actions Flex-column Justify-center">
                                            <Button type="submit"><FormattedMessage id="common.filter.ok"/></Button>
                                            <Button
                                                onClick={this.handleResetFilter}
                                                className="mt-1"
                                                variant="gray"
                                                viewType="minimal"><FormattedMessage id="common.filter.clear"/></Button>
                                        </div>
                                    </form>
                                )
                            }
                        </Form>
                    </Popover>
                </Overlay>
            </React.Fragment>
        )
    }
}

export default injectIntl(Filter);
