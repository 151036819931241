import * as api from '../../../api';
import FileTypeList from "../../../constants/FileTypeList";

export const REQUEST_PAGE_COMMON_SET_FILE_TYPES = 'REQUEST_PAGE_COMMON_SET_FILE_TYPES';

export const loadCommonFileTypes = () => async (dispatch, getState) => {
    const type = FileTypeList.Medical;

    try {
        const res = await api.loadFileTypeList(type);
        dispatch({
            type: REQUEST_PAGE_COMMON_SET_FILE_TYPES,
            payload: res.data
        });
    } catch (err) {
        console.error(err);
    }
};
