import React from 'react';
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './SaveChangesModal.scss';
import Button from "../../common/Button/Button";
import CloseButton from "../components/CloseButton/CloseButton";

const SaveChangesModal = ({
                              title,
                              message,
                              btnSave,
                              btnCancel,
                              onSuccess,
                              onCancel,
                              onClose
                          }) => {
    return (
        <React.Fragment>
            <CloseButton onClick={onClose}/>
            <div className="Modal__header">
                <FontAwesomeIcon className="Modal-save-changes__icon"
                                 icon={faQuestionCircle}
                                 color="#6592ab"/>
                <div className="Modal__title">{title}</div>
            </div>
            <div className="Modal__body">
                <p className="Modal__message">
                    {message}
                </p>
            </div>
            <div className="Modal__footer">
                <Button variant="gray" viewType="minimal" onClick={onCancel}>{btnCancel}</Button>
                <Button onClick={onSuccess}>{btnSave}</Button>
            </div>
        </React.Fragment>
    );
};

export default SaveChangesModal;
