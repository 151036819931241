import React, {useCallback, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useField} from 'react-final-form';

import './LanguageSwitcher.scss';
import {ProfileContext} from '../context/ProfileContext';

const LanguageSwitcher = React.memo((
    {className, langName, primary, primaryLangName, onChange},
) => {
    const {secondaryLang, setSecondaryLang} = useContext(ProfileContext);
    const langField = useField(langName, {subscription: {value: true}});
    const primaryLangNameField = useField(primaryLangName, {subscription: {value: true}});
    const [langs, setLangs] = useState([]);

    useEffect(() => {
        const primaryLang = primaryLangNameField.input.value;

        if (primary) {
            setLangs([primaryLang]);
        } else {
            const languages = langField.input.value || [];
            setLangs(languages.filter(l => l !== primaryLang));
        }
    }, [primaryLangNameField.input.value, primary, langField.input.value]);

    const handleItemClick = useCallback((lang) => {
        if (!primary && langs.length > 2 && lang !== secondaryLang) {
            setSecondaryLang(lang);
        }
    }, [primary, langs.length, secondaryLang, setSecondaryLang]);

    return (
        <ul className={cn(className, 'Language-switcher')}>
            {
                langs.map((lang, index) => (
                    <li
                        key={index}
                        className={cn(
                            'Language-switcher__item',
                            (lang === secondaryLang || primary) && 'Language-switcher__item--selected',
                        )}
                        onClick={() => handleItemClick(lang)}>
                        {lang}
                    </li>
                ))
            }
        </ul>
    );
});

LanguageSwitcher.propTypes = {
    langName: PropTypes.string.isRequired, // array of user langs {main, code}
    primaryLangName: PropTypes.string.isRequired, // array of user langs {main, code}
    primary: PropTypes.bool, // if primary we cannot change value
};

LanguageSwitcher.defaultProps = {
    langName: 'lang',
    primaryLangName: 'primaryLang',
    primary: false,
};

export default LanguageSwitcher;
