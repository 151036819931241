import {
    SET_VIEW_CLINIC_LOADING,
    CLEAR_VIEW_CLINIC_DATA,
    SET_VIEW_CLINIC_ERROR,
    SET_VIEW_CLINIC_DATA,
    SET_VIEW_CLINIC_DOCTORS,
} from './actions';
import Immutable from 'seamless-immutable';

const INITIAL_STATE = {
    loading: false,
    clinic: null,
    error: null,
    doctors: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_VIEW_CLINIC_LOADING:
            return Immutable.merge(state, {loading: action.payload});
        case CLEAR_VIEW_CLINIC_DATA:
            return Immutable.merge(state, INITIAL_STATE);
        case SET_VIEW_CLINIC_ERROR:
            return Immutable.merge(state, {error: action.payload});
        case SET_VIEW_CLINIC_DATA:
            return Immutable.merge(state, {clinic: action.payload});
        case SET_VIEW_CLINIC_DOCTORS:
            return Immutable.merge(state, {doctors: action.payload});
        default:
            return state;
    }
}
