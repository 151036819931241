import React, {useCallback, useEffect, useRef, useState} from 'react';
import { FieldInputProps } from 'react-final-form';

import TextBox from '../../../common/TextBox/TextBox';

interface Props extends FieldInputProps<string, HTMLInputElement> {
    onChange: React.ChangeEventHandler;
    placeholder?: string;
}

const MessageInput = React.forwardRef<HTMLInputElement, Props>(({
    onChange, 
    ...props
}, ref: React.MutableRefObject<HTMLInputElement>) => {
    const [minHeight, setMinHeight] = useState<number>();
    const [maxHeight, setMaxHeight] = useState<number>();
    const requestId = useRef(null);

    useEffect(() => {
        if (ref.current && !minHeight) {
            const computedStyle = window.getComputedStyle(ref.current);
            const lineHeight = parseInt(computedStyle.lineHeight);
            const height = parseInt(computedStyle.height);

            setMinHeight(height);
            setMaxHeight(height + lineHeight * 3);
        }
    }, [minHeight, ref]);

    const handleChange = useCallback((event) => {
        if (onChange) {
            onChange(event);
        }
        if (event.target.style.height !== minHeight + 'px') {
            event.target.style.height = minHeight + 'px';
        }

        let height;
        if (event.target.scrollHeight <= maxHeight) {
            if (event.target.scrollHeight > minHeight) {
                height = event.target.scrollHeight + 2 + 'px';
            } else {
                height = minHeight + 'px';
            }
        } else {
            height = maxHeight + 'px';
        }

        if (event.target.style.height !== height) {
            event.target.style.height = height;
        }
    }, [onChange, minHeight, maxHeight]);

    const checkEmptyOrChange = useCallback(() => {
        if (ref.current && !ref.current.value && ref.current.style.height) {
            ref.current.style.height = '';
        }
        if (ref.current && ref.current.scrollHeight && ref.current.value && !ref.current.style.height) {
            handleChange({target: ref.current});
        }
        requestId.current = requestAnimationFrame(checkEmptyOrChange);
    }, [handleChange, ref]);

    useEffect(() => {
        requestId.current = requestAnimationFrame(checkEmptyOrChange);
        return () => cancelAnimationFrame(requestId.current);
    }, [checkEmptyOrChange, ref]);

    return (
        <TextBox
            rows={1}
            multi
            onChange={handleChange}
            ref={ref}
            {...props} />
    )
})

export default MessageInput;
