import _range from 'lodash/range';

export const getRangeOfYears = (): number[] => {
	const currentYear = new Date().getFullYear();
	const fromMonth = new Date(currentYear - 100, 0);
	const toMonth = new Date(currentYear + 10, 11);

	const min = fromMonth.getFullYear();
	const max = toMonth.getFullYear();

	return _range(min, max);
};