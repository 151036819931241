import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {connect} from 'react-redux';
import cn from 'classnames';

import './Modal.scss';
import {hideModal} from '../../redux/modal/actions';
import {modalSelector} from '../../redux/modal/selectors';
import modals from './modals';

Modal.setAppElement('body');

class ModalRoot extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        shouldCloseOnOverlayClick: PropTypes.bool,
        shouldReturnFocusAfterClose: PropTypes.bool,
        showCloseButton: PropTypes.bool,
        params: PropTypes.any,
        hideModal: PropTypes.func,
        className: PropTypes.string,
        isWindow: PropTypes.bool,
    };

    static defaultProps = {
        shouldCloseOnOverlayClick: true,
        shouldReturnFocusAfterClose: false,
        showCloseButton: true,
        isWindow: false,
    };

    handleCloseModal = () => {
        const {onClose = this.props.hideModal} = this.props.params || {};
        const {isWindow} = this.props;

        if (isWindow) {
            document.body.style.removeProperty('overflow');
        }
        onClose();
    };

    disableScroll = () => {
        const {isWindow} = this.props;

        if (isWindow) {
            document.body.style.setProperty('overflow', 'hidden');
        }
    };

    render() {
        const {
            type,
            shouldCloseOnOverlayClick,
            shouldReturnFocusAfterClose,
            params,
            className,
            isWindow,
        } = this.props;
        const SpecificModal = type ? modals[type] : null;

        return (
            <div className="Modal-root">
                <Modal
                    onAfterOpen={this.disableScroll}
                    isOpen={!!SpecificModal}
                    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                    shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
                    onRequestClose={this.handleCloseModal}
                    className={cn('Modal', className)}
                    overlayClassName={cn('Modal-overlay', isWindow && 'Modal-overlay-window')}>
                    {SpecificModal && <SpecificModal {...params} hideModal={this.handleCloseModal} />}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => modalSelector(state);

const mapDispatchToProps = {
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
