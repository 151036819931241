import axios from 'axios';
import store from './store';
import {logoutUser} from '../redux/user/actions';
import ErrorCodes from '../constants/ErrorCodes';

const {getState, dispatch} = store;

axios.defaults.baseURL = 'https://lk.best2health.ru/api';
axios.defaults.headers = {
    'Authorization': 'Basic ' + btoa('user:123456'),
};

/* interceptors for all requests */
// interceptor for setting headers in request
axios.interceptors.request.use(config => {
    const {session} = getState();
    const {noSession} = config;

    if (!noSession) {
        config.params = {
            ...config.params,
            PHPSESSID: session,
        };
    }

    return config;
});

axios.interceptors.response.use(res => {
    // unauthenticated
    if (res.data &&
        res.data.status === false &&
        (res.data.error === ErrorCodes.LoginEmptyError ||
            res.data.error === ErrorCodes.LoginSessionError)) {
        dispatch(logoutUser());
        return Promise.reject(res);
    }

    // other errors
    if (res.data &&
        res.data.status === false &&
        res.data.error) {
        return Promise.reject(res);
    }

    return res;
});

export default axios;
