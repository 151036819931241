import React from 'react';
import cn from 'classnames';

import './MessageMeta.scss';
import {FormattedDate, FormattedTime} from 'react-intl';
import { MessageStatus } from 'components/ConsultationChat/Chat.interface';
import { MessageStatusIcon } from './MessageStatusIcon';

interface Props {
    className?: string;
    userName?: string,
    datetime?: Date;
    onClick?: React.MouseEventHandler;
    messageStatus?: MessageStatus;
    lang?: string,
}

const MessageMeta = ({userName, datetime, onClick, className, messageStatus, lang}: Props) => {
    return (
        <div className={cn('Health-chat__message_meta', className)}>
            {
                userName &&
                <React.Fragment>
                    <span onClick={onClick}>{userName}</span>
                </React.Fragment>
            }
            {
                userName && datetime &&
                <span>,&nbsp;</span>
            }
            {
                datetime &&
                <React.Fragment>
                    <FormattedDate value={datetime} /> &nbsp;
                    <FormattedTime
                        hour12={false}
                        hour="2-digit"
                        minute="numeric"
                        value={datetime} />
                </React.Fragment>
            }
            {
                lang &&
                <span>({lang})</span>
            }
            {messageStatus && <MessageStatusIcon status={messageStatus} />}
                
        </div>
    )
}

MessageMeta.displayName = 'HealthMessageMeta';

export default MessageMeta;
