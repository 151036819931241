import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './ViewFiles.scss';
import List from "../List/List";

const ViewFiles = React.memo(
    ({className, files, columns, maxCount, showMoreText, hideText, renderItem, noTruncate}) => {
        const [chunks, setChunks] = useState([]);
        const [listMaxCount, setListMaxCount] = useState(maxCount);

        useEffect(() => {
            const chunks = [[]];
            const totalFiles = files.reduce((total, {items}) => total + items.length, 0);
            const perFilesChunk = totalFiles / columns;
            let maxFilesLength = 0;

            let chunkIndex = 0;
            let chunkCount = 0;
            files.forEach((fileSection, index) => {
                if (files.length > columns) {
                    if (chunkCount + fileSection.items.length > perFilesChunk) {
                        chunks[chunkIndex].push(fileSection);
                        // create new chunk
                        chunks.push([]);
                        chunkIndex++;
                        chunkCount = 0;
                    } else {
                        chunks[chunkIndex].push(fileSection);
                        chunkCount += fileSection.items.length;
                    }
                } else {
                    chunks[chunkIndex].push(fileSection);
                    if (index < files.length - 1) {
                        chunks.push([]);
                        chunkIndex++;
                    }
                }

                if (fileSection.items.length > maxFilesLength) {
                    maxFilesLength = fileSection.items.length;
                }
            });

            setChunks(chunks);

            if (noTruncate) {
                setListMaxCount(maxFilesLength);
            }
        }, [columns, files, noTruncate]);

        return (
            <div className={cn(className, 'Health-view-files', columns > 1 && `row row-cols-${columns}`)}>
                {
                    chunks.map((chunk, i) => {
                        return (
                            <div key={i}
                                 className={cn("Health-view-files__column", columns > 1 && `col-${12 / columns}`)}>
                                {
                                    chunk.map((fileSection, key) => (
                                        <div key={key} className="Health-view-files__item mb-3">
                                            <h3>{fileSection.type}</h3>
                                            <List
                                                renderItem={renderItem}
                                                hideText={hideText}
                                                showMoreText={showMoreText}
                                                isTruncate
                                                columns={1}
                                                maxCount={listMaxCount}
                                                items={fileSection.items}/>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
);

ViewFiles.propTypes = {
    files: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            file: PropTypes.string,
        }))
    })),
    columns: PropTypes.number,
    maxCount: PropTypes.number,
    showMoreText: PropTypes.string,
    hideText: PropTypes.string,
    renderItem: PropTypes.func,
    noTruncate: PropTypes.bool,
};
ViewFiles.defaultProps = {
    files: [],
    columns: 1,
    noTruncate: false,
};
ViewFiles.displayName = 'HealthViewFiles';

export default ViewFiles;
