export const focusWatcher = (onFocusWatcher) => (formApi) => {
    let activeField;

    let unsubscribe = formApi.subscribe(
        newState => {
            activeField = newState.active;
        },
        {
            active: true,
        },
    );

    formApi.onFocus = (event) => {
        onFocusWatcher(event, activeField);
    };

    return () => {
        unsubscribe();
    }
};
