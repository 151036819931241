import ModalTypes from '../../constants/ModalTypes';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export function hideModal() {
    return {
        type: HIDE_MODAL,
    };
}

export function showConfirmModal({
                                     title,
                                     message,
                                     onSuccess,
                                     onCancel,
                                     btnOk,
                                     btnCancel,
                                     payload,
                                 }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.confirmModal,
            params: {
                title,
                message,
                onSuccess,
                onCancel,
                btnOk,
                btnCancel,
                payload,
            },
            className: 'Modal-confirm',
            shouldCloseOnOverlayClick: false,
        },
    };
}

export function showSaveChangesModal({
                                         title,
                                         message,
                                         onSuccess,
                                         onCancel,
                                         onClose,
                                         btnSave,
                                         btnCancel,
                                         payload,
                                     }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.saveChangesModal,
            params: {
                title,
                message,
                onSuccess,
                onCancel,
                onClose,
                btnSave,
                btnCancel,
                payload,
            },
            className: 'Modal-save-changes',
        },
    };
}

export function showViewClinicDetailsModal({
                                               clinicId,
                                               onSelect,
                                               onClose,
                                               payload,
                                               noSelect,
                                           }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.viewClinicDetailsModal,
            params: {
                clinicId,
                onClose,
                onSelect,
                payload,
                noSelect,
            },
            className: 'Modal-view-clinic-details',
            shouldCloseOnOverlayClick: false,
            isWindow: true,
        },
    }
}

export function showViewDoctorDetailsModal({
                                               doctorId,
                                               onSelect,
                                               onClose,
                                               payload,
                                               noSelect,
                                           }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.viewDoctorDetailsModal,
            params: {
                doctorId,
                onClose,
                onSelect,
                payload,
                noSelect,
            },
            className: 'Modal-view-doctor-details',
            shouldCloseOnOverlayClick: false,
            isWindow: true,
        },
    }
}

export function showAttachFilesModal({
                                         title,
                                         attachments,
                                         onSubmit,
                                         payload,
                                     }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.attachFilesModal,
            params: {
                title,
                attachments,
                onSubmit,
                payload,
            },
            className: 'Modal-attach-files',
            shouldCloseOnOverlayClick: false,
        },
    }
}

export function showAddOrEditMedicalRecordModal({
                                                    onAddOrEdit,
                                                    onClose,
                                                    file,
                                                    disableFileTypeField = false,
                                                    hideConclusion77FileType = false,
                                                    isAdd = false,
                                                    payload,
                                                }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.addOrEditMedicalRecordModal,
            params: {
                hideConclusion77FileType,
                disableFileTypeField,
                onAddOrEdit,
                onClose,
                file,
                isAdd,
                payload,
            },
            className: 'Modal-add-or-edit-medical-record',
            shouldCloseOnOverlayClick: false,
        },
    }
}

export function showAddFilesFromMedicalRecordsModal({
                                                        onAddRecords,
                                                        onClose,
                                                        payload,
                                                        queueProcessing = false,
                                                    }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.addFilesFromMedicalRecordsModal,
            params: {
                onAddRecords,
                onClose,
                payload,
            },
            className: 'Modal-add-files-from-medical-records',
            shouldCloseOnOverlayClick: false,
            queueProcessing,
        },
    }
}

export function showPreviewConclusionModal(
    {
        requestId,
        lang,
        payload, // current conclusion data on the lang
    }
) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.previewConclusionModal,
            params: {
                requestId,
                lang,
                payload,
            },
            className: 'Modal-preview-conclusion',
            shouldCloseOnOverlayClick: false,
            isWindow: true,
        },
    }
}

export function showEditTranslateModal(
    {
        title,
        record,
        isNew,
        payload,
        onSuccess,
        onError,
        defaultFields,
        shouldDisablePageNameField,
    }
) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.editTranslateModal,
            params: {
                title,
                record,
                payload,
                isNew,
                onSuccess,
                onError,
                defaultFields,
	            shouldDisablePageNameField,
            },
            className: 'Modal-edit-translate',
            shouldCloseOnOverlayClick: false,
        },
    }
}


export function showPrivacyPolicyModal() {
	return {
		type: SHOW_MODAL,
		payload: {
			type: ModalTypes.privacyPolicyModal,
			params: {},
		},
	};
}

export function showUserAgreementModal() {
	return {
		type: SHOW_MODAL,
		payload: {
			type: ModalTypes.userAgreementModal,
			params: {},
		},
	};
}
