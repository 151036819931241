import * as api from '../../api';
import {fetchTranslation} from "../translations/actions";
import pageNames from "../../constants/PageNames";
import {clearDataParams, getFileExtension} from "../../helpers/Common";
import {toServerFormat} from "../../helpers/Date";
import * as commonActions from "../common/actions";
import {readFileAsync} from "../../helpers/File";
import * as dateHelpers from "../../helpers/Date";
import { uploadRequestFiles } from '../../api';
import { MedicalFileTypeEnum } from '../../constants/FileTypeList';

export const SET_MEDICAL_RECORD_TABLE_PAGE_LOADING = 'SET_MEDICAL_RECORD_TABLE_PAGE_LOADING';
export const SET_MEDICAL_RECORD_TABLE_PAGE_RECORDS = 'SET_MEDICAL_RECORD_TABLE_PAGE_RECORDS';
export const SET_MEDICAL_RECORD_TABLE_PAGE_ERROR = 'SET_MEDICAL_RECORD_TABLE_PAGE_ERROR';

export const initMedicalRecordTablePage = () => async (dispatch, getState) => {
    dispatch(setMedicalRecordTableTablePageLoading(true));
    await Promise.all([
        dispatch(fetchTranslation({
            pageName: pageNames.MedicalRecords
        })),
        dispatch(commonActions.fetchMedicalFileTypes()),
    ]);
    dispatch(setMedicalRecordTableTablePageLoading(false));
};

export const setMedicalRecordTableTablePageLoading = (loading) => {
    return {
        type: SET_MEDICAL_RECORD_TABLE_PAGE_LOADING,
        payload: loading
    }
};

export const getMedicalRecords = (
    {
        query, currentPage, type,
        sortBy, orderBy, fileTypes = [], fromDate, toDate,
        pageSize = 10
    }
) => async (dispath, getState) => {
    const params = clearDataParams({
        full: true,
        s: query,
        page: currentPage - 1,
        type, // [TODO]: may be need to convert to number
        sortBy,
        orderBy,
        size: pageSize,
        fts: fileTypes.map(t => t.id).join(','),
        fromDate: fromDate && dateHelpers.toServerFormat(fromDate),
        toDate: toDate && dateHelpers.toServerFormat(toDate),
    });

    try {
        const res = await api.loadMedicalRecords(params);

        dispath({
            type: SET_MEDICAL_RECORD_TABLE_PAGE_RECORDS,
            payload: res.data
        })
    } catch (err) {
        dispath({
            type: SET_MEDICAL_RECORD_TABLE_PAGE_ERROR,
            payload: err
        })
    }
};

export const saveMedicalRecord = ({name, type, id, date}) => async () => {
    await api.saveMedicalRecord({
        name,
        type,
        id,
        date: toServerFormat(date),
    });
}

export const removeMedicalRecord = (id) => async () => {
    await api.removeMedicalRecord(id);
}

export const uploadMedicalRecord = ({name, type, lang, file, date, requestId}) => async (dispatch) => {
    let value = file.value;

    if(!value) {
        const {value: content} = await readFileAsync(file);
        value = content;
    }

    const extension = getFileExtension(file.name);

    const record = {
      requestId,
      name,
      type,
      date: toServerFormat(date),
      value,
      ext: extension,
    };

    const {data} = await api.saveMedicalRecord(record);

    const updateRequestData = {
        id: requestId,
        lang,
        files: [{
            id: data.id,
            label: {
                [lang]: name,
            },
        }]
    };

    await uploadRequestFiles(updateRequestData);
}
