import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import './AvatarText.scss';

const AvatarText = ({className, shortName, size, title}) => {
    return (
        <div title={title} className={cn('Health-avatar-text', size && `Health-avatar-text--${size}`, className)}>
            {shortName}
        </div>
    )
}

AvatarText.displayName = 'HealthAvatarText';
AvatarText.propTypes = {
    size: PropTypes.oneOf(['sm', 'lg', null]),
    shortName: PropTypes.string,
    title: PropTypes.string,
};

AvatarText.defaultProps = {
    size: 'sm',
};

export default AvatarText;
