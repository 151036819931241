import * as api from '../../api';
import {clearDataParams} from "../../helpers/Common";

export const SET_MEDICAL_RECORDS_MODAL_INIT = 'SET_MEDICAL_RECORDS_MODAL_INIT';
export const SET_MEDICAL_RECORDS_MODAL_LOADING = 'SET_MEDICAL_RECORDS_MODAL_LOADING';
export const SET_MEDICAL_RECORD_MODAL_RECORDS = 'SET_MEDICAL_RECORD_MODAL_RECORDS';
export const SET_MEDICAL_RECORD_MODAL_SELECTED = 'SET_MEDICAL_RECORD_MODAL_SELECTED';
export const SET_MEDICAL_RECORD_MODAL_UNSELECT = 'SET_MEDICAL_RECORD_MODAL_UNSELECT';
export const SET_MEDICAL_RECORD_MODAL_ERROR = 'SET_MEDICAL_RECORD_MODAL_ERROR';

export const initMedicalRecordsModal = () => ({
    type: SET_MEDICAL_RECORDS_MODAL_INIT,
});

export const setMedicalRecordsModalLoading = (loading) => {
    return {
        type: SET_MEDICAL_RECORDS_MODAL_LOADING,
        payload: loading
    }
};

export const loadMedicalRecords = (
    {
        currentPage, query,
    }
) => async (dispath) => {
    const params = clearDataParams({
        full: true,
        s: query,
        page: currentPage,
        sortBy: 'date',
        orderBy: 'desc',
        size: 10,
    });

    try {
        dispath(setMedicalRecordsModalLoading(true));
        const {data} = await api.loadMedicalRecords(params);

        dispath({
            type: SET_MEDICAL_RECORD_MODAL_RECORDS,
            payload: {
                records: data.list,
                count: data.all_count,
                pages: data.all_pages,
            }
        })
    } catch (err) {
        dispath({
            type: SET_MEDICAL_RECORD_MODAL_ERROR,
            payload: err
        })
    } finally {
        dispath(setMedicalRecordsModalLoading(false));
    }
};

export const selectMedicalRecord = (record) => ({
    type: SET_MEDICAL_RECORD_MODAL_SELECTED,
    payload: record
});

export const unselectMedicalRecord = (record) => ({
    type: SET_MEDICAL_RECORD_MODAL_UNSELECT,
    payload: record
});
