import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import areIntlLocalesSupported from 'intl-locales-supported';
import {LastLocationProvider} from 'react-router-last-location';
import { pdfjs } from 'react-pdf';

// Make sure moment.js has the required locale data
import 'moment';
import 'moment/locale/ru';

import './index.scss';
import context from './context';

pdfjs.GlobalWorkerOptions.workerSrc = `/lib/pdf.worker.min.js`;

const render = () => {
    // Re-require application root the only way to update view
    // after HMR
    const App = require('./App').default;

    ReactDOM.render(
        <Provider store={context.store}>
            <BrowserRouter>
                <LastLocationProvider>
                    <App/>
                </LastLocationProvider>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root'));
};

const enableHMR = () => {
    // TODO: types/node
    // @ts-ignore
    if (module.hot) {
        // @ts-ignore
        module.hot.accept(undefined, () => {
            render();
        });
    }
};

// If browser doesn't support Intl (i.e. Safari), APIs are missing, or if the built-in Intl is missing locale data (i.e. IE10),
if (!areIntlLocalesSupported(['en', 'ru'])
) {
    Promise.all([
        import(/* webpackChunkName: "intl-polyfill" */ 'intl'),
        import(/* webpackChunkName: "intl-polyfill" */ 'intl/locale-data/jsonp/en'),
        import(/* webpackChunkName: "intl-polyfill" */ 'intl/locale-data/jsonp/ru'),
    ]).then(([{default: IntlPolyfill}]) => {
        if (!window.Intl) {
            window.Intl = IntlPolyfill;
        }

        window.Intl.NumberFormat = IntlPolyfill.NumberFormat;
        window.Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;

        render();
        enableHMR();
    });
} else {
    render();
    enableHMR();
}
