import {setLoading} from '../loading/actions';
import pageNames from '../../constants/PageNames.js';
import {fetchTranslation} from "../translations/actions";
import * as api from "../../api";


export enum RememberPasswordError {
	ConfirmationError = 'confirmation', // нужно подтвердить почту
}

export interface RememberPasswordErrorResponse {
	error: RememberPasswordError;
	status: false;
}

export const initTranslatedRememberPassPage = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    await dispatch(fetchTranslation({
        lang: getState().lang,
        pageName: pageNames.RememberPassword
    }));
    dispatch(setLoading(false));
};

export const rememberPassword = (email, captcha) => async () => {
    return await api.rememberPassword(email, captcha);
};
