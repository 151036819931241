import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {routerMiddleware, connectRouter} from 'connected-react-router';
import {reducer as toastrReducer} from 'react-redux-toastr'

export function createReducer(history) {
    const reducers = require('./reducer').default;

    return combineReducers({
        ...reducers,
        router: connectRouter(history),
        toastr: toastrReducer
    });
}

export default function configureStore(history, initialState = {}) {
    const middleware = [
        routerMiddleware(history),
        thunk,
    ];
    const reducer = createReducer(history);
    // compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f));
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware)),
    );

    // Replace store in HMR
    if (module.hot) {
        module.hot.accept('./reducer', () => {
            store.replaceReducer(createReducer(history));
        });
    }

    return store;
}
