import React from "react";
import {Field} from "react-final-form";
import cn from 'classnames';

import Checkbox from "./Checkbox";
import FieldError from '../FieldError/FieldError';
import {hasError} from "../../../helpers/Common";

interface Props {
    name: string
    type?: 'checkbox';
    className?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    children?: React.ReactNode;
}

const CheckboxField = React.forwardRef<HTMLInputElement, Props>(
    (
        {children, className, onChange, ...props}, ref
    ) => {
        return (
            <Field
                {...props}
                type="checkbox"
                render={({input: {onChange: onChangeInput, ...inputProps}, meta}) => {
                    const onChangeValue = React.useCallback((event) => {
                        onChangeInput(event);
                        if (onChange) {
                            onChange(event);
                        }
                    }, [onChangeInput, onChange]);
        
                    const classes = cn(className, hasError(meta as any) && 'Health-checkbox--error');
                    return (
                        <div className="Health-form__field Flex-column">
                            <Checkbox
                                onChange={onChangeValue}
                                ref={ref}
                                className={classes}
                                {...inputProps}>{children}</Checkbox>
                            <FieldError name={inputProps.name}/>
                        </div>
                    )
                }}/>
        )
    }
);

CheckboxField.displayName = 'HealthCheckboxField';

export default CheckboxField;
