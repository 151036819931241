import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {FormattedDate, FormattedTime} from "react-intl";

const OverviewNotificationMeta = ({pathname, id, name, date}) => {
    const renderName = useCallback(() => {
        if (typeof name === 'function') {
            return name();
        }
        return (
            <span>{name}</span>
        )
    }, [name]);

    return (
        <React.Fragment>
                    <span className="Overview-notification__id">
                        <Link to={pathname}>
                            ID {id}
                        </Link>
                    </span>&nbsp;
            {renderName()}&nbsp;
            <span className="Overview-notification__date">
                    <FormattedDate value={date}/>&nbsp;
                <FormattedTime
                    hour12={false}
                    hour="2-digit"
                    minute="numeric"
                    value={date}/>
                    </span>
        </React.Fragment>
    )
}

OverviewNotificationMeta.displayName = 'OverviewNotificationMeta';
OverviewNotificationMeta.propTypes = {
    pathname: PropTypes.string,
    id: PropTypes.any,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    date: PropTypes.any,
};

export default OverviewNotificationMeta;
