import React from 'react';
import cn from 'classnames';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './TextBox.scss';
import Hint from "../Hint/Hint";

export interface Props {
    className?: string;
    ellipsis?: boolean,
    type?: string,
    name: string,
    disabled?: boolean,
    label?: string;
    labelClass?:  string,
    hint?:  string;
    inputClass?:  string,
    placeholder?: string;
    error?:   string | boolean;
    inline?:  boolean,
    multi?:  boolean,
    rows?:  number,
    cols?:  number,
    maxLength?:  number,
    autoComplete?:  string,
    icon?:  any,
    iconClick?: React.MouseEventHandler,
    readOnly?:  boolean,
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

const TextBox = React.forwardRef<any, Props>(({
        className, 
        ellipsis = false,
        label, 
        labelClass, 
        inputClass,
        inline = false,
        hint,
        name,
        error, 
        multi = false, 
        cols, 
        rows = 5, 
        icon, iconClick, ...props
    }, ref) => {
        const isErrorBool = typeof error === 'boolean';
        const classes = cn(
            'Health-textbox',
            className,
            inline && 'row',
            error && `Health-textbox--error${isErrorBool ? '-frame' : ''}`,
        );
        const inputClasses = cn(
            'Health-textbox__input',
            inputClass,
            ellipsis && 'Health-textbox__input--ellipsis',
            icon && 'Health-textbox__input--with-icon',
        );
        const labelClasses = cn(
            'Health-textbox__label',
            labelClass
        );

        return (
            <div className={classes}>
                {label && <label className={labelClasses} htmlFor={name}>{label}</label>}
                <div className={inputClasses}>
                    {
                        multi ?
                            <textarea ref={ref} name={name} {...props} cols={cols} rows={rows} aria-rowcount={rows} aria-colspan={cols}/> :
                            <input ref={ref} name={name} autoComplete="off" {...props}/>
                    }
                    {icon && <FontAwesomeIcon onClick={iconClick} className="Health-textbox__input-icon" icon={icon}/>}
                    {!isErrorBool && error && <span className="Health-form__field-error">{error}</span>}
                    {!error && hint && <Hint>{hint}</Hint>}
                </div>
            </div>
        );
    }
);

TextBox.defaultProps = {
    autoComplete: "off",
    readOnly: false,
};

TextBox.displayName = 'HealthTextBox';


export default TextBox;
