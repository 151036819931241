import {LANGUAGE_KEY} from "../../constants/Common";

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const setCurrentLanguage = (lang) => (dispatch) => {
    if (lang) {
        localStorage.setItem(LANGUAGE_KEY, lang);
        dispatch({
            type: SET_LANGUAGE,
            payload: lang
        });
    } else {
        localStorage.removeItem(LANGUAGE_KEY);
    }
};
