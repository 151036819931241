import React, {useCallback, useImperativeHandle, useMemo, useRef} from 'react';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {faVideo, faMicrophone, faTimes, faPaperclip} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import {FormattedMessage, useIntl} from "react-intl";

import './SendMessageForm.scss';
import {ROLES, toUserRole} from '../../../../constants/Roles';
import {requireFieldValidator} from 'libs/validation/fields';
import Button from '../../../common/Button/Button';
import {MessageRecipientTypes} from '../../constants/MessageRecipientTypes';
import MessageAvatar from '../MessageAvatar/MessageAvatar';
import MessageMeta from '../MessageMeta/MessageMeta';
import FileList from './components/FileList/FileList';
import SendMessageInputField from './components/SendMessageInputField';
import SendToButton from './components/SendToButton';
import IfCondition from "../../../common/IfCondition/IfCondition";
import { Attachment, Message } from '../../Chat.interface';

interface Props {
    onSendMessage: Function;
    onCancelReplyOrEdit: () => void;
    replyMessage: Message,
    editMessage: Message,
    role: ROLES,
    consultantLang: string,
    patientLang: string,
    sending: boolean,
    attachedFiles: Attachment[]
    onAttachFiles: () => void,
    onRemoveFile: (file: Attachment) => void,
    attachmentsOwner: string, // only coordinator; чтобы нельзя было аттачменты от пациента отправить ему же
}

const SendMessageForm = React.forwardRef<HTMLInputElement, Props>((
    {
        onSendMessage, replyMessage, onCancelReplyOrEdit, editMessage, role,
        consultantLang, patientLang, 
        sending = false, 
        attachedFiles = [], 
        onAttachFiles, onRemoveFile,
        attachmentsOwner = MessageRecipientTypes.DEFAULT,
    },
    ref) => {
    const inputRef = useRef<HTMLInputElement>();
    const intl = useIntl();

    // @ts-ignore
    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current.focus()
        },
    }), []);

    const handleSubmit = useCallback(async (values) => {
        if (!requireFieldValidator(values.text)) {
            const data = {
                ...values,
            };

            if (replyMessage) {
                data.replyId = replyMessage.id;
            }

            if (editMessage) {
                data.messageId = editMessage.id;
            }

            try {
                onSendMessage && await onSendMessage(data);
            } catch (error) {
                return error;
            }
        } else {
            return {
                [FORM_ERROR]: '',
            }
        }
    }, [editMessage, onSendMessage, replyMessage]);

    const renderReplyMessage = useCallback(() => {
        if (!replyMessage) {
            return null;
        }

        return (
            <div className="Health-chat__send-message-form_info">
                <MessageAvatar user={_.get(replyMessage, 'user')} isMe={false}/>
                <div className="Health-chat__send-message-form_info-body">
                    <MessageMeta
                        datetime={_.get(replyMessage, 'datetime')}
                        userName={_.get(replyMessage, ['user', 'name'])}/>
                    <div className="Health-chat__send-message-form_info-text">
                        {replyMessage.text}
                    </div>
                </div>
                <div>
                    <FontAwesomeIcon
                        title={intl.formatMessage({id: 'common.consultation_chat.tooltip_cancel_reply_or_edit'})}
                        onClick={onCancelReplyOrEdit}
                        className="Health-chat__send-message-form_info-action-cancel"
                        icon={faTimes}/>
                </div>
            </div>
        )
    }, [intl, onCancelReplyOrEdit, replyMessage]);

    const renderEditMessage = useCallback(() => {
        if (!editMessage) {
            return null;
        }

        return (
            <div className="Health-chat__send-message-form_info">
                <div className="Health-chat__send-message-form_info-body">
                    <div className="Health-chat__send-message-form_info-header">
                        <FormattedMessage id={'common.consultation_chat.edit_message_title'}/>
                    </div>
                    <div className="Health-chat__send-message-form_info-text">
                        {editMessage.text}
                    </div>
                </div>
                <div>
                    <FontAwesomeIcon
                        title={intl.formatMessage({id: 'common.consultation_chat.tooltip_cancel_edit_message'})}
                        onClick={onCancelReplyOrEdit}
                        className="Health-chat__send-message-form_info-action-cancel"
                        icon={faTimes}/>
                </div>
            </div>
        )
    }, [editMessage, intl, onCancelReplyOrEdit]);

    const initialValues = useMemo(() => {
        if (role === ROLES.PARTNER || role === ROLES.ADMIN_CLINIC) {
            if (replyMessage) {
                const sendToRole = toUserRole(replyMessage.user.rights);
                if (sendToRole === ROLES.PATIENT) {
                    return {
                        sendTo: MessageRecipientTypes.PATIENT,
                    }
                } else if (sendToRole === ROLES.DOCTOR) {
                    return {
                        sendTo: MessageRecipientTypes.CONSULTANT,
                    }
                } else {
                    return {
                        sendTo: MessageRecipientTypes.DEFAULT,
                    }
                }
            } else if (attachmentsOwner !== MessageRecipientTypes.DEFAULT) {
                return {
                    sendTo: attachmentsOwner,
                }
            }
        }
        if (editMessage) {
            return {
                text: editMessage.text,
                sendTo: MessageRecipientTypes.DEFAULT,
            }
        }
        return {
            sendTo: MessageRecipientTypes.DEFAULT,
        };
    }, [attachmentsOwner, editMessage, replyMessage, role]);

    const changeSendTo = useCallback((formApi, type) => {
        formApi.change('sendTo', type);
    }, []);

    const renderActionPanel = useCallback((formApi) => {
        const {text = ''} = formApi.getState().values;
        const isBlock = !`${text}`.trim().length;

        if (role === ROLES.ADMIN_CLINIC || role === ROLES.PARTNER) {
            if (editMessage) {
                return (
                    <div className='Health-chat__send-message-form_actions Justify-center'>
                        <Button
                            disabled={sending}
                            variant="blue"
                            size="lg"
                            type="submit">
                            <FormattedMessage id={'common.consultation_chat.btn_send_message'}/>
                        </Button>
                    </div>
                )
            }
            return (
                <div className='Health-chat__send-message-form_actions Justify-center'>
                    <SendToButton
                        disabled={sending || isBlock}
                        sendToType={MessageRecipientTypes.CONSULTANT}
                        onClick={() => changeSendTo(formApi, MessageRecipientTypes.CONSULTANT)}
                        variant="blue">
                        <FormattedMessage id={'common.consultation_chat.btn_send_consultant'}/>
                    </SendToButton>
                    <SendToButton
                        disabled={sending || isBlock}
                        sendToType={MessageRecipientTypes.PATIENT}
                        className="ml-2"
                        onClick={() => changeSendTo(formApi, MessageRecipientTypes.PATIENT)}
                        variant="gray">
                        <FormattedMessage id={'common.consultation_chat.btn_send_patient'}/>
                    </SendToButton>
                </div>
            )
        }

        return (
            <div className='Health-chat__send-message-form_actions'>
                <IfCondition condition={role === ROLES.PATIENT || role === ROLES.DOCTOR}>
                    <FontAwesomeIcon
                        title={intl.formatMessage({id: 'common.consultation_chat.tooltip_attach_files'})}
                        className="Health-chat__send-message-form_action"
                        onClick={onAttachFiles}
                        icon={faPaperclip}/>
                </IfCondition>
                <IfCondition condition={false}>
                    <FontAwesomeIcon
                        className="Health-chat__send-message-form_action"
                        icon={faVideo}
                        tabIndex={0}/>
                    <FontAwesomeIcon
                        className='Health-chat__send-message-form_action'
                        icon={faMicrophone}
                        tabIndex={0}/>
                </IfCondition>
                <Button
                    className="margin-left-auto"
                    variant="blue"
                    size="lg"
                    type="submit">
                    <FormattedMessage id={'common.consultation_chat.btn_send_message'}/>
                </Button>
            </div>
        );
    }, [role, onAttachFiles, editMessage, sending, changeSendTo]);

    const renderLanguages = useCallback(() => {
        if ((role === ROLES.ADMIN_CLINIC || role === ROLES.PARTNER) && !editMessage && !replyMessage) {
            return (
                <div className="Health-chat__send-message-form_languages">
                    <span>{consultantLang}</span>
                    <span className="ml-4">{patientLang}</span>
                </div>
            )
        }
        return null;
    }, [consultantLang, editMessage, patientLang, replyMessage, role]);

    return (
        <div className="Health-chat__send-message-form">
            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}>
                {
                    (form) => (
                        <React.Fragment>
                            {renderLanguages()}
                            {renderReplyMessage()}
                            {renderEditMessage()}
                            <form
                                onSubmit={async (event) => {
                                    const errors = await form.handleSubmit(event);
                                    if (!errors) {
                                        form.form.reset();
                                    }
                                    inputRef.current.focus();
                                }}
                                noValidate autoComplete='off' autoCapitalize='off'>
                                <SendMessageInputField
                                    hasAttachments={!!attachedFiles.length}
                                    ref={inputRef}
                                    name='text'/>
                                <FileList
                                    onRemove={onRemoveFile}
                                    files={attachedFiles}/>
                                {renderActionPanel(form.form)}
                            </form>
                        </React.Fragment>
                    )
                }
            </Form>
        </div>
    )
});

SendMessageForm.displayName = 'HealthChatSendMessageForm';

export default SendMessageForm;
