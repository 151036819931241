import React from 'react';
import {Form} from 'react-final-form'
import {FormattedMessage, useIntl} from "react-intl";

import TextBoxField from "../../../components/common/TextBox/TextBoxField";
import Button from "../../../components/common/Button/Button";

export const RecoveryPasswordForm = ({onSubmit}) => {
    const intl = useIntl();

    return (
        <React.Fragment>
            <Form onSubmit={onSubmit}>
                {
                    ({handleSubmit}) => {
                        return (
                            <form
                                autoComplete="off"
                                noValidate
                                onSubmit={handleSubmit}
                                className="mt-2">
                                <TextBoxField
                                    inline
                                    labelClass="col-4"
                                    inputClass="col-8"
                                    name="pass"
                                    type="password"
                                    hint={intl.formatMessage({id: "recoveryPass.pass_help"})}
                                    label={intl.formatMessage({id: "recoveryPass.pass"})}/>
                                <TextBoxField
                                    inline
                                    labelClass="col-4"
                                    inputClass="col-8"
                                    name="confirm_pass"
                                    type="password"
                                    label={intl.formatMessage({id: "recoveryPass.confirm_pass"})}/>
                                <div className="Flex-row Justify-end mt-2 mb-4">
                                    <Button type={'submit'}>
                                        <FormattedMessage id="recoveryPass.but_send"/>
                                    </Button>
                                </div>
                            </form>
                        )
                    }
                }
            </Form>
        </React.Fragment>
    );
};

export default RecoveryPasswordForm;
