import {setLoading} from '../loading/actions';
import pageNames from '../../constants/PageNames.js';
import {fetchTranslation} from "../translations/actions";
import {fetchUserInfo} from "../user/actions";

export const initMainPage = () => async (dispatch) => {
    dispatch(setLoading(true));
    await Promise.all([
        dispatch(fetchTranslation({
            pageName: pageNames.Main
        })),
        dispatch(fetchTranslation({
            pageName: pageNames.Common
        })),
        dispatch(fetchUserInfo())
    ]);
    dispatch(setLoading(false));
};
