import React from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import cn from 'classnames';

import './SystemPageHeader.scss';

interface Props {
	className?: string;
}

const SystemPageHeader: React.FC<Props> = ({className, children}) => {
	return (
		<div className={cn(className, 'System-page__header', 'Flex-row')}>
			<div className="Flex-column">
				<div className="System-page__title">
					<FormattedMessage id="main.menu_system" />
				</div>
				<div className={`System-page-nav`}>
					<NavLink
						className={`System-page-nav__link`}
						activeClassName={`System-page-nav__link--active`}
						to={`/main/system/translates`}
					>
						<FormattedMessage id="main.menu_translates" />
					</NavLink>

					<NavLink
						className={`System-page-nav__link`}
						activeClassName={`System-page-nav__link--active`}
						to={`/main/system/statuses`}
					>
						<FormattedMessage id="main.menu_statuses" />
					</NavLink>

					<NavLink
						className={`System-page-nav__link`}
						activeClassName={`System-page-nav__link--active`}
						to={`/main/system/agreements`}
					>
						<FormattedMessage id="main.menu_agreements" />
					</NavLink>
				</div>
			</div>
			<div className="System-page__right-side">{children}</div>
		</div>
	);
};

export default SystemPageHeader;
