import React from 'react';
import cn from 'classnames';

import './MenuList.scss';
import MenuListItem from '../MenuListItem/MenuListItem';
import type {MenuOption} from '../../../../SelectBox.interface';

interface Props<T> extends React.PropsWithRef<any> {
	options: MenuOption[];
	noOptionsMessage: string;
	itemKeySelector: string;
	optionRenderLabel: (o: T) => React.ReactNode;
	focusedOptionIndex: number;
	onSelectItem: (o: MenuOption) => void;
}

const MenuList = <T,>(
	{
		options,
		noOptionsMessage,
		itemKeySelector,
		optionRenderLabel,
		focusedOptionIndex,
		onSelectItem,
	}: Props<T>,
	ref: any,
) => {
	const itemKey = (index: number, option: MenuOption) => {
		const key = itemKeySelector && option.original[itemKeySelector];

		return key || index;
	}

	return (
		<div
			className={cn('Health-select-box__menu-list', {
				'Health-select-box__menu-list--no-message': !options.length,
			})}
			ref={ref}
		>
			{options.length
				? options.map((option, index) => (
						<MenuListItem
							onSelect={onSelectItem}
                            // @ts-expect-error
							optionRenderLabel={optionRenderLabel}
							isFocused={index === focusedOptionIndex}
							option={option}
							key={itemKey(index, option)}
						/>
				  ))
				: noOptionsMessage}
		</div>
	);
};

MenuList.displayName = 'HealthSelectBoxMenuList';

export default React.forwardRef(MenuList) as typeof MenuList;
