import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import './FilterPanel.scss';

const FilterPanel = ({className, title, children})=>{
    return (
        <div className={cn('Health-Filter-panel', className)}>
            <div className="Health-Filter-panel__header">
                <span className="Health-Filter-panel__title">
                    {title}
                </span>
            </div>
            <div className="Health-Filter-panel__content">
                {children}
            </div>
        </div>
    )
}

FilterPanel.displayName='HealthFilterPanel';
FilterPanel.propTypes = {
    title: PropTypes.string
};
FilterPanel.defaultProps = {};

export default FilterPanel;
