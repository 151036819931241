import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FormattedMessage} from "react-intl";

import {getRequestStatusClassName} from "../../../helpers/Request";
import {splitRequestStatus} from '../../../constants/RequestStatuses';

const UNKNOWN_STATUS = '-';

export const RequestStatusText = ({className, status, statusTextId, isColor, isNew}) => {
    const rootStatus = splitRequestStatus(status);

    let statusClassName = isColor ? getRequestStatusClassName({status: rootStatus, isNew}) : '';

    return (
        <span className={cn('Request-status-text', statusClassName, className)}>
            {statusTextId ? <FormattedMessage id={statusTextId}/> : UNKNOWN_STATUS}
        </span>
    )
};

RequestStatusText.propTypes = {
    status: PropTypes.string,
    isNew: PropTypes.bool,
    isColor: PropTypes.bool,
    statusTextId: PropTypes.string
};

RequestStatusText.defaultProps = {
    isNew: false,
    status: '',
    isColor: true,
};
