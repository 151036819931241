import Joi from "@hapi/joi";

import gender from '../constants/Gender';

export const requireValidator = (value) => !value;

export const maxLengthValidator = (value, max)=>value.length > max;

export const requireFieldValidator = (value) => {
    const {error} = Joi.string().trim().required().validate(value);
    return !!error;
};

export const numberFieldValidator = (value) => {
    const {error} = Joi.number().required().validate(value);
    return !!error;
};

export const genderFieldValidator = (value) => {
    const {error} = Joi.number().valid(gender.MALE, gender.FEMALE).required().validate(value);
    return !!error;
};

export const yearFieldValidator = (value) => {
    const {error} = Joi.number().min((new Date()).getFullYear() - 90).max((new Date()).getFullYear()).required().validate(value);
    return !!error;
};

export const specialitiesFieldValidator = (value) => {
    const {error} = Joi.array().min(1).validate(value);
    return !!error;
};

export const emailFieldValidator = (value) => {
    const {error} = Joi.string().email({
        tlds: false,
    }).required().validate(value);
    return !!error;
};

export const dateFieldValidator = (value)=>{
    const {error} = Joi.date().required();
    return !!error;
}
