import _ from 'lodash';

import pageNames from '../../constants/PageNames';
import * as api from "../../api";
import {isPageLoadedSelector} from "./selectors";

import rememberPassTranslations from './mocks/rememberPass';
import recoveryPassTranslations from './mocks/recoveryPass';
import {currentLanguageSelector} from "../lang/selectors";

export const SET_PAGE_TRANSLATION = 'SET_PAGE_TRANSLATION';

const mappingToKey = {
    [pageNames.Main]: 'main',
    [pageNames.Account]: 'account',
    [pageNames.ConfirmEmail]: 'confirmEmail',
    [pageNames.ContactUs]: 'contactUs',
    [pageNames.General]: 'general',
    [pageNames.Login]: 'login',
    [pageNames.Overview]: 'overview',
    [pageNames.Profile]: 'profile',
    [pageNames.Security]: 'security',
    [pageNames.Common]: 'common',
    [pageNames.EditProfile]: 'profile',
    [pageNames.ViewClinic]: 'viewClinic',
    [pageNames.ViewDoctor]: 'viewDoctor',
    [pageNames.ViewCoordinator]: 'viewCoordinator',
    [pageNames.PatientReg]: 'patientReg',
    [pageNames.MedicalRecords]: 'medicalRecords',
    [pageNames.Requests]: 'requests',
    [pageNames.Request]: 'request',
    [pageNames.RememberPassword]: 'remPass',
    [pageNames.RecoveryPassword]: 'recoveryPass',
    [pageNames.Translates]: 'translates',
    [pageNames.Statuses]: 'statuses',
};

const mocks = {
    [pageNames.RememberPassword]: rememberPassTranslations,
    [pageNames.RecoveryPassword]: recoveryPassTranslations,
    //[pageNames.MedicalRecords]: medicalRecordsTranslations,
}

const extend = {
    // [pageNames.Request]: requestTranslations,
    // [pageNames.Overview]: overviewTranslations,
}

export const fetchTranslation = ({pageName, lang}) => async (dispatch, getState) => {
    if (isPageLoadedSelector(getState(), pageName)) {
        return;
    }
    try {
        const language = lang ? lang : currentLanguageSelector(getState());
        let data;

        if (mocks[pageName]) {
            data = mocks[pageName][language];
        } else {
            const response = lang ? await api.pageLoadTranslations({pageName, lang}) :
                await api.loadTranslations({pageName});
            data = response.data;

            if (extend[pageName]) {
                data = _.merge(data, extend[pageName][language]);
            }
        }

        dispatch({
            type: SET_PAGE_TRANSLATION,
            payload: {
                pageName,
                translation: data,
                key: mappingToKey[pageName]
            }
        });
    } catch (err) {
        // TODO catch error
    }
};
