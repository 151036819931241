import React from 'react';
import PropTypes from 'prop-types';
import {setUrlSearchParams} from "../../../../helpers/UrlHelper";
import ViewDoctorLink from "../../../Links/ViewDoctorLink";

const DoctorLinkCell = React.memo(({cell: {value}, from, className, queryParams = {}}) => {
    const search = setUrlSearchParams(undefined, queryParams);
    const name = value ? value.name : null;
    return (
        <React.Fragment>
            {
                value ?
                    <ViewDoctorLink
                        className={className}
                        id={value.id}
                        from={from}
                        search={search}>
                        {value.name}
                    </ViewDoctorLink>
                    :
                    name ? <span className={className}>{name}</span> : null
            }
        </React.Fragment>
    )
});

DoctorLinkCell.propTypes = {
    from: PropTypes.string,
    queryParams: PropTypes.object,
};

export default DoctorLinkCell;
