import React from 'react';
import {FormattedMessage} from 'react-intl';

import CheckboxField from '../../common/Checkbox/CheckboxField';

import './AgreementCheckboxField.scss';
import {ShowUserAgreementLink} from 'components/Policy/ShowUserAgreementLink';

interface Props {
	name: string;
	rules1: string;
	rules2: string;
	rules3: string;
}

export const AgreementCheckboxField = React.memo(
	({rules1, rules2, rules3, name = 'agreement_accepted'}: Props) => {
		return (
			<div className="Health-form__field row">
				<label className="col-4" />
				<div className="col-8">
					<CheckboxField name={name}>
						<React.Fragment>
							<div className="Health-Agreement">
								<span>
									<FormattedMessage id={rules1} />
								</span>
								<ShowUserAgreementLink
									className="Health-Agreement__link"
									messageId={rules2}
								/>
								<span>
									<FormattedMessage id={rules3} />
								</span>
							</div>
						</React.Fragment>
					</CheckboxField>
				</div>
			</div>
		);
	},
);

AgreementCheckboxField.displayName = 'HealthAgreementCheckboxField';
