import Immutable from 'seamless-immutable';

import {
    CONSULTATION_PAGE_BEFORE_LOADING,
} from '../actionTypes';

import {
    CONSULTATION_PAGE_CONSULTATION_SET_DATA,
    CONSULTATION_PAGE_CONSULTATION_SET_LOADING,
    CONSULTATION_PAGE_CONSULTATION_SET_ERROR,
    CONSULTATION_PAGE_CONSULTATION_REFRESH_FILES,
} from './actions';

const INITIAL_STATE = {
    id: null,
    loading: false,
    data: null,
    error: null,
    com_langs: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTATION_PAGE_BEFORE_LOADING:
            return Immutable(INITIAL_STATE);
        case CONSULTATION_PAGE_CONSULTATION_SET_LOADING:
            return Immutable.merge(state, {
                loading: action.payload,
                error: null,
            });
        case CONSULTATION_PAGE_CONSULTATION_SET_DATA:
            return Immutable.merge(state, {
                loading: false,
                error: null,
                data: action.payload.data,
                id: action.payload.id,
            });
        case CONSULTATION_PAGE_CONSULTATION_SET_ERROR:
            return Immutable.merge(state, {
                loading: false,
                error: action.payload.error,
                id: action.payload.id,
                data: null,
            });
        case CONSULTATION_PAGE_CONSULTATION_REFRESH_FILES:
            return Immutable.merge(state, {
                data: {
                    ...state.data,
                    files: action.payload.files,
                },
            });
        default:
            return state;
    }
}
