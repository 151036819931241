import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {rootRequestPageSelector} from '../selectors';
import {transformRequestToFormData} from './utils';
import {RequestStatuses} from "../../../constants/RequestStatuses";
import * as commonSelectors from '../../common/selectors';
import {currentLanguageSelector} from 'redux/lang/selectors';

export const rootRequestSelector = createSelector(
    rootRequestPageSelector,
    (root) => root.request,
);

export const isRequestDirty = createSelector(
    rootRequestSelector,
    request => !_.isEqual(request.data, request.original), //JSON.stringify(request.data) !== JSON.stringify(request.original),
);

export const isRequestLoading = createSelector(
    rootRequestSelector,
    request => request.loading,
);

export const isRequestSaving = createSelector(
    rootRequestSelector,
    request => request.saving,
);

export const getRequestId = createSelector(
    rootRequestSelector,
    request => request.requestId,
);

export const isRequestNew = createSelector(
    rootRequestSelector,
    request => !!request.requestId,
);

export const getRequestData = createSelector(
    rootRequestSelector,
    request => Immutable.asMutable(request.data, {deep: true}),
);

export const getRequestFormData = createSelector(
    getRequestData,
    data => transformRequestToFormData(data),
);

interface RequestPaymentForm {
    merchantLogin: 'best2health', // идентификатор для кассы
    outSum: string
    outSumCurrency: string
    invId: number
    description: string
    signatureValue: string
    culture: string 
    //
    currencySymbol: string // custom
}

export const getPaymentForm = createSelector(
    getRequestData,
    currentLanguageSelector,
    (data, appLang: string): RequestPaymentForm => {
        const consult = data.doctor.consult

        return {
            merchantLogin: 'best2health', // идентификатор для кассы
            outSum: consult.totalToPay, // сумма консультация + перевод
            outSumCurrency: consult.code3, // валюта заявки
            invId: data.id, // id заявки
            description: `Оплата заявки №${data.id}`, // описание на разных языках
            signatureValue: consult.pay_signature,
            culture: appLang, // язык интерфейса
            //
            currencySymbol: consult.symbol
        }
    }
);

export const isRequestFormEditSpecialities = createSelector(
    getRequestData,
    data => {
        return data.status === RequestStatuses.Draft && !data.doctor
    },
);

export const isRequestDeclined = createSelector(
    rootRequestSelector,
    request => !!request.declined,
);

export const getRequestFormLanguages = createSelector(
    getRequestData,
    rootRequestSelector,
    (data, {com_langs = []}) => {
        if (data.doctor || data.clinic) {
            const {langs = [], lang_com = []} = data.doctor || data.clinic;
            return [
                ...langs.filter(l => l.code).map(l => ({
                    ...l,
                    translate: false,
                })),
                ...lang_com.filter(l => l.code).map(l => ({
                    ...l,
                    translate: true,
                })),
            ].sort((a, b) => a.label.localeCompare(b.label));
        }

        return Immutable.asMutable(com_langs, {deep: true}).map(l => ({
            ...l,
            translate: false,
        })).sort((a, b) => a.label.localeCompare(b.label));
    },
);

export const getCurrentLangCommunication = createSelector(
    getRequestData,
    getRequestFormLanguages,
    ({lang}, langs = []) => {
        return langs.find(l => l.code === lang);
    },
);

export const getCurrentLangCommunicationCode = createSelector(
    getRequestData,
    ({lang}) => lang,
);

export const getConsultantInfoSelector = createSelector(
    getRequestData,
    commonSelectors.getCountriesSelector,
    (data, countries) => {
        const country = data.country || _.get(data, 'doctor.country.id') || _.get(data, 'clinic.country.id')
        return {
            countryName: _.get(countries.find(c => c.id === country), 'label'),
            doctor: _.get(data, 'doctor'),
            clinic: _.get(data, 'clinic') || _.get(data, ['doctor', 'clinic']),
        }
    }
)
