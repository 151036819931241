import { SET_LANGUAGE } from './actions';
import {LANGUAGE_KEY} from "../../constants/Common";
import {getDomain} from "../../helpers/UrlHelper";

let initialLang = localStorage.getItem(LANGUAGE_KEY);

if (!initialLang) {
    const host = window.location.hostname;
    const domain = getDomain(host);
    if (domain === 'com') {
        initialLang = 'en';
    } else {
        initialLang = 'ru';
    }
    localStorage.setItem(LANGUAGE_KEY, initialLang);
}

export default (state = initialLang, action) => {
    switch (action.type) {
        case SET_LANGUAGE:
            return action.payload;
        default:
            return state;
    }
}
