import React from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from "react-intl";

import {RequestInfo} from '../../../../components/request/RequestInfo/RequestInfo';
import * as consultationSelectors from '../../../../redux/consultationPage/consultation/selectors';

const ConsultationInfoPage = (props) => {
    const data = useSelector(consultationSelectors.getConsultationData);
    const currentLang = useSelector(consultationSelectors.getConsultationCommunicationLanguage);

    return (
        <div className="Consultation-page__body Flex-column">
            <div className="Consultation-page__title">
                <FormattedMessage id={"request.page_titles.consultation_info"}/>
            </div>
            <RequestInfo
                className="mb-5"
                noDisplayFiles
                currentLang={currentLang}
                data={data}/>
        </div>
    )
};

export default ConsultationInfoPage;
