export const REQUEST_PAGE_BEFORE_LOADING_REQUEST = 'REQUEST_PAGE_BEFORE_LOADING_REQUEST';
export const REQUEST_PAGE_SHOW_LOADER = 'REQUEST_PAGE_SHOW_LOADER';
export const REQUEST_PAGE_HIDE_LOADER = 'REQUEST_PAGE_HIDE_LOADER';

export const showRequestPageLoader = () => ({
    type: REQUEST_PAGE_SHOW_LOADER,
});

export const hideRequestPageLoader = () => ({
    type: REQUEST_PAGE_HIDE_LOADER,
});
