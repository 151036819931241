import axios from 'axios';
import apiRoutes from '../constants/ApiRoutes';
import RequestActions from '../constants/RequestActions';

export const loadRequests = (params = {}) => {
    return axios.get(apiRoutes.requestList, {
        params,
    })
};

export const createRequest = (data) => {
    return axios.post(apiRoutes.requestEdit, {
        ...data,
        action: RequestActions.Save,
    });
};

export const saveRequest = (id, data = {}) => {
    return axios.post(apiRoutes.requestEdit, {
        id,
        ...data,
        action: RequestActions.Save,
    });
};

export const sendRequest = (id, data = {}) => {
    return axios.post(apiRoutes.requestEdit, {
        ...id ? {id} : {},
        ...data,
        action: RequestActions.Send,
    });
};

export const cancelRequest = (id) => {
    return axios.post(apiRoutes.requestEdit, {
        id,
        action: RequestActions.Cancel,
    });
};

export const confirmRequest = (id) => {
    return axios.post(apiRoutes.requestEdit, {
        id,
        action: RequestActions.Confirm,
    });
};

export const loadRequestInfo = (id) => {
    return axios.get(apiRoutes.requestInfo, {
        params: {
            id,
            _: Date.now(),
        },
    });
};

export const payRequest = (id) => {
    return axios.post(apiRoutes.requestPay, {
        id,
    });
};

export const uploadRequestFiles = ({id, files, lang}) => {
    return axios.post(apiRoutes.requestEdit, {
        id,
        lang,
        files,
        action: RequestActions.Attachments,
    });
}
