import {fetchTranslation} from "../translations/actions";
import pageNames from "../../constants/PageNames";
import * as api from '../../api';
import * as commonActions from '../common/actions';

export const SET_VIEW_CLINIC_LOADING = 'SET_VIEW_CLINIC_LOADING';
export const CLEAR_VIEW_CLINIC_DATA = 'CLEAR_VIEW_CLINIC_DATA';
export const SET_VIEW_CLINIC_ERROR = 'SET_VIEW_CLINIC_ERROR';
export const SET_VIEW_CLINIC_DATA = 'SET_VIEW_CLINIC_DATA';
export const SET_VIEW_CLINIC_DOCTORS = 'SET_VIEW_CLINIC_DOCTORS';

export const initViewClinic = (id) => async (dispatch, getState) => {
    dispatch({type: CLEAR_VIEW_CLINIC_DATA});
    dispatch(setViewClinicLoading(true));
    await Promise.all([
        dispatch(fetchTranslation({
            pageName: pageNames.ViewClinic
        })),
        dispatch(commonActions.fetchCurrencies()),
        dispatch(loadClinicInfo(id)),
        dispatch(fetchDoctors(id)),
    ]);
    dispatch(setViewClinicLoading(false));
};

export const setViewClinicLoading = (loading) => {
    return {
        type: SET_VIEW_CLINIC_LOADING,
        payload: loading
    }
};

export const loadClinicInfo = (id) => async (dispatch, getState) => {
    try {
        const res = await api.loadClinicInfo({cid: id});
        dispatch({
            type: SET_VIEW_CLINIC_DATA,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: SET_VIEW_CLINIC_ERROR,
            payload: err
        })
    }
};

export const fetchDoctors = (clinicId) => async (dispatch) => {
    try {
        const res = await api.loadDoctors({full: true, c: clinicId});

        dispatch({
            type: SET_VIEW_CLINIC_DOCTORS,
            payload: res.data.list
        })
    } catch (err) {

    }
};

