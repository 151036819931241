import React from 'react';
import cn from 'classnames';

import './Button.scss';

export interface Props {
    className?: string;
    active?: boolean;
    type?: 'button' | 'reset' | 'submit' | null;
    disabled?: boolean;
    size?: 'sm' | 'lg' | null;
    viewType?: 'minimal' | 'frameless' | null;
    variant?: 'blue' | 'gray' | 'ruby' | 'green' | 'orange' | 'primary' | null;
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(({ 
    type = 'button', 
    className, 
    size, 
    viewType, 
    variant = 'blue', 
    active = false, 
    ...props 
}, ref) => {
        const classes = cn(
            className,
            "Health-button",
            size && `Health-button--${size}`,
            viewType && `Health-button--${viewType}`,
            variant && `Health-button--${variant}`,
            active && 'Health-button--active',
        );
        return (
            <button type={type} className={classes} ref={ref} {...props} />
        );
    }
);


Button.displayName = "HealthButton";

export default Button;
