import React from "react";
import cn from "classnames";

import './Value.scss';
import type { Option, SelectedOption } from '../../SelectBox.interface';

interface Props {
    className?: string;
    placeholder: string;
    inputValue: string;
    selectedOption: SelectedOption
    optionRenderLabel: (o: Option) => React.ReactNode;
}

export const Value: React.FC<Props> = ({
                   placeholder,
                   selectedOption,
                   inputValue,
                   optionRenderLabel,
               }) => {
    const noSelectedOption = !selectedOption;

    if (inputValue) {
        return null;
    }

    return (
        <div className={cn('Health-select-box__value', {
            'Health-select-box__value--placeholder': noSelectedOption,
        })}>
            {
                noSelectedOption ?
                    placeholder :
                    optionRenderLabel(selectedOption.original)
            }
        </div>
    )
}

Value.displayName = 'HealthSelectBoxValue';

export default Value;
