import Joi from '@hapi/joi';

import gender from 'constants/Gender';

// TODO: entity files

export const requireValidator = (value: string) => !value;

export const requireFieldValidator = (value: string) => {
    const {error} = Joi.string().trim().required().validate(value);
    return !!error;
};

export const numberFieldValidator = (value: string) => {
    const {error} = Joi.number().required().validate(value);
    return !!error;
};

export const genderFieldValidator = (value: string) => {
    const {error} = Joi.number().valid(gender.MALE, gender.FEMALE).required().validate(value);
    return !!error;
};

export const yearFieldValidator = (value: string) => {
    const {error} = Joi.number().min((new Date()).getFullYear() - 90).max((new Date()).getFullYear()).required().validate(value);
    return !!error;
};

export const specialitiesFieldValidator = (value: string) => {
    const {error} = Joi.array().min(1).validate(value);
    return !!error;
};

export const emailFieldValidator = (value: string) => {
    const {error} = Joi.string().email({
        tlds: false,
    }).required().validate(value);
    return !!error;
};
