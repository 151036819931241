import {SET_SESSION} from './actions';
import Immutable from 'seamless-immutable';
import {SESSION_KEY} from '../../constants/Common';

const initialState = localStorage.getItem(SESSION_KEY);

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SESSION:
            return Immutable.from(action.payload);
        default:
            return state;
    }
}
