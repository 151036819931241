import React, {useCallback} from 'react';
import cn from 'classnames';
import _ from 'lodash';

import './MessageBox.scss';
import MessagePositions from '../../constants/MessagePositions';
import {useConsultationChatContext} from '../context/ConsultationChatContext';
import MessageActions from '../MessageActions/MessageActions';
import MessageAttachments from '../MessageAttachments/MessageAttachments';
import MessageAvatar from '../MessageAvatar/MessageAvatar';
import MessageMeta from '../MessageMeta/MessageMeta';
import ReplyMessage from '../ReplyMessage/ReplyMessage';
import {Message} from '../../Chat.interface';

interface Props {
	className?: string;
	message: Message;
	canActions: boolean;
	position: string;
	isMe: boolean;
	background: 'gray' | 'green' | 'blue' | null;
	isRenderMeta: boolean;
}

const MessageBoxContent = React.forwardRef(({
	message,
	position = MessagePositions.Left,
	className,
	background = 'gray',
	canActions = true,
	isMe = false,
	isRenderMeta = true,
}: Props, ref) => {
	const {
		onRemoveMessage,
		onEditMessage,
		role,
		onReplyMessage,
		onCopyAttachments,
		onCanAttachments,
	} = useConsultationChatContext();
	const isCopyAttachments =
		!!(message.attachments && message.attachments.length) &&
		!isMe &&
		onCanAttachments(message);

	const handleRemove = useCallback(() => {
		onRemoveMessage(message.id);
	}, [message.id, onRemoveMessage]);

	const handleEdit = useCallback(() => {
		onEditMessage(message.id);
	}, [message.id, onEditMessage]);

	const handleReply = useCallback(() => {
		onReplyMessage(message.id);
	}, [message.id, onReplyMessage]);

	const handleCopyAttachments = useCallback(() => {
		if (isCopyAttachments && onCopyAttachments) {
			onCopyAttachments(message);
		}
	}, [isCopyAttachments, message, onCopyAttachments]);

	return (
		<div
			ref={ref as any}
			className={cn('Health-chat__message-box', className, {
				'Health-chat__message-box--me': isMe,
				'Health-chat__message-box--left':
					position === MessagePositions.Left,
				'Health-chat__message-box--right':
					position === MessagePositions.Right,
			})}
		>
			<div>
				<MessageAvatar user={_.get(message, 'user')} isMe={isMe} />
			</div>
			<div className="Health-chat__message-box_body">
				{isRenderMeta && !isMe && (
					<MessageMeta userName={_.get(message, ['user', 'name'])} />
				)}
				<div
					className={cn(
						'Health-chat__message-box_text-container',
						background &&
							`Health-chat__message-box_text-container--${background}`,
					)}
				>
					{message.reply && <ReplyMessage message={message.reply} />}
					<pre className="Health-chat__message-box_text">
						{_.get(message, 'text')}
					</pre>
					<MessageAttachments attachments={message.attachments} />
					<MessageMeta
						messageStatus={message.status}
						className="Health-chat__message-box_datetime"
						datetime={_.get(message, 'datetime')}
					/>
				</div>
				{canActions && (
					<div>
						<MessageActions
							role={role}
							isReplyable={!isMe}
							isEditable={isMe}
							isDeletable={isMe}
							isCopyAttachments={isCopyAttachments}
							onCopyAttachments={handleCopyAttachments}
							onReply={handleReply}
							onEdit={handleEdit}
							onRemove={handleRemove}
						/>
					</div>
				)}
			</div>
		</div>
	);
});

MessageBoxContent.displayName = 'HealthMessageContent';

export default MessageBoxContent;
