import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useIntl} from "react-intl";

import ConsultationChat from '../../../../components/ConsultationChat/ConsultationChat';
import * as consultationSelectors from '../../../../redux/consultationPage/consultation/selectors';
import * as consultationActions from '../../../../redux/consultationPage/consultation/actions';
import {userIdSelector, userRoleSelector} from '../../../../redux/user/selectors';
import {showAttachFilesModal} from "../../../../redux/modal/actions";
import {logoutUser} from "../../../../redux/user/actions";

const ConsultationChatPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const phpsession = useSelector(state => state.session);
    const userId = useSelector(userIdSelector);
    const userRole = useSelector(userRoleSelector);
    const {id} = useParams();
    const consultationData = useSelector(consultationSelectors.getConsultationData);
    const allowChatting = useSelector(consultationSelectors.isAllowChatting)

    const handleMessageSent = useCallback(({needToRefreshFilesPage}) => {
        if (needToRefreshFilesPage) {
            dispatch(consultationActions.refreshFiles());
        }
    }, [dispatch]);

    const handleOpenAttachFiles = useCallback((params) => {
        dispatch(showAttachFilesModal({
            title: intl.formatMessage({id: 'common.dlg_attach_files.title'}),
            ...params,
        }));
    }, [dispatch, intl]);

    const handleUnauthorize = useCallback(() => {
        dispatch(logoutUser());
    }, [dispatch]);

    return (
        <div className="Consultation-page__body Consultation-page__chat">
            <ConsultationChat
                onUnauthorize={handleUnauthorize}
                onOpenAttachFiles={handleOpenAttachFiles}
                allowChatting={allowChatting}
                onMessageSent={handleMessageSent}
                lang={consultationData.lang}
                langCom={consultationData.lang_com}
                chatId={id}
                session={phpsession}
                role={userRole}
                me={userId}/>
        </div>
    )
};

export default ConsultationChatPage;
