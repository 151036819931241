import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";

import './ConsultationFilesPage.scss';
import RequestFileList from '../../../../components/request/RequestFileList/RequestFileList';
import useOpenFile from '../../../../hooks/useOpenFile';
import * as consultationSelectors from '../../../../redux/consultationPage/consultation/selectors';
import {isUnsavedMedicalConclusionFile} from '../../../../redux/consultationPage/consultation/selectors';
import {FormattedMessage, useIntl} from "react-intl";
import {RequestFileItem} from "../../../../components/request/RequestFileList/components/RequestFileItem/RequestFileItem";
import ButtonIcon from "../../../../components/common/ButtonIcon/ButtonIcon";
import {showAddOrEditMedicalRecordModal} from "../../../../redux/modal/actions";
import {addFileToMedicalRecord, refreshFiles} from "../../../../redux/consultationPage/consultation/actions";
import * as notifierActions from "../../../../redux/notifier/actions";
import { MedicalFileTypeEnum } from '../../../../constants/FileTypeList';

const ConsultationFilesPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const openFile = useOpenFile();
    const files = useSelector(consultationSelectors.getConsultationFiles);
    const currentLang = useSelector(consultationSelectors.getConsultationCommunicationLanguageCode);

    const handleOpenFile = useCallback((file) => {
        file && openFile(file);
    }, [openFile]);

    const handleAddFileToMedicalRecords = useCallback((file) => {
        const isUnsavedConclusionFile = isUnsavedMedicalConclusionFile(file);

        const modalOptions = {
            disableFileTypeField: isUnsavedConclusionFile,
            hideConclusion77FileType: !isUnsavedConclusionFile,
            isAdd: true,
            file: {
                ...file,
                name: file[currentLang],
                date: new Date(),
                type: isUnsavedConclusionFile ? MedicalFileTypeEnum.Conclusion : undefined
            },
            onAddOrEdit: async (file) => {
                try {
                    await dispatch(addFileToMedicalRecord(file));
                    dispatch(notifierActions.notifySuccess(intl.formatMessage({id: "request.notifications.file_added_to_medical_records"})));
                    dispatch(refreshFiles());
                } catch (err) {
                    dispatch(notifierActions.notifySuccess(intl.formatMessage({id: "request.notifications.error_add_file_to_medical_records"})));
                }
            }
        };

        dispatch(showAddOrEditMedicalRecordModal(modalOptions));
    }, [currentLang, dispatch, intl]);

    const fileRender = useCallback((props) => {
        const {file: {is_saved_in_medical_records}} = props;
        return (
            <RequestFileItem {...props}>
                {
                    !is_saved_in_medical_records &&
                    <ButtonIcon
                        className={"button--add-file-to_medical"}
                        size="1x"
                        frameless
                        icon={faPlusCircle}
                        title={intl.formatMessage({id: "request.buttons.add_file_to_medical_records"})}
                        onClick={() => handleAddFileToMedicalRecords(props.file)}/>
                }
            </RequestFileItem>
        )
    }, [handleAddFileToMedicalRecords, intl]);

    return (
        <div className="Consultation-page__body Flex-column Consultation-page--files">
            <div className="Consultation-page__title Consultation-page__title--files">
                <FormattedMessage id={"request.page_titles.consultation_files"}/>
            </div>
            <RequestFileList
                renderItem={fileRender}
                onClickFile={handleOpenFile}
                list={files}
                lang={currentLang}/>
        </div>
    )
};

export default ConsultationFilesPage;
