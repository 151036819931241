import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';

import {getCommunicationLanguagesSelector} from '../common/selectors';
import {fromServerFormat} from "../../helpers/Date";
import * as commonSelectors from '../common/selectors';

export const getProfilePageSelector = state => state.profilePage;

export const isLoadingSelector = createSelector(
    getProfilePageSelector,
    page => page.loading,
);

export const getMyProfileSelector = createSelector(
    getProfilePageSelector,
    getCommunicationLanguagesSelector,
    (page, languages) => {
        if (!page.profile) {
            return {};
        }
        const user = Immutable.asMutable(page.profile, {deep: true});

        const fields = Object.keys(user).map((key) => {
            if (typeof user[key] === 'object' &&
                user[key] &&
                !Array.isArray(user[key]) &&
                Object.keys(user[key]).some(fieldName => user.lang.some(l => l.code === fieldName))) {
                return key;
            }
            return null;
        }).filter(Boolean);

        return {
            primaryLang: user.lang.find(l => l.main).code,
            lang: user.lang.map(l => l.code),
            lang_com: languages.filter(l => (user.lang_com || []).includes(l.code)),
            sync_lang: !!user.sync_lang,
            country: user.country,
            gender: user.gender,
            gmt: user.gmt,
            birth: user.birth ? fromServerFormat(user.birth): undefined,
            photo: user.url,
            phone: user.phone,
            confirmed: user.confirmed,
            need_cure: Boolean(user.need_cure),
            fields: user.lang.reduce((acc, lang) => {
                acc[lang.code] = fields.reduce((obj, fieldName) => {
                    obj[fieldName] = user[fieldName][lang.code];
                    return obj;
                }, {});
                return acc;
            }, {}),
        };
    },
);

export const getTimezoneByCountryIdSelector = createSelector(
	commonSelectors.getTimezonesSelector,
	commonSelectors.getCountriesSelector,
	(timezones, countries) => countryId => {
		const country = countries.find(country => country.id === countryId);

		return timezones.find(tz => Number(tz.gmt) === Number(country.gmt));
	},
);