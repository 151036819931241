import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';

export const getOverviewPageSelector = state => state.overviewPage;

export const isNotificationsLoading = createSelector(
    getOverviewPageSelector,
    (page) => page.loading,
)

export const getNotificationsSelector = createSelector(
    getOverviewPageSelector,
    (page) => Immutable.asMutable(page.notifications, {deep: true})
)

export const getStatisticsSelector = createSelector(
    getOverviewPageSelector,
    (page) => Immutable.asMutable(page.statistics, {deep: true})
)

// Income - это количество заявок с определенным статусом, найти можно
// в ../requestTablePage/selectors.ts -> hasConsultationStatusChanges
export const getIncomeStatisticValueSelector = createSelector(
	getStatisticsSelector,
	statistics => {
		return statistics.income
	}
);