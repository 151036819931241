import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import './OverviewPage.scss';
import OverviewHeader from "./components/OverviewHeader/OverviewHeader";
import {SidePanel} from "../../components/SidePanel/SidePanel";
import OverviewNotifications from "./components/OverviewNotifications/OverviewNotifications";
import OverviewStatistics from "./components/OverviewStatistics/OverviewStatistics";
import {initOverviewPage} from "../../redux/overviewPage/actions";
import PageNames from "../../constants/PageNames";
import {isPageLoadedSelector} from "../../redux/translations/selectors";
import Loader from "../../components/Loader/Loader";
import {ViewModalContextProvider} from "../../components/Contexts/ViewModalContext";

const OverviewPage = () => {
    const dispatch = useDispatch();
    const isTranslationLoaded = useSelector(state => isPageLoadedSelector(state, PageNames.Overview))

    useEffect(() => {
        dispatch(initOverviewPage());
    }, [dispatch]);

    return (
        <div className={"Overview-page"}>
            {
                isTranslationLoaded &&
                <React.Fragment>
                    <OverviewHeader/>
                    <div className={"Overview-page__main"}>
                        <SidePanel left>
                            <OverviewStatistics/>
                        </SidePanel>
                        <div className={"Overview-page__content"}>
                            <ViewModalContextProvider>
                                <OverviewNotifications className={"Flex-1"}/>
                            </ViewModalContextProvider>
                        </div>
                    </div>
                </React.Fragment>
            }
            <Loader loading={!isTranslationLoaded}/>
        </div>
    )
}

OverviewPage.displayName = 'OverviewPage'

export default OverviewPage;
