import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as consultationPageActions from '../../../redux/consultationPage/actions';

export const useConsultationLoaderById = (id) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(consultationPageActions.loadConsultation({id}));
    }, [dispatch, id]);
}