import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {forEach, map} from '../../helpers/ElementChildren';
import TabNavItem from "./TabNavItem";
import TabPanel from "./TabPanel";

import './Tabs.scss';

const Tabs = React.forwardRef(({activeTabId, children, className, onSelect}, ref) => {
    const [activeTab, setActiveTab] = useState(null);
    const listNode = useRef(null);

    useEffect(() => {
        setActiveTab(activeTabId || getDefaultActiveTabId(children));
    }, [activeTabId, children]);

    const getDefaultActiveTabId = (children) => {
        let defaultActiveTabId;
        forEach(children, child => {
            if (defaultActiveTabId == null) {
                defaultActiveTabId = child.props.tabId;
            }
        });

        return defaultActiveTabId;
    };

    const handleClickTab = useCallback((event, tabId) => {
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        onSelect && onSelect(tabId);
        setActiveTab(tabId);
    }, [onSelect]);

    const getNextActiveChild = offset => {
        if (!listNode.current) return null;

        let items = Array.from(listNode.current.children);
        let activeChild = listNode.current.querySelector('.Health-tabs__nav-item--active');

        let index = items.indexOf(activeChild);
        if (index === -1) return null;

        let nextIndex = index + offset;
        if (nextIndex >= items.length) nextIndex = 0;
        if (nextIndex < 0) nextIndex = items.length - 1;
        return items[nextIndex];
    };

    const handleKeyDown = (event) => {
        let nextActiveChild;

        switch (event.key) {
            case 'ArrowLeft':
            case 'ArrowUp':
                // back
                nextActiveChild = getNextActiveChild(-1);
                break;
            case 'ArrowRight':
            case 'ArrowDown':
                // next
                nextActiveChild = getNextActiveChild(1);
                break;
            default:
                return;
        }

        if (!nextActiveChild) return;

        event.preventDefault();
        const nextActiveTab = nextActiveChild.dataset['tabId'];
        onSelect && onSelect(nextActiveTab);
        setActiveTab(nextActiveTab);
        nextActiveChild.focus();
    };

    const renderTab = (child, index) => {
        const {title, tabId, tabClassName} = child.props;
        if (title == null) {
            return null;
        }

        return (
            <TabNavItem
                key={index}
                onClick={handleClickTab}
                active={activeTab === tabId}
                tabId={tabId}
                className={tabClassName}>
                {title}
            </TabNavItem>
        );
    };

    return (
        <div
            className={cn(className, 'Health-tabs')}
            ref={ref}>
            <div
                ref={listNode}
                onKeyDown={handleKeyDown}
                className="Health-tabs__nav"
                role="tablist">
                {map(children, renderTab)}
            </div>
            <div className="Health-tabs__content">
                {
                    map(children, (child, index) => {
                        const {title, disabled, tabClassName, ...childProps} = child.props;
                        return <TabPanel
                            key={index}
                            {...childProps}
                            active={activeTab === childProps.tabId}/>;
                    })
                }
            </div>
        </div>
    )
});

Tabs.propTypes = {
    activeTabId: PropTypes.any,
    onSelect: PropTypes.func,
    children: PropTypes.instanceOf(Array).isRequired
};

Tabs.defaultProps = {};

Tabs.displayName = 'HealthTabs';


export default Tabs;
