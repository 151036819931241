import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import './MedicalRecordList.scss';
import MedicalRecordListItem from "./MedicalRecordListItem";

const MedicalRecordList = ({className, records, onAddItem, onRemoveItem, isAddedList}) => {
    return (
        <div className={cn("Medical-record-list", className)}>
            {
                records.map((record) => (
                    <MedicalRecordListItem
                        isAddedList={isAddedList}
                        onAdd={onAddItem}
                        onRemove={onRemoveItem}
                        key={record.id}
                        record={record}/>
                ))
            }
        </div>
    )
}

MedicalRecordList.displayName = 'MedicalRecordList';
MedicalRecordList.propTypes = {
    records: PropTypes.arrayOf(PropTypes.any),
    onAddItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    isAddedList: PropTypes.bool,
};
MedicalRecordList.defaultProps = {
    records: [],
    isAddedList: false,
};

export default MedicalRecordList;
