import React from 'react';
import { FadeLoader } from 'react-spinners';

import './Loader.scss';

const Loader = ({ loading }) => {
    const loaderStyles = {
        position: 'fixed',
        zIndex: '1',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    };

    return !loading ? '' : (
        <div className="Loader">
            <FadeLoader sizeUnit="px"
                        size={150}
                        css={loaderStyles}
                        color="#6592ab"
                        loading={loading}/>
        </div>
    )
};

export default Loader;
