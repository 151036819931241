import {useCallback, useState} from 'react';

import type {Attachment} from '../Chat.interface';

export const useAttachmentFiles = () => {
    const [attachments, setAttachments] = useState<Attachment[]>([]);

    const clear = useCallback(() => {
        setAttachments([]);
    }, []);

    const isEmpty = useCallback(() => {
        return !!attachments.length;
    }, [attachments.length]);

    const append = useCallback((attachments: Attachment[] = []) => {
        setAttachments(prev => [...prev, ...attachments]);
    }, []);

    const remove = useCallback((attachment: Attachment) => {
        setAttachments(prev => {
            return prev.filter(item => item !== attachment);
        });
    }, []);

    const merge = useCallback((attachments: Attachment[] = []) => {
        setAttachments(prev => {
            return [...prev, ...attachments].reduce((next, item) => {
                if (item.id) {
                    const index = next.findIndex(a => a.id === item.id);
                    if (index !== -1) {
                        next[index] = {
                            ...next[index],
                            ...item,
                        }
                        return next;
                    }
                }
                next.push(item);
                return next;
            }, [])
        });
    }, []);

    const set = useCallback((attachments: Attachment[] = []) => {
        setAttachments(attachments);
    }, []);

    return {
        attachments,
        clear,
        append,
        remove,
        merge,
        set,
        isEmpty,
    } as const;
};
