import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {FormattedMessage, useIntl} from "react-intl";

import './RequestDeclinePage.scss';
import Button from '../../../../components/common/Button/Button';
import Separator from '../../../../components/common/Separator/Separator';
import {CommentTypes} from '../../../../constants/CommentTypes';
import {RequestStatuses} from '../../../../constants/RequestStatuses';
import {useResetWindowScroll} from '../../../../hooks/useResetWindowScroll';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';
import {useRequestCurrentLangCommunication} from '../../hooks/useRequestCurrentLangCommunication';
import {RequestClinicCard} from '../RequestClinicCard/RequestClinicCard';
import {RequestDoctorCard} from '../RequestDoctorCard/RequestDoctorCard';
import {RequestInfo} from '../../../../components/request/RequestInfo/RequestInfo';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import {RequestNote} from '../RequestNote/RequestNote';
import {RequestPageHeader} from '../RequestPageHeader/RequestPageHeader';
import {SidePanel} from '../../../../components/SidePanel/SidePanel';
import RequestConsultantInfo from "./components/RequestConsultantInfo/RequestConsultantInfo";

export const RequestDeclinePage = () => {
    const intl = useIntl();
    const requestData = useSelector(requestSelectors.getRequestData);
    const requestManager = useContext(RequestManagerContext);
    const [requestTitle, setRequestTitle] = useState(() => intl.formatMessage({id: 'request.decline_page.declined_title'}));
    const currentLang = useRequestCurrentLangCommunication();

    useEffect(() => {
        if (requestData.status === RequestStatuses.DeclineByPatient) {
            setRequestTitle(intl.formatMessage({id: 'request.decline_page.refused_title'}));
        }
    }, [intl, requestData.status]);

    const handleOk = useCallback(() => {
        requestManager.goRequestTable();
    }, [requestManager]);

    useResetWindowScroll();

    const renderComment = useCallback(() => {
        const {status, lang, text} = requestData;

        if (status === RequestStatuses.DeclineByClinic) {
            if (requestData.clinic) {
                return (
                    <React.Fragment>
                        <h2 className="ruby-color">
                            <FormattedMessage id={"request.decline_page.clinic"} />
                        </h2>
                        <RequestNote
                            stretch
                            type={CommentTypes.Declined}
                            text={_.get(text, lang)}/>
                        <Separator className="mt-1"/>
                        <h2 className="Request-page__title mt-2">
                            <FormattedMessage id={"request.decline_page.selected_clinic"} />
                        </h2>
                        <RequestClinicCard
                            clinic={requestData.clinic}
                            isSelectable={false}
                        />
                        <Separator className="mt-1"/>
                    </React.Fragment>
                )
            } else if (requestData.doctor) {
                return (
                    <React.Fragment>
                        <h2 className="ruby-color">
                            <FormattedMessage id={"request.decline_page.doctor"} />
                        </h2>
                        <RequestNote
                            stretch
                            type={CommentTypes.Declined}
                            text={_.get(text, lang)}/>
                        <Separator className="mt-1"/>
                        <h2 className="Request-page__title mt-2">
                            <FormattedMessage id={"request.decline_page.selected_doctor"} />
                        </h2>
                        <RequestDoctorCard
                            doctor={requestData.doctor || {}}
                            isSelectable={false}
                        />
                        <Separator className="mt-1"/>
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <h2 className="ruby-color">
                            <FormattedMessage id={"request.decline_page.help_me"} />
                        </h2>
                        {
                            _.get(text, lang) &&
                            <RequestNote
                                stretch
                                type={CommentTypes.Declined}
                                text={_.get(text, lang)}/>
                        }
                        <Separator className="mt-1"/>
                    </React.Fragment>
                )
            }
        }

        if (status === RequestStatuses.DeclineByPatient) {
            return (
                <React.Fragment>
                    <h2 className="ruby-color">
                        <FormattedMessage id={"request.decline_page.refuse"} />
                    </h2>
                    <RequestConsultantInfo />
                    <Separator className="mt-1"/>
                </React.Fragment>
            )
        }
    }, [requestData]);

    return (
        <React.Fragment>
            <div className="Request-page__main Request-page__decline">
                <RequestPageHeader title={requestTitle} hideProgress/>
                <div className="Request-page__body">
                    {renderComment()}
                    <RequestInfo data={requestData} currentLang={currentLang}/>
                    <div className="Flex-row Justify-center Align-center mt-5 mb-4">
                        <Button
                            className="ml-2"
                            onClick={handleOk}>
                            <FormattedMessage id={"request.buttons.ok"}/>
                        </Button>
                    </div>
                </div>
            </div>
            <SidePanel/>
        </React.Fragment>
    )
};
