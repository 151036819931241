import React, {useCallback, useMemo} from 'react';
import {connect} from "react-redux";
import Joi from "@hapi/joi";
import {set} from "lodash";
import {Form} from "react-final-form";

import './EditTranslateModal.scss';
import CloseButton from "../components/CloseButton/CloseButton";
import EditTranslateForm from "./EditTranslateForm";
import * as commonSelectors from "../../../redux/common/selectors";
import * as translatesPageActions from "../../../redux/translatesPage/actions";

const buildSchema = (langs) => {
    const fields = langs.reduce((agg, code) => {
        agg[code] = Joi.string().trim().required();
        return agg;
    }, {});

    return Joi.object({
        pagename: Joi.string().trim().required(),
        param: Joi.string().trim().required(),
        fields,
    }).unknown();
};

const EditTranslateModal = (
    {
        title,
        isNew = false,
        onSuccess,
        onError,
        hideModal,
        record,
        languages,
        addTranslate,
        updateTranslate,
        // это нужно для переводов статусов/соглашений
	    defaultFields,
	    shouldDisablePageNameField = false,
    }
) => {
    const handleSubmit = useCallback(async (values) => {
        try {
            // validate
            const schema = buildSchema(languages.map(l => l.code));
            const {error, value} = schema.validate(values, {
                abortEarly: false,
            });
            if (error) {
                return error.details.reduce((obj, e) => {
                    set(obj, e.path, true);
                    return obj;
                }, {});
            }

            const {id, pagename, param, fields} = value;
            if (isNew) {
                await addTranslate({
                    pagename,
                    param,
                    ...fields,
                });
            } else {
                await updateTranslate({
                    id,
                    pagename,
                    param,
                    ...fields,
                })
            }
            hideModal();
            onSuccess && onSuccess();
        } catch (err) {
            onError && onError(err);
        }
    }, [languages, isNew, onSuccess, hideModal, addTranslate, updateTranslate, onError]);

    const handleClose = useCallback(() => {
        hideModal();
    }, [hideModal]);

    const initialState = useMemo(() => {
        if (isNew) {
            return {
                fields: {},
                ...defaultFields,
            };
        }
        const {id, pagename, param, ...rest} = record;

        return {
            id,
            pagename,
            param,
            fields: languages.reduce((agg, {code}) => {
                agg[code] = rest[code];

                return agg;
            }, {})
        }
    }, [isNew, /*languages,*/ record])

    return (
        <React.Fragment>
            <CloseButton onClick={handleClose}/>
            <div className="Modal__header">
                <div className="Modal__title">{title}</div>
            </div>
            <Form
                initialValues={initialState}
                onSubmit={handleSubmit}>
                {
                    (form) => (
                        <EditTranslateForm
                            isNew={isNew}
                            handleCancel={handleClose}
                            languages={languages}
                            form={form}
                            shouldDisablePageNameField={shouldDisablePageNameField}
                        />
                    )
                }
            </Form>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        languages: commonSelectors.getLanguagesSelector(state),
    };
};

export default connect(mapStateToProps, {
    addTranslate: translatesPageActions.addTranslate,
    updateTranslate: translatesPageActions.updateTranslate,
})(EditTranslateModal);
