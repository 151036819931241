import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import './ViewDoctorDetailsModal.scss';
import CloseButton from "../components/CloseButton/CloseButton";
import Button from "../../common/Button/Button";
import {ViewModalContextProvider} from "../../Contexts/ViewModalContext";
import ViewDoctor from "../../ViewDoctor/ViewDoctor";

const ViewDoctorDetailsModal = React.memo(
    ({
         onClose,
         onSelect,
         doctorId,
         hideModal,
         noSelect,
     }) => {
        const handleClose = React.useCallback(() => {
            onClose && onClose();
            hideModal();
        }, [hideModal, onClose]);

        const handleSelect = React.useCallback((doctorId) => {
            hideModal();
            onSelect && onSelect(doctorId);
        }, [hideModal, onSelect]);

        const SelectButton = useCallback(() => noSelect ? null : (
            <div className="Flex-row Justify-end mt-1 mb-1">
                <Button
                    viewType="minimal"
                    size="sm"
                    onClick={() => handleSelect && handleSelect(doctorId)}>
                    <span className="text-uppercase">
                        <FormattedMessage id={"common.buttons.select"} />
                    </span>
                </Button>
            </div>
        ), [doctorId, handleSelect, noSelect]);

        return (
            <React.Fragment>
                <CloseButton onClick={handleClose}/>
                <SelectButton/>
                <ViewModalContextProvider>
                    <ViewDoctor
                        modalView
                        id={doctorId}/>
                </ViewModalContextProvider>
                <SelectButton/>
            </React.Fragment>
        );
    }
);

ViewDoctorDetailsModal.propTypes = {
    doctorId: PropTypes.number,
};

ViewDoctorDetailsModal.defaultProps = {
    noSelect: false,
};

ViewDoctorDetailsModal.displayName = 'ViewDoctorDetailsModal';

export default ViewDoctorDetailsModal;
