import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {useLastLocation} from 'react-router-last-location';
import {useIntl} from "react-intl";
import {connect} from 'react-redux';

import {initTranslatedLoginPage, login} from '../../redux/login/actions';
import {setSession} from '../../redux/session/actions';
import LoginForm from './components/LoginForm';
import {EMAIL_REG_EXP} from '../../constants/RegExps';
import * as translationsSelectors from '../../redux/translations/selectors';
import pageNames from "../../constants/PageNames";
import {loadingSelector} from "../../redux/loading/selectors";
import {RegistrationResult} from "../../components/registration/RegistrationResult/RegistrationResult";
import {RegistrationPageLayout} from "../../components/registration/RegistrationPageLayout/RegistrationPageLayout";
import {getUrlSearchParams} from "../../helpers/UrlHelper";

export const LoginPage = ({match, initTranslatedLoginPage, loading, isTranslationLoaded, history, setSession, login}) => {
    const intl = useIntl();
    const [regState, setRegState] = useState('not_submitted');
    const [loginRequestLoading, setLoginRequestLoading] = useState(false);
    const lastLocation = useLastLocation();

    const onSubmit = async (value) => {
        if (!EMAIL_REG_EXP.test(value.email)) {
            return {
                email: intl.formatMessage({id: 'login.email_error'}),
            };
        }
        setLoginRequestLoading(true);
        try {
            const redirect = await login(value.email, value.pass);

            if (redirect) {
                history.push(redirect);
            }
        } catch (error) {
            setRegState(error.data);
            if (error.data.error === 'login_user_pass_error') {
                return {
                    email: intl.formatMessage({id: 'login.login_user_pass_error'}),
                    pass: intl.formatMessage({id: 'login.login_user_pass_error'}),
                };
            }
        } finally {
            setLoginRequestLoading(false);
        }
    };

    const renderBody = () => {
        if (regState === 'not_submitted' || regState.error === 'login_user_pass_error') {
            return <LoginForm
                onSubmit={onSubmit}
                lastLocation={lastLocation}/>
        } else {
            return <RegistrationResult
                status={regState.status}
                title="login.login_error_head"
                description="login.login_description"
                errorText={intl.formatMessage({id: `login.${regState.error}`})}
                btnGotoMain="login.but_back_main"
                redirectTo="/"
            />
        }
    };

    useEffect(() => {
        // Session param passed means it has been redirected from lk
        const session = match.params.session;
        const searchParams = getUrlSearchParams(window.location.search);

        if (session) {
            setSession(session);
            history.push('/main');
        } else {
            initTranslatedLoginPage(searchParams.lang);
        }
    }, [history, initTranslatedLoginPage, match.params.session, setSession]);

    return (
        <RegistrationPageLayout
            className="Login-page"
            isLoading={loading || loginRequestLoading}
            isTranslationLoaded={isTranslationLoaded}
            title="login.h1"
            copyright="login.copyright"
            linksConf="login.links_conf"
            noRerender
        >
            {renderBody()}
        </RegistrationPageLayout>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: loadingSelector(state),
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, pageNames.Login),
    }
};

export default connect(mapStateToProps, {
    initTranslatedLoginPage,
    setSession,
    login,
})(withRouter(LoginPage));
