import { setLoading } from '../loading/actions';
import pageNames from '../../constants/PageNames.js';
import {fetchTranslation} from "../translations/actions";

export const initSecurityPage = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    await dispatch(fetchTranslation({
        lang: getState().lang,
        pageName: pageNames.Security
    }));
    dispatch(setLoading(false));
};
