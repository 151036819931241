import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {toInteger} from 'lodash';

import './RequestTablePage.scss';
import SimpleTable from '../../components/common/SimpleTable/SimpleTable';
import Button from '../../components/common/Button/Button';
import {ViewModalContextProvider} from '../../components/Contexts/ViewModalContext';
import queryParams from '../../constants/QueryParams';
import {RequestStatuses, splitRequestStatus} from '../../constants/RequestStatuses';
import {getUrlSearchParams, setUrlSearchParams} from '../../helpers/UrlHelper';
import {despace} from '../../helpers/String';
import * as dateHelpers from './../../helpers/Date';
import Pagination from '../../components/Pagination/Pagination';
//
import * as requestsTablePageSelectors from '../../redux/requestsTablePage/selectors';
import {notifySuccess, notifyError} from '../../redux/notifier/actions';
import * as translationsSelectors from '../../redux/translations/selectors';
import * as langSelectors from '../../redux/lang/selectors';
import * as commonSelectors from '../../redux/common/selectors';
import {showConfirmModal} from '../../redux/modal/actions';
import * as userSelectors from "redux/user/selectors";
//
import pageNames from '../../constants/PageNames';
import {DEFAULT_PAGE_SIZE, PageSizes, PageSizeValues} from '../../constants/PageSizes';
import PageHeader from '../../components/PageHeader/PageHeader';
import ActionsCell from './components/cells/ActionsCell';
import DateTimeCell from './components/cells/DateTimeCell';
import {StatusCell} from './components/cells/StatusCell';
import {COLUMN_NAMES} from './constants/columns';
import HeaderCell from '../../components/common/SimpleTable/cells/HeaderCell';
import CommonCell from '../../components/common/SimpleTable/cells/CommonCell';
import Spinner from '../../components/Spinner/Spinner';
import ClinicLinkCell from '../../components/common/SimpleTable/cells/ClinicLinkCell';
import LocationStateFroms from '../../constants/LocationStateFroms';
import DoctorLinkCell from '../../components/common/SimpleTable/cells/DoctorLinkCell';
import QuickFilter from '../../components/QuickFilter/QuickFilter';
import {RequestsQuickFilterStatuses} from '../../constants/RequestsQuickFilterStatuses';
import {initRequestsTablePage, getRequests} from '../../redux/requestsTablePage/actions';
import {PriceCell} from "./components/cells/PriceCell";
import {Filter} from './components/Filter';
import PageNames from '../../constants/PageNames';


const defineColumns = [
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.id" />),
        accessor: COLUMN_NAMES.ID,
        width: 120,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.reason" />),
        accessor: COLUMN_NAMES.REASON,
        width: 450,
        sortable: true,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.doctor" />),
        accessor: COLUMN_NAMES.DOCTOR,
        width: 230,
        sortable: true,
        Cell: (props) => <DoctorLinkCell from={LocationStateFroms.requests} {...props} />,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.clinic" />),
        accessor: COLUMN_NAMES.CLINIC,
        width: 270,
        sortable: true,
        Cell: (props) => <ClinicLinkCell from={LocationStateFroms.requests} {...props} />,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.country" />),
        accessor: COLUMN_NAMES.COUNTRY,
        width: 180,
        sortable: true,
        Cell: (props) => <CommonCell labelField="name" {...props} />,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.create_date" />),
        accessor: COLUMN_NAMES.CREATE_DATE,
        width: 220,
        fixedWidth: true,
        sortable: true,
        Cell: (props) => <DateTimeCell {...props} />,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.edit_date" />),
        accessor: COLUMN_NAMES.EDIT_DATE,
        width: 220,
        fixedWidth: true,
        sortable: true,
        Cell: (props) => <DateTimeCell {...props} />,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.price" />),
        accessor: COLUMN_NAMES.PRICE,
        width: 140,
        align: 'right',
        Cell: (props) => <PriceCell {...props} />,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.status" />),
        accessor: COLUMN_NAMES.STATUS,
        width: 180,
        align: 'left',
        Cell: (props) => <StatusCell {...props} />,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.actions" />),
        accessor: COLUMN_NAMES.ACTIONS,
        width: 170,
        align: 'right',
        fixedWidth: true,
        sortable: true,
        Cell: (props) => <ActionsCell {...props} />,
    },
];

class RequestTablePage extends React.Component {
    DEFAULT_SORT_FIELD = COLUMN_NAMES.EDIT_DATE;
    DEFAULT_ORDER_BY = 'desc';

    constructor(props) {
        super(props);

        this.pageRef = React.createRef();
        this.state.columns = defineColumns;
    }

    static propTypes = {
        location: PropTypes.object,
        history: PropTypes.object,
        initConsultsPage: PropTypes.func,
        getRequests: PropTypes.func,
        notifySuccess: PropTypes.func,
        notifyError: PropTypes.func,
        showConfirmModal: PropTypes.func,
        lang: PropTypes.string,
        unreadChatMessagesCount: PropTypes.number,
        requests: PropTypes.any,
        totalPages: PropTypes.object,
        totalCounts: PropTypes.object,
        intl: PropTypes.object,
        isTranslationLoaded: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    state = {
        isInitialSortBy: false,
        isFetching: false,
        searchParams: {},
        sortBy: {
            id: this.DEFAULT_SORT_FIELD,
            desc: false,
        },
        columns: [],
    };

    componentDidMount() {
        const params = this.getSearchParams();

        this.props.initRequestsTablePage();

        this.setState(() => ({
            isInitialSortBy: true,
            isFetching: true,
            searchParams: params,
            sortBy: {id: params.sortBy, desc: params.orderBy !== 'asc'},
        }), () => {
            this.updateData();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location, unreadChatMessagesCount} = this.props;

		const hasDifferenceInSearch =
			location.search !== prevProps.location.search;
		const hasUnreadChatMessages =
			unreadChatMessagesCount !== prevProps.unreadChatMessagesCount;

		const hasTableUpdates = hasDifferenceInSearch || hasUnreadChatMessages;

		if (hasTableUpdates) {
			const params = this.getSearchParams();

			this.setState(
				() => ({
					isFetching: true,
					searchParams: params,
				}),
				() => {
					this.updateData();
				},
			);
		}
    }

    getSearchParams() {
        const search = getUrlSearchParams(this.props.location.search);
		const currentPage = toInteger(search[queryParams.currentPage]);
		const pageSize = toInteger(search[queryParams.pageSize]);
		const sortBy = search[queryParams.sortBy] || this.DEFAULT_SORT_FIELD;
		const orderBy =
			search[queryParams.orderBy] &&
			/(asc|desc)/.test(search[queryParams.orderBy])
				? search[queryParams.orderBy]
				: this.DEFAULT_ORDER_BY;

		return {
			currentPage: currentPage > 0 ? currentPage : 1,
			pageSize:
				PageSizeValues.indexOf(pageSize) === -1
					? DEFAULT_PAGE_SIZE
					: pageSize,
			query: despace(search[queryParams.query]),
			status: search[queryParams.status],
			clinics: search[queryParams.clinics]
				? search[queryParams.clinics]
						.split(',')
						.map(v => ({id: Number(v)}))
				: [],
			countries: search[queryParams.countries]
				? search[queryParams.countries]
						.split(',')
						.map(v => ({id: Number(v)}))
				: [],
			fromDate: dateHelpers.fromServerFormat(
				search[queryParams.fromDate],
			),
			toDate: dateHelpers.fromServerFormat(search[queryParams.toDate]),
			sortBy: sortBy,
			orderBy: orderBy,
			doctor: search[queryParams.doctor]
				? Number(search[queryParams.doctor])
				: null,
		};
    }

    updateData = () => {
        this.props.getRequests({
            ...this.state.searchParams,
        });
    };

    handleChangeQuickFilter = (status) => {
        const {history, location} = this.props;
        if (status !== this.state.searchParams.currentPage) {
            history.push({
                pathname: history.location.pathname,
                search: setUrlSearchParams(location.search, {
                    [queryParams.currentPage]: null,
                    [queryParams.status]: status === 'all' ? null : status,
                }),
            });
        }
    };

    handleChangePage = (page) => {
        const {history, location} = this.props;
        const currentPage = page + 1;

        if (currentPage !== this.state.searchParams.currentPage) {
            history.push({
                pathname: history.location.pathname,
                search: setUrlSearchParams(location.search, {
                    [queryParams.currentPage]: currentPage === 1 ? null : currentPage,
                }),
            });
        }
    };

    handleChangeSort = (sort) => {
        const {history, location} = this.props;
        const [{id: sortBy, desc}] = sort;

        history.push({
            pathname: history.location.pathname,
            search: setUrlSearchParams(location.search, {
                [queryParams.currentPage]: null,
                [queryParams.sortBy]: sortBy,
                [queryParams.orderBy]: desc ? 'desc' : 'asc',
            }),
        });
    };

    handlePageSizeChange = (value) => {
        const {history, location} = this.props;

        history.push({
            pathname: history.location.pathname,
            search: setUrlSearchParams(location.search, {
                [queryParams.currentPage]: null,
                [queryParams.pageSize]: value === DEFAULT_PAGE_SIZE ? null : value,
            }),
        });
    };

    getTdProps = (cell) => {
        switch (cell.column.originalId) {
          
            case COLUMN_NAMES.ACTIONS:
            case COLUMN_NAMES.PRICE:
                return {
                    className: 'text-align-right',
                };
            case COLUMN_NAMES.DOCTOR:
            case COLUMN_NAMES.CLINIC:
                return {
                    className: 'Table__cell--link',
                };
            default:
        }
        return {};
    };

    getRowProps = (row) => {
        const rootStatus = splitRequestStatus(row.values.status);

        return {
            className: rootStatus === RequestStatuses.Draft ? 'Table__row--gray' : '',
        };
    };

    getTotalPages = (status) => {
        return this.props.totalPages[status || 'all'];
    };

    showFillProfileModal() {
        const {intl, showConfirmModal: _showConfirmModal, history, location} = this.props;

        const t = (id) =>intl.formatMessage({id});

        _showConfirmModal({
            title: t("requests.dlg_fill_profile_before.title"),
            message: t("requests.dlg_fill_profile_before.description"),
            onSuccess: async () => {
                history.push(PageNames.Profile, {
                    from: LocationStateFroms.requests,
                    pathname: location.pathname,
                    search: location.search,
                });
            },
            btnOk: t("requests.dlg_fill_profile_before.submit"),
            btnCancel: intl.formatMessage({id: 'common.buttons.cancel'}),
        })
    }

    handleCreateRequest = () => {
        const {user, history, location} = this.props;

        if (user.fio) {
            history.push('/main/requests/new', {
                from: LocationStateFroms.requests,
                pathname: location.pathname,
                search: location.search,
            });

            return;
        }
     
        this.showFillProfileModal();   
    };

    applyFilter = values => {
		const {history, location} = this.props;

		history.push({
			pathname: history.location.pathname,
			search: setUrlSearchParams(location.search, {
				[queryParams.currentPage]: null,
				[queryParams.query]: values.query,
				[queryParams.clinics]: values.clinics.length
					? values.clinics.map(c => c.id).join(',')
					: null,
				[queryParams.countries]: values.countries.length
					? values.countries.map(c => c.id).join(',')
					: null,
				[queryParams.fromDate]: dateHelpers.toServerFormat(
					values.fromDate,
				),
				[queryParams.toDate]: dateHelpers.toServerFormat(values.toDate),
				[queryParams.doctor]: values.doctor,
			}),
		});
	};

	resetFilter = () => {
		const {history, location} = this.props;

		history.push({
			pathname: history.location.pathname,
			search: setUrlSearchParams(location.search, {
				[queryParams.currentPage]: null,
				[queryParams.query]: null,
				[queryParams.clinics]: null,
				[queryParams.countries]: null,
				[queryParams.fromDate]: null,
				[queryParams.toDate]: null,
				[queryParams.doctor]: null,
			}),
		});
	};

    render() {
        const {
            searchParams, isInitialSortBy, sortBy, columns,
        } = this.state;
        const {
            lang,
            doctors,
            clinics,
            countries,
            totalCounts, requests, intl, isTranslationLoaded, isLoading,
            unreadChatMessagesCount
        } = this.props;

        const filters = [
            {
                id: 'all',
                hasBadge: false,
                label: intl.formatMessage({id: 'common.short_filter.all'}),
                count: totalCounts['all'],
            },
            {
                id: RequestsQuickFilterStatuses.Consult,
                hasBadge: unreadChatMessagesCount > 0,
                label: intl.formatMessage({id: 'common.short_filter.consult'}),
                count: totalCounts[RequestsQuickFilterStatuses.Consult],
            },
            {
                id: RequestsQuickFilterStatuses.Active,
                hasBadge: totalCounts[RequestsQuickFilterStatuses.ActiveIncome] > 0,
                label: intl.formatMessage({id: 'common.short_filter.current'}),
                count: totalCounts[RequestsQuickFilterStatuses.Active],
            },
            {
                id: RequestsQuickFilterStatuses.Draft,
                hasBadge: false,
                label: intl.formatMessage({id: 'common.short_filter.draft'}),
                count: totalCounts[RequestsQuickFilterStatuses.Draft],
            },
            {
                id: RequestsQuickFilterStatuses.Cancel,
                hasBadge: totalCounts[RequestsQuickFilterStatuses.CancelIncome] > 0,
                label: intl.formatMessage({id: 'common.short_filter.cancel'}),
                count: totalCounts[RequestsQuickFilterStatuses.Cancel],
            },
            {
                id: RequestsQuickFilterStatuses.Completed,
                hasBadge: false,
                label: intl.formatMessage({id: 'common.short_filter.end'}),
                count: totalCounts[RequestsQuickFilterStatuses.Completed],
            },
        ]

        return (
            <React.Fragment>
                {isTranslationLoaded && !isLoading &&
                <div className="Requests-table-page Flex-column" ref={this.pageRef}>
                    <PageHeader className="Flex-row">
                        <QuickFilter
                            className="Flex-1"
                            onChange={this.handleChangeQuickFilter}
                            items={filters}
                            activeItem={searchParams.status ? searchParams.status : 'all'} />
                        <div className="Align-center Flex-row ">
                            <Filter	
									lang={lang}
									clinics={clinics}
									countries={countries}
									doctors={doctors}
									onApplyFilter={this.applyFilter}
									onResetFilter={this.resetFilter}
									data={searchParams}
									containerRef={this.pageRef}
								/>
                           
                            <Button
                                onClick={this.handleCreateRequest}
                                viewType="frameless"
                                variant="gray">
                                <FontAwesomeIcon size="lg" icon={faPlusCircle} />
                                <FormattedMessage id="requests.create_request" defaultMessage="Create request"/>
                            </Button>
                        </div>
                    </PageHeader>
                    <div className="Consults-table-page__table">
                        {
                            isInitialSortBy &&
                            <ViewModalContextProvider>
                                <SimpleTable
                                    initialState={{
                                        sortBy: [sortBy],
                                    }}
                                    onChangeSort={this.handleChangeSort}
                                    columns={columns}
                                    data={requests}
                                    getRowProps={this.getRowProps}
                                    getTdProps={this.getTdProps} />
                            </ViewModalContextProvider>
                        }
                    </div>
                    <div className="Flex-row Justify-center mt-4 mb-4">
                        <Pagination
                            onChangePageSize={this.handlePageSizeChange}
                            pageSize={searchParams.pageSize}
                            pageSizeOptions={PageSizes}
                            showPageSize
                            onChange={this.handleChangePage}
                            goToLabel={intl.formatMessage({id: 'common.pages_goto'})}
                            totalCount={this.getTotalPages(this.state.searchParams.status)}
                            forcePage={this.state.searchParams.currentPage - 1}
                            initialPage={this.state.searchParams.currentPage - 1} />
                    </div>
                </div>
                }
                <Spinner isLoading={!isTranslationLoaded || isLoading} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: requestsTablePageSelectors.isLoadingSelector(state),
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, pageNames.Requests),
        requests: requestsTablePageSelectors.getRequestsSelector(state),
        totalPages: requestsTablePageSelectors.getTotalPagesSelector(state),
        totalCounts: requestsTablePageSelectors.getTotalCountsSelector(state),
        clinics: commonSelectors.getClinicsSelector(state),
        countries: commonSelectors.getCountriesSelector(state),
        doctors: commonSelectors.getDoctorsSelector(state),
        lang: langSelectors.currentLanguageSelector(state),
        unreadChatMessagesCount: commonSelectors.getUnreadChatMessagesCountSelector(state),
        user: userSelectors.userSelector(state),
        unreadChatMessagesCount: commonSelectors.getUnreadChatMessagesCountSelector(state),
    }
};

export default connect(mapStateToProps, {
    initRequestsTablePage: initRequestsTablePage,
    getRequests: getRequests,
    notifySuccess,
    notifyError,
    showConfirmModal,
})(withRouter(injectIntl(RequestTablePage)));
