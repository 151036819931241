import React, {useCallback, useContext} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {FormattedMessage, useIntl} from "react-intl";

import './RequestConfirm.scss';
import Button from '../../../../components/common/Button/Button';
import Separator from '../../../../components/common/Separator/Separator';
import {InfoPanel} from '../../../../components/InfoPanel/InfoPanel';
import {useResetWindowScroll} from '../../../../hooks/useResetWindowScroll';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';
import {useRequestCurrentLangCommunication} from '../../hooks/useRequestCurrentLangCommunication';
import {RequestDoctorCard} from '../RequestDoctorCard/RequestDoctorCard';
import {RequestInfo} from '../../../../components/request/RequestInfo/RequestInfo';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import {RequestNote} from '../RequestNote/RequestNote';
import {RequestPageHeader} from '../RequestPageHeader/RequestPageHeader';
import {SidePanel} from '../../../../components/SidePanel/SidePanel';

export const RequestConfirm = () => {
    const requestManager = useContext(RequestManagerContext);
    const requestData = useSelector(requestSelectors.getRequestData);
    const currentLang = useRequestCurrentLangCommunication();
    const intl = useIntl();
    const doctorText = _.get(requestData.text, requestData.lang);

    useResetWindowScroll();

    const handleNextPage = useCallback(() => {
        requestManager.confirmRequest();
    }, [requestManager]);

    const handleDecline = useCallback(() => {
        requestManager.cancelRequest();
    }, [requestManager]);

    return (
        <React.Fragment>
            <div className="Request-page__main Request-confirm-page">
                <RequestPageHeader title={intl.formatMessage({id: 'request.request_title'})}/>
                <div className="Request-page__body">
                    <h2 className="Request-page__title">
                        <FormattedMessage id={"request.confirm.title"}/>
                    </h2>
                    <RequestDoctorCard
                        doctor={requestData.doctor || {}}
                        isConsultTranslate={
                            _.get(currentLang, 'translate', false) ||
                            !!_.get(requestData.doctor, ['consult', 'translate'], false)
                        }
                        isSelectable={false}/>
                    {
                        doctorText &&
                        <RequestNote text={doctorText}/>
                    }
                    <Separator className="mt-2"/>
                    <RequestInfo data={requestData} currentLang={currentLang}/>
                    <Separator className="mt-3"/>
                    <div className="Flex-row Justify-center Align-center mt-5 mb-4">
                        <Button
                            className="mr-2"
                            viewType="minimal"
                            onClick={handleDecline}>
                            <FormattedMessage id={"request.buttons.refuse"}/>
                        </Button>
                        <Button onClick={handleNextPage}>
                            <FormattedMessage id={"request.buttons.confirm"}/>
                        </Button>
                    </div>
                </div>
            </div>
            <SidePanel className="Request-confirm-page__side-panel">
                <InfoPanel
                    message={intl.formatMessage({id: 'request.confirm.message'})}
                />
            </SidePanel>
        </React.Fragment>
    )
};

