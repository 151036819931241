import axios from "axios";
import apiRoutes from "../constants/ApiRoutes";

/**
 * load list of requests
 * @param {string} params.lang - lang
 * @param {boolean} params.full - true load fulled info about consult
 * @param {number} params.size - page size
 * @param {number} params.page - page number
 * @param {string} params.status - status code see consult status codes in constants
 * @return {Promise<AxiosResponse<T>>}
 */
export const loadConsults = (params = {}) => {
    return axios.get(apiRoutes.requestList, {
        params
    });
};


/**
 * get count (number) of unread chat consultations
 */
export const getUnreadConsultationsCount = () => {
    return axios.get(apiRoutes.requestChatUnread);
};
