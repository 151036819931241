import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from 'react-intl';

import {CellProps} from '../../../components/common/SimpleTable/SimpleTable.interface';

interface Props extends CellProps<{id: number}> {
	editTranslate(id: number): void;
}

const ActionsCell = React.memo<Props>(
	({
		editTranslate,
		row: {
			original: {id},
		},
	}) => {
		const intl = useIntl();

		return (
			<div className="Flex-row Justify-end">
				<FontAwesomeIcon
					onClick={() => editTranslate(id)}
					title={intl.formatMessage({id: 'common.buttons.edit'})}
					className="gray-text-color Action-icon ml-1"
					size="lg"
					icon={faPen}
				/>
			</div>
		);
	},
);

export default ActionsCell;
