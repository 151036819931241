import Immutable from 'seamless-immutable';
import {
    SET_TRANSLATES_PAGE_LOADING,
    SET_TRANSLATES_PAGE_TRANSLATES,
} from "./actions";

const INITIAL_STATE = {
    loading: false,
    translates: [],
    all_count: 0,
    all_pages: 0,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_TRANSLATES_PAGE_LOADING:
            return Immutable.merge(state, {loading: action.payload});
        case SET_TRANSLATES_PAGE_TRANSLATES:
            const {list = [], ...rest} = action.payload;
            return Immutable.merge(state, {
                translates: list,
                ...rest
            });
        default:
            return state;
    }
}
