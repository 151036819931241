import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const TabNavItem = React.forwardRef(({
                                         className,
                                         tabId,
                                         onClick,
                                         active,
                                         children,
                                         ...props
                                     }, ref) => {
    const classes = cn(
        className,
        'Health-tabs__nav-item',
        active && 'Health-tabs__nav-item--active',
    );

    return (
        <div
            {...props}
            data-tab-id={tabId}
            className={classes}
            ref={ref}
            tabIndex={0}
            onClick={(event) => onClick && onClick(event, tabId)}
            role="tab">
            {children}
        </div>
    );
});

TabNavItem.propTypes = {
    tabId: PropTypes.any,
    active: PropTypes.bool,
    onClick: PropTypes.func,
};

TabNavItem.defaultProps = {};

export default TabNavItem;
