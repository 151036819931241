import {
    REQUEST_PAGE_COMMON_SET_FILE_TYPES,
} from './actions';
import Immutable from 'seamless-immutable';

const INITIAL_STATE = {
    fileTypes: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REQUEST_PAGE_COMMON_SET_FILE_TYPES:
            return Immutable.merge(state, {
                fileTypes: action.payload,
            }, {deep: true});
        default:
            return state;
    }
}
