import {push} from 'connected-react-router';

import {CONSULTATION_PAGE_BEFORE_LOADING} from './actionTypes';
import * as consultationActions from './consultation/actions';

export const loadConsultation = ({id}) => async (dispatch, getState) => {
    try {
        dispatch({type: CONSULTATION_PAGE_BEFORE_LOADING});

        dispatch(consultationActions.setConsultationLoading(true));
        await dispatch(consultationActions.loadConsultation({id}));
    } catch (err) {
        dispatch(push('/main/requests'));
    } finally {
        dispatch(consultationActions.setConsultationLoading(false));
    }
};
