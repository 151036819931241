import React, {useCallback, useContext, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedHTMLMessage, FormattedMessage, useIntl} from "react-intl";

import './RequestConsultants.scss';
import Button from '../../../../components/common/Button/Button';
import SelectBox from '../../../../components/common/SelectBox/SelectBox';
import Separator from '../../../../components/common/Separator/Separator';
import QuickFilter from '../../../../components/QuickFilter/QuickFilter';
import {useResetWindowScroll} from '../../../../hooks/useResetWindowScroll';
import ConsultantViews from '../../constants/ConsultantViews';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import {RequestConsultantsFilter} from './components/RequestConsultantsFilter/RequestConsultantsFilter';
import {RequestConsultantsList} from './components/RequestConsultantsList/RequestConsultantsList';
import {RequestPageHeader} from '../RequestPageHeader/RequestPageHeader';
// import {FavoriteSwitcher} from './components/FavoriteSwitcher';
import * as consultantSelectors from '../../../../redux/requestPage/consultant/selectors';
import * as consultantActions from '../../../../redux/requestPage/consultant/actions';
import * as requestActions from '../../../../redux/requestPage/request/actions';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';

export const RequestConsultants = () => {
    const currentView = useSelector(consultantSelectors.getConsultantViewSelector);
    const filter = useSelector(consultantSelectors.getConsultantFilterSelector);
    const dispatch = useDispatch();
    const requestManager = useContext(RequestManagerContext);
    const requestData = useSelector(requestSelectors.getRequestData);
    const intl = useIntl();

    useResetWindowScroll();

    const viewModes = useMemo(() => {
        return [
            {
                id: ConsultantViews.Doctors,
                label: intl.formatMessage({id: 'request.view_modes.doctors'}),
            },
            {
                id: ConsultantViews.Clinics,
                label: intl.formatMessage({id: 'request.view_modes.clinics'}),
            },
        ];
    }, [intl]);

    const sortingOptions = useMemo(() => {
        return [
            {
                id: 'name',
                label: intl.formatMessage({id: 'request.sorting.by_name'}),
            },
        ];
    }, [intl]);

    /* off until impl
    const handleChangeFavorite = useCallback((favorite) => {
        dispatch(consultantActions.setConsultantFilter({
            favorite,
        }));
        dispatch(consultantActions.updateConsultants());
    }, [dispatch]);
    */

    const handleChangeSortBy = useCallback((sort) => {
        dispatch(consultantActions.setConsultantFilter({
            sortBy: sort.value,
        }));
        dispatch(consultantActions.updateConsultants());
    }, [dispatch]);

    const handleChangeViewMode = useCallback((view) => {
        dispatch(consultantActions.changeConsultantsView(view));
    }, [dispatch]);

    const handleSelectClinic = useCallback(async (clinic) => {
        try {
            requestManager.showLoader(true);
            await dispatch(requestActions.selectRequestClinic(clinic, filter.specialities));
            requestManager.goNextPage();
        } finally {
            requestManager.showLoader(false);
        }
    }, [dispatch, filter.specialities, requestManager]);

    const handleSelectDoctor = useCallback(async (doctor) => {
        try {
            requestManager.showLoader(true);
            await dispatch(requestActions.selectRequestDoctor(doctor));
            requestManager.goNextPage();
        } finally {
            requestManager.showLoader(false);
        }
    }, [dispatch, requestManager]);

    const handleHelpMe = useCallback(async () => {
        try {
            requestManager.showLoader(true);
            await dispatch(requestActions.setRequestHelpMe(filter.country || requestData.country, filter.specialities));
            requestManager.goNextPage();
        } finally {
            requestManager.showLoader(false);
        }
    }, [dispatch, filter.country, filter.specialities, requestData.country, requestManager]);

    return (
        <React.Fragment>
            <div className="Request-page__main Request-consultants-page">
                <RequestPageHeader title={intl.formatMessage({id: 'request.request_new_title'})}>
                    <div className="Request-consultants-page__filter-panel">
                        <QuickFilter
                            onChange={handleChangeViewMode}
                            className="Flex-1"
                            withoutCount
                            items={viewModes}
                            activeItem={currentView}
                        />
                        {/*
                        <FavoriteSwitcher
                            className="mr-5"
                            on={filter.favorite}
                            onChange={handleChangeFavorite}/>
                        */}
                        <div className="Request-consultants-page__sort Flex-1 Justify-end">
                            <FormattedMessage id={"request.sort_by"}/>:
                            <SelectBox
                                onChange={handleChangeSortBy}
                                className="ml-1"
                                value={filter.sortBy}
                                options={sortingOptions}
                            />
                        </div>
                    </div>
                </RequestPageHeader>
                <div className="Request-page__body">
                    <div className="Request-consultants-page__title--sticky">
                        <h2 className="Request-page__title">
                            <FormattedMessage id={"request.page_titles.select_consultant"}/>
                        </h2>
                        <div className="Flex-row mb-1">
                            <p className="Flex-1">
                                <FormattedHTMLMessage id={"request.select_consultant_hint"}/>
                            </p>
                            <Button
                                active={!!requestData.helpMe}
                                disabled={!filter.country && !requestData.country}
                                onClick={handleHelpMe}
                                className="ml-5 mr-2"
                                viewType="minimal"
                                variant="ruby">
                                <FormattedMessage id={"request.buttons.help_me"}/>
                            </Button>
                        </div>
                    </div>
                    <Separator/>
                    <div className="Flex-column mb-5">
                        <RequestConsultantsList
                            onSelectDoctor={handleSelectDoctor}
                            onSelectClinic={handleSelectClinic}/>
                    </div>
                </div>
            </div>
            <RequestConsultantsFilter/>
        </React.Fragment>
    )
};

