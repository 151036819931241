import RequestStepIds from '../../../constants/RequestStepIds';

export const stepTransitions = {
    [RequestStepIds.RequestConsultantsId]: {
        nextStepId: RequestStepIds.RequestFormId,
    },
    [RequestStepIds.RequestFormId]: {
        nextStepId: RequestStepIds.RequestCheckoutId,
        prevStepId: RequestStepIds.RequestConsultantsId,
    },
    [RequestStepIds.RequestCheckoutId]: {
        nextStepId: RequestStepIds.RequestSuccessId,
        prevStepId: RequestStepIds.RequestFormId,
    },
    [RequestStepIds.RequestSuccessId]: {},
    [RequestStepIds.RequestDeclineId]: {},
    [RequestStepIds.RequestReplacementId]: {
        nextStepId: RequestStepIds.RequestConfirmId,
        prevStepId: RequestStepIds.RequestConsultantsId,
    },
    [RequestStepIds.RequestConfirmId]: {
        nextStepId: RequestStepIds.RequestPaymentId,
    },
    [RequestStepIds.RequestPaymentId]: {
        nextStepId: RequestStepIds.RequestPaymentSuccessId,
    },
};
