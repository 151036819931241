import type {w3cwebsocket} from 'websocket';

import type { RoleCodes } from '../../constants/Roles';
import { Lang } from '../../constants/Lang';


export interface Attachment {
    // existing
    id: number;
    ext: string,
    url: string,
    label: {
        [key: string]: string
    }
    // new attachment
    file: File
    name: string
}



export interface ChatUser {
    id: number;
    url: string;
    name: string;
    rights: RoleCodes;
    short: string // shortName means
}

export enum MessageStatus {
    Read = 'readed',
    Sent = 'sended'
}


export const isMessageStatusRead =  (status: MessageStatus) => status === MessageStatus.Read;
export const isMessageStatusSent =  (status: MessageStatus) => status === MessageStatus.Sent;


export interface Message {
    id: number; // make sure
    datetime: Date;
    text: string;
    attachments: Attachment[];
    reply: Message;
    user: ChatUser;
    status: MessageStatus
    lang: Lang
}

export type SendOptions = {
    text: string;
    lang: string;
    attachments: Attachment[]
    replyId: string
};

export type EditOptions = {messageId: number, text: string};
export type JoinOptions = {chatId: string, lang: string};

type ConnectionOptions = {
    phpsession: string, 
    userId: number
}

export interface ChatInstance {
    connect(options: ConnectionOptions): void;
    send(options: SendOptions): Promise<void>;
    edit(options: EditOptions): void;
    remove(messageId: number): void,
    join(options: JoinOptions): void,
    disconnect(): void,
    markRead(messageId: number): void;
    prev(): void,

    socket: w3cwebsocket;

    messages: Message[];
    chatId: string;
    phpsession: string;
    userId: number;
    error: any;
    joining: boolean;
    joined: boolean;
    connecting: boolean;
    connected: boolean;
    cursor: any;
    hasMore: boolean;
    fetching: boolean;
    connectionError: boolean;
    lang: string;
    sending: boolean;
    reconnecting?: boolean;
    attemts?: number;
    forceClose?: boolean;

    maxAttemts?: number;
    delayReconnect?: number;
    id?: number;
    messageId?: number;
    text?: string;
}