import {createSelector} from 'reselect';
import {ROLE_CODE} from '../../constants/Roles';
import {currentLanguageSelector} from '../lang/selectors';
import Immutable from 'seamless-immutable';

export const userSelector = state => state.user;

export const userInfoLoadedSelector = createSelector(
    userSelector,
    user => user.loaded,
);

export const userRoleSelector = createSelector(
    userSelector,
    user => ROLE_CODE[user.rights],
);

export const userIdSelector = createSelector(
    userSelector,
    user => user.id,
);

export const userComLangsSelector = createSelector(
    userSelector,
    user => Immutable.asMutable(user.langs, {deep: true}),
);

export const userMainLangSelector = createSelector(
    userSelector,
    user => user.lang_main_profile,
);

export const getSimpleUserInfoSelector = createSelector(
    userSelector,
    currentLanguageSelector,
    userRoleSelector,
    (user, currentLang, role) => {
        return {
            currentLang,
            clinicName: user.clinicName,
            role,
            fio: user.fio || user.email || `User ${user.id}`,
        };
    },
);

export const userNotifications = createSelector(
    userSelector,
    user => user.notify,
);

export const getUserGeneralInfo = createSelector(
    userSelector,
    currentLanguageSelector,
    (user, lang) => {
        return {
            lang,
            notify: user.notify.reduce((obj, n) => {
                obj[n.type] = n.value;
                return obj;
            }, {}),
        }
    },
);

export const getUserPermissions = createSelector(
    userSelector,
    user => user.permissions,
);

export const canUserTranslate = createSelector(
    getUserPermissions,
    permissions => permissions.translate,
);