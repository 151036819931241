import React from "react";
import cn from "classnames";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './Drop.scss';

interface Props {
    className?: string;
    isOpen: boolean;
    disabled: boolean,
}

export const Drop = ({isOpen,disabled}: Props) => {
    return (
        <div className={cn('Health-select-box__drop', {
            'Health-select-box__drop--disabled': disabled,
        })}>
            <FontAwesomeIcon
                icon={isOpen ? faChevronUp : faChevronDown}/>
        </div>
    )
}

Drop.displayName = 'HealthSelectBoxDrop';

export default Drop;
