import React from 'react';

import {FormattedMessage} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';

import Button from '../../../components/common/Button/Button';

interface Props {
	className?: string;
	onClick: React.MouseEventHandler;
}

export const AddTranslateButton = ({className, onClick}: Props) => (
	<Button
		className={className}
		onClick={onClick}
		viewType="frameless"
		variant="gray"
	>
		<FontAwesomeIcon className="mr-05" size="lg" icon={faPlusCircle} />
		<FormattedMessage id="common.buttons.add" />
	</Button>
);
