import React, {useCallback} from 'react';
import {useIntl} from "react-intl";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import { Attachment } from '../../../../Chat.interface';

interface Props {
    file: Attachment;
    onRemove: (file: Attachment) => void;
    onOpen?: (file: Attachment) => void;
}

const FileListItem = ({onRemove, onOpen, file}: Props) => {
    const intl = useIntl();

    const handleRemove = useCallback((event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        onRemove && onRemove(file);
    }, [file, onRemove]);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            onOpen && onOpen(file);
        }
    }, [file, onOpen]);

    return (
        <div
            tabIndex={0}
            onKeyDown={handleKeyDown}
            onClick={() => onOpen && onOpen(file)}
            className="Chat-file-list__item">
            <span className="Chat-file-list__item_text" title={file['name']}>{file['name']}</span>
            <FontAwesomeIcon
                title={intl.formatMessage({id: 'common.consultation_chat.tooltip_remove_file'})}
                onClick={handleRemove}
                className='Chat-file-list__item_remove'
                icon={faTimes} />
        </div>
    )
};

FileListItem.displayName = 'ChatFileListItem';

export default FileListItem;
