import React, {useState} from 'react';
import SafeButton from "../../../components/common/SafeButton/SafeButton";
import {Form} from "react-final-form";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import TextBoxField from "../../../components/common/TextBox/TextBoxField";
import {changeEmail} from "../../../redux/user/actions";
import {EMAIL_REG_EXP} from "../../../constants/RegExps";
import {isEmptyObject} from "../../../helpers/Common";
import ErrorCodes from "../../../constants/ErrorCodes";
import Hint from "../../../components/common/Hint/Hint";

const ChangeEmail = ({changeEmail}) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const intl = useIntl();

    const onSubmit = async (values) => {
        const errors = {};
        if (!values.newemail || !EMAIL_REG_EXP.test(values.newemail)) {
            errors.newemail = intl.formatMessage({id: 'security.email_error'});
        }
        if (!values.newpass) {
            errors.newpass = intl.formatMessage({id: 'security.pass_error'});
        }
        if (!isEmptyObject(errors)) {
            return errors;
        }
        const {error, status} = await changeEmail({
            email: values.newemail,
            password: values.newpass,
        });
        if (!status) {
            if (error === ErrorCodes.WrongCurrentPassword) {
                return {
                    newpass: intl.formatMessage({id: 'security.pass_error'})
                }
            } else if (error === ErrorCodes.SameEmail) {
                return {
                    newemail: intl.formatMessage({id: 'security.email_error'})
                }
            }
        } else {
            setIsSuccess(true);
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            render={
                ((props) => (
                    <form
                        onSubmit={
                        (event) => props.handleSubmit(event).then((res) => {
                            !res && props.reset()
                        })}
                        autoComplete="off"
                        className="Flex-column Health-form"
                          noValidate>
                        <fieldset className="Health-fieldset Flex-1">
                            <legend className="Health-fieldset__legend">
                                <FormattedMessage id="security.h2_email"/>
                            </legend>
                            <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />
                            <TextBoxField
                                inline
                                type="email"
                                name="newemail"
                                autoComplete="new-email"
                                label={intl.formatMessage({id: 'security.email'})}
                                labelClass="col-4"
                                inputClass="col-6"/>
                            <TextBoxField
                                inline
                                type="password"
                                name="newpass"
                                autoComplete="new-password"
                                label={intl.formatMessage({id: 'security.current_pass'})}
                                labelClass="col-4"
                                inputClass="col-6"/>
                            <Hint><FormattedMessage id="security.email_text"/></Hint>
                        </fieldset>
                        <div className="Flex-row Justify-center Actions">
                            <SafeButton
                                viewType="minimal"
                                disabled={props.pristine && !props.dirty}
                                type="submit"
                                loading={props.submitting}
                                isSuccess={isSuccess}
                                doneText={intl.formatMessage({id: 'common.info_save'})}>
                                <FormattedMessage id="common.buttons.save"/>
                            </SafeButton>
                        </div>
                    </form>
                ))}/>
    );
};

export default connect(null, {
    changeEmail
})(ChangeEmail);
