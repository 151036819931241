import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {noop} from 'lodash';
import {FormattedMessage} from "react-intl";

import {RequestNavigationButton} from './components/RequestNavigationButton';

export const RequestNavigationPanel = React.memo(
    ({className, onPrev, onNext, onSaveAndExit, canPrev, canNext, canSaveAndExit, btnNextText}) => {
        return (
            <div className={cn('Flex-row Justify-center', className)}>
                <RequestNavigationButton
                    onClick={onPrev}
                    viewType="minimal"
                    disabled={!canPrev}>
                    <FormattedMessage id={"request.buttons.back"} />
                </RequestNavigationButton>
                <RequestNavigationButton
                    onClick={onSaveAndExit}
                    className="ml-2 mr-2"
                    disabled={!canSaveAndExit}
                    variant="gray">
                    <FormattedMessage id={"request.buttons.save_and_exit"} />
                </RequestNavigationButton>
                <RequestNavigationButton
                    onClick={onNext}
                    disabled={!canNext}>
                    {btnNextText}
                </RequestNavigationButton>
            </div>
        )
    },
);

RequestNavigationPanel.propTypes = {
    onPrev: PropTypes.func,
    onNext: PropTypes.func,
    onSaveAndExit: PropTypes.func,
    canPrev: PropTypes.bool,
    canNext: PropTypes.bool,
    canSaveAndExit: PropTypes.bool,
    btnNextText: PropTypes.string,
};

RequestNavigationPanel.defaultProps = {
    onPrev: noop,
    onNext: noop,
    onSaveAndExit: noop,
    canPrev: false,
    canNext: false,
    canSaveAndExit: false,
};
