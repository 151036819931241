import _snakeCase from 'lodash/snakeCase'
import {RequestStatuses} from '../constants/RequestStatuses';

const consultationStatuses = [
    RequestStatuses.Active,
    RequestStatuses.CompletedNeedTranslate,
    RequestStatuses.End,
];

export const isConsultation = (status) => consultationStatuses.includes(status);

export const getRequestStatusClassName = ({status, isNew = false}) => {
    let className = '';

    if (isNew) {
        return 'brand-green';
    }

    switch (status) {
        case RequestStatuses.Draft:
            className = 'gray-text-color';
            break;
        case RequestStatuses.DeclineByClinic:
        case RequestStatuses.DeclineByPatient:
            className = 'ruby-color';
            break;
        case RequestStatuses.Accepted_5:
            className = 'brand-green';
            break;
        case RequestStatuses.Active:
        case RequestStatuses.NeedTranslate:
            className = 'brand-blue';
            break;
        default:
    }

    return className;
}

const isCoordinatorConsultation = ({coordinator}) => {
	// TODO: ask backend
	return (
		coordinator &&
		coordinator.id &&
		coordinator.id > 0 &&
		coordinator.name !== ''
	);
};

const is1_2Or2_2or3_3Status = (status) =>
	[
		RequestStatuses.Clinic_2_2,
		RequestStatuses.Doctor_3_3,
		RequestStatuses.HelpMe_1_2,
	].includes(status);

const withPrefix = (role, part) =>
	`common.request_status.for_${_snakeCase(role)}_${part}`;

const forRole = (role, status) =>
	withPrefix(role, `by_default_${status}`);

const forCoordinator = (role, status) =>
	withPrefix(role, `by_coordinator_${status}`);

export const getRequestStatusTextIdByConsultation = (
	currentRole,
	data,
) => {
	const [status = 0, substatus = 0] = (data.status || '').split('.');

	// format `status_substatus` (not 0.0 etc.)
	const statusText = `${status}_${substatus}`;

	if (is1_2Or2_2or3_3Status(data.status)) {
		if (isCoordinatorConsultation(data)) {
			return forCoordinator(currentRole, statusText);
		}
	}

	if (data.status) {
		return forRole(currentRole, statusText);
	}

	return null;
};
