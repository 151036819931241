import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';
import {isCompanyClinic} from '../../helpers/Common';
import {
	isDeclinedByClinicStatus,
	RequestStatuses,
} from 'constants/RequestStatuses';
import {isDate24HoursOld} from 'libs/date/is-date-24-hours-old';

export const getRequestsTablePageSelector = state => state.requestsTablePage;

export const getRequestsSelector = createSelector(
	getRequestsTablePageSelector,
	page => {
		const records = Immutable.asMutable(page.requests, {deep: true});

		return records.map(({clinic, ...record}) => {
			const isClinic = isCompanyClinic(clinic);
			return {
				...record,
				clinic: {
					...clinic,
					isClinic,
				},
			};
		});
	},
);

export const isLoadingSelector = createSelector(
	getRequestsTablePageSelector,
	page => page.loading,
);

export const getTotalCountsSelector = createSelector(
	getRequestsTablePageSelector,
	page => ({
		all: page.all_count,
		active: page.active_count,
		end: page.end_count,
		draft: page.draft_count,
		cancel: page.cancel_count,
		consult: page.consult_count,
		active_income: page.active_income_count,
		cancel_income: page.cancel_income_count,
		new_income: page.new_income_count,
		process_income: page.process_income_count,
	}),
);

export const getTotalPagesSelector = createSelector(
	getRequestsTablePageSelector,
	page => ({
		all: page.all_pages,
		active: page.active_pages,
		end: page.end_pages,
		draft: page.draft_pages,
		cancel: page.cancel_pages,
		consult: page.consult_pages,
	}),
);


const getRequestById = createSelector(
	getRequestsTablePageSelector,
	(_, id) => id,
	(page, id) => {
		const found = page.requests.find(r => r.id === id);

		return found;
	},
);

const hasConsultationStatusChanges = createSelector(
	getRequestById,
	request => {
		const {date_edit: lastEditedDate, status} = request;

		if (
			isDeclinedByClinicStatus(status) &&
			isDate24HoursOld(lastEditedDate)
		) {
			return false;
		}

		return [
			RequestStatuses.Accepted_5_1,
			RequestStatuses.Accepted_5_2,
			RequestStatuses.Accepted_5_3,
			RequestStatuses.DeclineByClinic,
		].includes(status);
	},
);

const hasConsultationEmptyMessages = createSelector(
	getRequestById,
	(request) => {
		const {messages, status} = request;

		return status === RequestStatuses.Active && messages <= 0;
	},
);

export const hasConsultationNewUpdatesSelector = createSelector(
	hasConsultationStatusChanges,
	hasConsultationEmptyMessages,
	(hasStatusChanges, hasConsultationEmptyMsgs) => {
		return hasStatusChanges || hasConsultationEmptyMsgs;
	},
);
