import {setLoading} from '../loading/actions';
import pageNames from '../../constants/PageNames.js';
import {fetchTranslation} from "../translations/actions";
import * as api from "../../api";
import {setCurrentLanguage} from "../lang/actions";

export const initPatientRegistrationPage = (initialLang) => async (dispatch, getState) => {
    const lang = initialLang || getState().lang;

    dispatch(setLoading(true));
    dispatch(setCurrentLanguage(lang));
    await dispatch(fetchTranslation({
        lang,
        pageName: pageNames.PatientReg
    }));
    dispatch(setLoading(false));
};

export const registerPatient = (params) => async (dispatch, getState) => {
    try {
        return await api.registerPatient(getState().lang, params);
    } catch (err) {
        throw err;
    }
};
