import React from 'react';
import cn from 'classnames';
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
    className?: string;
    onClick?: React.MouseEventHandler;
}

const CloseButton = ({className, ...props}: Props) => {
    return (
        <FontAwesomeIcon className={cn(className, "Modal__button-close")}
                         {...props}
                         icon={faTimes}
                         color="#6592ab"/>
    )
};

export default CloseButton;
