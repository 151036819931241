import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {toInteger} from 'lodash';

import './MedicalRecordTablePage.scss';
import SimpleTable from '../../components/common/SimpleTable/SimpleTable';
import Button from '../../components/common/Button/Button';
import {ViewModalContextProvider} from '../../components/Contexts/ViewModalContext';
import queryParams from '../../constants/QueryParams';
import {getUrlSearchParams, setUrlSearchParams} from '../../helpers/UrlHelper';
import {despace} from '../../helpers/String';
import Pagination from '../../components/Pagination/Pagination';
import {notifySuccess, notifyError} from '../../redux/notifier/actions';
import * as translationsSelectors from '../../redux/translations/selectors';
import * as commonSelectors from '../../redux/common/selectors';
import pageNames from '../../constants/PageNames';
import {showAddOrEditMedicalRecordModal, showConfirmModal} from '../../redux/modal/actions';
import {DEFAULT_PAGE_SIZE, PageSizes, PageSizeValues} from '../../constants/PageSizes';
import PageHeader from '../../components/PageHeader/PageHeader';
import {COLUMN_NAMES} from './constants/columns';
import HeaderCell from '../../components/common/SimpleTable/cells/HeaderCell';
import Spinner from '../../components/Spinner/Spinner';
import QuickFilter from '../../components/QuickFilter/QuickFilter';
import ActionsCell from "./components/ActionsCell";
import MedicalRecordTypes from "./constants/MedicalRecordTypes";
import * as medicalRecordSelectors from '../../redux/medicalRecordTablePage/selectors';
import * as medicalRecordActions from '../../redux/medicalRecordTablePage/actions';
import {NameCell} from "./components/NameCell";
import {DateCell} from "./components/DateCell";
import {FileTypeCell} from "./components/FileTypeCell";
import {fromServerFormat} from "../../helpers/Date";
import {getFilename} from "../../helpers/Common";
import Filter from "./components/Filter";
import * as dateHelpers from "../../helpers/Date";
import {currentLanguageSelector} from "../../redux/lang/selectors";
import {MedicalFileTypeEnum} from '../../constants/FileTypeList';

const defineColumns = [
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.date"/>),
        accessor: COLUMN_NAMES.DATE,
        width: 90,
        sortable: true,
        Cell: (props) => <DateCell {...props} />,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.name"/>),
        accessor: COLUMN_NAMES.NAME,
        width: 450,
        sortable: true,
        Cell: (props) => <NameCell {...props} />,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.type"/>),
        accessor: COLUMN_NAMES.TYPE,
        width: 100,
        sortable: true,
        Cell: (props) => <FileTypeCell {...props} />,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.actions"/>),
        accessor: COLUMN_NAMES.ACTIONS,
        width: 80,
        align: 'right',
        fixedWidth: true,
        Cell: (props) => <ActionsCell {...props} />,
    },
];

class MedicalRecordTablePage extends React.Component {
    DEFAULT_SORT_FIELD = COLUMN_NAMES.DATE;
    DEFAULT_ORDER_BY = 'desc';

    constructor(props) {
        super(props);

        this.pageRef = React.createRef();
        this.state.columns = defineColumns;
    }

    static propTypes = {
        location: PropTypes.object,
        history: PropTypes.object,
        initMedicalRecordTablePage: PropTypes.func,
        getMedicalRecords: PropTypes.func,
        notifySuccess: PropTypes.func,
        notifyError: PropTypes.func,
        showConfirmModal: PropTypes.func,
        saveMedicalRecord: PropTypes.func,
        showAddOrEditMedicalRecordModal: PropTypes.func,
        removeMedicalRecord: PropTypes.func,
        uploadMedicalRecord: PropTypes.func,
        lang: PropTypes.string,
        records: PropTypes.any,
        fileTypes: PropTypes.any,
        totalPages: PropTypes.object,
        totalCounts: PropTypes.object,
        intl: PropTypes.object,
        isTranslationLoaded: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    state = {
        isInitialSortBy: false,
        isFetching: false,
        searchParams: {},
        sortBy: {
            id: this.DEFAULT_SORT_FIELD,
            desc: false,
        },
        columns: [],
    };

    componentDidMount() {
        const params = this.getSearchParams();

        this.props.initMedicalRecordTablePage();

        this.setState(() => ({
            isInitialSortBy: true,
            isFetching: true,
            searchParams: params,
            sortBy: {id: params.sortBy, desc: params.orderBy !== 'asc'},
        }), () => {
            this.updateData();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;

        if (location.search !== prevProps.location.search) {
            const params = this.getSearchParams();

            this.setState(() => ({
                isFetching: true,
                searchParams: params,
            }), () => {
                this.updateData();
            });
        }
    }

    getSearchParams() {
        const search = getUrlSearchParams(this.props.location.search);
        const currentPage = toInteger(search[queryParams.currentPage]);
        const pageSize = toInteger(search[queryParams.pageSize]);
        const type = search[queryParams.type];
        const sortBy = search[queryParams.sortBy] || this.DEFAULT_SORT_FIELD;
        const orderBy = search[queryParams.orderBy] && /(asc|desc)/.test(search[queryParams.orderBy]) ?
            search[queryParams.orderBy] : this.DEFAULT_ORDER_BY;

        let fileTypes = search[queryParams.fileTypes] ?
            search[queryParams.fileTypes].split(',').map(v => ({id: Number(v)})) : [];
        // if conclusion view without filetype -> select 77 filetype by default
        if (this.isConclusionViewActive(type) && fileTypes.length < 1) {
            fileTypes = [{id: MedicalFileTypeEnum.Conclusion}];
        }

        return {
            currentPage: currentPage > 0 ? currentPage : 1,
            pageSize: PageSizeValues.indexOf(pageSize) === -1 ? DEFAULT_PAGE_SIZE : pageSize,
            query: despace(search[queryParams.query]),
            type,
            sortBy: sortBy,
            orderBy: orderBy,
            fileTypes: fileTypes,
            fromDate: dateHelpers.fromServerFormat(search[queryParams.fromDate]),
            toDate: dateHelpers.fromServerFormat(search[queryParams.toDate]),
        };
    }

    updateData = () => {
        this.props.getMedicalRecords({
            ...this.state.searchParams,
        });
    };

    // a.k.a. tab
    isConclusionViewActive (type) {
        return type === MedicalRecordTypes.Conclusion;
    }


    handleChangeQuickFilter = (type) => {
        const {history, location} = this.props;
        const {currentPage} = this.state.searchParams;

        let params = {
            [queryParams.currentPage]: null,
            [queryParams.fileTypes]: null,
            [queryParams.type]: type === 'all' ? null : type,
        };

        if (this.isConclusionViewActive(type)) {
            params = {
                ...params,
                [queryParams.fileTypes]: MedicalFileTypeEnum.Conclusion
            };
        }

        if (type !== currentPage) {
            history.push({
                pathname: history.location.pathname,
                search: setUrlSearchParams(location.search, params)
            });
        }
    };

    handleChangePage = (page) => {
        const {history, location} = this.props;
        const currentPage = page + 1;

        if (currentPage !== this.state.searchParams.currentPage) {
            history.push({
                pathname: history.location.pathname,
                search: setUrlSearchParams(location.search, {
                    [queryParams.currentPage]: currentPage === 1 ? null : currentPage,
                }),
            });
        }
    };

    handleChangeSort = (sort) => {
        const {history, location} = this.props;
        const [{id: sortBy, desc}] = sort;

        history.push({
            pathname: history.location.pathname,
            search: setUrlSearchParams(location.search, {
                [queryParams.currentPage]: null,
                [queryParams.sortBy]: sortBy,
                [queryParams.orderBy]: desc ? 'desc' : 'asc',
            }),
        });
    };

    handlePageSizeChange = (value) => {
        const {history, location} = this.props;

        history.push({
            pathname: history.location.pathname,
            search: setUrlSearchParams(location.search, {
                [queryParams.currentPage]: null,
                [queryParams.pageSize]: value === DEFAULT_PAGE_SIZE ? null : value,
            }),
        });
    };

    applyFilter = (values) => {
        const {history, location} = this.props;

        history.push({
            pathname: history.location.pathname,
            search: setUrlSearchParams(location.search, {
                [queryParams.currentPage]: null,
                [queryParams.query]: values.query,
                [queryParams.fileTypes]: values.fileTypes.length ? values.fileTypes.map(t => t.id).join(',') : null,
                [queryParams.fromDate]: dateHelpers.toServerFormat(values.fromDate),
                [queryParams.toDate]: dateHelpers.toServerFormat(values.toDate),
            })
        });
    };

    resetFilter = () => {
        const {history, location} = this.props;

        history.push({
            pathname: history.location.pathname,
            search: setUrlSearchParams(location.search, {
                [queryParams.currentPage]: null,
                [queryParams.query]: null,
                [queryParams.fileTypes]: null,
                [queryParams.fromDate]: null,
                [queryParams.toDate]: null,
            })
        });
    };

    handleEditRecord = ({name, id, type, date}) => {
        const {
            showAddOrEditMedicalRecordModal,
            saveMedicalRecord,
            notifySuccess,
            notifyError,
            intl,
        } = this.props;

        showAddOrEditMedicalRecordModal({
            isAdd: false,
            file: {
                name,
                id,
                date: fromServerFormat(date),
                type: type.id,
            },
            onAddOrEdit: async (editedRecord) => {
                try {
                    await saveMedicalRecord(editedRecord);
                    notifySuccess(intl.formatMessage({id: 'medicalRecords.notifications.file_saved'}));
                    this.updateData();
                } catch (error) {
                    notifyError(intl.formatMessage({id: 'medicalRecords.notifications.file_save_error'}));
                }
            },
        });
    };

    handleRemoveRecord = (record) => {
        const {
            removeMedicalRecord,
            showConfirmModal,
            intl,
        } = this.props;

        showConfirmModal({
            title: intl.formatMessage({id: 'medicalRecords.remove_file_title'}, record),
            onSuccess: async () => {
                try {
                    await removeMedicalRecord(record.id);
                    notifySuccess(intl.formatMessage({id: 'medicalRecords.notifications.file_removed'}));
                    this.updateData();
                } catch (error) {
                    notifyError(intl.formatMessage({id: 'medicalRecords.notifications.file_remove_error'}));
                }
            },
            btnOk: intl.formatMessage({id: 'common.buttons.yes'}),
            btnCancel: intl.formatMessage({id: 'common.buttons.no'}),
        });
    };

    getTdProps = (cell) => {
        switch (cell.column.originalId) {
            case COLUMN_NAMES.ACTIONS:
                return {
                    className: 'text-align-right',
                };
            default:
        }
        return {};
    };

    getCellProps = (cell) => {
        switch (cell.column.originalId) {
            case COLUMN_NAMES.ACTIONS:
                return {
                    onEditRecord: this.handleEditRecord,
                    onRemoveRecord: this.handleRemoveRecord,
                };
            default:
        }
        return {}
    }

    getTotalPages = (type) => {
        return this.props.totalPages[type || 'all'];
    };

    handleUploadFile = (event) => {
        const {
            showAddOrEditMedicalRecordModal,
            uploadMedicalRecord,
            notifySuccess,
            notifyError,
            intl,
        } = this.props;
        const uploadedFile = Array.from(event.target.files)[0];

        showAddOrEditMedicalRecordModal({
            isAdd: true,
            file: {
                name: getFilename(uploadedFile.name),
                type: 0,
            },
            onAddOrEdit: async ({name, type, date}) => {
                try {
                    await uploadMedicalRecord({
                        name,
                        type,
                        date,
                        file: uploadedFile,
                    });
                    notifySuccess(intl.formatMessage({id: 'medicalRecords.notifications.file_uploaded'}));
                    this.updateData();
                } catch (error) {
                    notifyError(intl.formatMessage({id: 'medicalRecords.notifications.file_upload_error'}));
                }
            },
        });
    }

    render() {
        const {
            searchParams, isInitialSortBy, sortBy, columns,
        } = this.state;
        const {
            totalCounts, records, intl, isTranslationLoaded, isLoading,
            lang, fileTypes,
        } = this.props;

        return (
            <React.Fragment>
                {isTranslationLoaded && !isLoading &&
                <div className="Medical-record-table-page Flex-column" ref={this.pageRef}>
                    <PageHeader className="Flex-row">
                        <QuickFilter
                            className="Flex-1"
                            onChange={this.handleChangeQuickFilter}
                            items={[
                                {
                                    id: MedicalRecordTypes.All,
                                    label: intl.formatMessage({id: 'medicalRecords.short_filter.all'}),
                                    count: totalCounts[MedicalRecordTypes.All],
                                },
                                {
                                    id: MedicalRecordTypes.Files,
                                    label: intl.formatMessage({id: 'medicalRecords.short_filter.files'}),
                                    count: totalCounts[MedicalRecordTypes.All], // fix after conclusion will save to medical records
                                },
                                {
                                    id: MedicalRecordTypes.Conclusion,
                                    label: intl.formatMessage({id: 'medicalRecords.short_filter.conclusion'}),
                                    count: totalCounts[MedicalRecordTypes.Conclusion],
                                },
                            ]}
                            activeItem={searchParams.type ? searchParams.type : 'all'}/>
                        <div className="Align-center Flex-row ">
                            <Filter
                                lang={lang}
                                fileTypes={fileTypes}
                                onApplyFilter={this.applyFilter}
                                onResetFilter={this.resetFilter}
                                data={searchParams}
                                btnText={intl.formatMessage({id: 'common.buttons.filter'})}
                                containerRef={this.pageRef}

                                disableFileTagInput={this.isConclusionViewActive(searchParams.type)}
                                />
                            <Button
                                className={"button--upload"}
                                viewType="frameless"
                                variant="gray">
                                <FontAwesomeIcon size="lg" icon={faPlusCircle}/>
                                <FormattedMessage id="medicalRecords.btn_upload_file" />
                                <input
                                    title={""}
                                    type="file"
                                    value={''}
                                    accept="application/pdf, image/jpeg, image/png"
                                    onChange={this.handleUploadFile}/>
                            </Button>
                        </div>
                    </PageHeader>
                    <div className="Medical-record-table-page__table">
                        {
                            isInitialSortBy &&
                            <ViewModalContextProvider>
                                <SimpleTable
                                    initialState={{
                                        sortBy: [sortBy],
                                    }}
                                    onChangeSort={this.handleChangeSort}
                                    columns={columns}
                                    data={records}
                                    getCellProps={this.getCellProps}
                                    getTdProps={this.getTdProps}/>
                            </ViewModalContextProvider>
                        }
                    </div>
                    <div className="Flex-row Justify-center mt-4 mb-4">
                        <Pagination
                            onChangePageSize={this.handlePageSizeChange}
                            pageSize={searchParams.pageSize}
                            pageSizeOptions={PageSizes}
                            showPageSize
                            onChange={this.handleChangePage}
                            goToLabel={intl.formatMessage({id: 'common.pages_goto'})}
                            totalCount={this.getTotalPages(this.state.searchParams.type)}
                            forcePage={this.state.searchParams.currentPage - 1}
                            initialPage={this.state.searchParams.currentPage - 1}/>
                    </div>
                </div>
                }
                <Spinner isLoading={!isTranslationLoaded || isLoading}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: medicalRecordSelectors.isLoadingSelector(state),
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, pageNames.MedicalRecords),
        records: medicalRecordSelectors.getRecordsSelector(state),
        totalPages: medicalRecordSelectors.getTotalPagesSelector(state),
        totalCounts: medicalRecordSelectors.getTotalCountsSelector(state),
        error: medicalRecordSelectors.getErrorSelector(state),
        lang: currentLanguageSelector(state),
        fileTypes: commonSelectors.getMedicalFileTypesSelector(state),
    }
};

export default connect(mapStateToProps, {
    initMedicalRecordTablePage: medicalRecordActions.initMedicalRecordTablePage,
    getMedicalRecords: medicalRecordActions.getMedicalRecords,
    notifySuccess,
    notifyError,
    showConfirmModal,
    saveMedicalRecord: medicalRecordActions.saveMedicalRecord,
    showAddOrEditMedicalRecordModal,
    removeMedicalRecord: medicalRecordActions.removeMedicalRecord,
    uploadMedicalRecord: medicalRecordActions.uploadMedicalRecord,
})(withRouter(injectIntl(MedicalRecordTablePage)));
