import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';

export const getMedicalRecordsModalSelector = state => state.medicalRecordsModal;

export const getSelectedRecordsSelector = createSelector(
    getMedicalRecordsModalSelector,
    (data) => Immutable.asMutable(data.selected, {deep: true})
        .map(record => {
            return {
                ...record,
                isSelected: true,
            }
        })
)

export const getRecordsSelector = createSelector(
    getMedicalRecordsModalSelector,
    getSelectedRecordsSelector,
    (data, selected) => {
        return Immutable.asMutable(data.records, {deep: true})
            .map(record => {
                return {
                    ...record,
                    isSelected: selected.some(item => item.id === record.id),
                }
            });
    }
);

export const isLoadingSelector = createSelector(
    getMedicalRecordsModalSelector,
    data => data.loading
);

export const getErrorSelector = createSelector(
    getMedicalRecordsModalSelector,
    data => data.error
);

export const getPaginationSelector = createSelector(
    getMedicalRecordsModalSelector,
    data => ({
        count: data.count,
        pages: data.pages,
    }),
);
