import React from 'react';
import cn from "classnames";
import {useField} from "react-final-form";

import DatePicker, {Props as DatePickerProps} from "./DatePicker";

interface Props extends Partial<DatePickerProps> {
    fieldClass: string,
    containerClass: string,
    fromName: string,
    toName: string,
    label: string,
    labelClass: string,
    pickerClass: string,
    locale: string,
    format?: string,
}

const DateRangeField = React.memo<Props>(
    ({
         fieldClass, fromName, toName, label, labelClass, pickerClass, containerClass,
         locale, format, ...props
     }) => {
        const {input: {value: fromValue = '', ...fromInput}} = useField(fromName);
        const {input: {value: toValue = '', ...toInput}} = useField(toName);

        const toDatePickerProps = React.useMemo(() => ({
            selectedDays: [fromValue, {from: fromValue, to: toValue}],
            disabledDays: {after: toValue},
            numberOfMonths: 2,
            toMonth: toValue ? toValue : undefined,
            modifiers: {start: fromValue, end: toValue}
        }), [fromValue, toValue]);

        const fromDatePickerProps = React.useMemo(() => ({
            selectedDays: [fromValue, {from: fromValue, to: toValue}],
            disabledDays: {before: fromValue},
            numberOfMonths: 2,
            month: fromValue,
            fromMonth: fromValue ? fromValue : undefined,
            modifiers: {start: fromValue, end: toValue}
        }), [fromValue, toValue]);


        return (
            <div className={cn(fieldClass, "Health-form__field")}>
                <div className={containerClass}>
                    {label && <label className={cn(labelClass, 'Health-form__field-label')}>{label}</label>}
                    <DatePicker
                        locale={locale}
                        format={format}
                        className={pickerClass}
                        dayPickerProps={toDatePickerProps}
                        value={fromValue ? fromValue : null} {...fromInput}/>
                    <DatePicker
                        locale={locale}
                        format={format}
                        className={pickerClass}
                        dayPickerProps={fromDatePickerProps}
                        value={toValue ? toValue : null} {...toInput}/>
                </div>
            </div>
        );
    }
);

DateRangeField.displayName = "HealthDateRangeField";

export default DateRangeField;
