import React, {useEffect, useState} from 'react';
import {FormSpy} from 'react-final-form';
import {useDispatch} from 'react-redux';

import * as requestActions from '../../../../../../redux/requestPage/request/actions';
import {transformFormDataToRequestData} from '../../../../../../redux/requestPage/request/utils';

const AutoSave = ({values, debounce = 500}) => {
    const dispatch = useDispatch();
    const [saved, setSaved] = useState(null);

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (JSON.stringify(saved) !== JSON.stringify(values)) {
                const extracted = transformFormDataToRequestData(values);

                dispatch(requestActions.updateFormData(extracted));
                setSaved(values);
            }
        }, debounce);
        return () => clearTimeout(timerId);
    }, [debounce, dispatch, saved, values]);

    return null;
};

export const RequestFormAutoSaver = (props) => {
    return (
        <FormSpy
            {...props}
            subscription={{values: true, active: true}}
            render={AutoSave} />
    )
};
