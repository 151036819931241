import {set} from 'lodash';
import sanitizeHtml from 'sanitize-html';

export const sanitizeHtmlData = (data) => {
    return sanitizeHtml(data, {
        allowedAttributes: {
            '*': ['style'],
            a: ['href', 'name', 'target'],
            img: ['src']
        },
        allowedSchemesAppliedToAttributes: ['href', 'src', 'cite', 'style'],
    });
}

export const isEmptyObject = (obj) => Object.entries(obj).length === 0 && obj.constructor === Object;

export const hasError = ({touched, error, submitError}) =>
    touched && (error || submitError);

export const reduceFormErrors = (errors) => {
    return errors.details.reduce((obj, e) => {
        set(obj, e.path, true);
        return obj;
    }, {});
};

export const getFileExtension = (name) => {
    const lastPointIndex = name.lastIndexOf('.');
    return name.slice(lastPointIndex + 1);
};

export const getFilename = (name) => {
    const lastPointIndex = name.lastIndexOf('.');
    return name.slice(0, lastPointIndex);
};

export const transformPhotoData = (photo) => {
    const transformedPhotoData = {};
    if (photo && photo.result) {
        transformedPhotoData.photo = photo.result;
    }
    if (photo && photo.name) {
        transformedPhotoData.ext = getFileExtension(photo.name);
    }
    if (typeof photo === 'string') {
        transformedPhotoData.ext = getFileExtension(photo);
    }
    return transformedPhotoData;
};

export const isCompanyClinic = (company) => !!company && !!company.id;

export const clearDataParams = (params) => Object.keys(params).reduce((out, key) => {
    if (params[key] !== undefined && params[key] !== null && params[key] !== '') {
        out[key] = params[key];
    }
    return out;
}, {});
