import React from 'react';
import cn from 'classnames';
import {faLink, faPen, faReply, faTimes} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";

import './MessageActions.scss';
import {ROLES} from '../../../../constants/Roles';
import ButtonIcon from '../../../common/ButtonIcon/ButtonIcon';

interface Props {
    className?: string;
    onReply: () => void;
    onEdit: () => void;
    onRemove: () => void;
    onCopyAttachments: () => void;
    isReplyable: boolean;
    isEditable: boolean;
    isDeletable: boolean;
    isCopyAttachments: boolean;
    role: ROLES
}

const MessageActions = ({
        className, 
        onReply, onEdit, onRemove, onCopyAttachments,
        isReplyable = false, 
        isEditable = false,
        isDeletable = false,
        isCopyAttachments = false,
        role,
    }: Props,
) => {
    const intl = useIntl();
    const canEdit = role === ROLES.PARTNER || role === ROLES.ADMIN_CLINIC;
    const canCopy = role === ROLES.PARTNER || role === ROLES.ADMIN_CLINIC;
    const canDelete = role === ROLES.PARTNER || role === ROLES.ADMIN_CLINIC;

    return (
        <div className={cn('Health-chat__message_actions', className)}>
            {
                canEdit &&
                <ButtonIcon
                    tabIndex={-1}
                    title={intl.formatMessage({id: 'common.consultation_chat.tooltip_edit_action'})}
                    disabled={!isEditable}
                    onClick={onEdit}
                    frameless
                    icon={faPen}/>
            }
            <ButtonIcon
                tabIndex={-1}
                title={intl.formatMessage({id: 'common.consultation_chat.tooltip_reply_action'})}
                disabled={!isReplyable}
                onClick={onReply}
                frameless
                icon={faReply}/>
            {
                canCopy &&
                <ButtonIcon
                    tabIndex={-1}
                    title={intl.formatMessage({id: 'common.consultation_chat.tooltip_copy_action'})}
                    disabled={!isCopyAttachments}
                    onClick={onCopyAttachments}
                    frameless
                    icon={faLink}/>
            }
            {
                canDelete &&
                <ButtonIcon
                    tabIndex={-1}
                    title={intl.formatMessage({id: 'common.consultation_chat.tooltip_remove_action'})}
                    disabled={!isDeletable}
                    onClick={onRemove}
                    frameless
                    icon={faTimes}/>
            }
        </div>
    )
}

MessageActions.displayName = 'HealthMessageActions';

export default MessageActions;
