import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';

import './RequestConsultantsList.scss';
import Separator from '../../../../../../components/common/Separator/Separator';
import Pagination from '../../../../../../components/Pagination/Pagination';
import Spinner from '../../../../../../components/Spinner/Spinner';
import * as consultantActions from '../../../../../../redux/requestPage/consultant/actions';
import * as consultantSelectors from '../../../../../../redux/requestPage/consultant/selectors';
import * as requestSelectors from '../../../../../../redux/requestPage/request/selectors';
import ConsultantViews from '../../../../constants/ConsultantViews';
import {RequestClinicCard} from '../../../RequestClinicCard/RequestClinicCard';
import {RequestDoctorCard} from '../../../RequestDoctorCard/RequestDoctorCard';
import _ from "lodash";
import {PageSizes} from "../../../../../../constants/PageSizes";

export const RequestConsultantsList = (
    {
        className, onSelectDoctor, onSelectClinic,
    },
) => {
    const view = useSelector(consultantSelectors.getConsultantViewSelector);
    const loading = useSelector(consultantSelectors.isConsultantLoadingSelector);
    const doctorList = useSelector(consultantSelectors.getConsultantDoctorsSelector);
    const clinicList = useSelector(consultantSelectors.getConsultantClinicsSelector);
    const filter = useSelector(consultantSelectors.getConsultantFilterSelector);
    const dispatch = useDispatch();
    const {clinic: requestClinic, doctor: requestDoctor} = useSelector(requestSelectors.getRequestData);

    useEffect(() => {
        dispatch(consultantActions.updateConsultants());
    }, [dispatch]);

    const handleSelectClinic = useCallback((clinic) => {
        if (onSelectClinic) {
            onSelectClinic(clinic);
        }
    }, [onSelectClinic]);

    const handleSelectDoctor = useCallback((doctor) => {
        if (onSelectDoctor) {
            onSelectDoctor(doctor);
        }
    }, [onSelectDoctor]);

    const handlePageChange = useCallback((currentPage) => {
        dispatch(consultantActions.setConsultantFilter({
            currentPage,
        }));
        dispatch(consultantActions.updateConsultants());
    }, [dispatch]);

    const handlePageSizeChange = useCallback((pageSize) => {
        dispatch(consultantActions.setConsultantFilter({
            pageSize,
        }));
        dispatch(consultantActions.updateConsultants());
    }, [dispatch]);

    const renderItem = useCallback((item) => {
        if (view === ConsultantViews.Doctors) {
            return (
                <RequestDoctorCard
                    doctor={item}
                    onSelect={handleSelectDoctor}
                    isSelectable
                    isConsultTranslate={!!_.get(item, ['consult', 'translate'], false)}
                    isSelected={requestDoctor ? requestDoctor.id === item.id : false}
                />)
        }

        if (view === ConsultantViews.Clinics) {
            return (
                <RequestClinicCard
                    clinic={item}
                    onSelect={handleSelectClinic}
                    isSelectable
                    isConsultTranslate={!!_.get(item, ['consult', 'translate'], false)}
                    isSelected={requestClinic ? requestClinic.id === item.id : false}
                />)
        }
    }, [handleSelectClinic, handleSelectDoctor, requestClinic, requestDoctor, view]);

    const renderList = useCallback(() => {
        const {list} = view === ConsultantViews.Clinics ? clinicList : doctorList;

        return (
            <ul>
                {
                    list.map((item) => (
                        <li key={item.id}>
                            {
                                renderItem(item)
                            }
                            <Separator/>
                        </li>
                    ))
                }
            </ul>
        )
    }, [clinicList, doctorList, renderItem, view]);

    const renderPagination = useCallback(() => {
        const {all_pages, list} = view === ConsultantViews.Clinics ? clinicList : doctorList;

        if (loading && !list.length) {
            return null;
        }

        return (
            <Pagination
                pageSizeOptions={PageSizes}
                showPageSize
                onChangePageSize={handlePageSizeChange}
                onChange={handlePageChange}
                totalCount={all_pages}
                pageSize={filter.pageSize}
                initialPage={filter.currentPage}
                forcePage={filter.currentPage}/>
        )
    }, [clinicList, doctorList, filter.currentPage, filter.pageSize, handlePageChange, handlePageSizeChange, loading, view]);

    return (
        <div className={cn('Request-page__consultants-list', className)}>
            <Spinner isLoading={loading}/>
            {renderList()}
            <div className="Flex-row Justify-center mt-3">
                {renderPagination()}
            </div>
        </div>
    )
};

RequestConsultantsList.propTypes = {
    onSelectDoctor: PropTypes.func,
    onSelectClinic: PropTypes.func,
};
