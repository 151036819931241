import {
    SET_VIEW_DOCTOR_LOADING,
    CLEAR_VIEW_DOCTOR_DATA,
    SET_VIEW_DOCTOR_ERROR,
    SET_VIEW_DOCTOR_DATA,
} from './actions';
import Immutable from 'seamless-immutable';

const INITIAL_STATE = {
    loading: false,
    doctor: null,
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_VIEW_DOCTOR_LOADING:
            return Immutable.merge(state, {loading: action.payload});
        case CLEAR_VIEW_DOCTOR_DATA:
            return Immutable.merge(state, INITIAL_STATE);
        case SET_VIEW_DOCTOR_ERROR:
            return Immutable.merge(state, {error: action.payload});
        case SET_VIEW_DOCTOR_DATA:
            return Immutable.merge(state, {doctor: action.payload});
        default:
            return state;
    }
}
