import React from 'react';
import PropTypes from 'prop-types';

import SelectTagListItem from '../../../../../../components/common/SelectTagList/SelectTagListItem';

const specialStar = '*';

const LanguageTagItem = (
    {option, label, ...props},
) => {
    const {translate} = option;
    return (
        <SelectTagListItem
            {...props}
            label={translate ? `${label} ${specialStar}` : label} />
    )
};

LanguageTagItem.propTypes = {
    disabled: PropTypes.bool,
    onToggle: PropTypes.func,
    isSelected: PropTypes.bool,
    label: PropTypes.string,
    id: PropTypes.any,
    option: PropTypes.object,
};
LanguageTagItem.defaultProps = {
    disabled: false,
    isSelected: false,
};
LanguageTagItem.displayName = 'HealthLanguageTagItem';

export default LanguageTagItem;
