import React from 'react';

import './MessageAttachments.scss';
import MessageAttachment from './components/MessageAttachment';
import { Attachment } from '../../Chat.interface';

interface Props {
    attachments: Attachment[];
    onClick?: (file: Attachment) => void;
}

const MessageAttachments = ({attachments = [], onClick}: Props) => {
    if (!attachments || !attachments.length) {
        return null;
    }

    return (
        <div className="Health-chat__message-attachments">
            {
                attachments.map(attachment => (
                    <MessageAttachment
                        onClick={onClick}
                        key={attachment.id}
                        attachment={attachment} />
                ))
            }
        </div>
    )
}

MessageAttachments.displayName = 'ChatMessageAttachments';

export default MessageAttachments;
