import React from 'react';
import type {CaptionElementProps} from 'react-day-picker';

import {getRangeOfYears} from 'libs/date/get-range-of-years';

import {DatePickerSelect} from './DatePickerSelect';

interface Props extends Partial<CaptionElementProps> {
	onChange(newDate: Date): void;
}


// TODO: WARN: Nested <form
export const YearMonthControl = ({
	date,
	locale,
	localeUtils,
	onChange,
}: Props) => {
	const years = React.useMemo(getRangeOfYears, []);
	const months = localeUtils.getMonths(locale);

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const {year, month} = e.target.form;
		
		onChange(new Date(year.value, month.value));
	};
	
	return (
		<form className="DayPicker-Caption">
			<DatePickerSelect<string>
				name="month"
				useIndexAsValue
				list={months}
				onChange={handleChange}
				value={date.getMonth()}
			/>

			<DatePickerSelect<number>
				name="year"
				list={years}
				onChange={handleChange}
				value={date.getFullYear()}
			/>
		</form>
	);
};
