import React from 'react';

import FileIcon from "../../../components/common/FileIcon/FileIcon";

export const NameCell = ({row: {original: {type, file, ext}}, cell: {value}}) => {

    if (file) {
        return (
            <div className="Flex-row Align-center">
                <a
                    className="link-text"
                    rel="noreferrer noopener"
                    target="_blank"
                    href={file}>
                    <FileIcon
                        ext={ext}
                        className="brand-gray"/>
                    <span className="ml-1" title={value || file}>{value || '-'}</span>
                </a>
            </div>
        )
    }

    // conclusion
    return (
        <span>{value}</span>
    )
};
