import pageNames from '../../constants/PageNames.js';
import {fetchTranslation} from "../translations/actions";
import * as commonActions from "../common/actions";
import * as api from "../../api";

export const SET_OVERVIEW_PAGE_NOTIFICATIONS = 'SET_OVERVIEW_PAGE_NOTIFICATIONS';

export const initOverviewPage = () => async (dispatch, getState) => {
    await Promise.all([
        dispatch(fetchTranslation({
            pageName: pageNames.Overview
        })),
        dispatch(loadNotifications()),
        dispatch(commonActions.fetchMedicalFileTypes()),
    ]);
};

export const loadNotifications = () => async (dispatch) => {
    const params = {
        overview: true,
        sortBy: 'date_edit',
        orderBy: 'desc',
    };

    try {
        const res = await api.loadConsults(params);

        dispatch({
            type: SET_OVERVIEW_PAGE_NOTIFICATIONS,
            payload: {
                notifications: res.data.list,
                statistics: {
                    active: res.data.active_count,
                    income: res.data.income_count,
                    all: res.data.all_count,
                    draft: res.data.draft_count,
                    end: res.data.end_count,
                    consult: res.data.consult_count,
                }
            }
        })
    } catch (err) {
    }
};
