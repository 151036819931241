import React from 'react';

import CommonDateCell from "../../../components/common/SimpleTable/cells/DateCell";

export const DateCell = (props) => {
    if(!props.cell.value){
        return <span>-</span>
    }

    return (
        <CommonDateCell {...props}/>
    )
};
