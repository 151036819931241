import {useCallback} from "react";

export const useLoadFiles = () => {
    return useCallback((files) => {
        return Promise.all(files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                    resolve({
                        file,
                        name: file.name,
                        value: reader.result,
                    });
                };

                reader.onerror = (error) => {
                    reject({error});
                }

                try {
                    reader.readAsDataURL(file);
                } catch (error) {
                    reject({error});
                }
            });
        }));
    }, []);
}
