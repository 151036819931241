import React from 'react';

interface _Props<T extends string | number> {
	name: string;
	list: T[];
    // should be constant
	useIndexAsValue?: boolean;
	onChange: React.ChangeEventHandler;
	value: number;
}

export const DatePickerSelect = <T extends string | number>({
	onChange,
	value,
	name,
	list,
	useIndexAsValue,
}: _Props<T>) => (
	<select
		className="DayPicker-select"
		name={name}
		onChange={onChange}
		value={value}
	>
		{list.map((listItem, i) => (
			<option key={i} value={useIndexAsValue ? i : listItem}>
				{listItem}
			</option>
		))}
	</select>
);
