import React from 'react';
import {notifyError, notifySuccess} from 'redux/notifier/actions';

import {useAppDispatch} from './use-app-dispatch';

export const useNotifier = () => {
	const dispatch = useAppDispatch();

	const _notifyError = React.useCallback(
		(v: string) => dispatch(notifyError(v)),
		[dispatch],
	);

	const _notifySuccess = React.useCallback(
		(v: string) => dispatch(notifySuccess(v)),
		[dispatch],
	);

	return {
		notifyError: _notifyError,
		notifySuccess: _notifySuccess,
	};
};
