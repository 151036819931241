import React, {useEffect, useState} from 'react';
import Joi from '@hapi/joi';
import {set} from 'lodash';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {isEmptyObject} from '../../helpers/Common';
import {RegistrationResult} from '../../components/registration/RegistrationResult/RegistrationResult';
import {RegistrationPageLayout} from '../../components/registration/RegistrationPageLayout/RegistrationPageLayout';
import {setLoading} from '../../redux/loading/actions';
import {loadingSelector} from '../../redux/loading/selectors';
import {
	initTranslatedRememberPassPage,
	rememberPassword,
	RememberPasswordError,
} from '../../redux/rememberPassPage/actions';
import {isPageLoadedSelector} from '../../redux/translations/selectors';
import PageNames from '../../constants/PageNames';
import RememberPasswordForm from './components/RememberPasswordForm';
import {currentLanguageSelector} from '../../redux/lang/selectors';
import { useNotifier } from 'hooks/use-notifier';

const buildSchema = ({errors}) =>
	Joi.object({
		email: Joi.string()
			.email({
				tlds: false,
			})
			.messages({
				'any.required': errors.email_error,
			})
			.required(),
	}).unknown();

export const RememberPasswordPage = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const isLoading = useSelector(loadingSelector);
	const lang = useSelector(currentLanguageSelector);
	const isTranslationLoaded = useSelector(state =>
        // @ts-expect-error wrong type
		isPageLoadedSelector(state, PageNames.RememberPassword),
	);
	const {notifyError} = useNotifier();
	const [status, setStatus] = useState('not_submitted');

	useEffect(() => {
		dispatch(initTranslatedRememberPassPage());
	}, [dispatch]);


	const showErrorNotification = (data) => {
		switch (data.error) {
			case RememberPasswordError.ConfirmationError: {
				notifyError(
					intl.formatMessage({id: 'remPass.confirmation_error'}),
				);
				break;
			}
			default: {
				notifyError(intl.formatMessage({id: 'remPass.unknown_error'}));
			}
		}
	};

	const onSubmit = async values => {
		const errors = {};
		const schema = buildSchema({
			errors: {
				email_error: intl.formatMessage({id: 'remPass.email_error'}),
			},
		});
		const {error, value} = schema.validate(values, {
			abortEarly: false,
		});
		if (error) {
			error.details.reduce((obj, e) => {
				set(obj, e.path, e.message);
				return obj;
			}, errors);
		}
		if (!values.captcha) {
			errors.captcha = '';
		}
		if (!isEmptyObject(errors)) {
			return errors;
		}
		dispatch(setLoading(true));
		try {
			const response = await dispatch(
				rememberPassword(value.email, value.captcha),
			);
			setStatus(response.data);
		} catch (e) {
			if (e.data) {
				showErrorNotification(e.data);
			}

			throw e;
		} finally {
			dispatch(setLoading(false));
		}
	};

	const renderBody = () => {
		if (status === 'not_submitted') {
			return (
				<RememberPasswordForm
					redirectTo="/login"
					lang={lang}
					onSubmit={onSubmit}
				/>
			);
		} else {
			return (
				<RegistrationResult
					status={status.status}
					title={
						status.status ? 'remPass.ok_head' : 'remPass.error_head'
					}
					description="remPass.ok_text"
					btnGotoMain="remPass.but_back_main"
					redirectTo="/login"
				/>
			);
		}
	};

	return (
		<RegistrationPageLayout
			className="Remember-password"
			isLoading={isLoading}
			isTranslationLoaded={isTranslationLoaded}
			title="remPass.h1"
			copyright="remPass.copyright"
			linksConf="remPass.links_conf"
		>
			{renderBody()}
		</RegistrationPageLayout>
	);
};
