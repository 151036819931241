import React, {useState} from 'react';
import SafeButton from "../../../components/common/SafeButton/SafeButton";
import {Form} from "react-final-form";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import TextBoxField from "../../../components/common/TextBox/TextBoxField";
import {removeAccountSubmit} from "../../../redux/user/actions";
import ErrorCodes from "../../../constants/ErrorCodes";
import Hint from "../../../components/common/Hint/Hint";

const RemoveAccount = ({removeAccountSubmit}) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const intl = useIntl();

    const onSubmit = async (values) => {
        if (!values.curpassword) {
            return {
                curpassword: intl.formatMessage({id: 'security.pass_error'})
            };
        }
        const {error, status} = await removeAccountSubmit(values.curpassword);
        if (!status) {
            if (error === ErrorCodes.WrongCurrentPassword) {
                return {
                    curpassword: intl.formatMessage({id: 'security.pass_error'})
                }
            }
        } else {
            setIsSuccess(true);
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            render={
                ((props) => (
                    <form onSubmit={
                        (event) => props.handleSubmit(event).then((res) => {
                            !res && props.reset()
                        })} autoComplete="off" className="Flex-column Health-form Remove-account"
                          noValidate>
                        <fieldset className="Health-fieldset Flex-1">
                            <legend className="Health-fieldset__legend">
                                <FormattedMessage id="security.h2_del"/>
                            </legend>
                            <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />
                            <Hint><FormattedMessage id="security.del_text"/></Hint>
                            <TextBoxField
                                inline
                                type="password"
                                name="curpassword"
                                autoComplete="new-password"
                                label={intl.formatMessage({id: 'security.current_pass'})}
                                labelClass="col-4"
                                inputClass="col-6"/>
                        </fieldset>
                        <div className="Flex-row Justify-center Actions">
                            <SafeButton
                                viewType="minimal"
                                variant="ruby"
                                disabled={props.pristine && !props.dirty}
                                type="submit"
                                loading={props.submitting}
                                isSuccess={isSuccess}
                                doneText={intl.formatMessage({id: 'common.info_send'})}>
                                <FormattedMessage id="common.buttons.send"/>
                            </SafeButton>
                        </div>
                    </form>
                ))}/>
    );
};

export default connect(null, {
    removeAccountSubmit
})(RemoveAccount);
