import confirmRegistrationReducer from './confirmRegistration/reducer';
import loadingReducer from './loading/reducer';
import sessionReducer from './session/reducer';
import userReducer from './user/reducer';
import commonReducer from './common/reducer';
import translationsReducer from './translations/reducer';
import langReducer from './lang/reducer';
import modalReducer from './modal/reducer';
import viewClinicReducer from './viewClinic/reducer';
import viewDoctorReducer from './viewDoctor/reducer';
import accountPageReducer from './accountPage/reducer';
import profilePageReducer from './profilePage/reducer';
import requestsTablePageReducer from './requestsTablePage/reducer';
import requestPageReducer from './requestPage/reducer';
import consultationPageReducer from './consultationPage/reducer';
import viewConclusionReducer from './viewConclusion/reducer';
import medicalRecordTablePageReducer from './medicalRecordTablePage/reducer';
import medicalRecordsModalReducer from './medicalRecordsModal/reducer';
import overviewPageReducer from './overviewPage/reducer';
import translatesPageReducer from './translatesPage/reducer';

export default {
    viewDoctor: viewDoctorReducer,
    viewClinic: viewClinicReducer,
    confirmRegistration: confirmRegistrationReducer,
    loading: loadingReducer,
    lang: langReducer,
    user: userReducer,
    common: commonReducer,
    session: sessionReducer,
    translations: translationsReducer,
    translatesPage: translatesPageReducer,
    modal: modalReducer,
    accountPage: accountPageReducer,
    profilePage: profilePageReducer,
    requestsTablePage: requestsTablePageReducer,
    requestPage: requestPageReducer,
    consultationPage: consultationPageReducer,
    viewConclusion: viewConclusionReducer,
    medicalRecordTablePage: medicalRecordTablePageReducer,
    medicalRecordsModal: medicalRecordsModalReducer,
    overviewPage: overviewPageReducer,
};
