import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Form} from "react-final-form";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

import TextBoxField from "../../common/TextBox/TextBoxField";

const SearchTextBox = ({onSearch, onClear, query, ...props}) => {
    const handleSubmit = useCallback((values) => {
        const query = (values.query || '').trim();
        if (query) {
            onSearch && onSearch(query);
        } else {
            onClear && onClear();
        }
    }, [onClear, onSearch]);

    const handleClear = useCallback(() => {
        onClear && onClear();
    }, [onClear])

    return (
        <Form
            initialValues={{
                query: query || '',
            }}
            onSubmit={handleSubmit}>
            {
                (form) => (
                    <form className="Flex-column Flex-1" noValidate onSubmit={form.handleSubmit} autoComplete="off">
                        <TextBoxField
                            icon={query ? faTimes : undefined}
                            iconClick={handleClear}
                            name="query"
                            {...props}/>
                    </form>
                )
            }
        </Form>
    )
};

SearchTextBox.propTypes = {
    onSearch: PropTypes.func,
    onClear: PropTypes.func,
    query: PropTypes.string,
}

export default SearchTextBox;
