import React, {useCallback} from 'react';
import {faUserMd, faUserNinja} from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import {useIntl} from "react-intl";

import './MessageAvatar.scss';
import LocationStateFroms from '../../../../constants/LocationStateFroms';
import {ROLES, toUserRole} from '../../../../constants/Roles';
import ViewDoctorLink from '../../../Links/ViewDoctorLink';
import Photo from '../../../Photo/Photo';
import AvatarText from "../../../AvatarText/AvatarText";
import { ChatUser } from '../../Chat.interface';

interface Props {
    className?: string;
    user: ChatUser;
    isMe: boolean;
}

const MessageAvatar = ({className, user = {} as ChatUser, isMe = false}: Props) => {
    const intl = useIntl();
    const userRole = toUserRole(user.rights);

    const renderAvatarText = useCallback(() => (
        <AvatarText
            className={cn('Health-chat__message-avatar', {
                'Health-chat__message-avatar--me': isMe
            }, className)}
            title={user.name}
            shortName={isMe?intl.formatMessage({id: "common.consultation_chat.me"}):user.short} />
    ), [className, intl, isMe, user.name, user.short]);

    if (!isMe && userRole === ROLES.DOCTOR && user.id) {
        return (
            <ViewDoctorLink
                className="Health-chat__message-avatar-link"
                id={user.id}
                //  @ts-ignore
                from={LocationStateFroms.chat}>
                {
                    user.url ?
                        <Photo
                            avatar
                            title={user.name}
                            className={cn("Health-chat__message-avatar", className)}
                            fallbackIcon={faUserMd}
                            fallbackIconSize={'2x'}
                            imageClassName="img-fluid figure__img" src={user.url}/>
                        :
                        renderAvatarText()
                }
            </ViewDoctorLink>
        )
    }

    if (!isMe && userRole === ROLES.PARTNER) {
        return (
            user.url ?
                <Photo
                    avatar
                    title={user.name}
                    className={cn("Health-chat__message-avatar", className)}
                    fallbackIcon={faUserNinja}
                    fallbackIconSize={'2x'}
                    imageClassName="img-fluid figure__img" src={user.url}/>
                :
                renderAvatarText()
        )
    }

    return (
        <React.Fragment>
            {renderAvatarText()}
        </React.Fragment>
    )
};


export default MessageAvatar;
