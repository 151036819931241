import React, {useCallback, useContext} from 'react';
import PropTypes from 'prop-types';
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './RequestSuccess.scss';
import Button from '../../../../components/common/Button/Button';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import {FormattedMessage} from "react-intl";

export const RequestSuccess = ({title, message}) => {
    const requestManager = useContext(RequestManagerContext);

    const handleOk = useCallback(() => {
        requestManager.goRequestTable();
    }, [requestManager]);

    return (
        <div className="Request-page__main Request-success-page">
            <FontAwesomeIcon
                className="Align-self-center mb-3"
                icon={faCheckCircle} size="4x"/>
            <h2 className="Request-success-page__title">
                {title}
            </h2>
            <div
                dangerouslySetInnerHTML={{__html: message}}
                className="Request-success-page__message" />
            <Button onClick={handleOk}>
                <span className="text-uppercase">
                    <FormattedMessage id="common.buttons.ok" defaultMessage={"OK"}/>
                </span>
            </Button>
        </div>
    )
};

RequestSuccess.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
};
