import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import './Price.scss';
import {FormattedNumber} from "react-intl";

const Price = ({className, currency, value, leftPosition, size}) => {
    return (
        <div className={cn('Health-price', className, {
            'Health-price--small': size === 'sm',
            'Health-price--large': size === 'lg',
        })}>
            {
                leftPosition && <span className="Health-price__currency">{currency}&nbsp;</span>
            }
            <span className="Health-price__price">
                <FormattedNumber value={value} />
            </span>
            {!leftPosition && <span className="Health-price__currency">&nbsp;{currency}</span>}
        </div>
    )
}

Price.displayName = "HealthPrice";
Price.propTypes = {
    currency: PropTypes.string,
    value: PropTypes.number,
    leftPosition: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'lg', null]),
};
Price.defaultProps = {
    leftPosition: true,
};

export default Price;
