import React, {useCallback} from 'react';
import cn from 'classnames';

import './FileList.scss';
import FileListItem from './FileListItem';
import { Attachment } from '../../../../Chat.interface';


interface Props {
    className?: string;
    files: Attachment[];
    onRemove: (file: Attachment) => void;
    containerRef?: React.RefObject<HTMLDivElement>,
}

const FileList = ({
                      className,
                      containerRef,
                      files = [],
                      onRemove,
                  }: Props) => {
    const handleRemove = useCallback((file: Attachment) => {
        onRemove && onRemove(file);
    }, [onRemove]);

    return (
        <div
            ref={containerRef}
            className={cn('Chat-file-list', className)}>
            {
                files.map((file, index) => (
                    <FileListItem
                        key={index}
                        onRemove={handleRemove}
                        file={file} />))
            }
        </div>
    )
}


FileList.displayName = 'HealthFileList';

export default FileList;
