export default {
    saveChangesModal: 'saveChangesModal',
    confirmModal: 'confirmModal',
    viewClinicDetailsModal: 'viewClinicDetailsModal',
    viewDoctorDetailsModal: 'viewDoctorDetailsModal',
    attachFilesModal: 'attachFilesModal',
    editTranslateModal: 'editTranslateModal',
    addOrEditMedicalRecordModal: 'addOrEditMedicalRecordModal',
    addFilesFromMedicalRecordsModal: 'addFilesFromMedicalRecordsModal',
    previewConclusionModal: 'previewConclusionModal',
    editLanguageModal: 'editLanguageModal',
    editCountryModal: 'editCountryModal',
    editSpecialtyModal: 'editSpecialtyModal',
    privacyPolicyModal: 'privacyPolicyModal',
    userAgreementModal: 'userAgreementModal'
}
