import React from "react";
import { useSelector } from "react-redux";
import _delay from "lodash/delay";

import * as userSelectors from "redux/user/selectors";
import * as userActions from "redux/user/actions";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { notifyWarning } from "redux/notifier/actions";

import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import PageName from "constants/PageNames";


const SECONDS_15 = 1000 * 15;

// Если пользователь зарегистрировался по короткому пути (без указания ФИО и т.п.)
// То напомним ему об заполнении обязательных полей в профиле

export const useCompleteProfileReminder = () => {
  const user = useSelector(userSelectors.userSelector);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isShowOnlyOnce = React.useRef(false);

  const DescriptionComponent = React.useMemo(
    () => (
      <p
        onClick={() => history.push(PageName.Profile)}
        style={{ cursor: "pointer" }}
      >
        <FormattedMessage id="common.advices.for_working_optimization" />{" "}
        <u>
          <FormattedMessage id="common.advices.fill_profile" />
        </u>
      </p>
    ),
    []
  );

  React.useEffect(() => {
    dispatch(userActions.fetchUserInfo);
  }, []);

  React.useEffect(() => {

    if (!user.loaded || user.fio) {
      return;
    }

    // guard, to show only once per session
    if (isShowOnlyOnce.current) {
      return;
    }

    const timerId = _delay(() => {
      dispatch(
        notifyWarning("", DescriptionComponent, {
          timeOut: SECONDS_15,
        })
      );
    }, 1000);

    isShowOnlyOnce.current = true;

    return () => clearTimeout(timerId)
  }, [user]);
};