import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';
import {getMedicalFileTypesSelector} from "../common/selectors";

export const getMedicalRecordTablePageSelector = state => state.medicalRecordTablePage;

export const getRecordsSelector = createSelector(
    getMedicalRecordTablePageSelector,
    getMedicalFileTypesSelector,
    (page, fileTypes) => {
        return Immutable.asMutable(page.records, {deep: true})
            .map(record => {
                return {
                    ...record,
                    type: fileTypes.find(filetype => filetype.id === record.type) || {
                        id: record.type,
                        label: '-',
                    }
                }
            });
    }
);

export const isLoadingSelector = createSelector(
    getMedicalRecordTablePageSelector,
    page => page.loading
);

export const getErrorSelector = createSelector(
    getMedicalRecordTablePageSelector,
    page => page.error
);

export const getTotalCountsSelector = createSelector(
    getMedicalRecordTablePageSelector,
    page => ({
        all: page.all_count,
        conclusion: page.conc_count, // conclusion_count,
        files: page.files_count,
    })
);

export const getTotalPagesSelector = createSelector(
    getMedicalRecordTablePageSelector,
    page => ({
        all: page.all_pages,
        conclusion: page.conc_pages,
        files: page.files_pages,
    })
);

export const getConsultById = createSelector(
    getRecordsSelector,
    (_, id) => id,
    (page, id) => {
        const index = page.requests.findIndex(c => c.id === id);

        return index !== -1 ? Immutable.asObject(Immutable.getIn(['records', index])) : null;
    }
);
