import {LOCATION_CHANGE} from 'connected-react-router';
import {SHOW_MODAL, HIDE_MODAL} from './actions';
import Immutable from "seamless-immutable";

const initialState = {
    type: null,
    params: null,
    className: null,
    shouldCloseOnOverlayClick: true,
    showCloseButton: true,
    modalsQueue: [],
    isModalFromQueue: false,
    isWindow: false,
};

const hideModal = (state) => Immutable.merge(state, initialState);

const getModalState = (payload = initialState) => ({
    type: payload.type,
    params: payload.params,
    className: payload.className,
    shouldCloseOnOverlayClick: payload.shouldCloseOnOverlayClick,
    showCloseButton: payload.showCloseButton,
    isModalFromQueue: payload.isModalFromQueue,
    isWindow: payload.isWindow,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            if (state.type !== initialState.type && action.payload.queueProcessing) {
                const {modalsQueue, ...rest} = state;
                return Immutable.merge(state, {
                    ...getModalState({
                        ...action.payload,
                        isModalFromQueue: true,
                    }),
                    modalsQueue: [
                        ...state.modalsQueue,
                        rest,
                    ]
                });
            }
            return Immutable.merge(state,
                getModalState({
                    ...action.payload,
                    isModalFromQueue: false
                })
            );
        case HIDE_MODAL:
            if (state.modalsQueue.length) {
                const modalPayload = state.modalsQueue[0];

                return Immutable.merge(state, {
                    ...modalPayload,
                    modalsQueue: state.modalsQueue.slice(1)
                });
            }
            return hideModal(state);
        case LOCATION_CHANGE:
            return state.type && !state.isModalFromQueue ? hideModal(state) : state;
        default:
            return state;
    }
}
