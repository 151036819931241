export const RequestsQuickFilterStatuses = {
    Draft: 'draft',
    Active: 'active',
    Completed: 'end',
    Cancel: 'cancel',
    Consult: 'consult',
    ActiveIncome: 'active_income',
    CancelIncome: 'cancel_income',
    NewIncome: 'new_income',
    ProcessIncome: 'process_income',
};

export const RequestsQuickFilterCodes = {
    '0': RequestsQuickFilterStatuses.Draft,
    '1': RequestsQuickFilterStatuses.Active,
    '6': RequestsQuickFilterStatuses.Cancel,
    '9': RequestsQuickFilterStatuses.Completed,
    '7': RequestsQuickFilterStatuses.Consult,
};

export const RequestsQuickFilterStatusCode = {
    [RequestsQuickFilterStatuses.Draft]: 0,
    [RequestsQuickFilterStatuses.Active]: 1,
    [RequestsQuickFilterStatuses.Cancel]: 6,
    [RequestsQuickFilterStatuses.Completed]: 9,
    [RequestsQuickFilterStatuses.Consult]: 7,
};

export const toRequestsQuickFilterStatusCode = (status) => RequestsQuickFilterStatusCode[status];
export const toRequestsQuickFilterStatus = (code) => RequestsQuickFilterCodes[`${code}`];
