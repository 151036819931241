import React, {useCallback, useContext} from "react";

import Button from "../../../../components/common/Button/Button";
import {RequestManagerContext} from "../RequestManager/RequestManagerContext";
import {FormattedMessage} from "react-intl";

const DeclineRequestButton = (props) => {
    const requestManager = useContext(RequestManagerContext);

    const handleDecline = useCallback(() => {
        requestManager.cancelRequest();
    }, [requestManager]);

    return (
        <Button
            {...props}
            viewType="minimal"
            onClick={handleDecline}>
            <FormattedMessage id="request.buttons.decline_request" defaultMessage={"Decline request"}/>
        </Button>
    )
}

DeclineRequestButton.displayName = 'DeclineRequestButton';

export default DeclineRequestButton;

