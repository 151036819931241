import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const SelectTagListItem = (
    {disabled, onToggle, isSelected, label},
) => {
    const classes = cn('Health-select-tag-list__item', {
        'Health-select-tag-list__item--disabled': disabled,
        'Health-select-tag-list__item--selected': isSelected,
    });

    const handleKeyDown = useCallback((event) => {
        if(disabled){
            return ;
        }
        if (event.key === 'Enter') {
            onToggle && onToggle();
        }
    }, [disabled, onToggle]);

    return (
        <div
            tabIndex={0}
            onClick={onToggle}
            onKeyDown={handleKeyDown}
            className={classes}>
            <span className="Health-select-tag-list__item_text">{label}</span>
        </div>
    )
};

SelectTagListItem.propTypes = {
    disabled: PropTypes.bool,
    onToggle: PropTypes.func,
    isSelected: PropTypes.bool,
    label: PropTypes.string,
    id: PropTypes.any,
};
SelectTagListItem.defaultProps = {
    disabled: false,
    isSelected: false,
};
SelectTagListItem.displayName = 'HealthSelectTagListItem';

export default SelectTagListItem;
