import React from "react";
import {Field} from "react-final-form";
import cn from "classnames";

import {hasError} from "../../../helpers/Common";
import SelectBox, {Props as SelectBoxProps} from "./SelectBox";

interface Props<T> extends SelectBoxProps<T> {
    name: string;
    fieldClassName?: string,
    fieldBodyClassName?: string,
    label?: string,
    labelClassName?: string,
    inline?: boolean,
    rightSide?: React.ReactNode;
    labelField?: string;
    idField?: string;
}

const SelectBoxField = <T,>({
         name,
         label,
         labelClassName,
         inline,
         fieldClassName,
         fieldBodyClassName,
         onChange,
         rightSide,
         ...props
     }: Props<T>, _ref) => {
        return (
            <Field
                name={name}
                render={
                    ({input: {onChange: onChangeInput, ...inputProps}, meta}) => {
                        // @ts-ignore
                        const error = hasError(meta) ? (meta.error || meta.submitError) : undefined;
                        const isErrorBool = typeof error === 'boolean';
                        const labelClass = cn(
                            labelClassName,
                            'Health-select-box__label',
                            inline && 'Health-select-box__label--inline'
                        );
                        const fieldClass = cn(
                            fieldClassName, 'Health-form__field',
                        );
                        // eslint-disable-next-line
                        const onChangeValue = React.useCallback((value) => {
                            onChangeInput(value);
                            if (onChange) {
                                onChange(value);
                            }
                            // eslint-disable-next-line
                        }, [onChangeInput, onChange]);

                        return (
                            <div className={fieldClass}>
                                {label && <label className={labelClass}>{label}</label>}
                                <div className={cn(fieldBodyClassName, 'Flex-column')}>
                                    <SelectBox<T>
                                        hasError={isErrorBool}
                                        {...props}
                                        {...inputProps}
                                        onChange={onChangeValue}/>
                                </div>
                                {rightSide}
                            </div>
                        )
                    }
                }
                type="selectbox"/>
        )
    };

SelectBoxField.displayName = '';

export default React.forwardRef(SelectBoxField) as typeof SelectBoxField;
