import React from 'react';
import {useIntl} from 'react-intl';

import CloseButton from '../components/CloseButton/CloseButton';

interface Props {
	onClose: VoidFunction;
	hideModal: VoidFunction;
}

const PrivacyPolicyModal = React.memo<Props>(({onClose, hideModal}) => {
	const intl = useIntl();

	const handleClose = React.useCallback(() => {
		onClose && onClose();
		hideModal();
	}, [hideModal, onClose]);

	// TODO: почему одно сообщение имеет три разных источника на разных страницах
	const name = 'app_agreements.privacy_policy';
	const html =
		intl.messages[`main.${name}`] ||
		intl.messages[`login.${name}`] ||
		intl.messages[`patientReg.${name}`] ||
		intl.messages[`remPass.${name}`];

	return (
		<React.Fragment>
			<CloseButton onClick={handleClose} />

			<div dangerouslySetInnerHTML={{__html: html as string}} />
		</React.Fragment>
	);
});

PrivacyPolicyModal.displayName = 'PrivacyPolicyModal';

export default PrivacyPolicyModal;
