import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import './ViewClinicDetailsModal.scss';
import CloseButton from "../components/CloseButton/CloseButton";
import ViewClinic from "../../ViewClinic/ViewClinic";
import Button from "../../common/Button/Button";
import {ViewModalContextProvider} from "../../Contexts/ViewModalContext";

const ViewClinicDetailsModal = React.memo(
    ({
         onClose,
         onSelect,
         clinicId,
         hideModal,
         noSelect,
     }) => {
        const handleClose = React.useCallback(() => {
            onClose && onClose();
            hideModal();
        }, [hideModal, onClose]);

        const handleSelect = React.useCallback((clinicId) => {
            hideModal();
            onSelect && onSelect(clinicId);
        }, [hideModal, onSelect]);

        const SelectButton = useCallback(() => noSelect ? null : (
            <div className="Flex-row Justify-end mt-1 mb-1">
                <Button
                    viewType="minimal"
                    size="sm"
                    onClick={() => handleSelect(clinicId)}>
                    <span className="text-uppercase">
                        <FormattedMessage id={"common.buttons.select"} />
                    </span>
                </Button>
            </div>
        ), [clinicId, handleSelect, noSelect]);

        return (
            <React.Fragment>
                <CloseButton onClick={handleClose}/>
                <SelectButton/>
                <ViewModalContextProvider>
                    <ViewClinic
                        modalView
                        id={clinicId}/>
                </ViewModalContextProvider>
                <SelectButton/>
            </React.Fragment>
        );
    }
);

ViewClinicDetailsModal.propTypes = {
    clinicId: PropTypes.number,
};

ViewClinicDetailsModal.defaultProps = {
    noSelect: false,
};

ViewClinicDetailsModal.displayName = 'ViewClinicDetailsModal';

export default ViewClinicDetailsModal;
