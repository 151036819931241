import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

import './ProfileHeader.scss';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {canUserTranslate} from '../../redux/user/selectors';

import BadgeV2 from 'components/common/Badge/BadgeV2';
import {getUnreadChatMessagesCountSelector} from 'redux/common/selectors';
import {getIncomeStatisticValueSelector} from 'redux/overviewPage/selectors';

const SystemMenu = ({baseUrl}) => {
	return (
		<React.Fragment>
			<div className="Profile-dropdown__group">
				<FormattedMessage id="main.menu_system" />
			</div>

			<Link
				to={`${baseUrl}/system/translates`}
				className="Profile-dropdown__item"
			>
				<FormattedMessage id="main.menu_translates" />
			</Link>

			<Link
				to={`${baseUrl}/system/statuses`}
				className="Profile-dropdown__item"
			>
				<FormattedMessage id="main.menu_statuses" />
			</Link>

			<Link
				to={`${baseUrl}/system/agreements`}
				className="Profile-dropdown__item"
			>
				<FormattedMessage id="main.menu_agreements" />
			</Link>
		</React.Fragment>
	);
};

const ProfileHeader = ({match, onSignOut, fio}) => {
	let [profileDropdownState, setProfileDropdownState] = useState(false);

	const isTranslateEnabledForUser = useSelector(canUserTranslate);

	const unreadChatMessagesCount = useSelector(
		getUnreadChatMessagesCountSelector,
	);
	const incomeStatisticCount = useSelector(
		getIncomeStatisticValueSelector,
	);
	useEffect(() => {
		document.addEventListener('click', handleLanguageDropdownOutsideClick);
		return () => {
			document.removeEventListener(
				'click',
				handleLanguageDropdownOutsideClick,
			);
		};
	}, []);

	const handleLanguageDropdownOutsideClick = e => {
		if (!e.target.closest('.Profile-header__admin-name')) {
			setProfileDropdownState(false);
		}
	};

	const renderProfileDropdown = () => {
		return (
			<div className="Profile-dropdown Flex-column">
				<div className="Profile-dropdown__group">
					<FormattedMessage id="main.menu_profile" />
				</div>
				<Link
					to={`${match.url}/account/general`}
					className="Profile-dropdown__item"
				>
					<FormattedMessage id="main.menu_general" />
				</Link>
				<Link
					to={`${match.url}/account/security`}
					className="Profile-dropdown__item"
				>
					<FormattedMessage id="main.menu_security" />
				</Link>
				<Link
					to={`${match.url}/account/profile`}
					className="Profile-dropdown__item"
				>
					<FormattedMessage id="main.menu_account" />
				</Link>

				{isTranslateEnabledForUser && (
					<SystemMenu baseUrl={match.url} />
				)}

				<div
					className="Profile-dropdown__item Profile-dropdown__sign-out"
					onClick={onSignOut}
				>
					<FormattedMessage id="main.menu_logout" />
				</div>
			</div>
		);
	};

	const toggleProfileDropdown = e => {
		setProfileDropdownState(!profileDropdownState);
		e.stopPropagation();
	};

	const navLinkClass = 'Align-center Flex-row Profile-header__link';

	const showConsultationRedBadge =
		unreadChatMessagesCount > 0 || incomeStatisticCount > 0;

	return (
		<header className="Align-center Flex-row Page-header Profile-header Justify-space-between">
			<Link to="/main" className="Page-home-logo Align-center Flex-row">
				<img
					src="/images/logo.png"
					className="Page-home-logo-img"
					alt="Best 2 health logo"
				/>
			</Link>
			<div className="Profile-header__links Flex-row">
				<NavLink
					activeClassName={'Profile-header__link--active'}
					to={`${match.url}/overview`}
					className={navLinkClass}
				>
					<FormattedMessage id="main.tab_review" />
				</NavLink>

				<NavLink
					activeClassName={'Profile-header__link--active'}
					to={`${match.url}/requests`}
					className={navLinkClass}
				>
					<BadgeV2
						transparent={!showConsultationRedBadge}
						variant="ruby"
					>
						<FormattedMessage id="main.tab_consult" />
					</BadgeV2>
				</NavLink>

				<NavLink
					activeClassName={'Profile-header__link--active'}
					to={`${match.url}/medical-records`}
					className={navLinkClass}
				>
					<FormattedMessage id="main.tab_medicals" />
				</NavLink>
				<NavLink
					activeClassName={'Profile-header__link--active'}
					to={`${match.url}/account/profile`}
					className={navLinkClass}
				>
					<FormattedMessage id="main.tab_profile" />
				</NavLink>
			</div>
			<div className="Flex-column Profile-header__profile-info">
				<div
					className="Profile-header__admin-name"
					onClick={toggleProfileDropdown}
				>
					<span className="Profile-header__admin-name-inner">
						{fio}
					</span>
					<FontAwesomeIcon
						icon={
							profileDropdownState ? faChevronUp : faChevronDown
						}
						className="Profile-header__arrow-icon"
					/>
					{profileDropdownState && renderProfileDropdown()}
				</div>
			</div>
		</header>
	);
};

ProfileHeader.propTypes = {
	onSignOut: PropTypes.func,
	fio: PropTypes.string,
};

export default withRouter(ProfileHeader);
