import React, {Component} from 'react';
import escape from 'escape-html';
import cn from 'classnames';

interface Props {
    query: string;
    selectedIndex: number
    suggestions: any[]
    handleClick: (index: number, event: React.MouseEvent<HTMLLIElement>) => void;
    minQueryLength: number;
    shouldRenderSuggestions: Function;
    isFocused: boolean;
    className?: string;
    labelField: string;
    renderSuggestion: Function;
}

class TagListSuggestions extends Component<Props> {
    static defaultProps = {
        minQueryLength: 1,
    };

    static dispayName = 'HealthTagListSuggestions';

    markIt = (input, query) => {
        const escapedRegex = query.trim().replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        const {[this.props.labelField]: labelValue} = input;

        return {
            __html: labelValue.replace(RegExp(escapedRegex, 'gi'), (x) => {
                return `<mark>${escape(x)}</mark>`;
            }),
        };
    };

    shouldRenderSuggestions = (query) => {
        const {minQueryLength, isFocused} = this.props;
        return query.length >= minQueryLength && isFocused;
    };

    renderSuggestion = (item, query: string) => {
        const {renderSuggestion} = this.props;
        if (typeof renderSuggestion === 'function') {
            return renderSuggestion(item, query, {labelField: this.props.labelField});
        }
        return <span dangerouslySetInnerHTML={this.markIt(item, query)}/>;
    };

    render() {
        const {props} = this;

        const suggestions = props.suggestions.map((item, i) => {
            return (
                <li
                    className={cn(
                        "Health-taglist__suggestion-item",
                        props.selectedIndex === i && "Health-taglist__suggestion-item--selected"
                    )}
                    key={i}
                    onMouseDown={props.handleClick.bind(null, i)}>
                    {this.renderSuggestion(item, props.query)}
                </li>
            );
        });

        // use the override, if provided
        const shouldRenderSuggestions =
            props.shouldRenderSuggestions || this.shouldRenderSuggestions;
        if (suggestions.length === 0 || !shouldRenderSuggestions(props.query)) {
            return null;
        }

        return (
            <div className={cn("Health-taglist__suggestion-container", props.className)}>
                <ul className="Health-taglist__suggestion-list"> {suggestions} </ul>
            </div>
        );
    }
}

export default TagListSuggestions;
