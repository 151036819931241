import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './List.scss';

const List = React.memo(
    ({className, items, renderItem, isTruncate, maxCount, showMoreText, hideText, columns}) => {
        const [chunks, setChunks] = useState([]);
        const [needTruncate, setNeedTruncate] = useState(true);
        const [isTruncated, setIsTruncated] = useState(isTruncate);

        useEffect(() => {
            if (items && items.length > maxCount) {
                setNeedTruncate(true);
            } else {
                setNeedTruncate(false);
            }
        }, [items, maxCount]);

        useEffect(() => {
            const chunks = [];
            const itemCount = isTruncated && needTruncate ? maxCount : items.length;
            const offset = itemCount % columns === 1 ? 1 : 0;
            const perItemsChunk = Math.floor(itemCount / columns) + offset;

            for (let i = 0; i < columns; i++) {
                let end = (i + 1) * perItemsChunk;
                if (end > itemCount) {
                    end = itemCount;
                }
                chunks.push(items.slice(i * perItemsChunk, end));
            }
            setChunks(chunks);
        }, [columns, isTruncated, items, maxCount, needTruncate]);

        const toggleTruncate = useCallback(() => {
            needTruncate && setIsTruncated(!isTruncated);
        }, [isTruncated, needTruncate]);

        return (
            <div
                className={cn(className, 'Health-list')}>
                <div className={cn('Health-list--columns', columns > 1 && `row row-cols-${columns}`)}>
                    {
                        chunks.map((items, i) => (
                            <div key={i} className={cn("Health-list__column", columns > 1 && `col-${12 / columns}`)}>
                                {
                                    items.map((item, i) => (
                                        <div key={i}
                                             className="Health-list__item">
                                            {renderItem(item)}
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
                {
                    needTruncate &&
                    <div>
                        <span className="Health-list__show-more"
                              onClick={toggleTruncate}>{isTruncated ? showMoreText : hideText}</span>
                    </div>
                }
            </div>
        );
    }
);

List.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any),
    renderItem: PropTypes.func,
    isTruncate: PropTypes.bool,
    maxCount: PropTypes.number,
    showMoreText: PropTypes.string,
    hideText: PropTypes.string,
    columns: PropTypes.oneOf([1, 2, 3, 4, null])
};
List.defaultProps = {
    items: [],
    isTruncate: false,
    maxCount: 0,
    renderItem: (item) => (<span className="Health-list__item">{item}</span>)
};
List.displayName = 'HealthList';

export default List;
