import {setLoading} from '../loading/actions';
import pageNames from '../../constants/PageNames.js';
import {fetchTranslation} from "../translations/actions";
import * as api from "../../api";
import {B2B_ROLES, LK_ROLES, ROLE_CODE} from "../../constants/Roles";
import {getB2BUrl} from "../../helpers/UrlHelper";
import {setSession} from "../session/actions";
import {setCurrentLanguage} from "../lang/actions";

export const initTranslatedLoginPage = (initialLang) => async (dispatch, getState) => {
    const lang = initialLang || getState().lang;

    dispatch(setLoading(true));
    dispatch(setCurrentLanguage(lang));
    await dispatch(fetchTranslation({
        lang,
        pageName: pageNames.Login
    }));
    dispatch(setLoading(false));
};

export const login = (email, password) => async (dispatch) => {
    const response = await api.login(email, password);

    if (response.data.status) {
        const rights = response.data.rights;
        if (B2B_ROLES.includes(ROLE_CODE[rights])) {
            window.location.replace(`${getB2BUrl()}/login/${response.data.id}`);
        } else if (LK_ROLES.includes(ROLE_CODE[rights])) {
            dispatch(setSession(response.data.id));
            return '/main';
        }
    } else {
        throw response;
    }
};
