import React, {memo} from "react";
import cn from "classnames";

import './MenuListItem.scss';
import type { MenuOption, Option } from "../../../../SelectBox.interface";

interface Props {
    option: MenuOption;
    optionRenderLabel: (o: Option) => React.ReactNode;
    isFocused: boolean;
    onSelect: (o: MenuOption) => void;
}

const MenuListItem = memo<Props>(({
                          optionRenderLabel,
                          isFocused,
                          option,
                          onSelect,
                      }) => {
    return (
        <div
            title={option.label}
            onClick={() => onSelect(option)}
            className={cn('Health-select-box__menu-list-item', {
                'Health-select-box__menu-list-item--focused': isFocused,
                'Health-select-box__menu-list-item--selected': option.isSelected,
            })}>
            {optionRenderLabel(option.original)}
        </div>
    )
})

MenuListItem.displayName = 'HealthSelectBoxMenuListItem';

export default MenuListItem;
