import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import './AuthorizationLink.scss';

export const AuthorizationLink = React.memo(
    (
        {
            text, link, redirectTo, className,
        }
    ) => {
        return (
            <div className={cn("Health-Authorization-link Flex-row Justify-end", className)}>
                <div><FormattedMessage id={text}/></div>
                <Link to={redirectTo}><FormattedMessage id={link}/></Link>
            </div>
        );
    }
);

AuthorizationLink.propTypes = {
    text: PropTypes.string,
    link: PropTypes.string,
    redirectTo: PropTypes.string,
};

AuthorizationLink.defaultProps = {
  redirectTo: '/login',
};

AuthorizationLink.displayName = 'HealthAuthorizationLink';
