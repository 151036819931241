import React from 'react';

import cn from 'classnames';

import './Checkbox.scss';

interface Props<T = HTMLInputElement> extends React.HTMLProps<T> {
    className?: string;
    disabled?: boolean;
    title?: string;
    name?: string;
    onBlur?: (event?: React.FocusEvent<T>) => void;
    onChange: (event: React.ChangeEvent<T>) => void;
    onFocus?: (event?: React.FocusEvent<T>) => void;
    type?: string;
    value: string;
    checked?: boolean;
    multiple?: boolean;
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
    (
        {className, title, children, ...props},
        ref
    ) => {
        const classes = cn(
            className,
            "Health-checkbox",
        );
        return (
            <label className={classes} title={title}>
                <input
                    {...props}
                    className="Health-checkbox__input"
                    ref={ref}
                    type='checkbox'/>
                {children ? <span className="Health-checkbox__label">{children}</span> : null}
            </label>
        )
    }
);

Checkbox.displayName = "HealthCheckbox";

export default Checkbox;
