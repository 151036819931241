import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Form} from 'react-final-form';
import {FormattedMessage, useIntl} from 'react-intl';
import Joi from '@hapi/joi';

import './ProfilePage.scss';
import LanguageManager from '../../components/profile/LanguageManager/LanguageManager';
import {LanguagesCommunication} from '../../components/profile/LanguagesCommunication/LanguagesCommunication';
import Spinner from '../../components/Spinner/Spinner';
import {reduceFormErrors} from '../../helpers/Common';
import * as translationsSelectors from '../../redux/translations/selectors';
import pageNames from '../../constants/PageNames';
import {initProfilePage} from '../../redux/profilePage/actions';
import PhotoUploaderField from '../../components/common/PhotoUploader/PhotoUploaderField';
import SelectBoxField from '../../components/common/SelectBox/SelectBoxField';
import SafeButton from '../../components/common/SafeButton/SafeButton';
import {saveMyProfile} from '../../redux/profilePage/actions';
import NavigationPrompt from 'react-router-navigation-prompt';
import PagePrompt from '../../components/PagePrompt/PagePrompt';
import * as notifierActions from '../../redux/notifier/actions';
import * as profilePageSelectors from '../../redux/profilePage/selectors';
import * as commonSelectors from '../../redux/common/selectors';
import PersonalInfo from '../../components/profile/PersonalInfo/PersonalInfo';
import gender from '../../constants/Gender';
import {ProfileContext} from '../../components/profile/context/ProfileContext';
import DatePickerField from "../../components/common/DatePicker/DatePickerField";
import {currentLanguageSelector} from "../../redux/lang/selectors";
import {useCountrySyncByTimezone} from './hooks/use-country-sync-by-timezone';
import SyncFields from 'components/fields/SyncFields';
import {InfoButtonIcon} from 'components/common/ButtonIcon/InfoButtonIcon';
import CheckboxField from 'components/common/Checkbox/CheckboxField';

const buildSchema = () => Joi.object({
    country: Joi.number().required(),
    gender: Joi.number().valid(gender.MALE, gender.FEMALE).required(),
    gmt: Joi.number().required(),
    lang: Joi.array().min(1).items(Joi.string().trim().required()),
    lang_com: Joi.array().min(1),
    fields: Joi.object().pattern(/\w/, Joi.object().keys({
        name: Joi.string().trim().required(),
        middlename: Joi.string().trim().allow(''),
        surname: Joi.string().trim().required(),
    }).unknown()),
    birth: Joi.date().less('now').required(),
}).unknown();

const ProfilePage = ({
                         initProfilePage,
                         loading,
                         countries,
                         timezones,
                         genders,
                         languages,
                         profile: {role: userRole, ...initialValues},
                         isTranslationLoaded,
                         saveMyProfile,
                         notifySuccess,
                         notifyError,
                         com_languages,
                         lang,
                     }) => {
    const [secondaryLang, setSecondaryLang] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const intl = useIntl();

    const {getTimezoneByCountryId} = useCountrySyncByTimezone();

    useEffect(() => {
        initProfilePage();
    }, [initProfilePage]);

    useEffect(() => {
        if (!secondaryLang && initialValues && initialValues.lang) {
            const secondaryLangs = initialValues.lang.filter(code => code !== initialValues.primaryLang);
            setSecondaryLang(initialValues.lang.length > 1 && secondaryLangs.length ?
                secondaryLangs[0] :
                null);
        }
    }, [initialValues, secondaryLang]);

    const handleChangeSecondaryLang = useCallback((langCode) => {
        setSecondaryLang(langCode);
    }, [setSecondaryLang]);

    const formValidator = useCallback((values) => {
        const validator = buildSchema();
        const {error} = validator.validate(values, {
            abortEarly: false,
        });
        if (error) {
            return reduceFormErrors(error);
        }
    }, []);

    const onSubmit = async (values) => {
        try {
            const errors = formValidator(values);
            if (errors) {
                notifyError('Проверьте правильность заполнения формы');
                return errors;
            }
            const {status} = await saveMyProfile(values);
            setIsSuccess(false);
            if (!status) {
                notifyError(intl.formatMessage({id: 'common.info_save'}))
            } else {
                setIsSuccess(true);
                notifySuccess(intl.formatMessage({id: 'common.info_save'}))
            }
        } catch (err) {
            setIsSuccess(true);
            notifyError(intl.formatMessage({id: 'common.info_save'}))
        }
    };

    return (
        <ProfileContext.Provider value={{
            secondaryLang,
            setSecondaryLang,
        }}>
            <Spinner
                isLoading={loading && !isTranslationLoaded}
                isBlocked/>
            {
                !loading && isTranslationLoaded && initialValues.lang &&
                <Form
                    validate={formValidator}
                    onSubmit={onSubmit}
                    initialValues={initialValues}>
                    {
                        (form) => (
                            <form onSubmit={form.handleSubmit} className="Health-form" noValidate autoComplete="off">
                                <div className="Profile Flex-column">
                                    <div className={`Flex-column`}>
                                        <div className="Profile__title Page__title">
                                            <FormattedMessage id="profile.h2_language"/>
                                            <InfoButtonIcon 
                                                className="ml-05 Profile__title-info"
                                                title={intl.formatMessage({id:"profile.tooltip_questionnaire_language"})} 
                                            />
                                        </div>
                                        <LanguageManager
                                            secondaryLang={secondaryLang}
                                            onChange={handleChangeSecondaryLang}
                                            name="lang"
                                            languages={languages}
                                            hint={intl.formatMessage({id: 'profile.language_help'})}
                                            placeholder={intl.formatMessage({id: 'common.letter_help'})}/>
                                    </div>
                                    <div className="Flex-row Profile__section">
                                        <PhotoUploaderField
                                            name="photo"
                                            uploadText={intl.formatMessage({id: 'profile.photo_load'})}
                                            removeText={intl.formatMessage({id: 'profile.photo_del'})}
                                            changeText={intl.formatMessage({id: 'profile.photo_edit'})}/>
                                        <div className="Left-side Flex-column Flex-1">
                                            <PersonalInfo secondaryLang={secondaryLang}/>
                                            <SelectBoxField
                                                placeholder={intl.formatMessage({id: 'common.select_help'})}
                                                labelClassName="col-2"
                                                fieldBodyClassName="col-4"
                                                fieldClassName="row"
                                                inline
                                                label={intl.formatMessage({id: 'profile.gender'})}
                                                name="gender"
                                                options={genders}/>
                                            <div className="row">
                                                <SyncFields
											    	initialSync={true}
											    	transform={
											    		getTimezoneByCountryId
											    	}
											    	fieldFrom={'country'}
											    	fieldTo={'gmt'}
											    />
                                                <div className="col-6">
                                                    <SelectBoxField
                                                        searchable
                                                        placeholder={intl.formatMessage({id: 'common.select_help'})}
                                                        labelClassName="col-4"
                                                        fieldBodyClassName="col-8"
                                                        fieldClassName="row"
                                                        inline
                                                        label={intl.formatMessage({id: 'profile.country'})}
                                                        name="country"
                                                        options={countries}/>
                                                </div>
                                                <div className="col-6">
                                                    <SelectBoxField
                                                        searchable
                                                        placeholder={intl.formatMessage({id: 'common.select_help'})}
                                                        excludeSelected={false}
                                                        labelClassName="col-4"
                                                        fieldBodyClassName="col-8"
                                                        fieldClassName="row"
                                                        inline
                                                        getOptionValue={o => o.gmt}
                                                        label={intl.formatMessage({id: 'profile.timezone'})}
                                                        name="gmt"
                                                        options={timezones}/>
                                                </div>
                                            </div>
                                            <DatePickerField
                                                inline
                                                locale={lang}
                                                pickerClass="col-6"
                                                containerClass="Health-date-picker__overlay--align-right"
                                                labelClass="col-2"
                                                inputClass="col-4"
                                                label={intl.formatMessage({id: 'profile.birth'})}
                                                name="birth"/>
                                        </div>
                                    </div>
                                    <LanguagesCommunication
                                        className="Profile__section"
                                        fromLangName="lang"
                                        languages={com_languages}
                                        syncLangName="sync_lang"
                                        syncText={intl.formatMessage({id: 'profile.sync_lang'})}
                                        placeholder={intl.formatMessage({id: 'common.letter_help'})}
                                        title={intl.formatMessage({id: 'profile.h2_lang_com'})}
                                        hint={intl.formatMessage({id: 'profile.patient_lang_com_help'})}
                                        name="lang_com"/>

									    <CheckboxField
									    	name="need_cure"
									    >
									    	<FormattedMessage id="profile.interested_in_consultation_with_treatment" />
									    </CheckboxField>
                                </div>
                                
                                <div className="Flex-row Justify-center Profile-foother">
                                    <SafeButton
                                        disabled={form.pristine && !form.dirty}
                                        viewType="minimal"
                                        type="submit"
                                        loading={form.submitting}
                                        isSuccess={isSuccess}
                                        doneText={intl.formatMessage({id: 'common.info_save'})}>
                                        <FormattedMessage id="common.buttons.save"/>
                                    </SafeButton>
                                </div>
                                {
                                    !form.pristine && form.dirty &&
                                    <NavigationPrompt when={!form.pristine && !form.submitting}>
                                        {
                                            ({isActive, onCancel, onConfirm}) => {
                                                if (isActive) {
                                                    return (
                                                        <PagePrompt
                                                            title={intl.formatMessage({id: 'common.dlg_leave_title'})}
                                                            btnSave={intl.formatMessage({id: 'common.buttons.save'})}
                                                            btnCancel={intl.formatMessage({id: 'common.buttons.exit_no_save'})}
                                                            onConfirm={onCancel}
                                                            onClose={onCancel}
                                                            onCancel={onConfirm}/>
                                                    );
                                                }
                                            }
                                        }
                                    </NavigationPrompt>
                                }
                            </form>
                        )
                    }
                </Form>
            }
        </ProfileContext.Provider>
    );
};

const mapStateToProps = state => {
    return {
        loading: profilePageSelectors.isLoadingSelector(state),
        countries: commonSelectors.getCountriesSelector(state),
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, pageNames.Profile),
        timezones: commonSelectors.getTimezonesSelector(state),
        genders: commonSelectors.getGendersSelector(state),
        languages: commonSelectors.getLanguagesSelector(state),
        profile: profilePageSelectors.getMyProfileSelector(state),
        com_languages: commonSelectors.getCommunicationLanguagesSelector(state),
        lang: currentLanguageSelector(state),
    };
};

const mapDispatchToProps = {
    initProfilePage,
    saveMyProfile,
    notifySuccess: notifierActions.notifySuccess,
    notifyError: notifierActions.notifyError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
