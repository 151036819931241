import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { SESSION_KEY } from '../constants/Common';

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            localStorage.getItem(SESSION_KEY) ? (
                <Component {...props}/>
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from : props.location }
                    }}
                />
            )
        }
    />


);