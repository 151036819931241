import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

const EmptyValue = ({className, value, emptyText}) => {
    return (
        <span className={className}>
            {
                value ? value :
                    emptyText ? emptyText : (
                        <FormattedMessage id="common.empty_value"/>
                    )
            }
        </span>
    )
}

EmptyValue.displayName = 'EmptyValue';
EmptyValue.propTypes = {
    value: PropTypes.any,
    emptyText: PropTypes.string,
};

export default EmptyValue;
