import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import './RequestPage.scss';
import Loader from '../../components/Loader/Loader';
import pageNames from '../../constants/PageNames';
import {useResetWindowScroll} from '../../hooks/useResetWindowScroll';
import {isRequestPageLoadingSelector} from '../../redux/requestPage/selectors';
import {fetchTranslation} from '../../redux/translations/actions';
import {isPageLoadedSelector} from '../../redux/translations/selectors';
import RequestManager from './components/RequestManager/RequestManager';
import * as requestCommonActions from '../../redux/requestPage/common/actions';
import * as commonActions from '../../redux/common/actions';

const RequestPage = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const isNew = !id || id === 'new';
    const isTranslationLoaded = useSelector(state => isPageLoadedSelector(state, pageNames.Request));
    const isLoading = useSelector(isRequestPageLoadingSelector);

    useEffect(() => {
        dispatch(requestCommonActions.loadCommonFileTypes());
    }, [dispatch]);

    useEffect(() => {
        dispatch(commonActions.fetchCountries());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchTranslation({pageName: pageNames.Request}));
    }, [dispatch]);

    useResetWindowScroll();

    return (
        <div className="Request-page">
            {
                isTranslationLoaded &&
                <RequestManager requestId={id} isNew={isNew} />
            }
            <Loader loading={isLoading || !isTranslationLoaded} />
        </div>
    )
};

export default RequestPage;
