import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import {Form} from 'react-final-form'
import {FormattedMessage, useIntl} from "react-intl";

import TextBoxField from "../../../components/common/TextBox/TextBoxField";
import {GOOGLE_CAPTCHA_API_KEY} from "../../../constants/Registration";
import Button from "../../../components/common/Button/Button";
import {AuthorizationLink} from "../../../components/registration/AuthorizationLink/AuthorizationLink";
import {AgreementCheckboxField} from "../../../components/registration/AgreementCheckboxField/AgreementCheckboxField";
import {getRootUrl} from '../../../helpers/UrlHelper';

export const PatientRegistrationForm = ({onSubmit, lang, redirectTo}) => {
    const intl = useIntl();
    const [captcha, setCaptchaValue] = useState(null);

    const onFormSubmit = (formValue) => {
        return onSubmit({...formValue, captcha});
    };

    const goMainPage = useCallback(() => window.location.replace(getRootUrl()), []);

    return (
        <React.Fragment>
            <AuthorizationLink
                text="patientReg.reg_has_acc"
                link="patientReg.reg_auth"
            />
            <Form onSubmit={onFormSubmit}>
                {
                    ({handleSubmit, submitErrors}) => {
                        return (
                            <form
                                autoComplete="off"
                                noValidate
                                onSubmit={handleSubmit}
                                className="mt-2">
                                <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />
                                <TextBoxField
                                    inline
                                    labelClass="col-4"
                                    inputClass="col-8"
                                    name="newemail"
                                    autoComplete="new-email"
                                    label={intl.formatMessage({id: "patientReg.email"})}/>
                                <TextBoxField
                                    inline
                                    labelClass="col-4"
                                    inputClass="col-8"
                                    name="newpassword"
                                    autoComplete="new-password"
                                    type="password"
                                    hint={intl.formatMessage({id: "patientReg.pass_help"})}
                                    label={intl.formatMessage({id: "patientReg.pass"})}/>
                                <TextBoxField
                                    inline
                                    labelClass="col-4"
                                    inputClass="col-8"
                                    name="confirm_pass"
                                    autoComplete="new-password"
                                    type="password"
                                    label={intl.formatMessage({id: "patientReg.confirm_pass"})}/>
                                <AgreementCheckboxField
                                    rules1="patientReg.reg_rules1"
                                    rules2="patientReg.reg_rules2_url"
                                    rules3="patientReg.reg_rules3"
                                />
                                <div className="Health-form__field row">
                                    <label className="col-4"/>
                                    <div className="col-8">
                                        <ReCAPTCHA
                                            sitekey={GOOGLE_CAPTCHA_API_KEY}
                                            onChange={setCaptchaValue}
                                            hl={lang}
                                        />
                                    </div>
                                </div>
                                <div className="Flex-row Justify-space-between mt-2 mb-4">
                                    <Button
                                        viewType="minimal"
                                        variant="gray"
                                        onClick={goMainPage}>
                                        <FormattedMessage id="patientReg.but_back_main"/>
                                    </Button>
                                    <Button type={'submit'}>
                                        <FormattedMessage id="patientReg.but_create"/>
                                    </Button>
                                </div>
                            </form>
                        )
                    }
                }
            </Form>
        </React.Fragment>
    );
};

PatientRegistrationForm.propTypes = {
    redirectTo: PropTypes.string,
};

export default PatientRegistrationForm;
