import React from 'react';

interface Props {
    className?: string;
    value?: string;
}

const SpanCell: React.FC<Props> = ({children, className, value}) => (
    <span className={className}>
        {children || value}
    </span>
);

export default SpanCell;
