import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const FileLink = React.memo(
    ({className, file}) => {
        return (
            <div className={cn(className, 'Health-file-link')}>
                {
                    file.file ?
                        <a
                            rel="noreferrer noopener"
                            className="Health-file-link__link"
                            href={file.file}
                            target="_blank">{file.name}</a>
                        :
                        <span className="Health-file-link__name">{file.name}</span>
                }
            </div>
        )
    }
);

FileLink.propTypes = {
    file: PropTypes.shape({
        name: PropTypes.string,
        file: PropTypes.string,
    })
};

FileLink.defaultProps = {};
FileLink.displayName = 'HealthFileLink';

export default FileLink;
