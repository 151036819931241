import React from 'react';
import cn from 'classnames';

import './PageHeader.scss';

const PageHeader = React.forwardRef(({className, children}, ref) => {
    return (
        <div ref={ref} className={cn(className, 'Health-page__header')}>
            {children}
        </div>
    )
});

export default PageHeader;
