import React, {createRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useIntl} from "react-intl";

import './RequestInfoPanel.scss';
import {InfoPanel} from '../../../../../../components/InfoPanel/InfoPanel';

const OFFSET_DEFAULT_Y = 162; // 162 px

const fields = [
    'reason', 'description', 'specialities', 'diagnostic', 'specials', 'files',
]

export const RequestInfoPanel = (
    {y, containerRef, className, activeField, targetHeight},
) => {
    const intl = useIntl();
    const [offsetY, setOffsetY] = useState(OFFSET_DEFAULT_Y);
    const panelRef = createRef();
    const [title, setTitle] = useState(() => intl.formatMessage({id: 'request.form_info_panel.default_title'}));
    const [message, setMessage] = useState(() => intl.formatHTMLMessage({id: 'request.form_info_panel.default_message'}));

    useEffect(() => {
        if (!containerRef.current || !panelRef.current || !y || !targetHeight || !activeField) {
            return;
        }
        const containerRect = containerRef.current.getBoundingClientRect();
        const panelRect = panelRef.current.getBoundingClientRect();

        const offset = y - panelRect.height / 2 + targetHeight / 2 - window.scrollY - containerRect.top;
        if (offset + panelRect.height > containerRect.height) {
            setOffsetY(containerRect.height - panelRect.height - 10);
        } else {
            setOffsetY(offset);
        }
    }, [activeField, containerRef, offsetY, panelRef, targetHeight, y]);

    useEffect(() => {
        if (!activeField) {
            return;
        }
        const fieldName = activeField.split('.')[0];

        if (fields.includes(fieldName) || /^files/i.test(fieldName)) {
            if (/^files/i.test(fieldName)) {
                setTitle(intl.formatMessage({id: 'request.form_info_panel.files_title'}));
                setMessage(intl.formatHTMLMessage({id: 'request.form_info_panel.files_message'}));
            } else {
                setTitle(intl.formatMessage({id: `request.form_info_panel.${fieldName}_title`}));
                setMessage(intl.formatHTMLMessage({id: `request.form_info_panel.${fieldName}_message`}));
            }
        }
    }, [activeField, intl]);

    return (
        <div
            className={cn('Request-form-page__info-panel', className)}
            style={{
                top: offsetY,
            }}>
            <InfoPanel
                ref={panelRef}
                title={title}
                message={message}
            />
        </div>
    )
};

RequestInfoPanel.propTypes = {
    y: PropTypes.number,
    targetHeight: PropTypes.number,
    containerRef: PropTypes.object.isRequired,
    activeField: PropTypes.string,
};
