import React from 'react';

import { useAppSelector } from 'hooks/use-app-selector';

import * as editUserProfileSelectors from 'redux/profilePage/selectors';

export const useCountrySyncByTimezone = () => {
    const getTimezoneByCountryId = useAppSelector(
		editUserProfileSelectors.getTimezoneByCountryIdSelector,
	);

	const _getTimezoneByCountryId = React.useCallback(countryId => {
		if (!countryId) {
			return '';
		}

		const tz = getTimezoneByCountryId(countryId);

		if (!tz) {
			console.warn(`Can't get a timezone for the country '${countryId}'`);
			return ''
		}

		return tz.gmt;
	}, [getTimezoneByCountryId]);


    return {
        getTimezoneByCountryId: _getTimezoneByCountryId
    };
}