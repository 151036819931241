import React, {useRef, useState, useEffect} from 'react';
import cn from 'classnames';
import TagListSuggestions from './TagListSuggestions';

import './TagList.scss';
import TagListItem from './TagListItem';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import Hint from '../Hint/Hint';

import type {Suggestion, Tag} from './TagList.interface';
import { useOutsideClick } from '../../../libs/hooks/use-outside-click';

export interface Props {
	className?: string;
	placeholder?: string;
	name?: string;
	disabled?: boolean;
	disabledRemoveTags?: boolean;
	inputClass?: string;
	handleInputChange?: (value: string) => void;
	handleInputFocus?: (value: unknown) => void;
	handleInputBlur?: (value: string) => void;
	inputRef?: React.RefObject<HTMLInputElement>;
	inputValue?: string;
	showArrow?: boolean;
	keepOpenAfterLastRemoved?: boolean;
	// label props
	label?: string;
	labelClass?: string;
	// suggestion props
	suggestions: Suggestion[];
    //
	labelField?: string;
	renderSuggestion: Function;
	shouldExcludeTags: boolean;
	// tags props
	tags?: Tag[];
	selectedTag?: any[];
	onChangeSelectedTag?: Function;
	canSelectTag?: boolean;
	canSelectedTagRemove?: boolean;
	tagsContainerClass?: string;
	multiSelecting?: boolean;
	// handlers
	handleRemoved?: Function;
	handleAddition?: Function;
	handleFilterSuggestions: Function;
	handleTagClick?: Function;
	minQueryLength: number;
	shouldRenderSuggestions?: Function;
	// hint
	hint?: string;
	// layout
	tagsPosition?: 'bottom' | 'right' | null;
	inline?: boolean;
	inputContainerClass?: string;
	containerClass?: string;
	inlineTags?: boolean; // render tags without container
	onFocus?: Function;
	onBlur?: Function;
}

const EMPTY_SUGGESTIONS = [];
const EMPTY_TAGS = [];
const EMPTY_SELECTED_TAG = [];

const TagList = ({
	tags = EMPTY_TAGS,
	suggestions: _suggestions = EMPTY_SUGGESTIONS,
	selectedTag = EMPTY_SELECTED_TAG,
    inputRef: _inputRef,
	minQueryLength = 1,
	hint,
	// string
	className,
	name,
	label,
	placeholder = 'Input value ...',
	inputClass,
	labelClass,
	inputValue,
	labelField = 'label',
	containerClass,
	inputContainerClass,
    tagsContainerClass,
	tagsPosition = 'bottom',
	// boolean
	inline = false,
	disabled = false,
	showArrow = true,
	keepOpenAfterLastRemoved = false,
	shouldExcludeTags = false,
	canSelectedTagRemove,
	canSelectTag = false,
	inlineTags = false,
	disabledRemoveTags = false,
    multiSelecting = false,
	// Functions
	shouldRenderSuggestions,
	renderSuggestion,
	handleFilterSuggestions,
	handleInputChange: _handleInputChange,
	handleInputFocus: _handleInputFocus,
    handleInputBlur: _handleInputBlur,
	handleTagClick: _handleTagClick,
	handleRemoved,
	handleAddition,
    onChangeSelectedTag,
	onFocus,
	onBlur,
}: Props) => {
	const inputRef = _inputRef || useRef<HTMLInputElement>();
	const containerRef = useRef<HTMLDivElement>();

	const [suggestions, setSuggestions] = useState(_suggestions);
	const [isFocused, setIsFocused] = useState(false);
	const [query, setQuery] = useState('');
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [selectionMode, setSelectionMode] = useState(false);

	const getQueryIndex = (query: string, item) => {
		return item[labelField].toLowerCase().indexOf(query.toLowerCase());
	};

	const filteredSuggestions = (query: string, suggestions: Suggestion[], excludedTags: Tag[]) => {
		if (handleFilterSuggestions) {
			return handleFilterSuggestions(query, suggestions, excludedTags, {
				labelField: labelField,
			});
		}

		if (excludedTags) {
			suggestions = suggestions.filter(
				s => !excludedTags.some(t => t.id === s.id),
			);
		}

		const exactSuggestions = suggestions.filter(item => {
			return getQueryIndex(query, item) === 0;
		});

		const partialSuggestions = suggestions.filter(item => {
			return getQueryIndex(query, item) > 0;
		});

		return exactSuggestions.concat(partialSuggestions);
	};

	const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		const value = event.target.value;

		if (_handleInputFocus) {
			_handleInputFocus(value);
		}

		if (minQueryLength) {
			setIsFocused(true);
		} else {
			const query = value.trim();
			const filteredItems = filteredSuggestions(
				query,
				_suggestions,
				shouldExcludeTags && tags,
			);

			setIsFocused(true);
			setQuery(query);
			setSuggestions(filteredItems);
			setSelectedIndex(
				selectedIndex >= filteredItems.length
					? filteredItems.length - 1
					: selectedIndex,
			);
		}

		if (onFocus) {
			onFocus();
		}
	};

	const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const value = event.target.value;

		if (_handleInputBlur) {
			_handleInputBlur(value);

			if (inputRef.current) {
				inputRef.current.value = '';
			}
		}

		setIsFocused(false);

		if (onBlur) {
			onBlur();
		}
	};

	const handleInputOnChange = event => {
		if (_handleInputChange) {
			_handleInputChange(event.target.value);
		}

		const query = event.target.value.trim();
		const filteredItems = filteredSuggestions(
			query,
			suggestions,
			shouldExcludeTags && tags,
		);

		setQuery(query);
		setSuggestions(filteredItems);
		setSelectedIndex(
			selectedIndex >= filteredItems.length
				? filteredItems.length - 1
				: selectedIndex,
		);
	};

	const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();

			const selectedQuery =
				selectionMode && selectedIndex !== -1
					? suggestions[selectedIndex]
					: {id: query, [labelField]: query};

			debugger;
			// @ts-ignore
			if (selectedQuery !== '') {
				addTag(selectedQuery as any);
			}
		}

		// hide suggestions menu
		if (event.key === 'Escape') {
			event.preventDefault();
			event.stopPropagation();

			setSelectedIndex(-1);
			setSuggestions([]);
			setSelectionMode(false);
		}

		if (event.key === 'ArrowUp') {
			event.preventDefault();
			setSelectedIndex(
				selectedIndex <= 0 ? suggestions.length - 1 : selectedIndex - 1,
			);
			setSelectionMode(true);
		}

		if (event.key === 'ArrowDown') {
			event.preventDefault();
			setSelectedIndex(
				suggestions.length === 0
					? -1
					: (selectedIndex + 1) % suggestions.length,
			);
			setSelectionMode(true);
		}
	};

	const handleSuggestionClick = (index: number) => {
		addTag(suggestions[index]);
	};

	const addTag = (tag: Tag) => {
		if (!tag.id || !tag[labelField]) {
			return;
		}
		const existingKeys = tags.map(tag => tag.id);

		// Return if tag has been already added
		if (existingKeys.indexOf(tag.id) >= 0) {
			return;
		}

		handleAddition && handleAddition(tag);

		// reset the state
		setQuery('');
        setIsFocused(false);
		setSelectionMode(false);
		setSelectedIndex(-1);

        if (disabled) {
			handleInputFocus({target: {value: ''}} as any);
			return;
		}
	};

	const handleTagRemove = (index: number, event) => {
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		handleRemoved && handleRemoved(tags[index], event);

		if (!keepOpenAfterLastRemoved) {
			return;
		}

		if (!(tags.length - 1)) {
			inputRef.current.focus();
		}
	};

	const handleTagClick = (index: number, event) => {
		if (handleTagClick) {
			handleTagClick(index, event);
		}

		const tagId = tags[index].id;

		if (canSelectTag) {
			if (multiSelecting) {
				const selectedTagIndex = selectedTag.findIndex(
					t => t === tagId,
				);
				if (selectedTagIndex === -1) {
					onChangeSelectedTag &&
						onChangeSelectedTag([...selectedTag, tagId]);
				} else {
					onChangeSelectedTag &&
						onChangeSelectedTag(
							selectedTag.filter(t => t !== tagId),
						);
				}
			} else {
				onChangeSelectedTag && onChangeSelectedTag([tagId]);
			}
		} else {
			inputRef.current.focus();
		}
	};

	const handleIconClick = (_event: React.MouseEvent) => {
		const filtered = filteredSuggestions(
			query,
			suggestions,
			shouldExcludeTags && tags,
		);

		setIsFocused(isFocused => !isFocused);
		setSuggestions(filtered);
		setSelectedIndex(
			selectedIndex >= filtered.length
				? filtered.length - 1
				: selectedIndex,
		);
	};

	const renderTagItems = () => {
		const containerClasses = cn(
			'Health-taglist__tags-container',
			tagsContainerClass,
			tagsPosition === 'bottom' &&
				'Health-taglist__tags-container--bottom',
		);

		const TagContainer = inlineTags ? React.Fragment : 'div';
		const tagContainerProps = inlineTags
			? {}
			: {className: containerClasses};

		return (
			<TagContainer {...tagContainerProps}>
				{tags.map((tag, index) => {
					return (
						<TagListItem
							key={`${tag.id}-${index}`}
							index={index}
							tag={tag}
							labelField={labelField}
							canSelectedTagRemove={canSelectedTagRemove}
							selectedTag={selectedTag}
							canSelectTag={canSelectTag}
							onRemove={handleTagRemove.bind(this, index)}
							disabledRemoveTags={disabledRemoveTags}
							onClick={handleTagClick.bind(this, index)}
						/>
					);
				})}
			</TagContainer>
		);
	};

	const renderInput = () => {
		const inputClasses = cn('Health-taglist__input');
		const labelClasses = cn(labelClass, 'Health-taglist__label');
		const containerClasses = cn(
			'Health-taglist__input-container',
			inputContainerClass,
			inline && 'row',
		);
		const inputWrapperClasses = cn(
			inputClass,
			'Health-taglist__input-wrapper',
		);

		return (
			<div className={containerClasses}>
				{label && (
					<label className={labelClasses} htmlFor={name}>
						{label}
					</label>
				)}
				<div className={inputWrapperClasses}>
					<input
						autoComplete="off"
						disabled={disabled}
						name={name}
						type="text"
						placeholder={placeholder}
						aria-label={placeholder}
						value={inputValue}
						ref={inputRef}
						onKeyDown={handleInputKeyDown}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						onChange={handleInputOnChange}
						className={inputClasses}
					/>
					{showArrow && (
						<FontAwesomeIcon
							onClick={handleIconClick}
							className="Health-taglist__input-icon"
							icon={isFocused ? faChevronUp : faChevronDown}
						/>
					)}
				</div>
				<TagListSuggestions
					minQueryLength={minQueryLength}
					handleClick={handleSuggestionClick}
					selectedIndex={selectedIndex}
					suggestions={suggestions}
					labelField={labelField}
					isFocused={isFocused}
					query={query.trim()}
					shouldRenderSuggestions={shouldRenderSuggestions}
					renderSuggestion={renderSuggestion}
				/>
			</div>
		);
	};

	const classes = cn(className, 'Health-taglist');
	const containerClasses = cn(
		tagsPosition === 'bottom' && 'Flex-column',
		tagsPosition === 'right' && !inlineTags && 'row',
		containerClass,
	);

    useEffect(() => {
        if (!query) {
            inputRef.current.value = '';
        }
    }, [query])

    useOutsideClick((event) => {
        const target = event.target as Element;

        if (!isFocused) {
            return;
        }
        
        if (!containerRef.current.contains(target)) {
            setIsFocused(false);
        }
    })

	return (
		<div className={classes} ref={containerRef}>
			<div className={containerClasses}>
				{renderInput()}
				{renderTagItems()}
			</div>
			{hint && <Hint>{hint}</Hint>}
		</div>
	);
};

(TagList as any).FilterSuggestions = {
	withStartFilter: (query: string, suggestions, excludedTags, {labelField}) => {
		if (excludedTags && excludedTags.length) {
			suggestions = suggestions.filter(
				s => !excludedTags.some(t => t.id === s.id),
			);
		}

		return suggestions.filter(item => {
			return (
				item[labelField]
					.toLowerCase()
					.replace(/["'«»]/g, '')
					.indexOf(query.toLowerCase()) === 0
			);
		});
	},
	withStartWordFilter: (query: string, suggestions, excludedTags, {labelField}) => {
		if (excludedTags && excludedTags.length) {
			suggestions = suggestions.filter(
				s => !excludedTags.some(t => t.id === s.id),
			);
		}

		return suggestions.filter(item => {
			const words = item[labelField].toLowerCase().split(/(\s|-)/g);
			return words.some(
				w =>
					w.length > 1 &&
					w
						.toLowerCase()
						.replace(/["'«»]/g, '')
						.indexOf(query.toLowerCase()) === 0,
			);
		});
	},
};

(TagList as any).RenderSuggestions = {
	simpleRender: (item, query: string, {labelField}) => {
		return <span>{item && item[labelField]}</span>;
	},
};

type TagListHelpers = {
	RenderSuggestions: {
		simpleRender: Function;
	};
	FilterSuggestions: {
		withStartFilter: Function;
		withStartWordFilter: Function;
	};
};

TagList.displayName = 'HealthTagList';

export default TagList as typeof TagList & TagListHelpers;
