import {SET_PROFILE_PAGE_LOADING, SET_PROFILE_PAGE_MY_PROFILE} from './actions';
import Immutable from 'seamless-immutable';

const INITIAL_STATE = {
    loading: false,
    profile: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PROFILE_PAGE_LOADING:
            return Immutable.merge(state, {loading: action.payload});
        case SET_PROFILE_PAGE_MY_PROFILE:
            return Immutable.merge(state, {
                profile: action.payload
            });
        default:
            return state;
    }
}
