export interface Option {
    id: string | number;
    label?: string;
}
export interface SelectedOption {
    label: string;
    value: number;
    original: Option;
    isSelected?: boolean;
}


export interface MenuOption {
    label: string;
    value: number;
    original: Option;
    isSelected: boolean;
}
export interface FocusedOption extends MenuOption  {
    index: number;
}

export enum MenuPosition {
    Down = 'down',
    Up = 'up'
}