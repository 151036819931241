import * as api from '../../api';
import {SESSION_KEY} from '../../constants/Common';
import * as loadingActions from '../loading/actions';
import {setCurrentLanguage} from '../lang/actions';

export const SET_USER_INFO = 'SET_USER_INFO';
export const PATCH_USER = 'PATCH_USER';

export const setUserInfo = (userInfo) => ({
    type: SET_USER_INFO,
    payload: userInfo,
});

export const patchUser = (partialData) => ({
    type: PATCH_USER,
    payload: partialData,
});

export const fetchUserInfo = () => async (dispatch) => {
    try {
        const res = await api.loadUserInfo();
        const {lang, ...shortInfo} = res.data;

        const userInfo = {
            ...shortInfo,
            clinicName: shortInfo.clinic_name,
        };
        dispatch(setCurrentLanguage(lang));
        dispatch(setUserInfo(userInfo));
    } catch (err) {
    }
};

export const logoutUser = () => async (dispatch) => {
    dispatch(loadingActions.setLoading(true));
    localStorage.removeItem(SESSION_KEY);
    try {
        await api.logout();
    } catch (err) {
    }
    window.location.reload();
};

export const updateUser = (partialData) => async (dispatch, getState) => {
    const {lang, ...data} = partialData;
    let res;

    try {
        res = await api.updateUser({profile: partialData});
        if (lang && getState().lang !== lang) {
            dispatch(setCurrentLanguage(lang));
            window.location.reload();
        } else {
            dispatch(patchUser(data));
        }
    } catch (err) {
        // TODO show notification
    }
    return res.data;
};

export const changeEmail = (email, password) => async () => {
    let res;
    try {
        res = await api.changeEmail({email, password});
    } catch (err) {
    }
    return res.data;
};

export const changePassword = (password, newpassword) => async () => {
    let res;
    try {
        res = await api.changePassword({password, newpassword});
    } catch (err) {
    }
    return res.data;
};

export const removeAccountSubmit = (password) => async () => {
    let res;
    try {
        res = await api.removeAccount(password);
    } catch (err) {
    }
    return res.data;
};
