import {createSelector} from 'reselect';
import Immutable from 'seamless-immutable';
import _size from 'lodash/size';

export const getCommonSelector = state => state.common;

export const getCountriesSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.countries, {deep: true}),
);

export const getCountryByIdSelector = createSelector(
    getCountriesSelector,
    (_, id) => id,
    (countries, id) => countries.find(c => c.id === id),
);

export const getSpecialitiesSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.specialities, {deep: true}),
);

export const getSpecialityByIdSelector = createSelector(
    getSpecialitiesSelector,
    (_, id) => id,
    (specialities, id) => specialities.find(c => c.id === id),
);

export const getCurrenciesSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.currencies, {deep: true}),
);

export const getLanguagesSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.languages, {deep: true}),
);

export const getCommunicationLanguagesSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.comm_languages, {deep: true}),
);

export const getTimezonesSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.timezones, {deep: true}),
);

export const getGendersSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.genders, {deep: true}),
);

export const getDepartmentsSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.departments, {deep: true}),
);

export const getClinicsSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.clinics, {deep: true}),
);

export const getFileTypesSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.fileTypes, {deep: true}),
);

export const getMedicalFileTypesSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.medicalFileTypes, {deep: true}),
);

export const getDoctorsSelector = createSelector(
    getCommonSelector,
    common => Immutable.asMutable(common.doctors, {deep: true}),
);

export const getUnreadChatMessagesSelector = createSelector(
	getCommonSelector,
	(common) => Immutable.asMutable(common.consultationUnreadChatMessages, {deep: true}),
)

export const getUnreadChatMessagesCountSelector = createSelector(
	getCommonSelector,
	(common) => _size(common.consultationUnreadChatMessages)
)
