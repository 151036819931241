import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {faUser} from "@fortawesome/free-solid-svg-icons";

import './OverviewNotification.scss';
import Alert from "../../../../../../components/common/Alert/Alert";
import Photo from "../../../../../../components/Photo/Photo";
import AvatarText from "../../../../../../components/AvatarText/AvatarText";

const OverviewNotification = ({renderHeader, renderContent, renderMeta, avatarUrl, shortName, name, variant, className, fallbackIcon}) => {
    return (
        <div className={cn("Overview-notification", className)}>
            <div className={"Overview-notification__left"}>
                {
                    avatarUrl ?
                        <Photo
                            title={name}
                            avatar
                            src={avatarUrl}
                            fallbackIcon={fallbackIcon}
                            fallbackIconSize={"lg"}/> :
                        <AvatarText
                            title={name}
                            shortName={shortName}/>
                }
            </div>
            <div className={"Overview-notification__right"}>
                <div className={"Overview-notification__meta"}>
                    {
                        renderMeta && renderMeta()
                    }
                </div>
                <Alert hover variant={variant}>
                    {
                        renderHeader &&
                        <h3 className="Health-alert__header">
                            {renderHeader()}
                        </h3>
                    }
                    <div className="Health-alert__content">
                        {renderContent && renderContent()}
                    </div>
                </Alert>
            </div>
        </div>
    )
}

OverviewNotification.displayName = 'OverviewNotification';

OverviewNotification.propTypes = {
    renderHeader: PropTypes.func,
    renderContent: PropTypes.func,
    renderMeta: PropTypes.func,
    avatarUrl: PropTypes.string,
    shortName: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.oneOf(['blue', 'gray', 'ruby', 'green', 'orange', 'primary', null]),
    fallbackIcon: PropTypes.any,
};

OverviewNotification.defaultProps = {
    fallbackIcon: faUser,
};

export default OverviewNotification;
