import React from 'react';

import {useAppDispatch} from 'hooks/use-app-dispatch';
import {showUserAgreementModal} from 'redux/modal/actions';
import {FormattedMessage} from 'react-intl';
import {ButtonAsLink} from '../common/Button/ButtonAsLink';

interface Props {
	className?: string
	messageId: string;
}

export const ShowUserAgreementLink = ({className, messageId}: Props) => {
	const dispatch = useAppDispatch();

	const _showPrivatePolicyModal = (e: React.MouseEvent) => {
		e.preventDefault();
		dispatch(showUserAgreementModal());
	};

	return (
		<ButtonAsLink 
			className={className} 
			onClick={_showPrivatePolicyModal}>
			<FormattedMessage id={messageId} />
		</ButtonAsLink>
	);
};
