import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FormattedMessage} from "react-intl";

import './RowDisplayField.scss';

export const RowDisplayField = React.memo(
    ({rowClass, labelClass, valueClass, children, labelKey}) => {
        return (
            <div className={cn('Health-row-display-field', rowClass)}>
                <div className={cn('Health-row-display-field__label', labelClass)}>
                    <FormattedMessage id={labelKey}/>:
                </div>
                <div className={cn('Health-row-display-field__value', valueClass)}>
                    {children}
                </div>
            </div>
        )
    }
);

RowDisplayField.propTypes = {
    rowClass: PropTypes.string,
    labelClass: PropTypes.string,
    valueClass: PropTypes.string,
    labelKey: PropTypes.string.isRequired,
};

RowDisplayField.displayName = 'HealthRowDisplayField';
