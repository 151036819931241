import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import {FormattedMessage, useIntl} from "react-intl";

import './RequestInfo.scss';
import useOpenFile from '../../../hooks/useOpenFile';
import Separator from '../../common/Separator/Separator';
import TextEllipsis from '../../TextEllipsis/TextEllipsis';
import RequestFileList from '../RequestFileList/RequestFileList';
import {RequestSectionTitle} from '../RequestSectionTitle/RequestSectionTitle';
import EmptyValue from "../../EmptyValue/EmptyValue";

export const RequestInfo = ({data, currentLang, className, noDisplayFiles}) => {
    const openFile = useOpenFile();
    const lang = data.lang;
    const intl = useIntl();

    const handleOpenFile = useCallback((file) => {
        file && openFile(file);
    }, [openFile]);

    return (
        <div className={cn('Request-info', className)}>
            <RequestSectionTitle className="mt-2">
                <FormattedMessage id={"common.request_info.language"}/>
            </RequestSectionTitle>
            <p>
                {_.get(currentLang, 'label')}
            </p>
            <Separator/>
            <RequestSectionTitle>
                <FormattedMessage id={"common.request_info.subject"}/>
            </RequestSectionTitle>
            <p>
                {_.get(data, `reason.${lang}`)}
            </p>
            <Separator/>
            <RequestSectionTitle>
                <FormattedMessage id={"common.request_info.description"}/>
            </RequestSectionTitle>
            <p>
                {_.get(data, `description.${lang}`)}
            </p>
            <Separator/>
            <RequestSectionTitle>
                <FormattedMessage id={"common.request_info.specialities"}/>
            </RequestSectionTitle>
            <p>
                <EmptyValue value={_.get(data, 'specialities', []).map(s => s.label).join(', ')}/>
            </p>
            <Separator/>
            <RequestSectionTitle>
                <FormattedMessage id={"common.request_info.diagnostic"}/>
            </RequestSectionTitle>
            <p>
                <EmptyValue value={_.get(data, `diagnostic.${lang}`)}/>
            </p>
            <Separator/>
            <RequestSectionTitle>
                <FormattedMessage id={"common.request_info.specials"}/>
            </RequestSectionTitle>
            {
                _.get(data, `specials.${lang}`, '') ?
                    <TextEllipsis
                        hideMoreText={intl.formatMessage({id: 'common.more_hide_all'})}
                        showMoreText={intl.formatMessage({id: 'common.more_show_all'})}
                        text={_.get(data, `specials.${lang}`, '')}/> :
                    <EmptyValue value={''}/>
            }
            {
                !noDisplayFiles &&
                <React.Fragment>
                    <Separator/>
                    <RequestSectionTitle>
                        <FormattedMessage id={"common.request_info.files"}/>
                    </RequestSectionTitle>
                    {
                        data.files.length ?
                            <RequestFileList
                                className="Request-info__files"
                                onClickFile={handleOpenFile}
                                list={data.files}
                                lang={lang}/> :
                            <FormattedMessage id={"common.request_info.no_files"}/>
                    }
                </React.Fragment>
            }
        </div>
    )
};

RequestInfo.propTypes = {
    data: PropTypes.object.isRequired,
    currentLang: PropTypes.object,
    noDisplayFiles: PropTypes.bool,
};

RequestInfo.defaultProps = {
    noDisplayFiles: false,
};
