import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect, useSelector} from 'react-redux';
import {faCircle, faHospital} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormattedMessage, useIntl} from 'react-intl';
import _ from 'lodash';

import './ViewClinic.scss';
import * as viewClinicActions from '../../redux/viewClinic/actions';
import * as viewClinicSelectors from '../../redux/viewClinic/selectors';
import * as translationsSelectors from '../../redux/translations/selectors';
import pageNames from '../../constants/PageNames';
import Hint from '../common/Hint/Hint';
import Spinner from '../Spinner/Spinner';
import Photo from '../Photo/Photo';
import TextEllipsis from '../TextEllipsis/TextEllipsis';
import {
    MAX_DISPLAYED_DEPARTMENTS,
    MAX_DISPLAYED_DOCTORS,
    MAX_DISPLAYED_FILES,
    MAX_DISPLAYED_SPECIALITIES,
    MAX_TRUNCATED_TEXT_LENGTH,
} from '../../constants/AppConfig';
import List from '../List/List';
import ViewFiles from '../ViewFiles/ViewFiles';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab';
import DoctorCard from '../DoctorCard/DoctorCard';
import LocationStateFroms from '../../constants/LocationStateFroms';
import {RowDisplayField} from '../RowDisplayField/RowDisplayField';
import FileItem from "../ViewFiles/components/FileItem";
import Price from "../Price/Price";

const COLUMNS_COUNT = 2;

const ViewClinic = React.memo(
    ({
         initViewClinic, id, className, isLoading, isTranslationLoaded,
         clinic, error, specialists, departments,
     }) => {
        const intl = useIntl();
        const lang_com = useSelector(viewClinicSelectors.getLangCommunication);

        useEffect(() => {
            initViewClinic(id);
        }, [initViewClinic, id]);

        return (
            <div className={cn(className, 'Health-view-clinic')}>
                {
                    isTranslationLoaded && !isLoading && clinic && !error &&
                    <React.Fragment>
                        <div className="Health-view-clinic__general mb-3">
                            <div className="Health-view-clinic__photo-wrapper Align-stretch bg-white">
                                <figure className="figure">
                                    <Photo
                                        fallbackIcon={faHospital}
                                        fallbackIconSize={'8x'}
                                        imageClassName="img-fluid figure__img" src={clinic.url}/>
                                    <figcaption
                                        className="figure__caption mt-1">
                                        {
                                            clinic.languages.map((lang) => (<span>{lang}</span>))
                                        }
                                    </figcaption>
                                </figure>
                            </div>
                            <div className="Flex-1">
                                <div className="ml-2">
                                    <h2>{clinic.name}</h2>
                                    <RowDisplayField labelKey="viewClinic.main_spec">
                                        <ul className="text-bold">
                                            {
                                                clinic.mainSpecialities.map((s, i) => (<li key={i}>{s}</li>))
                                            }
                                        </ul>
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewClinic.year">
                                        {clinic.year}
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewClinic.country">
                                        {clinic.country ? clinic.country.name : null}
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewClinic.city">
                                        {clinic.city}
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewClinic.clinic_ceo">
                                        {clinic.ceo.name}
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewClinic.price">
                                        <Price
                                            value={_.get(clinic, 'consult.value')}
                                            currency={_.get(clinic, 'consult.symbol')}/>
                                    </RowDisplayField>
                                    <RowDisplayField labelKey="viewClinic.translate_price">
                                        {
                                            clinic.consult.translate ?
                                                <Price
                                                    value={_.get(clinic, 'consult.translate')}
                                                    currency={_.get(clinic, 'consult.symbol')}/>
                                                : '-'
                                        }
                                    </RowDisplayField>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <h3><FormattedMessage id="viewClinic.tab_speciality"/></h3>
                            <List
                                columns={COLUMNS_COUNT}
                                renderItem={
                                    (item) => (
                                        <React.Fragment>
                                            <FontAwesomeIcon
                                                className="Health-list__item-style"
                                                icon={faCircle} size={'xs'}/>
                                            <span>{item}</span>
                                        </React.Fragment>
                                    )
                                }
                                isTruncate
                                hideText={intl.formatMessage({id: 'common.more_hide_all'})}
                                showMoreText={intl.formatMessage({id: 'common.more_show_all'})}
                                maxCount={MAX_DISPLAYED_SPECIALITIES}
                                items={clinic.specialities}/>
                        </div>
                        <div className="mb-3">
                            <h3><FormattedMessage id="viewClinic.clinic_lang_com"/></h3>
                            {
                                (lang_com || []).map((lang, index) => (
                                    <React.Fragment key={_.get(lang, 'code')}>
                                        <span>{_.get(lang, 'label')}</span>
                                        {
                                            _.get(lang, 'translate', false) &&
                                            <sup>*</sup>
                                        }
                                        {
                                            (index !== lang_com.length - 1) &&
                                            ', '
                                        }
                                    </React.Fragment>
                                ))
                            }
                            <Hint className="mt-1">
                                <FormattedMessage id={"viewClinic.translate_hint"}/>
                            </Hint>
                        </div>
                        <div className="mb-3">
                            <h3><FormattedMessage id="viewClinic.clinic_about"/></h3>
                            <TextEllipsis
                                text={clinic.about}
                                hideMoreText={intl.formatMessage({id: 'common.more_hide_all'})}
                                showMoreText={intl.formatMessage({id: 'common.more_show_all'})}
                                length={MAX_TRUNCATED_TEXT_LENGTH}/>
                        </div>
                        <ViewFiles
                            renderItem={(item) => <FileItem item={item}/>}
                            hideText={intl.formatMessage({id: 'common.more_hide_all'})}
                            showMoreText={intl.formatMessage({id: 'common.more_show_all'})}
                            maxCount={MAX_DISPLAYED_FILES}
                            columns={2}
                            files={clinic.files}/>
                        <div>
                            <h3><FormattedMessage id="viewClinic.clinic_struct"/></h3>
                            <Tabs>
                                <Tab tabId="specialities" title={intl.formatMessage({id: 'viewClinic.tab_specialist'})}>
                                    <List
                                        className="mt-3"
                                        renderItem={
                                            (doctor) =>
                                                <DoctorCard
                                                    from={LocationStateFroms.viewClinic}
                                                    className="mb-2"
                                                    doctor={doctor}/>
                                        }
                                        hideText={intl.formatMessage({id: 'common.more_hide_all'})}
                                        showMoreText={intl.formatMessage({id: 'common.more_show_all'})}
                                        isTruncate
                                        columns={COLUMNS_COUNT}
                                        maxCount={MAX_DISPLAYED_DOCTORS}
                                        items={specialists}/>
                                </Tab>
                                <Tab tabId="departments" title={intl.formatMessage({id: 'viewClinic.tab_speciality'})}>
                                    <div className="mt-3 ml-2">
                                        <List
                                            hideText={intl.formatMessage({id: 'common.more_hide_all'})}
                                            showMoreText={intl.formatMessage({id: 'common.more_show_all'})}
                                            isTruncate
                                            columns="1"
                                            maxCount={MAX_DISPLAYED_DEPARTMENTS}
                                            items={departments}
                                            renderItem={
                                                (department) =>
                                                    <div className="Department mb-3">
                                                        <h3>{department.speciality}</h3>
                                                        <List
                                                            className="mt-3 mb-2"
                                                            renderItem={
                                                                (doctor) =>
                                                                    <DoctorCard
                                                                        from={LocationStateFroms.viewClinic}
                                                                        className="mb-2"
                                                                        doctor={doctor}/>
                                                            }
                                                            hideText={intl.formatMessage({id: 'common.more_hide_all'})}
                                                            showMoreText={intl.formatMessage({id: 'common.more_show_all'})}
                                                            isTruncate
                                                            columns={COLUMNS_COUNT}
                                                            maxCount={MAX_DISPLAYED_DOCTORS}
                                                            items={department.doctors}/>
                                                    </div>
                                            }
                                        />
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </React.Fragment>
                }
                <Spinner isLoading={isLoading}/>
                {
                    error &&
                    <div>Ошибка загрузки клиники</div>
                }
            </div>
        );
    },
);

ViewClinic.propTypes = {
    id: PropTypes.any.isRequired,
    modalView: PropTypes.bool,
};
ViewClinic.defaultProps = {
    modalView: false,
};
ViewClinic.displayName = 'HealthViewClinic';

const mapStateToProps = (state) => {
    return {
        isLoading: viewClinicSelectors.isLoadingSelector(state),
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, pageNames.ViewClinic),
        clinic: viewClinicSelectors.getClinicDataSelector(state),
        error: viewClinicSelectors.getClinicErrorSelector(state),
        specialists: viewClinicSelectors.getSpecialists(state),
        departments: viewClinicSelectors.getDepartments(state),
    };
};

export default connect(mapStateToProps, {
    initViewClinic: viewClinicActions.initViewClinic,
})(ViewClinic);
