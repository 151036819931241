import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useField} from 'react-final-form';

import './LanguagesCommunication.scss';
import {hasError} from '../../../helpers/Common';
import CheckboxField from '../../common/Checkbox/CheckboxField';
import TagListField from '../../common/TagList/TagListField';
import TagList from '../../common/TagList/TagList';
import ConditionField from '../../fields/ConditionField';
import SyncFields from '../../fields/SyncFields';

export const LanguagesCommunication = (
    {className, title, hint, name, placeholder, languages, syncLangName, syncText, fromLangName},
) => {
    const langsFiled = useField(name);
    const syncFiled = useField(syncLangName);
    const fromLangNameField = useField(fromLangName);

    const transformFn = useCallback((codes) => {
        return languages.filter(l => codes.includes(l.code));
    }, [languages]);

    const handleChangeSyncLangs = useCallback((sync) => {
        if (!!sync) {
            const codes = fromLangNameField.input.value || [];
            langsFiled.input.onChange(transformFn(codes));
        }
    }, [fromLangNameField.input.value, langsFiled.input, transformFn]);

    return (
        <fieldset className={cn(className, 'Health-fieldset Languages-communication')}>
            <legend className="Health-fieldset__legend">
                {title}
            </legend>
            <ConditionField when={syncLangName} is={true}>
                <SyncFields
                    transform={transformFn}
                    fieldFrom={fromLangName}
                    fieldTo={name} />
            </ConditionField>
            <div className="row">
                <CheckboxField
                    onChange={handleChangeSyncLangs}
                    className="col-12"
                    name={syncLangName}>
                    {syncText}
                </CheckboxField>
            </div>
            <div className="row">
                <TagListField
                    disabledRemoveTags={syncFiled.input.value}
                    disabled={syncFiled.input.value}
                    name={name}
                    multiSelecting
                    showSuggestionsInFocus
                    showArrow
                    className={cn('col-12 Specialities-field', hasError(langsFiled.meta) && 'Health-taglist--error')}
                    inputContainerClass="Languages-communication__input"
                    tagsContainerClass="Languages-communication__tags"
                    hint={hint}
                    tagsPosition="right"
                    minQueryLength={0}
                    shouldExcludeTags
                    suggestions={languages}
                    handleFilterSuggestions={TagList.FilterSuggestions.withStartWordFilter}
                    renderSuggestion={TagList.RenderSuggestions.simpleRender}
                    placeholder={placeholder} />
            </div>
        </fieldset>
    )
};

LanguagesCommunication.propTypes = {
    placeholder: PropTypes.string,
    hint: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
    })),
    syncLangName: PropTypes.string.isRequired,
    syncText: PropTypes.string,
    fromLangName: PropTypes.string,
};

LanguagesCommunication.defaultProps = {};

LanguagesCommunication.displayName = 'LanguagesCommunication';
