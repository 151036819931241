import React, {PureComponent} from 'react';
import _ from 'lodash';

import './MessagesContainer.scss';
import {ROLES, toUserRole} from '../../../../constants/Roles';
import Spinner from '../../../Spinner/Spinner';
import {MessageBackgroundColors} from '../../constants/MessageBackgroundColors';
import MessagePositions from '../../constants/MessagePositions';
import MessageBox from '../MessageBox/MessageBox';
import { Message } from '../../Chat.interface';

interface Props {
    messages: Message[]
    me: unknown;
    fetching: boolean,
    hasMore: boolean,
    allowChatting: boolean,
    scrollLoadThreshold: number,
    onLoadPrevious: () => void;
    onMarkMessageRead(messageId: number): void;
    lang: string,
    langCom: string,
    role: string,
}

class MessagesContainer extends PureComponent<Props> {
    static defaultProps = {
        messages: [],
        fetching: false,
        hasMore: false,
        scrollLoadThreshold: 10,
    };

    static displayName = 'HealthMessagesContainer';

    private rafRequestId: null | number;
    private scrollTop: number;
    private scrollableRef: React.RefObject<any>;
    private prevMessageOwnerId: undefined | number;
    private prevMessagePosition:  undefined | string;

    constructor(props) {
        super(props);

        this.rafRequestId = null; // for cleaning up outstanding requestAnimationFrames on WillUnmount
        this.scrollTop = 0;
        this.scrollableRef = React.createRef();

        this.prevMessageOwnerId = undefined;
        this.prevMessagePosition = undefined;
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (prevProps.messages.length !== this.props.messages.length || prevProps.fetching !== this.props.fetching) {
            const scrollable = this.scrollableRef.current;
            return {
                isScrollDown: scrollable.scrollHeight === (scrollable.scrollTop + scrollable.clientHeight),
                bottomOffset: scrollable.scrollHeight - scrollable.scrollTop,
                topOffset: scrollable.scrollTop,
                prevScrollHeight: scrollable.scrollHeight,
            };
        }
        return null;
    }

    componentDidMount() {
        this.rafRequestId = window.requestAnimationFrame(this.pollScroll);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevMessages = prevProps.messages;
        const messages = this.props.messages;
        const prevMessagesLength = prevProps.messages.length;
        const messagesLength = this.props.messages.length;

        if (prevMessagesLength === 0 && messagesLength !== 0) {
            this.scrollToBottom();
        } else if (prevMessagesLength !== messagesLength) {
            if (messagesLength > prevMessagesLength &&
                prevMessages[prevMessagesLength - 1].id !== messages[messagesLength - 1].id &&
                `${messages[messagesLength - 1].user.id}` === this.props.me) {
                this.scrollToBottom();
            } else {
                if (snapshot.isScrollDown) {
                    this.scrollToBottom();
                }
            }
        }
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.rafRequestId);
    }

    scrollToBottom() {
        this.scrollableRef.current.scrollTop = this.scrollableRef.current.scrollHeight;
    }

    onScroll = () => {
        if (this.scrollableRef.current.scrollTop !== this.scrollTop) {
            if (this.shouldTriggerLoad()) {
                this.props.onLoadPrevious();
            }
            this.scrollTop = this.scrollableRef.current.scrollTop;
        }
    };

    pollScroll = () => {
        this.onScroll();
        this.rafRequestId = window.requestAnimationFrame(this.pollScroll);
    };

    isPassedThreshold = (scrollLoadThreshold, scrollTop, direction, hasMore, fetching) => {
        return hasMore && !fetching && (scrollTop <= scrollLoadThreshold) && direction < 0;
    };

    shouldTriggerLoad() {
        return this.isPassedThreshold(
            this.props.scrollLoadThreshold,
            this.scrollableRef.current.scrollTop,
            this.scrollableRef.current.scrollTop - this.scrollTop,
            this.props.hasMore,
            this.props.fetching);
    }

    isMe = (message: Message) => {
        return message.user.id === this.props.me;
    };

    canMessageActions = (message: Message) => {
        const {role, allowChatting} = this.props;
        const isMe = this.isMe(message);

        if (!allowChatting) {
            return false;
        }

        return (role === ROLES.PARTNER || role === ROLES.ADMIN_CLINIC) ? true : !isMe;
    };

    getMessagePosition = (message: Message) => {
        const {role, lang, langCom} = this.props;
        const isMe = this.isMe(message);

        // default strategy
        if (role === ROLES.PATIENT || role === ROLES.DOCTOR) {
            return isMe ? MessagePositions.Right : MessagePositions.Left;
        }

        // coordinator
        if (role === ROLES.ADMIN_CLINIC || role === ROLES.PARTNER) {
            const senderRole = toUserRole(message.user.rights);

            if (isMe) {
                if (message.lang === lang) { // patient
                    return MessagePositions.Right;
                }
                if (message.lang === langCom) { // doctor
                    return MessagePositions.Left;
                }
            } else if (senderRole === ROLES.PATIENT) {
                return MessagePositions.Right;
            } else if (senderRole === ROLES.DOCTOR) {
                return MessagePositions.Left;
            }
        }

        return MessagePositions.Left;
    };

    getMessageBackground = (message: Message) => {
        const {role} = this.props;
        const isMe = this.isMe(message);

        // default strategy
        if (role === ROLES.PATIENT || role === ROLES.DOCTOR) {
            return isMe ? MessageBackgroundColors.Green : MessageBackgroundColors.Gray;
        }

        // coordinator
        if (role === ROLES.ADMIN_CLINIC || role === ROLES.PARTNER) {
            const senderRole = toUserRole(message.user.rights);

            if (isMe) {
                return MessageBackgroundColors.Green;
            } else if (senderRole === ROLES.PATIENT) {
                return MessageBackgroundColors.Gray;
            } else if (senderRole === ROLES.DOCTOR) {
                return MessageBackgroundColors.Blue;
            }
        }

        return MessageBackgroundColors.Gray;
    }

    renderMessage = (message: Message) => {
        const {me, onMarkMessageRead} = this.props;
        const position = this.getMessagePosition(message);
        const ownerId = _.get(message, ['user', 'id']);
        let isRenderMeta = !(this.prevMessageOwnerId === ownerId && this.prevMessagePosition === position);

        this.prevMessageOwnerId = ownerId;
        this.prevMessagePosition = position;

        return (
            <MessageBox
                onMarkMessageRead={onMarkMessageRead}
                //
                background={this.getMessageBackground(message)}
                position={position}
                isMe={this.isMe(message)}
                isRenderMeta={isRenderMeta}
                canActions={this.canMessageActions(message)}
                key={message.id}
                message={message} />
        )
    }

    render() {
        const {messages, fetching} = this.props;
        this.prevMessageOwnerId = undefined;
        this.prevMessagePosition = undefined;

        return (
            <div className={'Health-chat__messages-container'} ref={this.scrollableRef}>
                <Spinner
                    isBlocked={false}
                    isLoading={fetching} />
                {
                    messages.map((message) => this.renderMessage(message))
                }
            </div>
        )
    }
}

export default MessagesContainer;
