import Immutable from 'seamless-immutable';

import {RequestStatuses} from '../../../constants/RequestStatuses';
import {
    REQUEST_PAGE_REQUEST_SELECT_CLINIC,
    REQUEST_PAGE_REQUEST_SELECT_DOCTOR,
    REQUEST_PAGE_REQUEST_HELP_ME,
    REQUEST_PAGE_REQUEST_SAVING,
    REQUEST_PAGE_REQUEST_SAVED,
    REQUEST_PAGE_REQUEST_SET_FORM_DATA,
    REQUEST_PAGE_REQUEST_UPDATE_FORM_DATA,
    REQUEST_PAGE_REQUEST_ERROR,
    REQUEST_PAGE_REQUEST_DECLINED,
    REQUEST_PAGE_REQUEST_LOADING,
    REQUEST_PAGE_REQUEST_LOADED,
    REQUEST_PAGE_REQUEST_CONFIRMED,
    REQUEST_PAGE_REQUEST_NEW,
    REQUEST_PAGE_REQUEST_SET_COM_LANGS,
    REQUEST_PAGE_REQUEST_CHANGE_LANG,
} from './actions';
import {REQUEST_PAGE_BEFORE_LOADING_REQUEST} from '../actions';
import {transformFormDataToRequestData, transformRequestToFormData} from './utils';

const INITIAL_STATE = {
    requestId: null,
    loading: false,
    error: null,
    data: {
        status: RequestStatuses.Draft,
    },
    original: {
        status: RequestStatuses.Draft,
    },
    saving: false,
    declined: false,
    com_langs: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REQUEST_PAGE_REQUEST_NEW:
        case REQUEST_PAGE_BEFORE_LOADING_REQUEST:
            return Immutable.merge(INITIAL_STATE, {
                data: {
                    lang: action.payload.lang,
                },
                original: {
                    lang: action.payload.lang,
                },
                com_langs: [],
            }, {deep: true});
        case REQUEST_PAGE_REQUEST_CHANGE_LANG:
            const currentLang = Immutable.getIn(state, ['data', 'lang']);
            const nextLang = action.payload;
            const data = Immutable.without(state.data, currentLang).asMutable();
            const formData = transformRequestToFormData(data);

            return Immutable.merge(state, {
                data: transformFormDataToRequestData({
                    ...formData,
                    lang: nextLang,
                }),
            }, {deep: true});
        case REQUEST_PAGE_REQUEST_SET_COM_LANGS:
            return Immutable.merge(state, {
                com_langs: action.payload,
            }, {deep: true});
        case REQUEST_PAGE_REQUEST_SELECT_CLINIC:
            const {specialities, ...clinic} = action.payload;
            return Immutable.merge(state, {
                data: {
                    doctor: null,
                    helpMe: false,
                    clinic,
                    specialities: specialities || [],
                    country: action.payload.country.id,
                },
            }, {deep: true});
        case REQUEST_PAGE_REQUEST_SELECT_DOCTOR:
            return Immutable.merge(state, {
                data: {
                    doctor: action.payload.doctor,
                    specialities: action.payload.specialities,
                    helpMe: false,
                    clinic: null,
                    country: action.payload.doctor.country.id,
                },
            }, {deep: true});
        case REQUEST_PAGE_REQUEST_HELP_ME:
            if (!Immutable.getIn(state, ['data', 'helpMe'], false)) {
                return Immutable.merge(state, {
                    data: {
                        doctor: null,
                        helpMe: true,
                        clinic: null,
                        specialities: action.payload.specialities || [],
                        country: action.payload.country,
                    },
                }, {deep: true});
            } else if (Immutable.getIn(state, ['data', 'country']) !== action.payload) {
                return Immutable.merge(state, {
                    data: {
                        country: action.payload,
                    },
                }, {deep: true});
            }
            return state;
        case REQUEST_PAGE_REQUEST_ERROR:
            return Immutable.merge(state, {
                loading: false,
                saving: false,
                error: action.payload,
            });
        case REQUEST_PAGE_REQUEST_SAVING:
            return Immutable.merge(state, {
                saving: true,
                error: null,
            });
        case REQUEST_PAGE_REQUEST_SAVED:
            return Immutable.merge(state, {
                saving: false,
                original: state.data,
                error: null,
            }, {deep: true});
        case REQUEST_PAGE_REQUEST_LOADING:
            return Immutable.merge(state, {
                loading: true,
                error: null,
            });
        case REQUEST_PAGE_REQUEST_LOADED:
            return Immutable.merge(state, {
                requestId: action.payload.id,
                loading: false,
                data: action.payload.request,
                original: action.payload.request,
                error: null,
                declined: false,
            }, {deep: true});
        case REQUEST_PAGE_REQUEST_CONFIRMED:
            if (Immutable.getIn(state, ['data', 'doctor_new'], null)) {
                const newData = {
                    ...state.data,
                    doctor: Immutable.getIn(state, ['data', 'doctor_new']),
                };
                delete newData['doctor_new'];
                return Immutable.merge(state, {
                    saving: false,
                    error: null,
                    data: newData,
                    original: newData,
                });
            }
            return Immutable.merge(state, {
                saving: false,
                error: null,
            }, {deep: true});
        case REQUEST_PAGE_REQUEST_DECLINED:
            return Immutable.set(state, 'declined', true);
        case REQUEST_PAGE_REQUEST_SET_FORM_DATA:
            return Immutable.merge(state, {
                data: action.payload,
            });
        case REQUEST_PAGE_REQUEST_UPDATE_FORM_DATA:
            return Immutable.merge(state, {
                data: action.payload,
            }, {deep: true});
        default:
            return state;
    }
}
