import React, {useCallback} from 'react';
import {
	faFileAlt,
	faFileUpload,
	faComments,
} from '@fortawesome/free-solid-svg-icons';

import {withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';

import './OverviewHeader.scss';
import OverviewAction from '../OverviewAction/OverviewAction';
import OverviewUploadFileAction from '../OverviewAction/OverviewUploadFileAction';
import * as medicalRecordActions from 'redux/medicalRecordTablePage/actions';
import * as notifyActions from 'redux/notifier/actions';
import {getStatisticsSelector} from 'redux/overviewPage/selectors';
import { getUnreadChatMessagesCountSelector } from 'redux/common/selectors';

import {showAddOrEditMedicalRecordModal} from 'redux/modal/actions';

import {getFilename} from '../../../../helpers/Common';
import OverviewProfileAction from './components/OverviewProfileAction/OverviewProfileAction';
import {RequestsQuickFilterStatuses} from 'constants/RequestsQuickFilterStatuses';

const CREATE_REQUEST_URL = '/main/requests/new';

const OverviewHeader = ({history}) => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const stats = useSelector(getStatisticsSelector);
    const unreadChatMessagesCount = useSelector(getUnreadChatMessagesCountSelector)

	const handleCreateNewRequest = useCallback(() => {
		history.push(CREATE_REQUEST_URL);
	}, [history]);

	const handleUploadFile = useCallback(
		uploadedFile => {
			dispatch(
				showAddOrEditMedicalRecordModal({
					isAdd: true,
					file: {
						name: getFilename(uploadedFile.name),
						type: 0,
					},
					onAddOrEdit: async ({name, type, date}) => {
						try {
							await dispatch(
								medicalRecordActions.uploadMedicalRecord({
									name,
									type,
									date,
									file: uploadedFile,
								}),
							);
							dispatch(
								notifyActions.notifySuccess(
									intl.formatMessage({
										id: 'overview.notifications.file_uploaded',
									}),
								),
							);
						} catch (error) {
							dispatch(
								notifyActions.notifyError(
									intl.formatMessage({
										id: 'overview.notifications.file_upload_error',
									}),
								),
							);
						}
					},
				}),
			);
		},
		[dispatch, intl],
	);

	const handleRedirectRequest = useCallback(
		status => {
			history.push(`/main/requests?s=${status}`);
		},
		[history],
	);
    

	return (
		<div className={'Overview-header'}>
			<OverviewAction
				title={intl.formatMessage({
					id: 'overview.actions.create_request',
				})}
				icon={faFileAlt}
				variant={'green'}
				onClick={handleCreateNewRequest}
			/>

			<OverviewAction
				disabled={!stats.active}
				renderBadge={() => <span>{stats.active}</span>}
				renderBottomBadge={() => <span>{stats.income}</span>}
				title={intl.formatMessage({
					id: 'overview.actions.current_requests',
				})}
				icon={faFileAlt}
				onClick={() =>
					handleRedirectRequest(RequestsQuickFilterStatuses.Active)
				}
			/>

			<OverviewAction
				disabled={!stats.consult}
				renderBadge={() => <span>{stats.consult}</span>}
				renderBottomBadge={() => unreadChatMessagesCount}
				title={intl.formatMessage({id: 'overview.actions.consults'})}
				icon={faComments}
				onClick={() =>
					handleRedirectRequest(RequestsQuickFilterStatuses.Consult)
				}
			/>
			<OverviewUploadFileAction
				onUploadFile={handleUploadFile}
				title={intl.formatMessage({id: 'overview.actions.upload_file'})}
				icon={faFileUpload}
				variant={'primary'}
			/>
			<OverviewProfileAction />
		</div>
	);
};

OverviewHeader.displayName = 'OverviewHeader';

export default withRouter(OverviewHeader);
