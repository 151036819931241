import {useEffect, useState} from "react";

import type { Option, SelectedOption, MenuOption } from '../SelectBox.interface';

const emptyOptions = [];

export const useMenuOptions = (
    options: Option[],
    staticOptions = emptyOptions as Option[],
    inputValue: string,
    selectedOption: SelectedOption,
    getOptionLabel: (o: Option) => string,
    getOptionValue: (o: Option) => number,
    excludeSelected: boolean,
    searchable: boolean,
) => {
    const [menuOptions, setMenuOptions] = useState<MenuOption[]>([]);

    useEffect(() => {
        const searchValue = searchable ? inputValue : '';
        const selectedValue = selectedOption ? selectedOption.value : null;

        const isOptionFilterMatch = (option: MenuOption) => {
            const optionLabel = option.label || '';

            // eslint-disable-next-line
            if (excludeSelected && `${option.value}` === `${selectedValue}`) {
                return false;
            }

            return searchable ?
                optionLabel.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 :
                true
        }

        const parseMenuOption = (option: Option) => {
            const value = getOptionValue(option);
            const label = getOptionLabel(option);

            const menuOption: MenuOption = {
                original: option,
                value,
                label,
                isSelected: `${value}` === `${selectedValue}`,
            };

            if (!isOptionFilterMatch(menuOption)) {
                return;
            }

            return menuOption;
        }

        const nextOptions = [...staticOptions, ...options].reduce((agg, option) => {
            const menuOption = parseMenuOption(option);

            if (menuOption) {
                agg.push(menuOption);
            }
            return agg;
        }, [] as MenuOption[])

        setMenuOptions(nextOptions);
    }, [excludeSelected, getOptionLabel, getOptionValue, inputValue, options, searchable, selectedOption, staticOptions])

    return menuOptions;
}