import React from 'react';

import SpanCell from './SpanCell';
import type { CellProps } from '../SimpleTable.interface';

interface Props extends CellProps {
    labelField: string;
    className?: string;
}

const CommonCell = React.memo(({labelField, cell: {value}, className}: Props) => {
    return (
        <SpanCell className={className}>
            {
                labelField && typeof value === 'object' ?
                    value[labelField] :
                    value
            }
        </SpanCell>
    )
});


export default CommonCell;
