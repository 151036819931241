import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';

export const BackLink = React.memo(
    ({
         className, text, to,
     }) => {
        return (
            <Link
                className={cn('Health-back-link Health-button Health-button--frameless Health-button--primary', className)}
                to={to}>
                <FontAwesomeIcon
                    className="mr-1"
                    size="lg"
                    icon={faLongArrowAltLeft} />
                {text}
            </Link>
        )
    },
);

BackLink.propTypes = {
    text: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
};

BackLink.defaultProps = {};

BackLink.displayName = 'HealthBackLink';


