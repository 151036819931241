import React from 'react';

import TagListField from 'components/common/TagList/TagListField';
import TagList from 'components/common/TagList/TagList';

import { useTranslation } from 'libs/hooks/use-translation';

interface Props {
    name?: string;
    items: any[]
}

export const CountryListField = ({
    name = 'countries',
    items
}: Props) => {
    const {t} = useTranslation();

	return (
		<TagListField
				    //@ts-ignore
			className="mt-2"
		//	staticOptions={staticOptions}
			placeholder={t('common.letter_help')}
			inputContainerClass="row m-0"
			labelClass="col-4 text-uppercase"
			inputClass="col-8"
			label={t('common.filter.country')}
			shouldExcludeTags
			minQueryLength={0}
			labelField="label"
			suggestions={items}
			handleFilterSuggestions={TagList.FilterSuggestions.withStartFilter}
			renderSuggestion={TagList.RenderSuggestions.simpleRender}
			name={name}
		/>
	);
};
