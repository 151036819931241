import React from "react";
import cn from "classnames";
import {useSelector} from "react-redux";

import './OverviewNotifications.scss';
import {getNotificationsSelector} from "../../../../redux/overviewPage/selectors";
import OverviewNotificationPatient from "./components/OverviewNotificationPatient/OverviewNotificationPatient";

const OverviewNotifications = ({className}) => {
    const notifications = useSelector(getNotificationsSelector);

    return (
        <div className={cn("Overview-notifications", className)}>
            {
                notifications.map((notification) =>
                    <OverviewNotificationPatient
                        key={notification.id}
                        data={notification}/>
                )
            }
        </div>
    )
}

OverviewNotifications.displayName = 'OverviewNotifications';

export default OverviewNotifications;
