import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {isString, get} from 'lodash';

import './RequestFileItem.scss';
import {getFileExtension} from '../../../../../helpers/Common';
import FileIcon from '../../../../common/FileIcon/FileIcon';

export const RequestFileItem = ({file, lang, onClick, children}) => {
    const handleClick = useCallback(() => {
        onClick && onClick(file);
    }, [file, onClick]);

    const fileExt = useMemo(() => {
        // from server
        if (isString(file.file)) {
            return file.ext;
        }
        const fileName = get(file, 'file.name');

        return getFileExtension(fileName);
    }, [file]);

    return (
        <div className="Request-file-list__item">
            <FileIcon
                color="#768c99"
                ext={fileExt}
                onClick={handleClick}
                className="mr-1"/>
            <span
                onClick={handleClick}
                className="Request-file-list__item_text">
                {file[lang]}
            </span>
            {children}
        </div>
    )
};

RequestFileItem.propTypes = {
    onClick: PropTypes.func,
    file: PropTypes.object,
    lang: PropTypes.string,
};
