import React, {useState} from 'react';
import cn from 'classnames';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import type {IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core';
import './Photo.scss';

interface Props {
	className?: string;
	alt?: string;
	title?: string;
	src: string;
	imageClassName?: string;
	fallbackIcon: IconProp;
	fallbackIconSize: SizeProp;
	avatar?: boolean;
	size?: SizeProp;
	onClick?: React.MouseEventHandler;
}

const Photo: React.FC<Props> = ({
	className,
	imageClassName,
	fallbackIcon,
	fallbackIconSize,
	avatar,
	size = 'sm',
	src = '',
	...props
}) => {
	const [errorState, setErrorState] = useState(false);

	const classes = cn(
		className,
		'Health-photo',
		avatar && 'Health-photo--avatar',
		avatar && size && `Health-photo--avatar-${size}`,
	);

	return (
		<div className={classes}>
			{errorState ? (
				<span {...props} className="Health-photo__fallback-wrapper">
					<FontAwesomeIcon
						className="Health-photo__fallback"
						size={fallbackIconSize}
						icon={fallbackIcon}
					/>
				</span>
			) : (
				<img
					{...props}
					src={src}
					className={imageClassName}
					onError={() => setErrorState(true)}
					alt={props.alt || src}
				/>
			)}
		</div>
	);
};

Photo.displayName = 'HealthPhoto';

export default Photo;
