import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera} from "@fortawesome/free-solid-svg-icons";

import './PhotoUploader.scss';

class PhotoUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state.value = !!props.value ? props.value : null;
    }

    static propTypes = {
        uploadText: PropTypes.string,
        changeText: PropTypes.string,
        removeText: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        name: PropTypes.string
    };

    state = {
        value: null
    };

    static defaultProps = {};

    static displayName = 'HealthPhotoUploader';

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            })
        }
    }

    handleOnChange = (event) => {
        if (event.target.files.length && event.target.files[0]) {
            const reader = new FileReader();
            const file = event.target.files[0];
            reader.onloadend = () => {
                const value = {
                    name: file.name,
                    result: reader.result
                };
                this.setState(() => ({
                    value
                }), () => {
                    this.props.onChange && this.props.onChange(value);
                });
            };

            reader.readAsDataURL(event.target.files[0]);
        }
    };

    handleRemove = (event) => {
        this.setState(() => ({
            value: null
        }));
        this.props.onChange && this.props.onChange(null);
    };

    render() {
        const {
            className, changeText, removeText, uploadText, name
        } = this.props;
        const {value} = this.state;
        const classes = cn('Health-photo-uploader', 'Flex-column', className);
        let avatarUrl;
        if (typeof value === 'string' && !!value) {
            avatarUrl = value;
        } else if (typeof value === 'object' && value && !!value.result) {
            avatarUrl = value.result;
        }

        return (
            <div className={classes}>
                {
                    !avatarUrl ?
                        <label className={`Health-photo-uploader__upload Justify-center Align-center Flex-column`}>
                            <FontAwesomeIcon icon={faCamera} size={'2x'}/>
                            <div className="Health-photo-uploader__upload-label">{uploadText}</div>
                            <input name={name}
                                   type='file'
                                   accept="image/jpeg, image/png"
                                   className={`Health-photo-uploader__input`}
                                   onChange={this.handleOnChange}/>
                        </label> :
                        <img className="Health-photo-uploader__avatar"
                             src={avatarUrl}/>
                }
                <label className={`Health-photo-uploader__change`}>
                    <span>{changeText}</span>
                    <input name={name}
                           type='file'
                           accept="image/jpeg, image/png"
                           className={`Health-photo-uploader__input`}
                           onChange={this.handleOnChange}/>
                </label>
                <div className={`Health-photo-uploader__remove`}
                     onClick={this.handleRemove}>
                    {removeText}
                </div>
            </div>
        );
    }
}

export default PhotoUploader;
