import React, {useCallback, useMemo, useState} from "react";
import PropTypes from "prop-types";
import AutoSizer from 'react-virtualized-auto-sizer';
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";

import './ConsultationConclusionPreview.scss';
import PdfViewer from "../../../../../../components/PdfViewer/PdfViewer";
import {getPdfConclusionUrl} from "../../../../../../helpers/UrlHelper";
import Button from "../../../../../../components/common/Button/Button";
import {loadConclusionAsFile} from "../../../../../../redux/consultationPage/consultation/actions";
import * as notifierActions from "../../../../../../redux/notifier/actions";
import {hideModal, showAddOrEditMedicalRecordModal} from "../../../../../../redux/modal/actions";
import * as medicalRecordActions from "../../../../../../redux/medicalRecordTablePage/actions";
import {loadConsultation} from '../../../../../../redux/consultationPage/actions';
import {addFileToMedicalRecord} from "../../../../../../redux/consultationPage/consultation/actions";

import Spinner from "../../../../../../components/Spinner/Spinner";
import { MedicalFileTypeEnum } from "../../../../../../constants/FileTypeList";
import { ConclusionPortalToHeader } from './ConclusionPortalToHeader';

const SaveConclusionButton = (props) => (
    <ConclusionPortalToHeader
        intoId="portal-for-conclusion-controls"
    >
        <Button
            className="Consultation-conclusion-preview__saving-button"
            onClick={props.onClick}
        >
            <FormattedMessage id={'request.consultation_conclusion_page.save_conclusion_to_medical'}/>
        </Button>
    </ConclusionPortalToHeader>
);

const ConsultationConclusionPreview = ({
    id,
    lang,
    phpsession,
    unsavedConclusionFile,
    isFileSaved
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const pdfUrl = useMemo(() => {
        return getPdfConclusionUrl(id, lang, phpsession);
    }, [lang, id, phpsession]);
    const [isLoading, setLoading] = useState(false);


    const handleSaveConclusion = useCallback(async () => {
        try {
            setLoading(true);
            const file = await loadConclusionAsFile(pdfUrl);

            const fileName = unsavedConclusionFile ? unsavedConclusionFile[lang] : '';

            dispatch(showAddOrEditMedicalRecordModal({
                isAdd: true,
                disableFileTypeField: true,
                file: {
                    name: fileName,
                    type: MedicalFileTypeEnum.Conclusion,
                    date: new Date(),
                },
                onAddOrEdit: async (fileData) => {
                    const {name, type, date} = fileData;

                    try {
                        if (unsavedConclusionFile) {
                            await dispatch(addFileToMedicalRecord({
                                id: unsavedConclusionFile.id,
                                name: unsavedConclusionFile[lang],
                                type,
                                date,
                            }));
                        } else {
                            await dispatch(medicalRecordActions.uploadMedicalRecord({
                                requestId: id,
                                lang,
                                name,
                                type,
                                date,
                                file,
                            }));
                        }

                        await dispatch(loadConsultation({id}));
                        setLoading(false);
                        dispatch(notifierActions.notifySuccess(intl.formatMessage({id: "request.notifications.file_added_to_medical_records"})));
                    } catch (error) {
                        dispatch(notifierActions.notifyError(intl.formatMessage({id: "request.notifications.error_add_file_to_medical_records"})));
                    }
                },
                onClose: () => {
                    setLoading(false);
                    dispatch(hideModal());
                }
            }))
        } catch (error) {
            setLoading(false);
            dispatch(notifierActions.notifyError(intl.formatMessage({id: "request.notifications.error_add_file_to_medical_records"})));
        }
    }, [dispatch, intl, pdfUrl, unsavedConclusionFile]);

    return (
        <div className="Consultation-conclusion-preview">
           {!isFileSaved && <SaveConclusionButton onClick={handleSaveConclusion} />}

            <div className="Consultation-conclusion-preview__container">
                <div className="Consultation-conclusion-preview__wrapper">
                    <AutoSizer disableHeight>
                        {
                            ({width}) => (
                                <PdfViewer
                                    width={width}
                                    fileUrl={pdfUrl}/>
                            )
                        }
                    </AutoSizer>
                </div>
            </div>
            <Spinner isLoading={isLoading} />
        </div>
    )
}

ConsultationConclusionPreview.displayName = 'ConsultationConclusionPreview';
ConsultationConclusionPreview.propTypes = {
    id: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    phpsession: PropTypes.string.isRequired,
};

export default ConsultationConclusionPreview;
