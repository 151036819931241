import * as api from "../../api";
import {setLoading} from '../loading/actions';
import pageNames from '../../constants/PageNames.js';
import {fetchTranslation} from "../translations/actions";

export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';

export const initConfirmEmailPage = (hash) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    await Promise.all([
        dispatch(confirmEmail(hash)),
        dispatch(fetchTranslation({
            lang: getState().lang,
            pageName: pageNames.ConfirmEmail,
        }))
    ]);
    dispatch(setLoading(false));
};

export const confirmEmail = (hash) => async (dispatch) => {
    try {
        const response = await api.confirmEmail(hash);
        dispatch({
            type: CONFIRM_EMAIL,
            payload: response.data
        });
    } catch (e) {
        dispatch({
            type: CONFIRM_EMAIL,
            payload: e.data
        });
    }
};
