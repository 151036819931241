import React, {useCallback, useContext} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FormattedMessage} from 'react-intl';
import {faHospital} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import './RequestClinicCard.scss';
import Button from '../../../../components/common/Button/Button';
import Photo from '../../../../components/Photo/Photo';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import ViewClinicLink from "../../../../components/Links/ViewClinicLink";
import LocationStateFroms from "../../../../constants/LocationStateFroms";
import Price from "../../../../components/Price/Price";

export const RequestClinicCard = (
    {
        clinic, onSelect,
        className, isSelectable, isSelected,
        isConsultTranslate,
    },
) => {
    const manager = useContext(RequestManagerContext);

    const handleSelect = useCallback(() => {
        if (onSelect) {
            onSelect(clinic);
        }
    }, [clinic, onSelect]);

    const handleView = useCallback((event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        manager.viewClinic(clinic.id, !isSelectable, handleSelect)
    }, [clinic.id, handleSelect, isSelectable, manager]);

    const renderLocation = useCallback(() => {
        const city = _.isObject(clinic.city) ? clinic.city.label : clinic.city;
        const country = _.isObject(clinic.country) ? clinic.country.name || clinic.country.label : clinic.country;

        return (
            <div className="Request-clinic-card__location">
                <span className="text-uppercase">
                    {country}
                </span>
                <br/>
                {
                    city ?
                        <span>{city}</span> : null
                }
            </div>
        )
    }, [clinic.city, clinic.country]);

    const renderSpeciality = useCallback(() => {
        const text = (clinic.mainSpecialities || []).join(',\n');
        return (
            <div
                className="Request-clinic-card__speciality"
                title={text}>
                <span>
                    {text}
                </span>
            </div>
        )
    }, [clinic.mainSpecialities]);

    return (
        <div className={cn('Request-clinic-card', className)}>
            <div className="Request-clinic-card__avatar">
                <Photo
                    avatar
                    size="lg"
                    onClick={handleView}
                    src={clinic.url}
                    alt={clinic.name}
                    fallbackIcon={faHospital}
                    fallbackIconSize={'4x'}/>
                {renderLocation()}
            </div>
            <div className="Request-clinic-card__general">
                <h3>
                    <ViewClinicLink
                        onClick={handleView}
                        id={clinic.id}
                        className="link-text"
                        from={LocationStateFroms.request}>
                        {clinic.name}
                    </ViewClinicLink>
                </h3>
                {renderSpeciality()}
                <div className="Request-clinic-card__languages">
                    <span className="Request-clinic-card__languages_label">
                        <FormattedMessage id="request.clinic_card.language_label"/>:
                    </span>
                    <span
                        title={(clinic.languages || []).join(', ')}
                        className="Request-clinic-card__languages_values">
                        {(clinic.languages || []).join(', ')}
                    </span>
                </div>
                {
                    !!(clinic.lang_com && clinic.lang_com.length) &&
                    <div className="Request-clinic-card__languages">
                        <span className="Request-clinic-card__languages_label">
                            <FormattedMessage id="request.clinic_card.translate_label"/>:
                        </span>
                        <span
                            title={(clinic.lang_com || []).map(l => l.label).join(', ')}
                            className="Request-clinic-card__languages_values">
                            {(clinic.lang_com || []).map(l => l.label).join(', ')}
                        </span>
                    </div>
                }
            </div>
            <div className="Request-clinic-card__actions">
                <div
                    className="Request-clinic-card__consult-title">
                    <FormattedMessage id={"request.clinic_card.consultation_price"}/>
                </div>
                <Price
                    className="block"
                    size="lg"
                    value={_.get(clinic, 'consult.value')}
                    currency={_.get(clinic, 'consult.symbol')}/>
                {
                    isConsultTranslate &&
                    <React.Fragment>
                        <div
                            className="Request-clinic-card__consult-title">
                            <FormattedMessage id={"request.clinic_card.translation_price"}/>
                        </div>
                        <Price
                            className="block"
                            size="lg"
                            value={_.get(clinic, 'consult.translate')}
                            currency={_.get(clinic, 'consult.symbol')}/>
                    </React.Fragment>
                }
                {
                    isSelectable &&
                    <Button
                        active={isSelected}
                        onClick={handleSelect}
                        size="sm"
                        viewType="minimal">
                        <span className="text-uppercase">
                            <FormattedMessage id={"request.buttons.select"}/>
                        </span>
                    </Button>
                }
            </div>
        </div>
    );
};

RequestClinicCard.propTypes = {
    clinic: PropTypes.shape({
        id: PropTypes.any.isRequired,
    }),
    onSelect: PropTypes.func,
    isSelectable: PropTypes.bool,
    isSelected: PropTypes.bool,
    isConsultTranslate: PropTypes.bool,
};

RequestClinicCard.defaultProps = {
    isSelectable: false,
    isSelected: false,
    isConsultTranslate: false,
};
