import React from "react";
import {useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import './OverviewStatistics.scss';
import Badge from "../../../../components/common/Badge/Badge";
import {getStatisticsSelector} from "../../../../redux/overviewPage/selectors";

const OverviewStatistics = () => {
    const stats = useSelector(getStatisticsSelector);

    return (
        <div className={"Overview-statistics"}>
            <h2 className="Overview__title">
                <FormattedMessage id={"overview.statistics.title"}/>
            </h2>
            <div className={'Flex-row mb-2 Align-center'}>
                <div className={'Flex-1'}>
                    <FormattedMessage id={"overview.statistics.all_requests"}/>
                </div>
                <Link to={'/main/requests'}>
                    <Badge variant="darkgray" link>
                        {stats.all}
                    </Badge>
                </Link>
            </div>
            <div className={'Flex-row mb-2 Align-center'}>
                <div className={'Flex-1'}>
                    <FormattedMessage id={"overview.statistics.draft_requests"}/>
                </div>
                <Link to={'/main/requests?s=draft'}>
                    <Badge variant="darkgray" link>
                        {stats.draft}
                    </Badge>
                </Link>
            </div>
            <div className={'Flex-row mb-2 Align-center'}>
                <div className={'Flex-1'}>
                    <FormattedMessage id={"overview.statistics.active_requests"}/>
                </div>
                <Link to={'/main/requests?s=active'}>
                    <Badge variant="darkgray" link>
                        {stats.active}
                    </Badge>
                </Link>
            </div>
            <div className={'Flex-row mb-2 Align-center'}>
                <div className={'Flex-1'}>
                    <FormattedMessage id={"overview.statistics.end_requests"}/>
                </div>
                <Link to={'/main/requests?s=end'}>
                    <Badge variant="darkgray" link>
                        {stats.end}
                    </Badge>
                </Link>
            </div>
        </div>
    )
}

OverviewStatistics.displayName = 'OverviewStatistics'

export default OverviewStatistics;
