import {useCallback, useReducer, useRef} from 'react';
import _ from 'lodash';
import {w3cwebsocket as WebSocketClient} from 'websocket';

import {getFileExtension} from '../../helpers/Common';
import {getWebSocketUrl} from '../../helpers/UrlHelper';
import * as api from '../../api';
import {ChatInstance, SendOptions, EditOptions, JoinOptions, MessageStatus, Attachment} from './Chat.interface';

const WEB_SOCKET_URL = getWebSocketUrl();
const CHAT_FILE_TYPE = 0;

const initialState = {
    messages: [],
    chatId: null,
    phpsession: null,
    userId: null,
    error: null,
    joining: false,
    joined: false,
    connecting: false,
    connected: false,
    cursor: null,
    hasMore: false,
    fetching: false,
    connectionError: false,
    lang: null,
    sending: false,
};

// previous name - 'actions'
enum ChatEvents {
    connecting = 'connecting',
    connected = 'connected',
    disconnected = 'disconnected',
    joining = 'joining',
    error = 'error',
    messages = 'messages',
    message = 'message',
    fetching = 'fetching',
    removing = 'removing',
    removed = 'removed',
    editing = 'editing',
    connectionError = 'connectionError',
    sending = 'sending',
    sent = 'sent',
    readMessage = 'readMessage'
};

const socketActions = {
    messages: 'messages',
    message: 'message',
    removed: 'removed',
    unauthorize: 'unauthorize',
}

interface Options {
    onUnauthorize: () => void;
}

type Action = { type: ChatEvents, payload?: Partial<ChatInstance> }

const useConsultationChat = ({onUnauthorize}: Options = {} as Options) => {
    const chatInstance = useRef<ChatInstance>({} as ChatInstance);

    const reducer = (state: typeof initialState, action: Action) => {
        let messages, newMessages, hasMore;

        switch (action.type) {
            case ChatEvents.connecting:
                return {
                    ...state,
                    ...initialState,
                    connecting: true,
                    phpsession: action.payload.phpsession,
                    userId: action.payload.userId,
                    connectionError: false,
                };
            case ChatEvents.connected:
                return {
                    ...state,
                    connecting: false,
                    connected: true,
                    error: null,
                    connectionError: false,
                };
            case ChatEvents.joining:
                return {
                    ...state,
                    joining: true,
                    chatId: action.payload.chatId,
                    lang: action.payload.lang,
                };
            case ChatEvents.fetching:
                return {
                    ...state,
                    fetching: true,
                };
            case ChatEvents.messages:
                const addMessages = [];
                const updatedMessages = [];
                messages = state.messages;
                newMessages = action.payload.messages;
                hasMore = action.payload.hasMore;

                // eslint-disable-next-line array-callback-return
                newMessages.forEach(message => {
                    const existMessage = messages.find(m => m.id === message.id);
                    if (existMessage) {
                        updatedMessages.push({
                            ...existMessage,
                            ...message,
                        });
                    } else {
                        addMessages.push(message);
                    }
                });

                messages = [...addMessages, ...messages.filter(message => {
                    return updatedMessages.find(m => m.id === message.id) || message;

                })].sort((m1, m2) => {
                    const d1 = new Date(m1.datetime);
                    const d2 = new Date(m2.datetime);

                    if (d1.getTime() === d2.getTime()) {
                        return 0;
                    }

                    return d1.getTime() > d2.getTime() ? 1 : -1;
                });

                return {
                    ...state,
                    messages,
                    cursor: messages.length ? messages[0].id : null,
                    fetching: false,
                    joining: false,
                    joined: true,
                    hasMore,
                };
            case ChatEvents.message:
                messages = state.messages;
                const isExist = messages.find(m => m.id === action.payload.id);

                if (isExist) {
                    newMessages = messages.map(m => {
                        if (m.id === action.payload.id) {
                            return {
                                ...m,
                                ...action.payload,
                                isEditing: false,
                                isRemoving: false,
                            }
                        }
                        return m;
                    })
                } else {
                    newMessages = [...messages, action.payload];
                }

                return {
                    ...state,
                    messages: newMessages,
                };
            case ChatEvents.error:
                return {
                    ...state,
                    error: action.payload.error,
                };
            case ChatEvents.connectionError:
                return {
                    ...state,
                    connectionError: true,
                };
            case ChatEvents.disconnected:
                return {
                    ...state,
                    connected: false,
                    fetching: false,
                    connecting: false,
                    joining: false,
                };
            case ChatEvents.removing:
                return {
                    ...state,
                    messages: state.messages.map((message) => {
                        if (message.id === action.payload.messageId) {
                            return {
                                ...message,
                                isRemoving: true,
                            }
                        }
                        return message;
                    }),
                }
            case ChatEvents.removed:
                return {
                    ...state,
                    messages: state.messages.filter((message) => message.id !== action.payload.messageId),
                }
            case ChatEvents.editing:
                return {
                    ...state,
                    messages: state.messages.map((message) => {
                        if (message.id === action.payload.messageId) {
                            return {
                                ...message,
                                isEditing: true,
                                text: action.payload.text,
                            }
                        }
                        return message;
                    }),
                }
            case ChatEvents.sending:
                return {
                    ...state,
                    sending: true,
                }
            case ChatEvents.sent:
                return {
                    ...state,
                    sending: false,
                }
            case ChatEvents.readMessage: {
                return {
                    ...state,
                    messages: state.messages.map((message) => {
                        if (message.id === action.payload.messageId) {
                            return {
                                ...message,
                                status: MessageStatus.Read
                            }
                        }
                        return message;
                    }),
                }
            }
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleOpen = useCallback(() => {
        chatInstance.current.reconnecting = false;
        chatInstance.current.attemts = 0;
        dispatch({
            type: ChatEvents.connected,
            payload: {},
        });
    }, []);

    const handleError = useCallback((error) => {
        dispatch({
            type: ChatEvents.error,
            payload: {
                error,
            },
        });
    }, []);

    const handleMessages = useCallback((event) => {
        const data = JSON.parse(event.data);

        switch (data.type) {
            case socketActions.messages:
                return dispatch({
                    type: ChatEvents.messages,
                    payload: data.payload,
                });
            case socketActions.message:
                return dispatch({
                    type: ChatEvents.message,
                    payload: data.payload,
                });
            case socketActions.removed:
                return dispatch({
                    type: ChatEvents.removed,
                    payload: data.payload,
                })
            case socketActions.unauthorize:
                onUnauthorize && onUnauthorize();
                return;
            default:
                console.warn('Unknown socket message', event);
        }
    }, [onUnauthorize]);

    const handleClose = useCallback((event) => {
        if (chatInstance.current.forceClose) {
            return;
        }
        if (chatInstance.current.attemts < chatInstance.current.maxAttemts) {
            chatInstance.current.reconnecting = true;
            chatInstance.current.attemts++;
            setTimeout(() => {
                chatInstance.current.connect(chatInstance.current);
            }, chatInstance.current.attemts * chatInstance.current.delayReconnect);
        } else {
            dispatch({type: ChatEvents.connectionError});
            chatInstance.current.reconnecting = false;
            chatInstance.current.disconnect();
        }

        console.log('close socket', chatInstance.current.attemts);
    }, []);

    const isDisconnected = useCallback(() => {
        return !chatInstance.current.socket && !state.connected;
    }, [state.connected]);

    const createNewSocket = useCallback(() => {
        const socket = new WebSocketClient(WEB_SOCKET_URL);
        // attach handlers
        socket.onopen = handleOpen;
        socket.onerror = handleError;
        socket.onmessage = handleMessages;
        socket.onclose = handleClose;

        return socket;
    }, [handleClose, handleError, handleMessages, handleOpen]);

    const disconnect = useCallback(() => {
        if (!chatInstance.current.socket) {
            return;
        }
        chatInstance.current.reconnecting = false;
        chatInstance.current.forceClose = true;
        chatInstance.current.socket.close();
        chatInstance.current.socket = null;
        dispatch({type: ChatEvents.disconnected});
    }, []);

    const connect = useCallback(({phpsession, userId}) => {
        if (!chatInstance.current.reconnecting) {
            if (chatInstance.current.socket) {
                disconnect();
            }

            chatInstance.current.reconnecting = false;
            chatInstance.current.forceClose = false;
            chatInstance.current.attemts = 0;
            chatInstance.current.maxAttemts = 10;
            chatInstance.current.delayReconnect = 200;
        }
        dispatch({
            type: ChatEvents.connecting,
            payload: {
                phpsession,
                userId,
            },
        });
        chatInstance.current.socket = createNewSocket();
    }, [createNewSocket, disconnect]);

    const join = useCallback(({chatId, lang}: JoinOptions) => {
        if (isDisconnected()) {
            return;
        }
        dispatch({
            type: ChatEvents.joining,
            payload: {
                chatId,
                lang,
            },
        });
        chatInstance.current.socket.send(JSON.stringify({
            type: 'join',
            payload: {
                phpsession: state.phpsession,
                chatId,
                userId: state.userId,
                lang,
            },
        }));
    }, [isDisconnected, state.phpsession, state.userId]);

    const uploadAttachments = useCallback(async (attachments: Attachment[]) => {
        if (!attachments.length) {
            return {
                files: [],
            };
        }

        const newFiles = attachments.filter(file => !file.id)
        const existingFiles = attachments.filter(file => file.id).map((file) => ({
            id: file.id,
            label: {
                [state.lang]: file.name
            },
        }))

        const newReadFiles = await Promise.all(newFiles.map(attachment => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                    resolve({
                        type_id: CHAT_FILE_TYPE,
                        label: {
                            [state.lang]: attachment.name || attachment.file.name,
                        },
                        value: reader.result,
                        ext: getFileExtension(attachment.file.name),
                    });
                };

                reader.onerror = (error) => {
                    reject({error});
                }

                reader.readAsDataURL(attachment.file);
            });
        }));

        // @ts-ignore
        const {data, error} = await api.uploadRequestFiles({
            id: state.chatId, 
            files: [...existingFiles, ...newReadFiles], 
            lang: state.lang
        });

        if (error) {
            throw new Error(error);
        }

        return data;
    }, [state.chatId, state.lang]);

    const send = useCallback(async ({
        text,
        lang, 
        attachments = [], 
        replyId
    }: SendOptions) => {
        if (isDisconnected()) {
            return;
        }
        dispatch({type: ChatEvents.sending});

        try {
            const uploadedAttachments = await uploadAttachments(attachments);

            const messageAttachments = [
                ...attachments.filter(file => !!file.id).map(file => ({
                    id: file.id,
                })),
                ...uploadedAttachments.files.map(file => ({
                    id: file,
                })),
            ];

            chatInstance.current.socket.send(JSON.stringify({
                type: 'send',
                payload: {
                    chatId: state.chatId,
                    userId: state.userId,
                    text,
                    lang,
                    attachments: messageAttachments,
                    replyId,
                },
            }));
        } catch (error) {
            throw error;
        } finally {
            dispatch({type: ChatEvents.sent});
        }
    }, [isDisconnected, state.chatId, state.userId, uploadAttachments]);

    const prev = useCallback(() => {
        if (isDisconnected()) {
            return;
        }
        if (state.hasMore && state.cursor) {
            dispatch({type: ChatEvents.fetching});
            chatInstance.current.socket.send(JSON.stringify({
                type: 'prev',
                payload: {
                    messageId: state.cursor,
                    chatId: state.chatId,
                },
            }));
        }
    }, [isDisconnected, state.chatId, state.cursor, state.hasMore]);

    const remove = useCallback((messageId) => {
        if (isDisconnected()) {
            return;
        }
        dispatch({type: ChatEvents.removing, payload: {messageId}});
        chatInstance.current.socket.send(JSON.stringify({
            type: 'remove',
            payload: {
                messageId,
                chatId: state.chatId,
            },
        }));
    }, [isDisconnected, state.chatId]);

    const edit = useCallback(({messageId, text}: EditOptions) => {
        if (isDisconnected()) {
            return;
        }
        dispatch({type: ChatEvents.editing, payload: {messageId, text}});
        chatInstance.current.socket.send(JSON.stringify({
            type: 'send',
            payload: {
                messageId,
                text,
            },
        }));
    }, [isDisconnected]);


    const markRead = useCallback((messageId: number) => {
        if (isDisconnected()) {
            return;
        }

        // looks interesting with the delay
        _.delay(() => {
            dispatch({type: ChatEvents.readMessage, payload: {messageId}});
        }, 500);

        chatInstance.current.socket.send(JSON.stringify({
            type: 'read',
            payload: {
                messageId,
            },
        }));
    }, [isDisconnected])

    Object.assign(chatInstance.current, {
        ...state,
        connect,
        disconnect,
        join,
        send,
        prev,
        remove,
        edit,
        markRead
    });

    return chatInstance.current;
};

export default useConsultationChat;
