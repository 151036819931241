import {
    SET_PAGE_TRANSLATION
} from './actions';
import Immutable from 'seamless-immutable';
import {MDText} from "i18n-react";
import pageNames from '../../constants/PageNames';

const initialState = {
    messages: {},
    loaded: {},
    [pageNames.Main]: new MDText({}),
    [pageNames.Account]: new MDText({}),
    [pageNames.ConfirmEmail]: new MDText({}),
    [pageNames.ContactUs]: new MDText({}),
    [pageNames.General]: new MDText({}), // unused
    [pageNames.Login]: new MDText({}),
    [pageNames.Overview]: new MDText({}),
    [pageNames.Profile]: new MDText({}),
    [pageNames.Security]: new MDText({}),
    [pageNames.Common]: new MDText({}),
    [pageNames.EditProfile]: new MDText({}),
    [pageNames.ViewClinic]: new MDText({}),
    [pageNames.ViewDoctor]: new MDText({}),
    [pageNames.ViewCoordinator]: new MDText({}),
    [pageNames.PatientReg]: new MDText({}),
    [pageNames.MedicalRecords]: new MDText({}),
};

const expandMessages = (baseKey, translations, agg = {}) => {
    return Object.keys(translations).reduce((obj, key) => {
        if (typeof translations[key] === 'object') {
            return expandMessages(`${baseKey}.${key}`, translations[key], obj)
        } else {
            obj[`${baseKey}.${key}`] = translations[key];
        }
        return obj;
    }, agg)
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGE_TRANSLATION:
            const translation = new MDText({...action.payload.translation, notFound: ''});
            return Immutable.merge(state, {
                [action.payload.pageName]: translation,
                messages: expandMessages(action.payload.key, action.payload.translation),
                loaded: {
                    [action.payload.pageName]: true
                }
            }, {deep: true});
        default:
            return state;
    }
}
