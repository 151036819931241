import React from 'react';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import _ from 'lodash';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import './RequestSelectedConsultant.scss';
import {RequestStatuses, splitRequestStatus} from '../../../../constants/RequestStatuses';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';
import * as commonSelectors from '../../../../redux/common/selectors';
import {RequestClinicCard} from '../RequestClinicCard/RequestClinicCard';
import {RequestDoctorCard} from '../RequestDoctorCard/RequestDoctorCard';
import {RequestSectionTitle} from '../../../../components/request/RequestSectionTitle/RequestSectionTitle';
import Hint from "../../../../components/common/Hint/Hint";
import IfCondition from "../../../../components/common/IfCondition/IfCondition";

export const RequestSelectedConsultant = ({className, viewHelpMeHint}) => {
    const requestData = useSelector(requestSelectors.getRequestData);
    const status = splitRequestStatus(requestData.status);
    const currentLang = useSelector(requestSelectors.getCurrentLangCommunication);
    const country = useSelector(state => commonSelectors.getCountryByIdSelector(state, requestData.country))

    if ((status === RequestStatuses.Draft && requestData.clinic) ||
        (status === RequestStatuses.Clinic_2 && requestData.clinic)) {
        return (
            <div className={cn('Request-page__selected-consultant', className)}>
                <RequestSectionTitle>
                    <FormattedMessage id={"request.selected_consultant.clinic"}/>
                </RequestSectionTitle>
                <RequestClinicCard
                    clinic={requestData.clinic}
                    isSelectable={false}
                    isConsultTranslate={_.get(currentLang, 'translate', false)}
                />
            </div>
        )
    }

    if ((status === RequestStatuses.Draft && requestData.doctor) ||
        (status === RequestStatuses.Doctor_3 && requestData.doctor)) {
        return (
            <div className={cn('Request-page__selected-consultant', className)}>
                <RequestSectionTitle>
                    <FormattedMessage id={"request.selected_consultant.doctor"}/>
                </RequestSectionTitle>
                <RequestDoctorCard
                    doctor={requestData.doctor || {}}
                    isSelectable={false}
                    isConsultTranslate={_.get(currentLang, 'translate', false)}
                />
            </div>
        )
    }

    if (requestData.helpMe || status === RequestStatuses.HelpMe_1) {
        return (
            <div className={cn('Request-page__selected-consultant', className)}>
                <RequestSectionTitle>
                    <FormattedMessage id={"request.selected_consultant.help_me"}/>
                </RequestSectionTitle>
                {
                    viewHelpMeHint && (
                        <React.Fragment>
                            <div className="mb-1">
                                <FormattedMessage id={"request.selected_consultant.country"}/>:
                                <span className="ml-1">{_.get(country, 'label')}</span>
                            </div>
                            <IfCondition condition={status === RequestStatuses.Draft}>
                                <Hint>
                                    <FormattedMessage id={"request.selected_consultant.help_me_hint"}/>
                                </Hint>
                            </IfCondition>
                        </React.Fragment>
                    )
                }
            </div>
        )
    }

    return null;
};

RequestSelectedConsultant.displayName = 'RequestSelectedConsultant';
RequestSelectedConsultant.propTypes = {
    viewHelpMeHint: PropTypes.bool,
};
RequestSelectedConsultant.defaultProps = {
    viewHelpMeHint: false,
};
