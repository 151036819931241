import React from 'react';
import cn from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";
import {isString} from "lodash";

import type { CellProps } from '../SimpleTable.interface';

interface Props extends CellProps {
    className?: string;
    title?: string;
    titleKey?: string;
    children?: React.ReactNode;
    align: string;
}

const HeaderCommonCell = React.memo<Props>(({column, children, className, title}) => {
    const renderSort = () => (
        column.sortable ?
            <div className="Table__sort-container">
                <FontAwesomeIcon
                    icon={faSortUp}
                    className={cn("Table__sort Table__sort-up", {
                        "Table__sort--sorted": column.isSorted,
                        "Table__sort--active": column.isSorted && !column.isSortedDesc
                    })}/>
                <FontAwesomeIcon
                    icon={faSortDown}
                    className={cn("Table__sort Table__sort-down", {
                        "Table__sort--sorted": column.isSorted,
                        "Table__sort--active": column.isSorted && column.isSortedDesc
                    })}/>
            </div> : null
    );

    return (
        <div
            title={title}
            className={cn(className, "Table__cell-header-wrapper", column.alignHeader && `Table__cell-header-wrapper--${column.alignHeader}`)}>
            {
                isString(children) ?
                    <span className="Table__cell-header-text">
                        {children}
                    </span>
                    : children
            }
            {renderSort()}
        </div>
    );
});

HeaderCommonCell.displayName = "HeaderCommonCell";


export default HeaderCommonCell;
