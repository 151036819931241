import {createSelector} from 'reselect';
import {rootRequestSelector} from '../request/selectors';
import {rootRequestPageSelector} from '../selectors';

export const rootConsultantSelector = createSelector(
    rootRequestPageSelector,
    (root) => root.consultant,
);

export const isConsultantLoadingSelector = createSelector(
    rootConsultantSelector,
    (consultant) => consultant.loading > 0,
);

export const getConsultantFilterSelector = createSelector(
    rootConsultantSelector,
    (consultant) => consultant.filter,
);

export const getConsultantClinicsSelector = createSelector(
    rootConsultantSelector,
    (consultant) => consultant.clinics,
);

export const getConsultantDoctorsSelector = createSelector(
    rootConsultantSelector,
    (consultant) => consultant.doctors,
);

export const getConsultantViewSelector = createSelector(
    rootConsultantSelector,
    (consultant) => consultant.view,
);

export const canConsultantsNextPage = createSelector(
    rootRequestSelector,
    request => {
        const {data} = request;

        return !!(data.initial.clinic || data.initial.doctor) || data.initial.helpMe;
    },
);
