import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './SelectTagList.scss';
import SelectTagListItem from './SelectTagListItem';

class SelectTagList extends React.Component {

    constructor(props) {
        super(props);

        const {multiSelecting, selectedOption} = this.props;

        this.state.selectedOption = multiSelecting ? [...selectedOption] : selectedOption;
    }

    static displayName = 'HealthSelectTagList';

    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.any),
        selectedOption: PropTypes.oneOfType(PropTypes.string, PropTypes.arrayOf(PropTypes.any)),
        idField: PropTypes.string,
        labelField: PropTypes.string,
        disabled: PropTypes.bool,
        renderItem: PropTypes.func,
        onChange: PropTypes.func,
        multiSelecting: PropTypes.bool,
        containerRef: PropTypes.any,
    };

    static defaultProps = {
        disabled: false,
        idField: 'id',
        labelField: 'label',
        options: [],
        selectedOption: [],
        multiSelecting: false,
    };

    state = {};

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedOption !== this.props.selectedOption) {
            this.setState({
                selectedOption: this.props.multiSelecting ?
                    [...this.props.selectedOption] :
                    this.props.selectedOption,
            });
        }
    }

    getFieldValue = (option, field) => {
        return option[field];
    };

    hasOptionSelected = (option) => {
        const {
            multiSelecting,
            idField,
        } = this.props;
        const {selectedOption} = this.state;

        if (multiSelecting) {
            return selectedOption.includes(this.getFieldValue(option, idField));
        }
        return selectedOption === this.getFieldValue(option, idField);
    };

    toggleOption = (option) => {
        const {
            multiSelecting,
            idField,
            onChange,
            disabled,
        } = this.props;
        if (disabled) {
            return;
        }

        const {selectedOption} = this.state;
        const idValue = this.getFieldValue(option, idField);
        let newSelectedOptions = [];

        if (multiSelecting) {
            if (selectedOption.includes(idValue)) {
                newSelectedOptions = selectedOption.filter(o => o !== idValue);
            } else {
                newSelectedOptions = [...selectedOption, idValue];
            }
        } else {
            newSelectedOptions = idValue;
        }

        this.setState({
            selectedOption: newSelectedOptions,
        }, () => {
            if (onChange) {
                onChange(this.state.selectedOption);
            }
        });
    };

    renderOptions = () => {
        const {
            options,
            idField,
            labelField,
            disabled,
            renderItem,
        } = this.props;

        return (
            <div className={cn('Health-select-tag-list__options')}>
                {
                    options.map((option) => {
                        if (renderItem) {
                            return renderItem({
                                option,
                                disabled,
                                onToggle: () => this.toggleOption(option),
                                isSelected: this.hasOptionSelected(option),
                                label: this.getFieldValue(option, labelField),
                                id: this.getFieldValue(option, idField),
                                key: this.getFieldValue(option, idField),
                            });
                        }
                        return (
                            <SelectTagListItem
                                disabled={disabled}
                                onToggle={() => this.toggleOption(option)}
                                isSelected={this.hasOptionSelected(option)}
                                label={this.getFieldValue(option, labelField)}
                                id={this.getFieldValue(option, idField)}
                                key={this.getFieldValue(option, idField)}/>
                        )
                    })
                }
            </div>
        )
    };

    render() {
        const {
            className,
            disabled,
            containerRef,
        } = this.props;

        return (
            <div
                ref={containerRef}
                className={cn('Health-select-tag-list', className, {
                    'Health-select-tag-list--disabled': disabled,
                })}>
                {this.renderOptions()}
            </div>
        )
    }
}

export default SelectTagList;
