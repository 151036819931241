import {createSelector} from "reselect";

export const translationsSelector = state => state.translations;

export const getPageTranslationSelector = createSelector(
    translationsSelector,
    (_, pageName) => pageName,
    (translations, pageName) => translations[pageName]
);

export const getMessagesSelector = createSelector(
    translationsSelector,
    (translations) => translations.messages
);

export const isPageLoadedSelector = createSelector(
    translationsSelector,
    (_, pageName) => pageName,
    (translations, pageName) => !!translations.loaded[pageName]
);
