import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import './SecurityPage.scss';
import {initSecurityPage} from '../../redux/securityPage/actions';
import * as translationsSelectors from '../../redux/translations/selectors';
import pageNames from "../../constants/PageNames";
import {loadingSelector} from "../../redux/loading/selectors";
import ChangeEmail from "./components/ChangeEmail";
import ChangePassword from "./components/ChangePassword";
import RemoveAccount from "./components/RemoveAccount";

export const SecurityPage = ({loading, initSecurityPage, isTranslationLoaded}) => {
    useEffect(() => {
        initSecurityPage();
    }, [initSecurityPage]);

    return (
        <>
            {!loading && isTranslationLoaded &&
            <div className="Security-page">
                <div className="row">
                    <div className="col-6">
                        <ChangeEmail/>
                    </div>
                    <div className={`Flex-column col-6`}>
                        <ChangePassword/>
                        <RemoveAccount/>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: loadingSelector(state),
        isTranslationLoaded: translationsSelectors.isPageLoadedSelector(state, pageNames.Security),
    }
};

export default connect(mapStateToProps, {
    initSecurityPage,
})(withRouter(SecurityPage));
