import React from 'react';

import {useTranslation} from 'libs/hooks/use-translation';

// the initiative by FE developer for clear interface
const STATUS_ROLE_TEXT_REGEX = /request_status\/for_([a-z_]+)_[0-9]/;
const STATUS_TEXT_REGEX = /[0-9]_[0-9]$/;

const getRoleTextId = (role: string) =>
	({
		admin_clinic_by_default: 'common.user_role.clinic_admin',
		admin_clinic_by_coordinator: 'common.user_role.clinic_admin',
		//
		patient_by_default: 'common.user_role.patient',
		patient_by_coordinator: 'common.user_role.patient',
		//
		partner_by_default: 'common.user_role.coordinator',
		partner_by_coordinator: 'common.user_role.coordinator',
		//
		doctor_by_default: 'common.user_role.doctor',
		doctor_by_coordinator: 'common.user_role.doctor',
		//
		admin_by_default: 'common.user_role.admin',
		admin_by_coordinator: 'common.user_role.admin',
	}[role]);

const getRoleStatus = (param: string) => {
	try {
		const [, role] = param.match(STATUS_ROLE_TEXT_REGEX);

		return getRoleTextId(role);
	} catch {
		return '';
	}
};

const LONG_DASH = '—';

const getStatusValue = (param: string) => {
	try {
		const [value] = param.match(STATUS_TEXT_REGEX);
		const [int, float] = value.split('_'); // 1_1 => 1.1

		return `${int}.${float}`;
	} catch {
		return '';
	}
};

export const useStatusMapper = (translates: any[]) => {
	const {t} = useTranslation();

	const map = () =>
		translates.map(data => {
			const roleTextId = getRoleStatus(data.param);
			const status = getStatusValue(data.param);

			return {
				...data,
				status: status || LONG_DASH,
				forRoleTextId: roleTextId ? t(roleTextId) : LONG_DASH,
			};
		});

	return React.useMemo(map, [translates]);
};
