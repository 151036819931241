import React, {forwardRef} from "react";
import cn from "classnames";

import './Input.scss';

interface Props<T = HTMLInputElement> {
    className?: string;
    disabled: boolean;
    readOnly: boolean,
    onBlur?: (event?: React.FocusEvent<T>) => void;
    onChange: (event: React.ChangeEvent<T>) => void;
    onFocus?: (event?: React.FocusEvent<T>) => void;
    inputValue: string;
}

const Input = forwardRef<HTMLInputElement, Props>(
    ({
         onBlur,
         onFocus,
         onChange,
         inputValue,
         readOnly,
         disabled,
     }, ref) => {
        return <input
            tabIndex={0}
            spellCheck="false"
            autoCorrect="off"
            autoComplete="off"
            autoCapitalize="none"
            disabled={disabled}
            className={cn('Health-select-box__input')}
            value={inputValue}
            readOnly={readOnly}
            onChange={!readOnly ? onChange : undefined}
            onBlur={onBlur}
            onFocus={onFocus}
            ref={ref}/>
    }
)

Input.displayName = 'HealthSelectBoxInput';

export default Input;
