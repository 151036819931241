import Immutable from 'seamless-immutable';
import {SET_USER_INFO, PATCH_USER} from "./actions";

const initialState = {
    clinicName: null,
    notify: [],
    rights: null,
    error: null,
    loaded: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PATCH_USER:
            return Immutable.merge(state, action.payload, {deep: true});
        case SET_USER_INFO:
            return Immutable.from({
                ...action.payload,
                loaded: true
            });
        default:
            return state;
    }
}
