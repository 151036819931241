import React from 'react';
import PropTypes from 'prop-types';
import {useField} from 'react-final-form';
import {hasError} from '../../../helpers/Common';

import './FieldError.scss';

const FieldError = ({name}) => {
    const {
        meta: {touched, error, submitError}
    } = useField(name, {subscription: {touched: true, error: true, submitError: true}});
    return hasError({touched, error, submitError}) ? <span className="Health-form__field-error">{error || submitError}</span> : null;
};

FieldError.propTypes = {
    name: PropTypes.string.isRequired
};

export default FieldError;
