import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './RequestHeaderFieldInfo.scss';

export const RequestHeaderFieldInfo = ({className, label, value, children, title}) => {
    return (
        <div className={cn('Request-header__field-info', className)}>
            <div className="Request-header__field-info_label">{label}</div>
            <div
                title={title}
                className="Request-header__field-info_value">
                {children ? children : value}
            </div>
        </div>
    )
};

RequestHeaderFieldInfo.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    title: PropTypes.any,
};
