import React from 'react';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {
	faPen,
	faFileAlt,
	faDonate,
	faComments,
	faTimesCircle,
	faFilePdf,
	faCheckDouble,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import LocationStateFroms from '../../../../constants/LocationStateFroms';
import {isConsultation} from '../../../../helpers/Request';
import {
	RequestStatuses,
	splitRequestStatus,
} from '../../../../constants/RequestStatuses';
import BadgeV2 from 'components/common/Badge/BadgeV2';
import {hasConsultationNewUpdatesSelector} from 'redux/requestsTablePage/selectors';
import {useAppSelector} from 'hooks/use-app-selector';

const ActionsCell = React.memo(
	({
		row: {
			original,
			values: {id, status},
		},
	}) => {
		const intl = useIntl();
		// TODO: move in a parent component (compute before the render)
		const hasConsultationNewUpdates = useAppSelector(state =>
			hasConsultationNewUpdatesSelector(state, original.id),
		);

		const rootStatus = splitRequestStatus(status);
		let pathname;
		let titleKey = 'common.buttons.edit';
		let icon = faPen;

		if (isConsultation(status)) {
			pathname = `/main/requests/consultation/${id}`;
		} else {
			pathname = `/main/requests/${id}`;
		}

		// eslint-disable-next-line default-case
		switch (rootStatus) {
			case RequestStatuses.Draft:
				titleKey = 'common.buttons.edit';
				icon = faPen;
				break;
			case RequestStatuses.HelpMe_1:
			case RequestStatuses.Clinic_2:
			case RequestStatuses.Doctor_3:
				titleKey = 'common.buttons.view';
				icon = faFileAlt;
				break;
			case RequestStatuses.Accepted_5:
				if (
					status === RequestStatuses.Accepted_5_1 ||
					status === RequestStatuses.Accepted_5_3
				) {
					titleKey = 'common.buttons.confirm';
					icon = faCheckDouble;
				} else {
					// 5_2
					titleKey = 'common.buttons.pay';
					icon = faDonate;
				}
				break;
			case RequestStatuses.Active:
			case RequestStatuses.NeedTranslate:
				pathname = `/main/requests/consultation/${id}/chat`;
				titleKey = 'common.buttons.view';
				icon = faComments;
				break;
			case RequestStatuses.DeclineByClinic:
			case RequestStatuses.DeclineByPatient:
				titleKey = 'common.buttons.view';
				icon = faTimesCircle;
				break;
			case RequestStatuses.End:
				pathname = `/main/requests/consultation/${id}/conclusion`;
				titleKey = 'common.buttons.view';
				icon = faFilePdf;
				break;
		}

		const hasConsultationNewMessages = original.unread;
		const showRedBadge =
			hasConsultationNewMessages || hasConsultationNewUpdates;

		return (
			<Link
				className="ml-2"
				to={{
					pathname,
					state: {
						from: LocationStateFroms.requests,
						pathname: window.location.pathname,
						search: window.location.search,
					},
				}}
			>
				<BadgeV2 variant="ruby" transparent={!showRedBadge}>
					<FontAwesomeIcon
						title={intl.formatMessage({id: titleKey})}
						className="gray-text-color Action-icon"
						size="lg"
						icon={icon}
					/>
				</BadgeV2>
			</Link>
		);
	},
);

export default ActionsCell;
