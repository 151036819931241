export default {
    RequestFormId: 'request-form-id',
    RequestConsultantsId: 'request-consultants-id',
    RequestCheckoutId: 'request-checkout-id',
    RequestSuccessId: 'request-success-id',
    RequestConfirmId: 'request-confirm-id',
    RequestReplacementId: 'request-replacement-id',
    RequestDeclineId: 'request-decline-id',
    RequestPaymentId: 'request-payment-id',
    RequestSendingId: 'request-sending-id',
    RequestPaymentSuccessId: 'request-payment-success-id',
};
