import * as api from '../../../api';
import * as consultantsSelectors from './selectors';
import {currentLanguageSelector} from "../../lang/selectors";
import {clearDataParams} from "../../../helpers/Common";

export const REQUEST_PAGE_CONSULTANT_SET_FILTER = 'REQUEST_PAGE_CONSULTANT_SET_FILTER';
export const REQUEST_PAGE_CONSULTANT_SET_VIEW = 'REQUEST_PAGE_CONSULTANT_SET_VIEW';
export const REQUEST_PAGE_CONSULTANT_SET_CLINICS = 'REQUEST_PAGE_CONSULTANT_SET_CLINICS';
export const REQUEST_PAGE_CONSULTANT_SET_DOCTORS = 'REQUEST_PAGE_CONSULTANT_SET_DOCTORS';
export const REQUEST_PAGE_CONSULTANT_SET_LOADING = 'REQUEST_PAGE_CONSULTANT_SET_LOADING';
export const REQUEST_PAGE_CONSULTANT_SET_ERROR = 'REQUEST_PAGE_CONSULTANT_SET_ERROR';
export const REQUEST_PAGE_CONSULTANT_UPDATE_CONSULTANTS = 'REQUEST_PAGE_CONSULTANT_UPDATE_CONSULTANTS';

export const setConsultantFilter = (filter) => ({
    type: REQUEST_PAGE_CONSULTANT_SET_FILTER,
    payload: filter,
});

export const setConsultantView = (view) => ({
    type: REQUEST_PAGE_CONSULTANT_SET_VIEW,
    payload: view,
});

export const setConsultantLoading = (value) => ({
    type: REQUEST_PAGE_CONSULTANT_SET_LOADING,
    payload: value,
});

export const setConsultantError = (error) => ({
    type: REQUEST_PAGE_CONSULTANT_SET_ERROR,
    payload: error,
});

export const updateConsultants = () => async (dispatch, getState) => {
    const view = consultantsSelectors.getConsultantViewSelector(getState());
    const filter = consultantsSelectors.getConsultantFilterSelector(getState());
    const lang = currentLanguageSelector(getState());

    dispatch(setConsultantLoading(true));
    try {
        const params = clearDataParams({
            full: true,
            s: filter.query,
            l: filter.langs ? filter.langs.map(l => l.id).join(',') : null, // filter language
            page: filter.currentPage,
            cou: filter.country ? filter.country : null,
            spec: filter.specialities ? filter.specialities.map(s => s.id).join(',') : null,
            sortBy: filter.sortBy,
            orderBy: filter.orderBy,
            size: filter.pageSize,
            lang, // data language
        });

        if (view === 'clinics') {
            const response = await api.loadClinics(params);

            dispatch({
                type: REQUEST_PAGE_CONSULTANT_SET_CLINICS,
                payload: response.data,
            });
        } else {
            const res = await api.loadDoctors(params);

            dispatch({
                type: REQUEST_PAGE_CONSULTANT_SET_DOCTORS,
                payload: res.data,
            })
        }
    } catch (err) {
        dispatch(setConsultantError(err.data));
    } finally {
        dispatch(setConsultantLoading(false));
    }
};

export const changeConsultantsView = (newView) => async (dispatch, getState) => {
    dispatch(setConsultantView(newView));
    dispatch(setConsultantFilter({
        currentPage: 0,
    }));

    dispatch(updateConsultants());
};
