import React, {useCallback} from "react";
import PropTypes from 'prop-types';
import cn from 'classnames';

import OverviewAction from "./OverviewAction";

const OverviewUploadFileAction = ({onUploadFile, className, ...props}) => {
    const handleUploadFile = useCallback((event) => {
        const uploadedFile = Array.from(event.target.files)[0];
        onUploadFile && onUploadFile(uploadedFile);
    }, [onUploadFile]);

    return (
        <OverviewAction
            className={cn(className, 'button--upload')}
            {...props}>
            <input
                tabIndex={-1}
                title={""}
                type="file"
                value={''}
                accept="application/pdf, image/jpeg, image/png"
                onChange={handleUploadFile}/>
        </OverviewAction>
    )
}

OverviewUploadFileAction.displayName = 'OverviewUploadFileAction';

OverviewUploadFileAction.propTypes = {
    onUploadFile: PropTypes.func,
};

export default OverviewUploadFileAction;
