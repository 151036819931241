import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useSelector} from 'react-redux';

import './RequestStatus.scss';
import {RequestStatusText} from '../../../../components/request/RequestStatusText/RequestStatusText';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';
import {userRoleSelector} from '../../../../redux/user/selectors';
import {getRequestStatusTextIdByConsultation} from '../../../../helpers/Request';

export const RequestStatus = ({className, title}) => {
    const requestData = useSelector(requestSelectors.getRequestData);
    const requestId = useSelector(requestSelectors.getRequestId);
    const userRole = useSelector(userRoleSelector);

    const statusTextId = getRequestStatusTextIdByConsultation(userRole, requestData);

    return (
        <div className={cn('Request-page__status', className)}>
            <div>
                {title}: <RequestStatusText  statusTextId={statusTextId} status={requestData.status} isNew={!requestId}/>
            </div>
        </div>
    )
};

RequestStatus.propTypes = {
    title: PropTypes.string,
};

RequestStatus.defaultProps = {};
