import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import './Alert.scss';

const Alert = ({className, variant, children}) => {
    const classes = cn('Health-alert',
        variant && `Health-alert--${variant}`
        , className);

    return (
        <div className={classes}>
            {children}
        </div>
    )
}

Alert.diplayName = 'HealthAlert';
Alert.propTypes = {
    variant: PropTypes.oneOf(['blue', 'gray', 'ruby', 'green', 'orange', 'primary', null]),
};
Alert.defaultProps = {
    variant: 'primary',
};

export default Alert;
