import React, {useMemo} from 'react';
import {useField} from 'react-final-form';

import Button, {type Props as ButtonProps} from '../../../../common/Button/Button';
import {MessageRecipientTypes} from '../../../constants/MessageRecipientTypes';

interface Props extends ButtonProps {
    sendToType: MessageRecipientTypes
}

const SendToButton: React.FC<Props> = ({children, sendToType, disabled, ...props}) => {
    const {input: {value: sendToValue}} = useField('sendTo');

    const isDisabled = useMemo(() => {
        if (disabled) {
            return disabled;
        }
        if (sendToValue === MessageRecipientTypes.DEFAULT) {
            return false;
        }
        return sendToValue !== sendToType;
    }, [sendToType, sendToValue, disabled]);

    return (
        <Button
            {...props}
            disabled={isDisabled}
            size="lg"
            type="submit">
            {children}
        </Button>
    )
}

export default SendToButton;
