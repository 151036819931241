import axios from "axios";
import apiRoutes from "../constants/ApiRoutes";

/**
 * load list of doctors
 * @param {string} params.lang - lang
 * @param {boolean} params.full - true load fulled info about doctor
 * @param {number} params.size - page size
 * @param {number} params.page - page number
 * @param {number} params.cou - list of countries ids
 * @param {string} params.la - language filter
 * @param {number} params.spec - list of specialities ids
 * @param {number} params.c - list of clinics ids
 * @param {string} params.s - query (term)
 * @param {string} params.status - status code see doctor status codes in constants
 * @return {Promise<AxiosResponse<T>>}
 */
export const loadDoctors = (params = {}, options = {}) => {
    return axios.get(apiRoutes.doctorList, {
        params,
        ...options,
    });
};

export const loadDoctorInfo = ({id, lang} = {}) => {
    return axios.get(apiRoutes.doctorInfo, {
        params: {
            ...id ? {d: id} : {},
            ...lang ? {lang} : {},
        }
    });
};
