import _ from 'lodash';

import * as api from '../../api';
import * as selectors from './selectors';
import {fetchTranslation} from "../translations/actions";
import pageNames from "../../constants/PageNames";
// import {generateViewConclusionPrefix} from "./utils";

export const INIT_VIEW_CONCLUSION = 'INIT_VIEW_CONCLUSION';
export const SET_VIEW_CONCLUSION_LOADING = 'SET_VIEW_CONCLUSION_LOADING';
export const SET_VIEW_CONCLUSION_DOCTOR_INFO = 'SET_VIEW_CONCLUSION_DOCTOR_INFO';
export const SET_VIEW_CONCLUSION_CLINIC_INFO = 'SET_VIEW_CONCLUSION_CLINIC_INFO';
export const SET_VIEW_CONCLUSION_ERROR = 'SET_VIEW_CONCLUSION_ERROR';
export const SET_VIEW_CONCLUSION_PREVIEW_DATA = 'SET_VIEW_CONCLUSION_PREVIEW_DATA';
export const SET_VIEW_CONCLUSION_DATA = 'SET_VIEW_CONCLUSION_DATA';

const loadDoctorInfo = ({doctorId, lang}) => async (dispatch, getState) => {
    const {data: doctorInfo} = await api.loadDoctorInfo({id: doctorId, lang});
    dispatch({
        type: SET_VIEW_CONCLUSION_DOCTOR_INFO,
        payload: doctorInfo,
    });
}

const loadClinicInfo = ({clinicId, lang}) => async (dispatch, getState) => {
    const {data: clinicInfo} = await api.loadClinicInfo({cid: clinicId, lang});
    dispatch({
        type: SET_VIEW_CONCLUSION_CLINIC_INFO,
        payload: clinicInfo,
    })
}

const setViewConclusionLoading = (loading) => {
    return {
        type: SET_VIEW_CONCLUSION_LOADING,
        payload: loading,
    }
};

export const loadViewConclusion = ({id, lang, previewData}) => async (dispatch, getState) => {

    dispatch({type: INIT_VIEW_CONCLUSION});
    dispatch(setViewConclusionLoading(true));

    if (previewData) {
        dispatch({
            type: SET_VIEW_CONCLUSION_PREVIEW_DATA,
            payload: previewData,
        });
    }

    try {
        await dispatch(fetchTranslation({
            pageName: pageNames.ViewDoctor,
            // [TODO: Conclusion set lang] не поддерживается пока загрузка текстов для заключения в тон языка
            /*lang,
            prefix: generateViewConclusionPrefix(lang),*/
        }));

        const {data: requestInfo} = await api.loadRequestInfo(id);

        await dispatch(loadDoctorInfo({
            doctorId: requestInfo.doctor.id,
            lang,
        }));

        const doctorInfo = selectors.getConclusionDoctorInfoSelector(getState());

        const clinicId = _.get(requestInfo, ['clinic', 'id']) || _.get(doctorInfo, ['clinic', 'id']);

        if (clinicId) {
            await dispatch(loadClinicInfo({clinicId, lang}));
        }

        dispatch({
            type: SET_VIEW_CONCLUSION_DATA,
            payload: {
                data: requestInfo,
                lang,
            }
        });
    } catch (err) {
        dispatch({
            type: SET_VIEW_CONCLUSION_ERROR,
            payload: err,
        })
    } finally {
        dispatch(setViewConclusionLoading(false));
    }
}
