import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {FormattedMessage} from "react-intl";

import FileIcon from "../../../../common/FileIcon/FileIcon";
import Button from "../../../../common/Button/Button";
import IfCondition from "../../../../common/IfCondition/IfCondition";

const MedicalRecordListItem = ({className, record, onAdd, onRemove, isAddedList}) => {
    const [isHover, setIsHover] = useState(false);
    const {isSelected, name, ext} = record;
    const showRemoveButton = (isHover && isSelected) || isAddedList;

    const handleMouseEnter = useCallback(() => {
        setIsHover(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setIsHover(false);
    }, []);

    const handleAdd = useCallback(() => {
        if (!isSelected) {
            onAdd && onAdd(record);
        }
    }, [isSelected, onAdd, record]);

    const handleRemove = useCallback(() => {
        if (isSelected) {
            onRemove && onRemove(record);
        }
    }, [isSelected, onRemove, record]);

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={cn("Medical-record-list__item", {
                'Medical-record-list__item--selected': isSelected,
                'Medical-record-list__item--added': isAddedList,
            }, className)}>
            <FileIcon
                className="Medical-record-list__item-icon"
                ext={ext}/>
            <div className="Medical-record-list__item-name">
                <span>{name}</span>
            </div>
            <div className="Medical-record-list__item-action">
                <IfCondition condition={!showRemoveButton}>
                    <Button
                        variant={isSelected ? "gray" : "blue"}
                        disabled={isSelected}
                        viewType="minimal"
                        size="sm"
                        onClick={handleAdd}>
                        <span className="text-uppercase">
                            <FormattedMessage
                                id={isSelected ?
                                    'common.dlg_add_files_from_medical_records.btn_added' :
                                    'common.dlg_add_files_from_medical_records.btn_add_record'
                                }/>
                        </span>
                    </Button>
                </IfCondition>
                <IfCondition condition={showRemoveButton}>
                    <Button
                        variant="ruby"
                        viewType="minimal"
                        size="sm"
                        onClick={handleRemove}>
                        <span className="text-uppercase">
                            <FormattedMessage id={'common.dlg_add_files_from_medical_records.btn_remove_record'}/>
                        </span>
                    </Button>
                </IfCondition>
            </div>
        </div>
    )
}

MedicalRecordListItem.displayName = 'MedicalRecordListItem';
MedicalRecordListItem.propTypes = {
    record: PropTypes.any,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    isAddedList: PropTypes.bool,
};
MedicalRecordListItem.defaultProps = {};

export default MedicalRecordListItem;
