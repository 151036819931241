import React from 'react';
import {useIntl} from "react-intl";
import cn from 'classnames';
import {faTimes, faDownload, faPen} from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "../../../components/common/ButtonIcon/ButtonIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ActionsCell = React.memo((
    {
        row: {original},
        onEditRecord,
        onRemoveRecord,
    }
    ) => {
        const intl = useIntl();
        const {file} = original;

        return (
            <div className="Flex-row Justify-end">
                <ButtonIcon
                    onClick={() => onEditRecord(original)}
                    frameless
                    title={intl.formatMessage({id: 'common.buttons.edit'})}
                    className={cn("Action-icon Action-icon--edit ml-1")}
                    size="lg"
                    icon={faPen}/>
                <a
                    className={cn("Action-icon Action-icon--download ml-1")}
                    href={file} download>
                    <FontAwesomeIcon
                        title={intl.formatMessage({id: 'common.buttons.download'})}
                        size={"lg"}
                        icon={faDownload}/>
                </a>
                <ButtonIcon
                    onClick={() => onRemoveRecord(original)}
                    frameless
                    title={intl.formatMessage({id: 'common.buttons.delete'})}
                    className={cn("Action-icon Action-icon--delete ml-1")}
                    size="lg"
                    icon={faTimes}/>
            </div>
        )
    },
);

export default ActionsCell;
