export function downloadFile(src) {
    const iframeId = 'iframeDownloader';
    let iframe = document.getElementById(iframeId);

    if (iframe === null) {
        iframe = document.createElement('iframe');
        iframe.id = iframeId;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
    }
    iframe.src = src;
}

export function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            resolve({value: reader.result});
        };

        reader.onerror = (error) => {
            reject({error});
        }

        reader.readAsDataURL(file);
    })
}
