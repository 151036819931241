import React from 'react';
import PropTypes from 'prop-types';

export const ViewModalContext = React.createContext(null);

export const ViewModalContextProvider = ({children, ...props}) => {
    return (
        <ViewModalContext.Provider value={props}>
            {children}
        </ViewModalContext.Provider>
    )
}

ViewModalContextProvider.displayName = 'ViewModalContextProvider';
ViewModalContextProvider.propTypes = {
    preventViewLink: PropTypes.bool,
    modalView: PropTypes.bool,
    noSelect: PropTypes.bool,
}
ViewModalContextProvider.defaultProps = {
    preventViewLink: false,
    modalView: true,
    noSelect: true,
}
