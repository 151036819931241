import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import {FORM_ERROR} from "final-form";
import _ from 'lodash';
import {faEye, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";

import './AttachFilesModal.scss';
import Button from '../../common/Button/Button';
import TextBoxField from '../../common/TextBox/TextBoxField';
import CloseButton from '../components/CloseButton/CloseButton';
import {getFilename} from "../../../helpers/Common";
import ButtonIcon from "../../common/ButtonIcon/ButtonIcon";
import useOpenFile from "../../../hooks/useOpenFile";
import {showAddFilesFromMedicalRecordsModal, showAttachFilesModal} from "../../../redux/modal/actions";
import {addMedicalRecords} from "./mutators/add-medical-records.mutator";

const MAX_FILE_SIZE_BYTES = 26214400;
const MAX_FILE_SIZE_MB = (MAX_FILE_SIZE_BYTES / (1024 * 1024)).toFixed(2);
const MAX_FILES = 10;

const AttachFilesModal = ({
                              title,
                              onSubmit,
                              onClose,
                              hideModal,
                              attachments = [],
                          }) => {
    const intl = useIntl();
    const openFile = useOpenFile();
    const dispatch = useDispatch();

    const handleFileChange = useCallback((event, fieldsApi) => {
        const attachedFiles = Array.from(event.target.files);

        if (attachedFiles.length) {
            attachedFiles.forEach(file => fieldsApi.push({
                file,
                name: getFilename(file.name),
            }));
        }

        return true;
    }, []);

    const validateForm = useCallback(({files}) => {
        let error = undefined;

        if (MAX_FILES && files.length > MAX_FILES) {
            return {
                [FORM_ERROR]: intl.formatMessage({id: 'common.dlg_attach_files.error_max_files'}, {max: MAX_FILES}),
            };
        }

        for (let i = 0; i < files.length; i++) {
            if (!files[i].id && files[i].file.size > MAX_FILE_SIZE_BYTES) { // only attached files
                error = _.set(
                    {...error},
                    `files[${i}].name`,
                    intl.formatMessage({id: 'common.dlg_attach_files.error_max_size'}, {max: MAX_FILE_SIZE_MB})
                );
            }
        }

        return error;
    }, [intl]);

    const handleSubmit = useCallback((values) => {
        const errors = validateForm(values);

        if (errors) {
            return errors;
        }

        onSubmit && onSubmit(values.files);
        hideModal();
    }, [hideModal, onSubmit, validateForm]);

    const handleClose = useCallback(() => {
        onClose && onClose();
        hideModal();
    }, [hideModal, onClose]);

    const handleViewFile = useCallback((record) => {
        if (record.id || record.file.result) {
            return openFile(record);
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            record.file.result = reader.result;
            openFile(record);
        };

        reader.onerror = (error) => {
            // TODO handle error, show notification
        }

        reader.readAsDataURL(record.file);
    }, [openFile]);

    const renderFormError = useCallback((formApi) => {
        if (formApi.errors && formApi.errors[FORM_ERROR]) {
            return (
                <div className="Modal-attach-files__form-error">{formApi.errors[FORM_ERROR]}</div>
            )
        }
        return null;
    }, []);

    const handleAddFromMedicalRecords = useCallback(({form, values}) => {
        dispatch(showAddFilesFromMedicalRecordsModal({
            onAddRecords: (records = []) => {
                form.mutators.addMedicalRecords(records);
            },
            onClose: () => {
                dispatch(showAttachFilesModal({
                    title,
                    attachments: values.files,
                    onSubmit,
                }));
            },
        }));
    }, [dispatch, onSubmit, title]);

    return (
        <Form
            mutators={{
                ...arrayMutators,
                addMedicalRecords,
            }}
            validateOnBlur
            initialValues={{
                files: attachments,
            }}
            validate={validateForm}
            onSubmit={handleSubmit}>
            {
                (formApi) => (
                    <form
                        onSubmit={formApi.handleSubmit}
                        noValidate autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off">
                        <CloseButton onClick={handleClose}/>
                        <div className="Modal__header">
                            <div className="Modal__title">{title}</div>
                        </div>
                        <div className="Modal__body">
                            <FieldArray name={'files'}>
                                {
                                    ({fields: fieldsApi}) =>
                                        (
                                            <div>
                                                <div className="mt-2 mb-1 text-align-right">
                                                    <Button
                                                        className={"button--upload"}
                                                        viewType="minimal"
                                                        size="sm">
                                                        <FormattedMessage id={"common.dlg_attach_files.btn_add_from_device"}/>
                                                        <input
                                                            title={""}
                                                            multiple
                                                            type="file"
                                                            value={''}
                                                            accept="application/pdf, image/jpeg, image/png"
                                                            onChange={(event) => handleFileChange(event, fieldsApi)}/>
                                                    </Button>
                                                    <Button
                                                        className={"ml-1"}
                                                        onClick={() => handleAddFromMedicalRecords(formApi)}
                                                        viewType="minimal"
                                                        size="sm">
                                                        <FormattedMessage
                                                            id={"common.dlg_attach_files.btn_add_from_medical_records"}/>
                                                    </Button>
                                                </div>
                                                {renderFormError(formApi)}
                                                <div>
                                                    {
                                                        fieldsApi.map((fieldName, index) => (
                                                            <div className="Flex-row" key={index}>
                                                                <TextBoxField
                                                                    name={`${fieldName}.name`}
                                                                    placeholder={intl.formatMessage({id: 'common.dlg_attach_files.input_file_placeholder'})}
                                                                    fieldClassName="Flex-1"/>
                                                                <ButtonIcon
                                                                    type="button"
                                                                    className="ml-1 mb-1"
                                                                    size="lg"
                                                                    title={intl.formatMessage({id: 'common.dlg_attach_files.tooltip_view'})}
                                                                    onClick={() => handleViewFile(fieldsApi.value[index])}
                                                                    frameless
                                                                    icon={faEye}/>
                                                                <ButtonIcon
                                                                    type="button"
                                                                    className="ruby-color ml-1 mb-1"
                                                                    size="lg"
                                                                    title={intl.formatMessage({id: 'common.dlg_attach_files.tooltip_remove'})}
                                                                    onClick={() => fieldsApi.remove(index)}
                                                                    frameless
                                                                    icon={faTimes}/>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )
                                }
                            </FieldArray>
                        </div>
                        <div className="Modal__footer">
                            <Button
                                type="button"
                                variant="gray"
                                viewType="minimal"
                                onClick={handleClose}>
                                <FormattedMessage id={'common.dlg_attach_files.btn_close'}/>
                            </Button>
                            <Button type="submit">
                                <FormattedMessage id={'common.dlg_attach_files.btn_ok'}/>
                            </Button>
                        </div>
                    </form>
                )
            }
        </Form>
    );
};

export default AttachFilesModal;
