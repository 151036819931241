import {batch} from 'react-redux';
import {push} from 'connected-react-router';
import _ from 'lodash';

import * as api from '../../../api';
import {RequestStatuses, splitRequestStatus} from '../../../constants/RequestStatuses';
import {isConsultation} from '../../../helpers/Request';
import * as requestSelectors from './selectors';
import * as commonSelectors from '../../common/selectors';
import * as userSelectors from '../../user/selectors';
import * as commonActions from '../../common/actions';
import {detectRequestLang, toRequestData} from './utils';

export const REQUEST_PAGE_REQUEST_SELECT_DOCTOR = 'REQUEST_PAGE_REQUEST_SELECT_DOCTOR';
export const REQUEST_PAGE_REQUEST_SELECT_CLINIC = 'REQUEST_PAGE_REQUEST_SELECT_CLINIC';
export const REQUEST_PAGE_REQUEST_HELP_ME = 'REQUEST_PAGE_REQUEST_HELP_ME';
export const REQUEST_PAGE_REQUEST_SAVING = 'REQUEST_PAGE_REQUEST_SAVING';
export const REQUEST_PAGE_REQUEST_SAVED = 'REQUEST_PAGE_REQUEST_SAVED';
export const REQUEST_PAGE_REQUEST_ERROR = 'REQUEST_PAGE_REQUEST_ERROR';
export const REQUEST_PAGE_REQUEST_SET_FORM_DATA = 'REQUEST_PAGE_REQUEST_SET_FORM_DATA';
export const REQUEST_PAGE_REQUEST_UPDATE_FORM_DATA = 'REQUEST_PAGE_REQUEST_UPDATE_FORM_DATA';
export const REQUEST_PAGE_REQUEST_DECLINED = 'REQUEST_PAGE_REQUEST_DECLINED';
export const REQUEST_PAGE_REQUEST_LOADING = 'REQUEST_PAGE_REQUEST_LOADING';
export const REQUEST_PAGE_REQUEST_LOADED = 'REQUEST_PAGE_REQUEST_LOADED';
export const REQUEST_PAGE_REQUEST_CONFIRMED = 'REQUEST_PAGE_REQUEST_CONFIRMED';
export const REQUEST_PAGE_REQUEST_NEW = 'REQUEST_PAGE_REQUEST_NEW';
export const REQUEST_PAGE_REQUEST_SET_COM_LANGS = 'REQUEST_PAGE_REQUEST_SET_COM_LANGS';
export const REQUEST_PAGE_REQUEST_CHANGE_LANG = 'REQUEST_PAGE_REQUEST_CHANGE_LANG';

export const newRequest = (lang) => ({
    type: REQUEST_PAGE_REQUEST_NEW,
    payload: {
        lang,
    },
});

export const selectRequestClinic = (clinic, specialities) => async (dispatch, getState) => {
    const {data: clinicInfo} = await api.loadClinicInfo({cid: clinic.id});
    const userMainLangCode = userSelectors.userMainLangSelector(getState());
    const userComLangs = userSelectors.userComLangsSelector(getState());
    const currentRequestLangCode = requestSelectors.getCurrentLangCommunicationCode(getState());

    const newLang = detectRequestLang({
        userMainLangCode,
        userComLangs,
        consultantLangs: clinicInfo.langs,
        consultantComLangs: clinicInfo.lang_com,
    });

    batch(() => {
        dispatch({
            type: REQUEST_PAGE_REQUEST_SELECT_CLINIC,
            payload: {
                ...clinicInfo,
                ...clinic,
                specialities,
            },
        });
        if (newLang !== currentRequestLangCode) {
            dispatch({
                type: REQUEST_PAGE_REQUEST_CHANGE_LANG,
                payload: newLang,
            });
        }
    });
};

export const selectRequestDoctor = (doctor) => async (dispatch, getState) => {
    const specs = doctor.specs;
    const specialities = commonSelectors.getSpecialitiesSelector(getState()) || [];
    const {data: doctorInfo} = await api.loadDoctorInfo({id: doctor.id});
    const userMainLangCode = userSelectors.userMainLangSelector(getState());
    const userComLangs = userSelectors.userComLangsSelector(getState());
    const currentRequestLangCode = requestSelectors.getCurrentLangCommunicationCode(getState());

    const newLang = detectRequestLang({
        userMainLangCode,
        userComLangs,
        consultantLangs: doctorInfo.langs,
        consultantComLangs: doctorInfo.lang_com,
    });

    batch(() => {
        dispatch({
            type: REQUEST_PAGE_REQUEST_SELECT_DOCTOR,
            payload: {
                doctor: {
                    ...doctorInfo,
                    id: doctor.id,
                    ...doctor,
                },
                specialities: specialities.filter(s => specs.includes(s.label)),
            },
        });
        if (newLang !== currentRequestLangCode) {
            dispatch({
                type: REQUEST_PAGE_REQUEST_CHANGE_LANG,
                payload: newLang,
            });
        }
    });
};

export const setRequestHelpMe = (country, specialities) => async (dispatch, getState) => {
    const {data: {lang_com}} = await api.loadClinicComLang({countryId: country});
    const userMainLangCode = userSelectors.userMainLangSelector(getState());
    const userComLangs = userSelectors.userComLangsSelector(getState());
    const currentRequestLangCode = requestSelectors.getCurrentLangCommunicationCode(getState());

    const newLang = detectRequestLang({
        userMainLangCode,
        userComLangs,
        consultantLangs: lang_com,
    });

    batch(() => {
        dispatch({
            type: REQUEST_PAGE_REQUEST_SET_COM_LANGS,
            payload: lang_com,
        });
        dispatch({
            type: REQUEST_PAGE_REQUEST_HELP_ME,
            payload: {
                country,
                specialities,
            },
        });
        if (newLang !== currentRequestLangCode) {
            dispatch({
                type: REQUEST_PAGE_REQUEST_CHANGE_LANG,
                payload: newLang,
            });
        }
    });
};

export const setFormData = (data) => ({
    type: REQUEST_PAGE_REQUEST_SET_FORM_DATA,
    payload: data,
});

export const updateFormData = (data) => ({
    type: REQUEST_PAGE_REQUEST_UPDATE_FORM_DATA,
    payload: data,
});

export const saveRequest = () => async (dispatch, getState) => {
    const data = requestSelectors.getRequestData(getState());
    const id = requestSelectors.getRequestId(getState());
    const currentLangCommunication = requestSelectors.getCurrentLangCommunication(getState());

    dispatch({type: REQUEST_PAGE_REQUEST_SAVING});
    try {
        const formData = toRequestData(data, currentLangCommunication);

        // костыль добавить пустые поля иначе падает на сервере
        if (!_.get(formData, ['reason', data.lang])) {
            _.set(formData, ['reason', data.lang], '');
        }
        if (!_.get(formData, ['description', data.lang])) {
            _.set(formData, ['description', data.lang], '');
        }

        if (id) {
            await api.saveRequest(id, formData);
        } else {
            await api.createRequest(formData);
        }
    } catch (err) {
        dispatch({
            type: REQUEST_PAGE_REQUEST_ERROR,
            payload: err,
        });
        throw err;
    }
    dispatch({type: REQUEST_PAGE_REQUEST_SAVED});
};

export const sendRequest = () => async (dispatch, getState) => {
    const data = requestSelectors.getRequestData(getState());
    const id = requestSelectors.getRequestId(getState());
    const currentLangCommunication = requestSelectors.getCurrentLangCommunication(getState());

    dispatch({type: REQUEST_PAGE_REQUEST_SAVING});
    try {
        const formData = toRequestData(data, currentLangCommunication);

        await api.sendRequest(id, formData);
    } catch (err) {
        dispatch({
            type: REQUEST_PAGE_REQUEST_ERROR,
            payload: err,
        });
        throw err;
    }
    dispatch({type: REQUEST_PAGE_REQUEST_SAVED});
};

export const cancelRequest = () => async (dispatch, getState) => {
    const id = requestSelectors.getRequestId(getState());

    try {
        await api.cancelRequest(id);
        dispatch({
            type: REQUEST_PAGE_REQUEST_DECLINED,
        });
    } catch (err) {
        throw err;
    }
};

export const confirmRequest = () => async (dispatch, getState) => {
    const id = requestSelectors.getRequestId(getState());

    try {
        await api.confirmRequest(id);
        dispatch({
            type: REQUEST_PAGE_REQUEST_CONFIRMED,
        });
    } catch (err) {
        throw err;
    }
};

export const payRequest = () => async (dispatch, getState) => {
    const id = requestSelectors.getRequestId(getState());

    await api.payRequest(id);
};

export const loadRequest = (id) => async (dispatch, getState) => {
    dispatch({type: REQUEST_PAGE_REQUEST_LOADING});

    try {
        let {data: request} = await api.loadRequestInfo(id);
        const rootStatus = splitRequestStatus(request.status);

        if (isConsultation(request.status)) {
            dispatch(push(`/main/requests/consultation/${id}`));
            return;
        }

        if (request.status === RequestStatuses.Draft) {
            request.helpMe = !request.doctor && !request.clinic;
        } else {
            request.helpMe = rootStatus === RequestStatuses.HelpMe_1;
        }

        if (!request.doctor && !request.clinic) {
            if (request.country) {
                const {data: {lang_com}} = await api.loadClinicComLang({countryId: request.country});
                dispatch({
                    type: REQUEST_PAGE_REQUEST_SET_COM_LANGS,
                    payload: lang_com,
                })
            } else {
                await dispatch(commonActions.fetchLanguages());
                dispatch({
                    type: REQUEST_PAGE_REQUEST_SET_COM_LANGS,
                    payload: commonSelectors.getLanguagesSelector(getState()),
                })
            }
        }

        if (request.doctor) {
            const {data: doctor} = await api.loadDoctorInfo({id: request.doctor.id});
            request.doctor = {
                langs: doctor.langs,
                languages: doctor.languages,
                lang_com: doctor.lang_com,
                id: request.doctor.id,
                ...request.doctor,
            };
        } else {
            request.doctor = null;
        }

        if (request.clinic) {
            const {data: clinic} = await api.loadClinicInfo({cid: request.clinic});
            request.clinic = {
                ...clinic,
                id: request.clinic,
            };
        } else {
            request.clinic = null;
        }

        if (request.specialities) {
            await dispatch(commonActions.fetchSpecialities());
            request.specialities = request.specialities
                .map(id => commonSelectors.getSpecialityByIdSelector(getState(), id))
                .filter(Boolean);
        }

        dispatch({
            type: REQUEST_PAGE_REQUEST_LOADED,
            payload: {
                id,
                request,
            },
        });
    } catch (err) {
        dispatch({
            type: REQUEST_PAGE_REQUEST_ERROR,
            payload: err,
        });
        throw err;
    }
};
