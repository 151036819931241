import Immutable from 'seamless-immutable';
import {
    SET_MEDICAL_RECORD_TABLE_PAGE_LOADING,
    SET_MEDICAL_RECORD_TABLE_PAGE_RECORDS,
    SET_MEDICAL_RECORD_TABLE_PAGE_ERROR,
} from "./actions";

const INITIAL_STATE = {
    loading: false,
    records: [],
    all_count: 0,
    all_pages: 0,
    conc_count: 0, // conc_count
    conc_pages: 0, // conc_pages
    files_count: 0,
    files_pages: 0,
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MEDICAL_RECORD_TABLE_PAGE_LOADING:
            return Immutable.merge(state, {
                loading: action.payload,
                error: null,
            });
        case SET_MEDICAL_RECORD_TABLE_PAGE_RECORDS:
            const {list = [], ...rest} = action.payload;
            return Immutable.merge(state, {
                records: list,
                ...rest
            });
        case SET_MEDICAL_RECORD_TABLE_PAGE_ERROR:
            return Immutable.merge(state, {error: action.payload});
        default:
            return state;
    }
}
