import {createSelector} from 'reselect';
import _ from 'lodash';
import Immutable from 'seamless-immutable';

import {getCommunicationLanguagesSelector, getCountriesSelector} from '../../common/selectors';
import {rootConsultationPageSelector} from '../selectors';
import {RequestStatuses} from "../../../constants/RequestStatuses";

export const rootConsultationSelector = createSelector(
    rootConsultationPageSelector,
    page => page.consultation,
);

export const getConsultationId = createSelector(
    rootConsultationSelector,
    consultation => consultation.id,
);

export const getConsultationData = createSelector(
    rootConsultationSelector,
    getCountriesSelector,
    (consultation, countries) => {
        if (!consultation.data) {
            return {};
        }
        return {
            ...consultation.data,
            countryInfo: countries.find(c => c.id === consultation.data.country),
        }
    },
);

export const getConsultationReason = createSelector(
    getConsultationData,
    (data) => {
        if (!data) {
            return null;
        }
        return _.get(data, ['reason', data.lang], '');
    },
);

export const getConsultationFiles = createSelector(
    rootConsultationSelector,
    consultation => {
        return Immutable.asMutable(_.get(consultation, 'data.files', []));
    },
);

export const getConsultationConclusionData = createSelector(
    getConsultationData,
    (consultation) => {
        const {lang} = consultation;

        return {
            ...consultation.conclusion,
            lang,
        }
    },
);

export const isConsultationLoading = createSelector(
    rootConsultationSelector,
    consultation => consultation.loading,
);

export const isAllowChatting = createSelector(
    getConsultationData,
    (consultation) => {
        return consultation.status !== RequestStatuses.End;
    },
);

export const isConsultationCompleted = createSelector(
    getConsultationData,
    (consultation) => {
        return consultation.status === RequestStatuses.End;
    },
);

export const isConsultationError = createSelector(
    rootConsultationSelector,
    consultation => !consultation.error,
);

export const getConsultationCommunicationLanguage = createSelector(
    getConsultationData,
    getCommunicationLanguagesSelector,
    (consultation = {}, languages = []) => {
        return languages.find(l => l.code === consultation.lang)
    },
);

export const getConsultationCommunicationLanguageCode = createSelector(
    getConsultationCommunicationLanguage,
    lang => {
        return _.get(lang, 'code', '');
    },
);

const isMedicalConclusionFile = file => file.is_conclusion_file && file.is_saved_in_medical_records;

export const isUnsavedMedicalConclusionFile = file => file.is_conclusion_file && !file.is_saved_in_medical_records;

export const isConclusionFileSavedSelector = createSelector(
    getConsultationFiles,
    files => {
        return files.some(file => isMedicalConclusionFile(file));
    },
);

/*
    if files contain conclusion file (file.conc is true)
    and one hasn't been saved yet (file.med is false)
    and file type is conclusion (77) (may skip, additinal check)
*/
export const getUnsavedMedicalConclusionFile = createSelector(
    getConsultationFiles,
    files => {
        return files.find(file => isUnsavedMedicalConclusionFile(file));
    },
);


