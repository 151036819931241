import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faPen} from "@fortawesome/free-solid-svg-icons";
import {useIntl} from "react-intl";

const ActionsCell = React.memo(({
                                    removeTranslate,
                                    editTranslate,
                                    row: {original: {id}}
                                }) => {
    const intl = useIntl();

    return (
        <div className="Flex-row Justify-end">
            <FontAwesomeIcon
                onClick={() => removeTranslate(id)}
                title={intl.formatMessage({id: 'common.buttons.delete'})}
                className="ruby-color Action-icon"
                size="lg"
                icon={faTimes}/>
            <FontAwesomeIcon
                onClick={() => editTranslate(id)}
                title={intl.formatMessage({id: 'common.buttons.edit'})}
                className="gray-text-color Action-icon ml-1"
                size="lg"
                icon={faPen}/>
        </div>
    )
});

export default ActionsCell;
