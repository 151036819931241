import React, {useCallback, useContext} from 'react';
import _ from 'lodash';
import {FormattedMessage, useIntl} from "react-intl";

import './RequestReplacement.scss';
import {useSelector} from 'react-redux';
import Button from '../../../../components/common/Button/Button';
import Separator from '../../../../components/common/Separator/Separator';
import {InfoPanel} from '../../../../components/InfoPanel/InfoPanel';
import {CommentTypes} from '../../../../constants/CommentTypes';
import {useResetWindowScroll} from '../../../../hooks/useResetWindowScroll';
import * as requestSelectors from '../../../../redux/requestPage/request/selectors';
import {useRequestCurrentLangCommunication} from '../../hooks/useRequestCurrentLangCommunication';
import {RequestDoctorCard} from '../RequestDoctorCard/RequestDoctorCard';
import {RequestInfo} from '../../../../components/request/RequestInfo/RequestInfo';
import {RequestManagerContext} from '../RequestManager/RequestManagerContext';
import {RequestNote} from '../RequestNote/RequestNote';
import {RequestPageHeader} from '../RequestPageHeader/RequestPageHeader';
import {SidePanel} from '../../../../components/SidePanel/SidePanel';
import DeclineRequestButton from "../common/DeclineRequestButton";

export const RequestReplacement = () => {
    const requestManager = useContext(RequestManagerContext);
    const requestData = useSelector(requestSelectors.getRequestData);
    const currentLang = useRequestCurrentLangCommunication();
    const intl = useIntl();

    useResetWindowScroll();

    const handleAccept = useCallback(() => {
        requestManager.confirmReplacement();
    }, [requestManager]);

    return (
        <React.Fragment>
            <div className="Request-page__main Request-replacement-page">
                <RequestPageHeader title={intl.formatMessage({id: 'request.request_title'})} />
                <div className="Request-page__body">
                    <h2 className="Request-page__title ruby-color">
                        <FormattedMessage id={"request.replacement.decline_title"} />
                    </h2>
                    <RequestDoctorCard
                        isConsultTranslate={!!_.get(requestData.doctor, ['consult', 'translate'], false)}
                        doctor={requestData.doctor || {}}
                        isSelectable={false} />
                    <h2 className="Request-page__title mt-3">
                        <FormattedMessage id={"request.replacement.replace_title"} />
                    </h2>
                    {
                        requestData.doctor_new &&
                        <RequestDoctorCard
                            isConsultTranslate={!!_.get(requestData.doctor_new, ['consult', 'translate'], false)}
                            doctor={requestData.doctor_new || {}}
                            isSelectable={false} />
                    }
                    <RequestNote
                        type={CommentTypes.Accepted}
                        text={_.get(requestData.text, requestData.lang)} />
                    <Separator className="mt-2" />
                    <RequestInfo data={requestData} currentLang={currentLang} />
                    <Separator className="mt-3" />
                    <div className="Flex-row Justify-center Align-center mt-5 mb-4">
                        <DeclineRequestButton />
                        <Button
                            className="ml-2"
                            onClick={handleAccept}>
                            <FormattedMessage id={"request.buttons.confirm"} />
                        </Button>
                    </div>
                </div>
            </div>
            <SidePanel className="Request-replacement-page__side-panel">
                <InfoPanel
                    title={intl.formatMessage({id: 'request.replacement.panel_title'})}
                    message={intl.formatMessage({id: 'request.replacement.panel_message'})}
                />
            </SidePanel>
        </React.Fragment>
    )
};
