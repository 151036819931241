import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";

import FileUploaderField from '../../../../../../components/common/FileUploader/FileUploaderField';
import TextBoxField from '../../../../../../components/common/TextBox/TextBoxField';

const RequestDocumentItem = React.memo(({translations, fieldName, onRemove}) => {
    const intl = useIntl()

    return (
        <div className="Request-document-manager__item Flex-row Align-center">
            <FileUploaderField
                editable={false}
                name={`${fieldName}.file`}
                className="mr-2 mb-1"
                viewBtnText={translations.viewBtnText}
                editBtnText={translations.editBtnText}
                deleteBtnText={translations.deleteBtnText} />
            <TextBoxField
                name={`${fieldName}.label`}
                placeholder={translations.placeholder}
                fieldClassName="Flex-1" />
            <FontAwesomeIcon
                title={intl.formatMessage({id: "common.tooltips.remove_file"})}
                size="lg"
                onClick={onRemove}
                icon={faTimes}
                className="Request-document-manager__remove-document mb-1" />
        </div>
    );
});

RequestDocumentItem.propTypes = {
    translations: PropTypes.shape({
        placeholder: PropTypes.string,
        viewBtnText: PropTypes.string,
        editBtnText: PropTypes.string,
        deleteBtnText: PropTypes.string,
    }),
    fieldName: PropTypes.string.isRequired,
    onRemove: PropTypes.func,
};
RequestDocumentItem.defaultProps = {
    translations: {},
};
RequestDocumentItem.displayName = 'RequestDocumentItem';

export default RequestDocumentItem;
