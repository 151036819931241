import Immutable from 'seamless-immutable';
import {
    SET_COMMON_COUNTRIES,
    SET_COMMON_SPECIALITIES,
    SET_COMMON_CURRENCIES,
    SET_COMMON_DOCTORS,
    SET_COMMON_LANGUAGES,
    SET_COMMON_TIMEZONES,
    SET_COMMON_GENDERS,
    SET_COMMON_DEPARTMENTS,
    SET_COMMON_CLINICS,
    SET_COMMON_COMMUNICATION_LANGUAGES,
    SET_COMMON_FILE_TYPES,
    SET_COMMON_MEDICAL_FILE_TYPES,
    SET_UNREAD_CHAT_MESSAGES,
} from './actions';

const INITIAL_STATE = {
    countries: [],
    specialities: [],
    currencies: [],
    languages: [],
    timezones: [],
    genders: [],
    doctors: [],
    departments: [],
    clinics: [],
    comm_languages: [],
    fileTypes: [],
    medicalFileTypes: [],
    consultationUnreadChatMessages: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_COMMON_COMMUNICATION_LANGUAGES:
            return Immutable.merge(state, { comm_languages: action.payload });
        case SET_COMMON_COUNTRIES:
            return Immutable.merge(state, { countries: action.payload });
        case SET_COMMON_SPECIALITIES:
            return Immutable.merge(state, { specialities: action.payload });
        case SET_COMMON_CURRENCIES:
            return Immutable.merge(state, { currencies: action.payload });
        case SET_COMMON_LANGUAGES:
            return Immutable.merge(state, { languages: action.payload });
        case SET_COMMON_TIMEZONES:
            return Immutable.merge(state, { timezones: action.payload });
        case SET_COMMON_GENDERS:
            return Immutable.merge(state, { genders: action.payload });
        case SET_COMMON_DEPARTMENTS:
            return Immutable.merge(state, { departments: action.payload });
        case SET_COMMON_CLINICS:
            return Immutable.merge(state, { clinics: action.payload });
        case SET_COMMON_FILE_TYPES:
            return Immutable.merge(state, { fileTypes: action.payload });
        case SET_COMMON_MEDICAL_FILE_TYPES:
            return Immutable.merge(state, { medicalFileTypes: action.payload });
        case SET_COMMON_DOCTORS:
            return Immutable.merge(state, { doctors: action.payload });
        case SET_UNREAD_CHAT_MESSAGES:
            return Immutable.merge(state, { consultationUnreadChatMessages: action.payload });
        default:
        return state;
    }
}
