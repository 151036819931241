import * as api from '../../api';
import * as langSelectors from '../lang/selectors';
import * as commonSelectors from './selectors';
import FileTypeList, {UserRoleToFileType} from "../../constants/FileTypeList";
import {ROLES} from "../../constants/Roles";

export const SET_COMMON_COUNTRIES = 'SET_COMMON_COUNTRIES';
export const SET_COMMON_SPECIALITIES = 'SET_COMMON_SPECIALITIES';
export const SET_COMMON_CURRENCIES = 'SET_COMMON_CURRENCIES';
export const SET_COMMON_LANGUAGES = 'SET_COMMON_LANGUAGES';
export const SET_COMMON_TIMEZONES = 'SET_COMMON_TIMEZONES';
export const SET_COMMON_GENDERS = 'SET_COMMON_GENDERS';
export const SET_COMMON_DEPARTMENTS = 'SET_COMMON_DEPARTMENTS';
export const SET_COMMON_CLINICS = 'SET_COMMON_CLINICS';
export const SET_COMMON_COMMUNICATION_LANGUAGES = 'SET_COMMON_COMMUNICATION_LANGUAGES';
export const SET_COMMON_FILE_TYPES = 'SET_COMMON_FILE_TYPES';
export const SET_COMMON_MEDICAL_FILE_TYPES = 'SET_COMMON_MEDICAL_FILE_TYPES';
export const SET_COMMON_DOCTORS = 'SET_COMMON_DOCTORS';

export const SET_UNREAD_CHAT_MESSAGES = 'SET_UNREAD_CHAT_MESSAGES';

export const fetchCountries = (params) => async (dispatch, getState) => {
    const entities = commonSelectors.getCountriesSelector(getState());
    if (entities.length) {
        return;
    }
    try {
        const response = await api.loadCountries(params);
        dispatch({
            type: SET_COMMON_COUNTRIES,
            payload: response.data
        });
    } catch (err) {
    }
};

export const fetchSpecialities = () => async (dispatch, getState) => {
    const entities = commonSelectors.getSpecialitiesSelector(getState());
    if (entities.length) {
        return;
    }
    try {
        const response = await api.loadSpecialities();
        dispatch({
            type: SET_COMMON_SPECIALITIES,
            payload: response.data
        });
    } catch (err) {
    }
};

export const fetchCurrencies = () => async (dispatch, getState) => {
    const entities = commonSelectors.getCurrenciesSelector(getState());
    if (entities.length) {
        return;
    }
    try {
        const response = await api.loadCurrencies();
        dispatch({
            type: SET_COMMON_CURRENCIES,
            payload: response.data
        });
    } catch (err) {
    }
};

export const fetchLanguages = () => async (dispatch, getState) => {
    const entities = commonSelectors.getLanguagesSelector(getState());
    if (entities.length) {
        return;
    }
    try {
        const response = await api.loadLanguages();
        dispatch({
            type: SET_COMMON_LANGUAGES,
            payload: response.data
        });
    } catch (err) {
    }
};

export const fetchCommunicationLanguages = () => async (dispatch, getState) => {
    const entities = commonSelectors.getCommunicationLanguagesSelector(getState());
    if (entities.length) {
        return;
    }
    try {
        const response = await api.loadCommunicationLanguages();
        dispatch({
            type: SET_COMMON_COMMUNICATION_LANGUAGES,
            payload: response.data
        });
    } catch (err) {
    }
};

export const fetchTimezones = () => async (dispatch, getState) => {
    const entities = commonSelectors.getTimezonesSelector(getState());
    if (entities.length) {
        return;
    }
    try {
        const response = await api.loadTimezones();
        dispatch({
            type: SET_COMMON_TIMEZONES,
            payload: response.data
        });
    } catch (err) {
    }
};

export const fetchGenders = () => async (dispatch, getState) => {
    const entities = commonSelectors.getGendersSelector(getState());
    if (entities.length) {
        return;
    }
    try {
        const response = await api.loadGenders();
        dispatch({
            type: SET_COMMON_GENDERS,
            payload: response.data
        });
    } catch (err) {
    }
};

export const fetchDepartments = (lang) => async (dispatch, getState) => {
    try {
        const currentLang = langSelectors.currentLanguageSelector(getState());

        const response = await api.loadDepartments({lang: lang || currentLang});
        dispatch({
            type: SET_COMMON_DEPARTMENTS,
            payload: response.data
        });
    } catch (err) {
    }
};

export const fetchClinics = (lang) => async (dispatch, getState) => {
    try {
        const currentLang = langSelectors.currentLanguageSelector(getState());

        const response = await api.loadClinics({lang: lang || currentLang});
        dispatch({
            type: SET_COMMON_CLINICS,
            payload: response.data
        });
    } catch (err) {
    }
};

export const fetchFileTypes = () => async (dispatch, getState) => {
    const type = UserRoleToFileType[ROLES.PATIENT];
    const entities = commonSelectors.getFileTypesSelector(getState());
    if (entities.length) {
        return;
    }

    try {
        const res = await api.loadFileTypeList(type);
        dispatch({
            type: SET_COMMON_FILE_TYPES,
            payload: res.data
        });
    } catch (err) {
        console.error(err);
    }
};


export const fetchMedicalFileTypes = () => async (dispatch, getState) => {
    const entities = commonSelectors.getMedicalFileTypesSelector(getState());
    if (entities.length) {
        return;
    }

    try {
        const res = await api.loadFileTypeList(FileTypeList.Medical);
        dispatch({
            type: SET_COMMON_MEDICAL_FILE_TYPES,
            payload: res.data
        });
    } catch (err) {
        console.error(err);
    }
};

export const fetchDoctors = (lang) => async (dispatch, getState) => {
    try {
        const currentLang = langSelectors.currentLanguageSelector(getState());

        const response = await api.loadDoctors({lang: lang || currentLang});
        dispatch({
            type: SET_COMMON_DOCTORS,
            payload: response.data
        });
    } catch (err) {
    }
};


export const fetchUnreadChatMessages = () => async (dispatch) => {
    const response = await api.getUnreadConsultationsCount();
    
    dispatch({
        type: SET_UNREAD_CHAT_MESSAGES,
        payload: response.data
    });
};