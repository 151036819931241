import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {type DayModifiers} from 'react-day-picker';
import cn from 'classnames';

// Include the locale utils designed for moment
import MomentLocaleUtils, {
	// @ts-ignore
	formatDate,
	// @ts-ignore
	parseDate,
} from 'react-day-picker/moment';

import {YearMonthControl} from './ui/YearMonthControl';
import './DatePicker.scss';

export interface Props<T = HTMLInputElement> {
	name: string;
	className?: string;
	label?: string;
	labelClass?: string;
	inputClass?: string;
	inline?: boolean;
	error?: string | boolean;
	value?: Date;
	locale: string;
	format?: string;
	placeholder?: string;
	dayPickerProps?: {};
	onBlur?: (event?: React.FocusEvent<T>) => void;
	onFocus?: (event?: React.FocusEvent<T>) => void;
	onChange?(
		day: Date,
		DayModifiers?: DayModifiers,
		dayPickerInput?: DayPickerInput,
	): void;
}

const CURRENT_DATE = new Date();

const DatePicker = React.forwardRef<DayPickerInput, Props>(
	(
		{
			name,
			className,
			onChange,
			locale = 'en',
			format = 'L',
			dayPickerProps,
			label,
			labelClass,
			error,
			inline,
			inputClass,
			placeholder,
			onFocus,
			onBlur,
			...props
		},
		ref,
	) => {
		const value = props.value;
		//
		const isErrorBool = typeof error === 'boolean';
		const classes = cn(className, {
			'Health-date-picker': true,
			row: inline,
			[`Health-date-picker--error${isErrorBool ? '-frame' : ''}`]: error,
		});

		const inputClasses = cn('Health-date-picker__input', inputClass);
		const labelClasses = cn('Health-date-picker__label', labelClass);

		const captionElement = React.useCallback(
			({date, locale, localeUtils}) => (
				<YearMonthControl
					date={date}
					locale={locale}
					localeUtils={localeUtils}
					onChange={onChange}
				/>
			),
			[onChange],
		);
		return (
			<div className={classes}>
				{label && (
					<label className={labelClasses} htmlFor={name}>
						{label}
					</label>
				)}
				<div className={inputClasses}>
					<DayPickerInput
						value={value}
						onDayChange={onChange}
						formatDate={formatDate}
						parseDate={parseDate}
						placeholder={
							placeholder ||
							`${formatDate(new Date(), format, locale)}`
						}
						inputProps={{
							name,
							autoComplete: 'off',
							onFocus,
							onBlur,
						}}
						dayPickerProps={{
							...dayPickerProps,
							locale: locale,
							month: value || CURRENT_DATE,
							captionElement,
							localeUtils: MomentLocaleUtils,
						}}
						ref={ref}
					/>
					{!isErrorBool && error && (
						<span className="Health-form__field-error">
							{error}
						</span>
					)}
				</div>
			</div>
		);
	},
);

DatePicker.displayName = 'HealthDatePicker';

export default React.memo(DatePicker);
