import {faSearch} from '@fortawesome/free-solid-svg-icons';
import React, {useCallback, useEffect} from 'react';
import {Form} from 'react-final-form';
import {FormattedHTMLMessage, FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector, batch} from 'react-redux';

import './RequestConsultantsFilter.scss';
import Button from '../../../../../../components/common/Button/Button';
import SelectBoxField from '../../../../../../components/common/SelectBox/SelectBoxField';
import Separator from '../../../../../../components/common/Separator/Separator';
import TagListField from '../../../../../../components/common/TagList/TagListField';
import TagList from '../../../../../../components/common/TagList/TagList';
import TextBoxField from '../../../../../../components/common/TextBox/TextBoxField';
import * as consultantSelectors from '../../../../../../redux/requestPage/consultant/selectors';
import * as consultantActions from '../../../../../../redux/requestPage/consultant/actions';
import * as commonActions from '../../../../../../redux/common/actions';
import * as commonSelectors from '../../../../../../redux/common/selectors';
import {SidePanel} from '../../../../../../components/SidePanel/SidePanel';
import FilterPanel from "../../../../../../components/FilterPanel/FilterPanel";

export const RequestConsultantsFilter = () => {
    const dispatch = useDispatch();
    const filter = useSelector(consultantSelectors.getConsultantFilterSelector);
    const countries = useSelector(commonSelectors.getCountriesSelector);
    const specialities = useSelector(commonSelectors.getSpecialitiesSelector);
    const langs = useSelector(commonSelectors.getCommunicationLanguagesSelector);
    const intl = useIntl();

    useEffect(() => {
        dispatch(commonActions.fetchCountries());
    }, [dispatch]);

    useEffect(() => {
        dispatch(commonActions.fetchSpecialities());
    }, [dispatch]);

    useEffect(() => {
        dispatch(commonActions.fetchCommunicationLanguages());
    }, [dispatch]);

    const handleSubmit = useCallback((values) => {
        batch(() => {
            dispatch(consultantActions.setConsultantFilter({
                query: values.query,
                specialities: values.specialities,
                country: values.country,
                langs: values.langs,
            }));
            dispatch(consultantActions.updateConsultants());
        });

    }, [dispatch]);

    const handleReset = useCallback((form) => {
        form.reset();
        if (filter.query !== null || filter.specialities.length || filter.country || filter.langs.length) {
            batch(() => {
                dispatch(consultantActions.setConsultantFilter({
                    query: null,
                    specialities: [],
                    country: null,
                    langs: [],
                }));
                dispatch(consultantActions.updateConsultants());
            });
        }
    }, [dispatch, filter.country, filter.langs.length, filter.query, filter.specialities.length]);

    return (
        <SidePanel className="Request-page__consultants-filter">
            <FilterPanel
                className="Request-page__consultants-filter--sticky"
                title={intl.formatMessage({id: "request.filter.title"})}>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={filter}>
                    {
                        (form) => (
                            <form className="Flex-column Flex-1" onSubmit={form.handleSubmit} noValidate
                                  autoComplete="off">
                                <div className="Health-SidePanel__body">

                                    <TextBoxField
                                        labelClass="mb-1"
                                        label={intl.formatMessage({id: 'request.filter.search_label'})}
                                        icon={faSearch}
                                        placeholder={intl.formatMessage({id: 'request.filter.search_placeholder'})}
                                        name="query"/>
                                    <Separator/>
                                    <SelectBoxField
                                        searchable
                                        labelClassName="text-uppercase mb-1"
                                        placeholder={intl.formatMessage({id: 'request.filter.country_placeholder'})}
                                        label={intl.formatMessage({id: 'request.filter.country_label'})}
                                        options={countries}
                                        name='country'/>
                                    <Separator/>
                                    <TagListField
                                        inline={false}
                                        showArrow
                                        minQueryLength={0}
                                        placeholder={intl.formatMessage({id: 'request.filter.lang_placeholder'})}
                                        labelClass="text-uppercase mb-1"
                                        label={intl.formatMessage({id: 'request.filter.lang_label'})}
                                        shouldExcludeTags
                                        labelField="label"
                                        suggestions={langs}
                                        handleFilterSuggestions={TagList.FilterSuggestions.withStartWordFilter}
                                        renderSuggestion={TagList.RenderSuggestions.simpleRender}
                                        name='langs'/>
                                    <Separator/>
                                    <TagListField
                                        minQueryLength={0}
                                        showArrow
                                        placeholder={intl.formatMessage({id: 'request.filter.specialities_placeholder'})}
                                        labelClass="text-uppercase mb-1"
                                        label={intl.formatMessage({id: 'request.filter.specialities_label'})}
                                        shouldExcludeTags
                                        labelField="label"
                                        suggestions={specialities}
                                        handleFilterSuggestions={TagList.FilterSuggestions.withStartWordFilter}
                                        renderSuggestion={TagList.RenderSuggestions.simpleRender}
                                        name='specialities'/>
                                    <Separator/>
                                </div>
                                <div className="Health-SidePanel__actions Flex-column Justify-center mt-2">
                                    <Button
                                        disabled={form.pristine && !form.dirty}
                                        variant="gray"
                                        viewType="minimal"
                                        type="submit">
                                        <FormattedMessage id="common.filter.ok"/>
                                    </Button>
                                    <Button
                                        onClick={() => handleReset(form.form)}
                                        className="mt-1"
                                        variant="ruby"
                                        viewType="frameless">
                                        <FormattedMessage id="common.filter.clear"/>
                                    </Button>
                                </div>
                                <div className="Request-page__consultants-filter_help mt-2">
                                    <FormattedHTMLMessage id="request.filter.help" />
                                </div>
                            </form>
                        )
                    }
                </Form>
            </FilterPanel>
        </SidePanel>
    )
};
