import {SESSION_KEY} from "../../constants/Common";

export const SET_SESSION = 'SET_SESSION';

export const setSession = (session) => (dispatch) => {
    localStorage.setItem(SESSION_KEY, session);
    dispatch({
        type: SET_SESSION,
        payload: session
    });
};
