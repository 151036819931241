import React, {useMemo} from "react";
import AutoSizer from 'react-virtualized-auto-sizer';
import {useSelector} from "react-redux";

import './PreviewConclusionModal.scss';
import CloseButton from "../components/CloseButton/CloseButton";
import PdfViewer from "../../PdfViewer/PdfViewer";
import {getPdfConclusionUrl} from "../../../helpers/UrlHelper";

const PreviewConclusionModal = ({requestId, lang, hideModal}) => {
    const phpsession = useSelector(state => state.session);
    const handleClose = React.useCallback(() => {
        hideModal();
    }, [hideModal]);

    const pdfUrl = useMemo(() => {
        return getPdfConclusionUrl(requestId, lang, phpsession);
    }, [lang, phpsession, requestId]);

    return (
        <React.Fragment>
            <CloseButton onClick={handleClose}/>
            <AutoSizer disableHeight>
                {
                    ({width}) => (
                        <PdfViewer
                            onClose={handleClose}
                            width={width}
                            fileUrl={pdfUrl}/>
                    )
                }
            </AutoSizer>
        </React.Fragment>
    )
}

PreviewConclusionModal.displayName = 'PreviewConclusionModal';

export default PreviewConclusionModal;
