import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const TabPanel = React.forwardRef(({tabId, className, children, active}, ref) => {
    if (!active) return null;

    return (
        <div
            className={cn(className, 'Health-tabs__panel')}
            ref={ref}
            role="tabpanel">
            {children}
        </div>
    );
});

TabPanel.propTypes = {
    tabId: PropTypes.any,
    active: PropTypes.bool,
};
TabPanel.defaultProps = {};
TabPanel.displayName = 'HealthTabPanel';

export default TabPanel;
