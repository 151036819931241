import React, {useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";

import {isConsultation} from "../../../../../../helpers/Request";
import {RequestStatuses} from "../../../../../../constants/RequestStatuses";
import OverviewNotification from "../OverviewNotification/OverviewNotification";
import OverviewNotificationMeta from "../OverviewNotificationMeta/OverviewNotificationMeta";
import ViewDoctorLink from "../../../../../../components/Links/ViewDoctorLink";
import ViewClinicLink from "../../../../../../components/Links/ViewClinicLink";

const OverviewNotificationPatient = ({data}) => {
    const intl = useIntl();

    const pathname = useMemo(() => {
        let pathname;

        if (isConsultation(data.status)) {
            pathname = `/main/requests/consultation/${data.id}`;
        } else {
            pathname = `/main/requests/${data.id}`;
        }

        // eslint-disable-next-line default-case
        switch (data.status) {
            case RequestStatuses.Active:
            case RequestStatuses.NeedTranslate:
                pathname = `/main/requests/consultation/${data.id}/chat`;
                break;
            case RequestStatuses.End:
                pathname = `/main/requests/consultation/${data.id}/conclusion`;
                break;
        }

        return pathname;
    }, [data.id, data.status]);

    const renderHeader = useCallback(() => {
        let text;

        switch (data.status) {
            case RequestStatuses.Accepted_5_1:
            case RequestStatuses.Accepted_5_3:
                text = intl.formatMessage({id: "overview.request_notification.title_confirm_5_1"});
                break;
            case RequestStatuses.Accepted_5_2:
                text = intl.formatMessage({id: "overview.request_notification.title_confirm_5_2"});
                break;
            case RequestStatuses.Active:
                text = intl.formatMessage({id: "overview.request_notification.title_active"});
                break;
            case RequestStatuses.End:
                text = intl.formatMessage({id: "overview.request_notification.title_end"});
                break;
            default:
                text = data.status;
        }
        return (
            <Link to={pathname} tabIndex={-1}>
                {text}
            </Link>
        )
    }, [data.status, intl, pathname]);

    const entity = useMemo(() => {
        if (data.doctor) {
            return {
                type: 'doctor',
                id: data.doctor.id,
                url: data.doctor.url,
                short: data.doctor.short,
                name: data.doctor.name,
            };
        }
        if (data.clinic) {
            return {
                type: 'clinic',
                id: data.clinic.id,
                url: data.clinic.url,
                short: data.clinic.short,
                name: data.clinic.name,
            };
        }
        return {
            type: 'none',
            id: null,
            url: '/images/logo_plus.png',
            short: 'BH',
            name: 'best2health',
        };
    }, [data.clinic, data.doctor]);

    const renderContent = useCallback(() => {
        return (
            <p>{data.reason}</p>
        )
    }, [data.reason]);

    const variant = useMemo(() => {
        switch (data.status) {
            case RequestStatuses.Accepted_5_1:
            case RequestStatuses.Accepted_5_2:
            case RequestStatuses.Accepted_5_3:
                return 'green';
            case RequestStatuses.Active:
                return 'blue';
            default:
                return 'gray'
        }
    }, [data.status]);

    const name = useCallback(() => {
        if (entity.type === 'doctor') {
            return (
                <ViewDoctorLink id={entity.id} from={undefined}>
                    {entity.name}
                </ViewDoctorLink>
            )
        }
        if (entity.type === 'clinic') {
            return (
                <ViewClinicLink id={entity.id} from={undefined}>
                    {entity.name}
                </ViewClinicLink>
            )
        }
        return (
            <span>{entity.name}</span>
        )
    }, [entity.id, entity.name, entity.type]);

    const renderMeta = useCallback(() => {
        return <OverviewNotificationMeta
            date={data.date_edit}
            pathname={pathname}
            name={name}
            id={data.id}/>
    }, [data.date_edit, data.id, name, pathname]);

    return (
        <OverviewNotification
            className={"Overview-notification--patient"}
            variant={variant}
            renderMeta={renderMeta}
            renderContent={renderContent}
            renderHeader={renderHeader}
            name={entity.name}
            shortName={entity.short}
            avatarUrl={entity.url}/>
    )
}

OverviewNotificationPatient.displayName = 'OverviewNotificationPatient'
OverviewNotificationPatient.propTypes = {
    data: PropTypes.object,
}

export default OverviewNotificationPatient;
