import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {RequestFileItem} from "./components/RequestFileItem/RequestFileItem";

const RequestFileList = ({className, list, lang, onClickFile, renderItem}) => {
    return (
        <div className={cn('Request-file-list', className)}>
            {
                list.map((file, index) => {
                    if (renderItem) {
                        return renderItem({
                            onClick: onClickFile,
                            lang,
                            key: index,
                            file,
                        });
                    }
                    return (
                        <RequestFileItem
                            onClick={onClickFile}
                            lang={lang}
                            key={index}
                            file={file} />
                    )
                })
            }
        </div>
    )
}

RequestFileList.propTypes = {
    lang: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
    onClickFile: PropTypes.func,
    renderItem: PropTypes.func,
};
RequestFileList.defaultProps = {
    list: [],
};
RequestFileList.displayName = 'RequestFileList';

export default RequestFileList;
