import {forOwn, isNil, isUndefined} from 'lodash';

import apiRoutes from "../constants/ApiRoutes";

export const getDomain = (host) => host ? host.substring(host.lastIndexOf('.') + 1) : '';
export const getRootUrl = () => `http://best2health.${getDomain(window.location.hostname)}`;
export const getB2BUrl = () => `http://b2b.best2health.${getDomain(window.location.hostname)}`;

export const getWebSocketUrl = (port = 8085) => {
    if (window.location.hostname === 'localhost') {
        return 'wss://best2health.ru:8085';
    }
    const parts = window.location.hostname.split('.').splice(1, 2);
    return `wss://${parts.join('.')}:${port}`;
};

export const getPdfConclusionUrl = (requestId, lang, phpsession) => {
    return `https://lk.best2health.ru/api${apiRoutes.conclusionPdf}?${setUrlSearchParams('?', {
        id: requestId,
        lang,
        PHPSESSID: phpsession,
    })}`;
};

export const getUrlSearchParams = (searchString) => {
    const search = new URLSearchParams(searchString);
    const params = {};

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of search) {
        params[key] = value;
    }

    return params;
};

export function setUrlSearchParams(searchString = '?', values = {}) {
    const search = new URLSearchParams(searchString);

    forOwn(values, (value, key) => {
        if (value === '' || isNil(value) || isUndefined(value)) {
            search.delete(key); // remove empty value from search query
        } else {
            search.set(key, value);
        }
    });

    return search.toString();
}

export const buildBackLink = (history, match) => {
    const {state} = history.location;

    if (state) {
        return `${state.pathname}${state.search}`
    } else {
        return match.path.split('/').slice(0, 3).join('/');
    }
};

export function parseUrl(url) {
    const a = document.createElement('a');

    a.href = url;
    return a.href;
}
