import Immutable from 'seamless-immutable';
import {
	SET_REQUESTS_TABLE_PAGE_LOADING,
	SET_REQUESTS_TABLE_PAGE_CONSULTS,
} from './actions';

const INITIAL_STATE = {
	loading: false,
	requests: [],
	all_count: 0,
	all_pages: 0,
	draft_count: 0,
	draft_pages: 0,
	active_count: 0,
	active_pages: 0,
	end_count: 0,
	end_pages: 0,
	cancel_count: 0,
	cancel_pages: 0,
	consult_count: 0,
	consult_pages: 0,
	//
	active_income_count: 0,
	cancel_income_count: 0,
	new_income_count: 0,
	process_income_count: 0,
	//
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_REQUESTS_TABLE_PAGE_LOADING:
			return Immutable.merge(state, {loading: action.payload});
		case SET_REQUESTS_TABLE_PAGE_CONSULTS:
			const {list = [], ...rest} = action.payload;
			return Immutable.merge(state, {
				requests: list,
				...rest,
			});
		default:
			return state;
	}
};
