import React from 'react';

import {COLUMN_NAMES} from './constants/columns';
import HeaderCell from "../../components/common/SimpleTable/cells/HeaderCell";
import ActionsCell from "./components/ActionsCell";

export const defineColumns = [
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.id"/>),
        accessor: COLUMN_NAMES.ID,
        width: 6,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.for_role"/>),
        accessor: COLUMN_NAMES.FOR_ROLE_TEXT_ID,
        width: 8,
        sortable: false,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.status"/>),
        accessor: COLUMN_NAMES.STATUS,
        width: 5,
        sortable: false,
    },
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.param"/>),
        accessor: COLUMN_NAMES.PARAM,
        width: 30,
        sortable: true,
    },

];

export const actionColumns = [
    {
        Header: (props) => (<HeaderCell {...props} titleKey="common.table.actions"/>),
        accessor: COLUMN_NAMES.ACTIONS,
        width: 100,
        align: 'right',
        widthFixed: true,
        Cell: (props) => <ActionsCell {...props}/>
    }
]