import React from 'react';
import {FadeLoader} from 'react-spinners';

interface Props {
    className?: string;
    content: string
}

export const SpinnerBody = ({content}: Props) => (
    <div className="Health-spinner__content">
    <span className="Health-spinner__message">{content}</span>
    <div className='Health-spinner__block'>
        <FadeLoader
            color="#6592ab"/>
    </div>
    </div>
);