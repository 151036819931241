

export enum Lang {
    RU = 'ru',
    EN = 'en',
    /*
    FR = 'fr',
    ES = 'es',
    DE = 'de',
    IT = 'it',
    HE = 'he'
    */
}

export type LangDictionary = Record<Lang, string>;