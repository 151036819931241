import React from 'react';
import cn from 'classnames';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import type {IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core'

import './ButtonIcon.scss';

export interface Props {
    className?: string;
    tabIndex?: number;
    icon: IconProp;
    color?: string;
    size?: SizeProp;
    title: string;
    frameless: boolean;
    disabled?: boolean;
    type?: 'button' | 'reset' | 'submit' | null,
    iconClass?: string,
    onClick?: React.MouseEventHandler;
}

const ButtonIcon = ({
    className, 
    icon, 
    color, 
    size = 'sm',
    title, 
    frameless = false, 
    disabled = false,
    type = 'button', 
    iconClass, 
    ...props
}: Props) => {
    return (
        <button
            className={cn('Health-button-icon', className, {
                'Health-button-icon--frameless': frameless,
                'Health-button-icon--disabled': disabled,
            })}
            type={type}
            disabled={disabled}
            {...props}>
            <FontAwesomeIcon
                className={iconClass}
                title={title}
                size={size}
                color={color}
                icon={icon} />
        </button>
    )
}

ButtonIcon.displayName = 'HealthButtonIcon'

export default ButtonIcon;
