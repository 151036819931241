import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const PaginationItem = React.memo(({selected, children, page, onClick, className, breakView, ...props}) => {
    return (
        <li className={
            cn(
                className,
                'Pagination__item', selected && 'Pagination__item--selected',
                breakView && 'Pagination__item-break'
            )}>
            <a
                {...props}
                onClick={onClick}
                role="button"
                className="Pagination__item-link"
                tabIndex="0"
                aria-label={!children ? page : ''}
                aria-current={!children ? 'page' : ''}
                onKeyPress={onClick}
            >
                {children ? children : page}
            </a>
        </li>
    );
});

PaginationItem.propTypes = {
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    page: PropTypes.number,
    breakView: PropTypes.bool
};

PaginationItem.defaultProps = {
    selected: false,
    breakView: false
};

PaginationItem.displayName = 'PaginationItem';

export default PaginationItem;
