import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {debounce} from 'lodash';

import './AddFilesFromMedicalRecordsModal.scss';
import CloseButton from "../components/CloseButton/CloseButton";
import Button from "../../common/Button/Button";
import QuickFilter from "../../QuickFilter/QuickFilter";
import TextBox from "../../common/TextBox/TextBox";
import Pagination from "../../Pagination/Pagination";
import MedicalRecordList from "./components/MedicalRecordList/MedicalRecordList";
import Spinner from "../../Spinner/Spinner";
import * as medicalRecordsActions from '../../../redux/medicalRecordsModal/actions';
import * as medicalRecordsSelectors from '../../../redux/medicalRecordsModal/selectors';
import IfCondition from "../../common/IfCondition/IfCondition";

const TABS = {
    All: 'all',
    Added: 'added',
}

const MAX_FILES = 15;
const SEARCH_TIMEOUT = 300; // ms

const AddFilesFromMedicalRecordsModal = ({
                                             onAddRecords,
                                             onClose,
                                             hideModal,
                                         }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [tabActive, setTabActive] = useState(TABS.All);
    const [filter, setFilter] = useState({
        currentPage: 0,
        query: null,
    });
    const records = useSelector(medicalRecordsSelectors.getRecordsSelector);
    const selectedRecords = useSelector(medicalRecordsSelectors.getSelectedRecordsSelector);
    const isLoading = useSelector(medicalRecordsSelectors.isLoadingSelector);
    const pagination = useSelector(medicalRecordsSelectors.getPaginationSelector);
    const isValid = selectedRecords.length < MAX_FILES;

    useEffect(() => {
        dispatch(medicalRecordsActions.initMedicalRecordsModal());
    }, [dispatch]);

    useEffect(() => {
        dispatch(medicalRecordsActions.loadMedicalRecords(filter));
    }, [dispatch, filter]);

    const tabs = useMemo(() => {
        return [
            {
                id: TABS.All,
                label: intl.formatMessage({id: 'common.dlg_add_files_from_medical_records.tab_all'}),
            },
            {
                id: TABS.Added,
                label: intl.formatMessage({id: 'common.dlg_add_files_from_medical_records.tab_added'}),
            },
        ];
    }, [intl]);

    const handleClose = useCallback(() => {
        onClose && onClose();
        hideModal();
    }, [hideModal, onClose]);

    const handleAdd = useCallback(() => {
        onAddRecords && onAddRecords(selectedRecords);
        hideModal();
    }, [hideModal, onAddRecords, selectedRecords]);

    const handleChangeTab = useCallback((tab) => {
        setTabActive(tab);
    }, []);

    const handleAddItem = useCallback((item) => {
        dispatch(medicalRecordsActions.selectMedicalRecord(item));
    }, [dispatch]);

    const handleRemoveItem = useCallback((item) => {
        dispatch(medicalRecordsActions.unselectMedicalRecord(item));
    }, [dispatch]);

    const activeRecords = useMemo(() => {
        if (tabActive === TABS.All) {
            return records;
        }

        return selectedRecords;
    }, [records, selectedRecords, tabActive]);

    const handlePaginationChange = useCallback((value) => {
        if (filter.currentPage !== value) {
            setFilter(prev => ({
                ...prev,
                currentPage: value,
            }))
        }
    }, [filter.currentPage]);

    const searchChange = useCallback(debounce((value) => {
        const query = value.trim();

        if (filter.query !== query) {
            setFilter(prev => ({
                ...prev,
                query: query.length ? query : null,
            }))
        }
    }, SEARCH_TIMEOUT), [filter.query]);

    const handleQueryChange = useCallback((event) => {
        searchChange(event.target.value);
    }, [searchChange]);

    return (
        <React.Fragment>
            <CloseButton onClick={handleClose}/>
            <div className="Modal__header">
                <div className="Modal__title">
                    <FormattedMessage id={'common.dlg_add_files_from_medical_records.title'}/>
                </div>
                <div className="Modal__subheader">
                    <div className="Flex-row">
                        <QuickFilter
                            onChange={handleChangeTab}
                            className="Flex-1"
                            withoutCount
                            items={tabs}
                            activeItem={tabActive}
                        />
                        <div className="Flex-row Align-center Align-self-start">
                            <span className="mr-1">
                                <FormattedMessage id={'common.dlg_add_files_from_medical_records.search_label'}/>
                            </span>
                            <TextBox
                                onChange={handleQueryChange}
                                icon={faSearch}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Modal__body">
                <IfCondition condition={!isValid}>
                    <span className="Medical-record__error">
                        <FormattedMessage
                            values={{max: MAX_FILES}}
                            id="common.dlg_add_files_from_medical_records.error_max_files"/>
                    </span>
                </IfCondition>
                <div className="Medical-record-list__wrapper">
                    <MedicalRecordList
                        isAddedList={tabActive === TABS.Added}
                        onAddItem={handleAddItem}
                        onRemoveItem={handleRemoveItem}
                        records={activeRecords}/>
                </div>
                <IfCondition condition={tabActive === TABS.All}>
                    {
                        pagination.pages > 1 &&
                        <div className="Pagination__wrapper">
                            <Pagination
                                onChange={handlePaginationChange}
                                initialPage={filter.currentPage}
                                forcePage={filter.currentPage}
                                goToLabel={intl.formatMessage({id: 'common.pages_goto'})}
                                totalCount={2}/>
                        </div>
                    }
                </IfCondition>
                <Spinner isLoading={isLoading}/>
            </div>
            <div className="Modal__footer">
                <Button
                    type="button"
                    variant="gray"
                    viewType="minimal"
                    onClick={handleClose}>
                    <FormattedMessage id={'common.dlg_add_files_from_medical_records.btn_cancel'}/>
                </Button>
                <Button
                    disabled={!isValid}
                    onClick={handleAdd}>
                    <FormattedMessage id={'common.dlg_add_files_from_medical_records.btn_add'}/>
                </Button>
            </div>
        </React.Fragment>
    )
}

AddFilesFromMedicalRecordsModal.displayName = 'AddFilesFromMedicalRecordsModal';

export default AddFilesFromMedicalRecordsModal;
