import React from 'react';
import {FormattedDate, FormattedTime} from 'react-intl';

const DateTimeCell = (props) => {
    return (
        <div className={props.className}>
            {
                props.cell.value &&
                <React.Fragment>
                    <FormattedDate value={props.cell.value.split(' ')[0]} />&nbsp;
                    <FormattedTime
                        hour12={false}
                        hour="2-digit"
                        minute="numeric"
                        value={props.cell.value} />
                </React.Fragment>
            }
        </div>
    )
};

export default DateTimeCell;
