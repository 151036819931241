import {createSelector} from 'reselect';
import {rootRequestPageSelector} from '../selectors';

export const rootManagerSelector = createSelector(
    rootRequestPageSelector,
    (root) => root.manager,
);

export const getCurrentStepSelector = createSelector(
    rootManagerSelector,
    (manager) => manager.currentStep,
);

export const getBackLinkSelector = createSelector(
    rootManagerSelector,
    (manager) => manager.backLink,
);
